import React, { useState, useRef } from 'react';
import { QrReader } from 'react-qr-reader';
import { apicallback } from '../../callbacks/index';
import { useStoreState } from 'easy-peasy';
import { openToasterAlert } from '../../components/alert/Index';
import { List } from 'react-content-loader';
import { useNavigate } from 'react-router-dom';
const Index = (props) => {
  const authorization = useStoreState((actions) => actions.authorization);
  const [alertInfoCamera, setAlertInfoCamera] = useState(true);
  const history = useNavigate();
  const intervalRef = useRef(null);
  const getUtilities = async (utility_id) => {
    setAlertInfoCamera(false);
    const response = await apicallback(
      null,
      '/getUtilityById',
      {
        utility_id: utility_id,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      await verifyUser(response.data.collection, utility_id);
    } else {
      setAlertInfoCamera(true);
      openToasterAlert(
        'error',
        "We don't recognize that QR Codes. Please ensure you're scanning the correct QR Code."
      );
    }
  };

  const verifyUser = async (collection_id, utility_id) => {
    const response = await apicallback(
      null,
      '/verifyUser',
      {
        collection_id: collection_id,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      intervalRef.current = setInterval(async () => {
        await getVerificationStatus(collection_id, utility_id);
      }, 10000);
    }
  };

  const getVerificationStatus = async (collection_id, utility_id) => {
    const response = await apicallback(
      null,
      '/getVerificationStatus',
      { collection_id: collection_id },
      'private',
      authorization && authorization.token
    );
    if (response.status == 'success') {
      if (response.verification_status != 'pending') {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        if (response.verification_status == 'false') {
          setAlertInfoCamera(true);
          openToasterAlert(
            'error',
            'Your connected wallet is not eligible for this utility.'
          );
        } else {
          props.closeModel(false);
          history(`/utility/detail/${utility_id}`);
        }
      }
    } else {
      setAlertInfoCamera(true);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      if (response.verification_status == 'false') {
        openToasterAlert(
          'error',
          'Your connected wallet is not eligible for this utility.'
        );
      }
    }
  };

  return (
    <>
      <div className="alertUIModal">
        <div className="position-relative mb-4">
          <h3 className="text-center mb-3">
            {alertInfoCamera ? 'Scan QR Code' : 'Processing...'}
          </h3>
          <button
            onClick={() => {
              props.closeModel(false);
              clearInterval(intervalRef.current);
              intervalRef.current = null;
            }}
            type="button"
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
        <hr />
        {alertInfoCamera ? (
          <div style={{ width: '100%' }}>
            <QrReader
              constraints={{
                facingMode: 'environment',
              }}
              scanDelay={1000}
              videoId="video"
              onResult={(result, error) => {
                if (!!result) {
                  getUtilities(result?.text);
                }
                if (!!error) {
                  //console.info(error);
                }
              }}
              style={{ width: '100%' }}
            />
            {/* <p className="text-center">
              <small>Provide us camera access to scan QR Codes.</small>
            </p> */}
          </div>
        ) : (
          <>
            <p className="my-4">Please wait while we process this utility.</p>
            <List style={{ width: '100%' }} />
          </>
        )}
      </div>
    </>
  );
};

export default Index;

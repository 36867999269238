import React, { useState, useEffect, use } from 'react';
import { Link } from 'react-router-dom';
import { img1 } from '../../../assets/Index';
const Utility = (props) => {
  const [events, setEvents] = useState([]);
  const [loader, setLoader] = useState(true);
  const [visible, setVisible] = useState(8);
  const [alertInfo, setAlertInfo] = useState(false);
  const [info, setInfo] = useState(null);
  useEffect(async () => {
    props.handelTierId('');
    props.handelTierName('');
    await getUtilities();
  }, []);
  const getUtilities = async () => {
    const response = await props.apicallback(
      null,
      '/getUtilityByCollection',
      {
        collection_id: props.collection_id,
      },
      'private',
      props.authorization && props.authorization.token
    );
    if (response && response.status == 'success') {
      setEvents(response.data);
    }
    setLoader(false);
  };

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 8);
  };
  // const goToOtherTab = () => {
  //   document.querySelector('.react-tabs-utility').click();
  // };

  const handelClick = (type, obj) => {
    props.handelTierName(obj?.tier_name || '');
    if (type === 'More Details') {
      setInfo(obj);
      setAlertInfo(true);
    } else if (type == 'Provide Proof') {
      props.handelTierId(obj.deployed_tier_id);
      document.querySelector('.react-tabs-utility').click();
    }
    return;
  };

  return (
    <>
      {alertInfo ? (
        <div className="alertUIModal">
          <div className="position-relative mb-4">
            <h3 className="text-center mb-3">More Details</h3>
            <button
              onClick={() => setAlertInfo(false)}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <hr />
          <p className="text-center mt-3">{info.description}</p>
          {info.external_link ? (
            <button
              className="sc-button style-4 btn-block mt-4"
              onClick={() => props.openUrl(info.external_link)}
            >
              More details
            </button>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      {loader ? (
        props.facebookLoader
      ) : events.length > 0 ? (
        <ul className="bid-history-list">
          {events.slice(0, visible).map((item, index) => (
            <li
              key={index}
              data-aos="flip-up"
              data-aos-duration={1000 * (index + 1)}
            >
              <div className="content">
                <div className="client">
                  <div className="sc-author-box style-2">
                    <div className="author-avatar">
                      <Link
                        to={
                          item.type === 'Token Redeem'
                            ? `/utility/detail/${item.id}`
                            : '#'
                        }
                        onClick={() => {
                          handelClick(item.type, item);
                        }}
                        state={{ userNfts: props.userNfts }}
                      >
                        <img
                          src={item.image ? item.image : img1}
                          alt={item.title}
                          className="avatar"
                        />
                      </Link>
                    </div>
                    <div className="author-infor">
                      <div className="name">
                        <h6>
                          {' '}
                          <Link
                            to={
                              item.type === 'Token Redeem'
                                ? `/utility/detail/${item.id}`
                                : '#'
                            }
                            onClick={() => handelClick(item.type, item)}
                            state={{ userNfts: props.userNfts }}
                          >
                            {' '}
                            {item.title}{' '}
                          </Link>
                        </h6>
                      </div>
                      <span>{item?.tier_name}</span>
                      <span className="time">{item.date_text}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        ''
      )}
      {visible < events.length && (
        <div className="btn-activity mg-t-10 center">
          <Link
            to="#"
            id="load-more"
            className="sc-button loadmore fl-button pri-3"
            onClick={showMoreItems}
          >
            <span>Load More</span>
          </Link>
        </div>
      )}
    </>
  );
};

export default React.memo(Utility);

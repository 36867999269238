import React, { useState, useEffect } from 'react';
import DeletePortfolio from '../../components/models/DeletePortfolio';
import { useNavigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { loadingGif } from '../../assets/Index';
// Using an ES6 transpiler like Babel
import Slider from 'react-rangeslider';
// To include the default styles
import 'react-rangeslider/lib/index.css';
const UpdateModal = (props) => {
  const history = useNavigate();
  const [collectionList, setCollections] = useState([]);
  const [range, setRange] = useState(0);
  const [collection_id, setCollectionId] = useState('');
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [nft_id, setNftId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState('');
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    if (props.selectedObj != null) {
      setCollectionId(props.selectedObj.collection);
      setNftId(props.selectedObj.id);
      setName(props.selectedObj.name);
      setRange(props.selectedObj.rarity);
      setSearchValue(props.selectedObj.collection_name);
    }
  }, []);

  const getCollections = async (name = '') => {
    const response = await props.apicallback(
      null,
      '/searchAllCollections',
      {
        name: name,
        offset: 0,
        limit: '',
      },
      '',
      props.authorization && props.authorization.token
    );
    if (response && response.status == 'success') {
      return response.data;
    } else {
      return [];
    }
  };
  const handelSearch = async (name) => {
    const collection = await getCollections(name);
    setCollections(collection);
    if (name !== '') {
      if (collection.length > 0) {
        if (collection.length > 5) {
          document.getElementById('search-dropdown').style.height = '320px';
          document.getElementById('search-dropdown').style.overflow = 'auto';
        } else {
          document.getElementById('search-dropdown').style.height = 'auto';
        }
      } else {
        setCollectionId('');
      }
    } else {
      setCollections([]);
    }
  };

  const handelForm = async () => {
    if (collection_id == '' || name == '') {
      setError(
        collection_id ? 'NFT Name required.' : 'Please choose NFT Collection.'
      );
    } else {
      setLoading(true);
      const response = await props.apicallback(
        null,
        '/createUpdatePortfolioNft',
        {
          collection_id: collection_id,
          name: name,
          rarity: range,
          nft_id: nft_id,
        },
        'private',
        props.authorization && props.authorization.token
      );
      if (response && response.status == 'success') {
        setLoading(false);
        props.closeModal();
      } else {
        alert(response.status);
        setLoading(false);
      }
    }
  };

  const onHandelDelete = async () => {
    setLoading(true);
    const response = await props.apicallback(
      null,
      '/deletePortfolioNft',
      {
        nft_id: nft_id,
      },
      'private',
      props.authorization && props.authorization.token
    );
    if (response && response.status == 'success') {
      setLoading(false);
      props.closeModal();
      setModalShow(false);
    } else {
      setLoading(false);
      setModalShow(false);
    }
  };

  return (
    <div className="addNftPortfolioModal">
      <div className="w-100 position-relative">
        <h3 className="text-center mb-3">
          {props.selectedObj ? 'Edit NFT Portfolio' : 'Add NFT to Portfolio'}
        </h3>
        <button
          onClick={() => props.closeModal()}
          type="button"
          className="btn-close"
          aria-label="Close"
        ></button>
        <div className="row mt-5">
          <div className="col-12 mb-4">
            <div className="position-relative">
              <input
                className={`form-control`}
                placeholder="NFT Collection (type to search)"
                value={searchValue}
                onChange={(e) => {
                  handelSearch(e.target.value, false);
                  setSearchValue(e.target.value);
                }}
              />
              {collectionList.length > 0 ? (
                <ul className="search-dropdown" id="search-dropdown">
                  {collectionList.length > 0
                    ? collectionList.map((item, key) => (
                        <li
                          key={key}
                          onClick={() => {
                            setCollectionId(item ? item.collection_id : '');
                            setSearchValue(item.collection_name);
                            setCollections([]);
                          }}
                        >
                          <img
                            src={item.image_url ? item.image_url : loadingGif}
                            alt={item.collection_name}
                          />
                          <h5 >
                            <Link to="#">{item.collection_name}</Link>
                          </h5>
                        </li>
                      ))
                    : ''}
                </ul>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="col-12 mb-4">
            <input
              value={name}
              className="form-control"
              placeholder="NFT token ID"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-12">
            <div className="rarity-slider">
              <h4 style={{ color: '#848484' }} className="mb-5">
                NFT Rarity{' '}
                <span className="text-main">
                  {range > 0
                    ? parseFloat(range).toFixed(2).replace('.00', '') + 'x'
                    : ''}
                </span>
              </h4>
              <div className="row">
                <div className="col-2 pr-0">
                  <span className="slider-1">Floor</span>
                </div>
                <div className="col-8 px-0">
                  <Slider
                    min={0}
                    max={5}
                    step="0.01"
                    value={
                      range > 0
                        ? parseFloat(range).toFixed(2).replace('.00', '')
                        : range
                    }
                    tooltip={true}
                    onChange={(value) => setRange(value)}
                    tipProps={{ visible: true }}
                  />
                </div>
                <div className="col-2 pl-0">
                  <span className="slider-2">5x</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {error ? (
          <div className="alert alert-danger mt-3" role="alert">
            <b>{error}</b>
          </div>
        ) : (
          ''
        )}
        <button
          className="sc-button style-4 btn-block mt-4 d-flex justify-content-center align-item-center"
          onClick={() => handelForm()}
          disabled={loading}
        >
          {loading ? <Bars color="#ffffff" height={30} width={80} /> : 'SUBMIT'}
        </button>

        {props.selectedObj ? (
          <div className="text-center mt-4">
            <Link
              to="#"
              className="text-danger"
              onClick={() => setModalShow(true)}
            >
              <u>
                <h4>Remove NFT</h4>
              </u>
            </Link>
          </div>
        ) : (
          ''
        )}
      </div>
      {modalShow ? (
        <DeletePortfolio
          show={modalShow}
          onHide={() => setModalShow(false)}
          bar={<Bars color="#ffffff" height={30} width={80} />}
          onHandelDelete={() => onHandelDelete()}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default UpdateModal;

import React, { useState, useEffect } from 'react';
import { useWeb3Modal } from '@web3modal/react';
//Connect Etherium Functionality
import { useAccount } from 'wagmi';

const Index = () => {
  //Address
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();

  // On  Web3 wallet connect.
  useEffect(async () => {
    if (address && isConnected) {
      if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        var apns = {
          launchApp: function () {
            window.location = `com.nftsapp://wallet-connect?wallet=${address}`;
            this.timer = setTimeout(this.openWebApp, 500);
          },
        };
      
      } else {
        var apns = {
          launchApp: function () {
            window.location = `app://com.nftchills?wallet=${address}`;
            this.timer = setTimeout(this.openWebApp, 500);
          },
        };
      }
      apns.launchApp();
    } else {
      await open({ route: 'ConnectWallet' });
    }
  }, [address, isConnected]);

  useEffect(async () => {
    try {
      setTimeout(
        function () {
          document.getElementById('header_main').remove();
          document.getElementById('footer').remove();
        }.bind(this),
        400
      );
    } catch (error) {}
  }, []);

  return <></>;
};

export default Index;

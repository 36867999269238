import './video_card.scss';
import {
  circle_clock,
  circle_eye,
  thumb_like,
  video_play,
  video_lock,
  like_fill,
} from '../../assets/Index';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import loadable from '@loadable/component';
const LoginSignUp = loadable(() =>
  import('../../components/models/LoginSignUp')
);
export default function Card(props) {
  const { key, data, authorization } = props;
  const [showModal, setModalShowSignup] = useState(false);
  const history = useNavigate();
  function handelClick(value, id) {
    if (value) {
      history(`/resource/${id}`);
    } else {
      setModalShowSignup(true);
    }
  }

  return (
    <>
      {' '}
      <div
        className="a-card"
        key={key}
        onClick={() =>
          handelClick(
            data.login_required && authorization == false ? false : true,
            data.id
          )
        }
      >
        <div className="image">
          {data.login_required && authorization == false ? (
            <div className="lock">
              <img src={video_lock} alt={data.title} className="mb-3" />
              <span>
              Sign Up or Sign<br/> In to Watch this Video
              </span>
            </div>
          ) : (
            <img src={video_play} className="play" alt={data.title} />
          )}
          <img src={data.cover_image} alt={data.title} />
        </div>
        <div className="info">
          <h4>{data.title}</h4>
          <p>{data.subtitle}</p>
          <div className="sections d-flex align-items-center justify-content-around mt-4">
            <span>
              <img src={data.likes_status ? like_fill : thumb_like} />
              {data.likes}
            </span>
            <span className="border-left-right">
              <img src={circle_clock} />
              {data.duration}
            </span>
            <span>
              <img src={circle_eye} />
              {data.views}
            </span>
          </div>
        </div>
      </div>
      <LoginSignUp
        show={showModal}
        onHide={() => setModalShowSignup(false)}
        text={'Sign Up or Sign In to Watch this Video'}
        url={true}
      />
    </>
  );
}

import { Swiper, SwiperSlide } from 'swiper/react';
import { openUrl } from '../../callbacks/index';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import './slider.scss';
import { Navigation } from 'swiper';

export default function Slider(props) {
  const { list } = props;
  return (
    <>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        className="single-slider"
      >
        {list.map((value, key) => (
          <SwiperSlide key={key}>
            <div className="w-adjust">
              <h3>{value.title}</h3>
              <button
                className="mt-4"
                onClick={() => openUrl(value.button_url)}
              >
                {value.button_text}
              </button>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

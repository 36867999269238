import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { useStoreState } from 'easy-peasy';
import AvatarEditor from 'react-avatar-editor';
//Images
import { noimageselected, coverImg } from '../../assets/Index';
//Compoent
import { apicallback, dataURLtoFile } from '../../callbacks/index';
import FacebookLoader from '../../components/content_loader/FacebookLoader';
import InstagramLoader from '../../components/content_loader/InstagramLoader';
import { openToasterAlert } from '../../components/alert/Index';
const Edit_Profile = () => {
  const authorization = useStoreState((actions) => actions.authorization);
  const [profileimage, setprofileimage] = useState(null);
  const [bannerimage, setbannerimage] = useState(null);
  const [username, setusername] = useState('');
  const [email, setemail] = useState(null);
  const [profile, setprofile] = useState({});
  const [loading, setLoading] = useState(false);
  const [zoom, setZoom] = useState(null);
  const [zoomCover, setZoomCover] = useState(null);
  const editor = useRef(null);
  const editorCover = useRef(null);
  const div = document.getElementById('option-profile');
  useEffect(() => {
    getProfile();
  }, []);

  const onProfileChange = (event) => {
    // setprofileimage(event.target.files[0]);
    let pro = profile;
    pro.profile_image = URL.createObjectURL(event.target.files[0]);
    setprofile(pro);
  };

  const onBannerChange = (event) => {
    //setbannerimage(event.target.files[0]);
    let pro = profile;
    pro.banner_image = URL.createObjectURL(event.target.files[0]);
    setprofile(pro);
  };

  const getProfile = async () => {
    const response = await apicallback(
      null,
      '/getUserInfo',
      {
        user_id: authorization.data.id.toString(),
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      setprofile(response.data);
      setusername(response.data.username);
      setemail(response.data.email);
    } else {
      openToasterAlert('error', response.message);
    }
  };

  const updateProfile = async () => {
    setLoading(true);
    const formData = new FormData();
    // Update the formData object
    formData.append('username', username ? username : '');
    if (profileimage != null) {
      formData.append('profile_image', profileimage);
    }
    if (bannerimage != null) {
      formData.append('banner_image', bannerimage);
    }
    formData.append('user_id', authorization.data.id.toString());
    const response = await apicallback(
      null,
      '/updateUserInfo',
      formData,
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      openToasterAlert('success', `Profile updated successfully.`);
      setprofile(response.data);
      let auth = authorization;
      auth.data.username = response.data.username;
      auth.data.profile_image = response.data.profile_image;
      auth.data.wallet_address = response.data.wallet_address;
      localStorage.setItem('Authorization', JSON.stringify(auth));
      setTimeout(
        async function () {
          if (profile.email != email) {
            await updateEmail();
          } else {
            window.location.reload();
          }
        }.bind(this),
        1500
      );
    } else {
      openToasterAlert('error', response.message);
      setLoading(false);
    }
  };

  const updateEmail = async () => {
    const response = await apicallback(
      null,
      '/updateUserEmail',
      {
        email: email,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      let auth = authorization;
      auth.data.email = response.data.email;
      localStorage.setItem('Authorization', JSON.stringify(auth));
      window.location.reload();
    } else {
      openToasterAlert('error', response.message);
      setLoading(false);
    }
  };

  const imageDrag = () => {
    if (editor) {
      try {
        const canvas = editor.current.getImage();
        if (canvas) {
          const file = dataURLtoFile(
            canvas.toDataURL(),
            `${username ? username : 'image'}.png`
          );
          setprofileimage(file);
        }
      } catch (error) {}
    }
  };
  const imageDragCover = () => {
    if (editorCover) {
      try {
        const canvas = editorCover.current.getImage();
        if (canvas) {
          const file = dataURLtoFile(
            canvas.toDataURL(),
            `${username ? username : 'cover_image'}.png`
          );
          setbannerimage(file);
        }
      } catch (error) {}
    }
  };

  return (
    <div
      className="tf-create-item tf-section"
      style={{ padding: '139px 0 59px' }}
    >
      <div className="themesflat-container">
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-6 col-11 mx-auto">
            {profile && profile.id ? (
              <div className="sc-card-profile text-center">
                <div className="card-media">
                  {zoom ? (
                    <>
                      {' '}
                      <AvatarEditor
                        ref={editor}
                        image={profile.profile_image}
                        width={200}
                        height={200}
                        border={0}
                        color={[0, 0, 0, 0.5]} // RGBA
                        scale={zoom}
                        borderRadius={100}
                        rotate={0}
                        onImageChange={() => imageDrag()}
                        onImageReady={() => imageDrag()}
                      />
                      <input
                        name="scale"
                        type="range"
                        min="1"
                        max="10"
                        step="0.01"
                        value={zoom}
                        onChange={(e) => {
                          setZoom(e.target.value);
                        }}
                      />
                    </>
                  ) : profile.profile_image != null ? (
                    <img
                      id="profileimg"
                      src={profile.profile_image}
                      alt="Add your NFTs to manage your portfolio and estimated real-time portfolio value."
                    />
                  ) : (
                    <img
                      id="profileimg"
                      src={noimageselected}
                      alt="Add your NFTs to manage your portfolio and estimated real-time portfolio value."
                    />
                  )}
                </div>
                <div id="upload-profile" className="position-relative">
                  <Link to="#" className="btn-upload">
                    Upload New Photo{' '}
                  </Link>
                  <input
                    id="tf-upload-img"
                    type="file"
                    name="profile"
                    required=""
                    onChange={(event) => {
                      onProfileChange(event);
                      setZoom(1.2);
                    }}
                    onClick={() => {
                      setZoom(null);
                    }}
                  />
                </div>
              </div>
            ) : (
              <InstagramLoader value={1} coloum={12} center={true} />
            )}
          </div>
          <div className="col-xl-9 col-lg-8 col-md-12 col-12">
            {profile && profile.id ? (
              <div className="form-upload-profile" id>
                <div
                  className="option-profile clearfix position-relative"
                  id="option-profile"
                >
                  {zoomCover ? (
                    <>
                      <AvatarEditor
                        ref={editorCover}
                        image={
                          profile && profile.banner_image
                            ? profile.banner_image
                            : coverImg
                        }
                        width={div ? div.clientWidth : 250}
                        height={175}
                        border={1.2}
                        color={[0, 0, 0, 0.5]} // RGBA
                        scale={zoomCover}
                        borderRadius={0}
                        rotate={0}
                        onImageChange={() => imageDragCover()}
                        onImageReady={() => imageDragCover()}
                      />
                      <input
                        className="w-100"
                        name="scale"
                        type="range"
                        min="1"
                        max="10"
                        step="0.01"
                        value={zoomCover}
                        onChange={(e) => {
                          setZoomCover(e.target.value);
                        }}
                      />
                    </>
                  ) : profile.banner_image ? (
                    <img
                      src={profile.banner_image}
                      alt="Add your NFTs to manage your portfolio and estimated real-time portfolio value."
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <img
                      src={coverImg}
                      alt="Add your NFTs to manage your portfolio and estimated real-time portfolio value."
                      style={{ width: '100%' }}
                    />
                  )}
                  <div
                    id="upload-profile"
                    className="position-absolute uploadFileBtn w-25"
                    style={{ zIndex: '1' }}
                  >
                    <Link to="#" className="btn-upload">
                      {zoomCover ? 'Change cover photo' : 'Upload New Photo'}
                    </Link>
                    <input
                      id="tf-upload-img"
                      type="file"
                      name="profile"
                      required=""
                      onChange={(event) => {
                        onBannerChange(event);
                        setZoomCover(1);
                      }}
                      onClick={() => {
                        setZoomCover(null);
                      }}
                    />
                  </div>
                </div>

                <form action="#" className="form-profile">
                  <div className="form-infor-profile">
                    {/* <div > */}
                    <div className="info-account" style={{ width: '100%' }}>
                      <h4 className="title-create-item">Account info</h4>
                      <fieldset>
                        <h4 className="title-infor-account">Username</h4>
                        <input
                          className="text-lowercase"
                          type="text"
                          placeholder="Username"
                          required
                          value={username}
                          onChange={(e) =>
                            setusername(e.target.value.replace(/ /g, ''))
                          }
                        />
                      </fieldset>

                      <fieldset>
                        <h4 className="title-infor-account">Email</h4>
                        <input
                          type="email"
                          placeholder="Enter your email"
                          required
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </fieldset>
                    </div>
                  </div>
                  <button
                    className="sc-button mg-t-15 d-flex justify-content-center align-item-center"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      updateProfile();
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <Bars color="#ffffff" height={30} width={80} />
                    ) : (
                      'Update Profile'
                    )}
                  </button>
                </form>
              </div>
            ) : (
              <FacebookLoader value={1} coloum={12} center={true} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit_Profile;

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import {
  imgEth,
  imgTrade,
  imgCube,
  ens_icon,
  lnikCube,
} from '../../assets/Index';
//import { StreamChat } from 'stream-chat';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { apicallback, openUrl } from '../../callbacks/index';
import PageTitle from '../../components/pagetitle/PageTitle';
import { IoArrowBackSharp } from '../../components/icons/Index';
import parse from 'html-react-parser';
// import {
//   Chat,
//   Channel,
//   ChannelHeader,
//   MessageInput,
//   MessageList,
//   Thread,
//   Window,
// } from 'stream-chat-react';
// import 'stream-chat-react/dist/css/index.css';
import 'react-tabs/style/react-tabs.css';
import InstagramLoader from '../../components/content_loader/InstagramLoader';
import FacebookLoader from '../../components/content_loader/FacebookLoader';
import Announcements from './components/Announcements';
import EventList from './components/EventList';
import Utility from './components/Utility';
import Verification from './components/Verification';
import { getCollectionById } from '../../apis/Index';
const Index = () => {
  const history = useNavigate();
  const { slug } = useParams();
  ///States
  const [tabsData, setTabsData] = useState({});
  const [channel, setchannel] = useState(null);
  const [userNfts, setUserNfts] = useState([]);
  const [verifyStauts, setVerifyStauts] = useState(false);
  const [verifyMessage, setVerifyMessage] = useState('');
  const [istabsdataloaded, setIsTabsDataLoaded] = useState(false);
  const [tabindex, setTabIndex] = useState([]);
  const [deployed_tier_id, setDeployedTierId] = useState(null);
  const [tier_name, setTierName] = useState(null);
  //Redux
  const authorization = useStoreState((actions) => actions.authorization);
  const [streamToken, setstreamToken] = useState(null);
  const [collectionInfo, setCollectionInfo] = useState(null);
  const selectedTab = useStoreState((state) => state.selectedTab);
  //Action
  const setselectedTab = useStoreActions((actions) => actions.setselectedTab);
  //Keys
  //const chatClient = StreamChat.getInstance('c8t5du4wufqg');
  useEffect(async () => {
    if (authorization == null) {
      history(`/sign-in`);
    }
    await collectionInfoApi();
    goToOtherTab();
  }, []);

  const collectionInfoApi = async () => {
    const response = await getCollectionById({
      collection_id: '',
      collection_slug: slug,
    });
    if (response.status === 'success') {
      setCollectionInfo(response.data);
      await getTabs(response.data.collection_id);
    } else {
      history(`/rewards`);
    }
  };

  // const initChat = async () => {
  //   await generateChatToken();
  //   const col_img = collectionInfo ? collectionInfo.image_url : '';
  //   const col_name = collectionInfo ? collectionInfo.collection_name : '';
  //   chatClient.connectUser(
  //     {
  //       id: `id_${authorization.data.id}`,
  //       name: `${
  //         authorization.data.username
  //           ? authorization.data.username
  //           : 'id_' + authorization.data.id
  //       }`,
  //       image: `${
  //         authorization.data.profile_image
  //           ? authorization.data.profile_image
  //           : ''
  //       }`,
  //     },
  //     JSON.parse(localStorage.getItem('Stream')).toString()
  //   );
  //   let rr = await chatClient.channel(
  //     'livestream',
  //     `collection_${collectionInfo ? collectionInfo.collection_id : ''}`,
  //     {
  //       // add as many custom fields as you'd like
  //       image: col_img,
  //       name: `${col_name} Chat`,
  //     }
  //   );
  //   setchannel(rr);
  // };

  const getTabs = async (id) => {
    const response = await apicallback(
      null,
      '/getZoneTabs',
      {
        collection_id: id,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      setTabsData(response.data);
      let lis = [];
      let keys = Object.keys(response.data);
      keys.forEach((element) => {
        if (response.data[element] == true) {
          lis.push(element);
        }
      });
      setTabIndex(lis);
      // if (response.data && response.data.chat_tab_enabled) {
      //   await initChat();
      // }
      setIsTabsDataLoaded(true);
    } else {
      history(`/rewards`);
    }
  };

  const goToOtherTab = () => {
    const tabName = document.getElementsByClassName('react-tabs__tab');
    tabName[selectedTab].click();
    document.querySelector('.react-tabs-utility-set').click();
  };

  const generateChatToken = async () => {
    const response = await apicallback(
      null,
      '/generateChatToken',
      {},
      'private',
      authorization && authorization.token,
      'get'
    );
    if (response && response.status == 'success') {
      localStorage.setItem('Stream', JSON.stringify(response.data.token));
    }
  };

  return (
    <section className="tf-section today-pick">
      <PageTitle
        meta={{
          title: 'Rewards',
          description:
            'Rewards are exclusive areas where only NFT holders of a collection can access. Rewards provide announcements, events and the ability to utilize NFTs IRL at our participating partner collections.',
        }}
      />
      {collectionInfo ? (
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-md-12">
              <div className="tf-section tf-item-details p-0">
                <div className="themesflat-container px-0 px-sm-4">
                  <div className="mb-5">
                    <Link to="/rewards" className="h4 d-flex">
                      <IoArrowBackSharp className="mr-2" /> Go back
                    </Link>
                  </div>
                  <div className="content-right">
                    <div className="sc-item-details">
                      <h2 className="style2 d-flex">
                        <img
                          width="50px"
                          className="mr-4"
                          style={{ borderRadius: '10px', height: '100%' }}
                          src={collectionInfo ? collectionInfo.image_url : ''}
                          alt={
                            collectionInfo ? collectionInfo.collection_name : ''
                          }
                        />{' '}
                        {collectionInfo ? collectionInfo.collection_name : ''}
                      </h2>
                      {istabsdataloaded ? (
                        <div className="flat-tabs themesflat-tabs">
                          <Tabs>
                            <TabList>
                              {tabsData && tabsData.chat_tab_enabled ? (
                                <Tab
                                  onClick={() => {
                                    setselectedTab(
                                      tabindex.indexOf('chat_tab_enabled')
                                    );
                                  }}
                                  eventKey="Chat"
                                >
                                  Chat
                                </Tab>
                              ) : (
                                ''
                              )}

                              {tabsData && tabsData.details_tab_enabled ? (
                                <Tab
                                  onClick={() =>
                                    setselectedTab(
                                      tabindex.indexOf('details_tab_enabled')
                                    )
                                  }
                                  eventKey="Details"
                                >
                                  Details
                                </Tab>
                              ) : (
                                ''
                              )}
                              {tabsData &&
                              tabsData.announcements_tab_enabled ? (
                                <Tab
                                  onClick={() =>
                                    setselectedTab(
                                      tabindex.indexOf(
                                        'announcements_tab_enabled'
                                      )
                                    )
                                  }
                                  eventKey="Announcements"
                                >
                                  Announcements
                                </Tab>
                              ) : (
                                ''
                              )}
                              {tabsData && tabsData.events_tab_enabled ? (
                                <Tab
                                  onClick={() =>
                                    setselectedTab(
                                      tabindex.indexOf('events_tab_enabled')
                                    )
                                  }
                                  eventKey="Events"
                                >
                                  Events
                                </Tab>
                              ) : (
                                ''
                              )}
                              {tabsData && tabsData.utility_tab_enabled ? (
                                <Tab
                                  className="react-tabs__tab react-tabs-utility-set"
                                  onClick={() =>
                                    setselectedTab(
                                      tabindex.indexOf('utility_tab_enabled')
                                    )
                                  }
                                  verifyStauts={verifyStauts}
                                  eventKey="Utility (Rewards)"
                                  title="Utility (Rewards)"
                                >
                                  Utility (Rewards)
                                </Tab>
                              ) : (
                                ''
                              )}
                              {tabsData && tabsData.proof_tab_enabled ? (
                                <Tab
                                  className="react-tabs__tab react-tabs-utility"
                                  onClick={() =>
                                    setselectedTab(
                                      tabindex.indexOf('proof_tab_enabled')
                                    )
                                  }
                                  is_erc20={collectionInfo.is_erc20}
                                  eventKey="Proof"
                                >
                                  Proof
                                </Tab>
                              ) : (
                                ''
                              )}
                            </TabList>

                            {/* {tabsData && tabsData.chat_tab_enabled ? (
                              <TabPanel>
                                <span id="go-to-chat"></span>
                                {channel ? (
                                  <Chat
                                    client={chatClient}
                                    theme="livestream dark"
                                  >
                                    <Channel channel={channel}>
                                      <Window>
                                        <ChannelHeader />
                                        <MessageList />
                                        <MessageInput />
                                      </Window>
                                      <Thread />
                                    </Channel>
                                  </Chat>
                                ) : (
                                  ''
                                )}
                              </TabPanel>
                            ) : (
                              ''
                            )} */}
                            {tabsData && tabsData.details_tab_enabled ? (
                              <TabPanel>
                                <div className="meta-item">
                                  <div className="left d-none d-sm-block">
                                    {collectionInfo.is_featured ? (
                                      <span className="liked heart wishlist-button mg-l-8"></span>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <div className="right cursor-pointer">
                                    {collectionInfo.external_link ? (
                                      <>
                                        <h5
                                          onClick={() =>
                                            openUrl(
                                              collectionInfo.external_link
                                            )
                                          }
                                        >
                                          <i className="far fa-share-square"></i>{' '}
                                          Launch Website
                                        </h5>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="inner-post mg-t-40 inner-post-member"
                                  // dangerouslySetInnerHTML={{ __html: obj.content }}
                                >
                                  {parse(
                                    collectionInfo
                                      ? collectionInfo.description
                                      : ''
                                  )}
                                </div>

                                <div className="client-infor sc-card-product mt-5 mb-0">
                                  <div className="meta-info">
                                    <div className="author">
                                      <div className="avatar">
                                        <img src={lnikCube} alt="Floor Price" />
                                      </div>
                                      <div className="info">
                                        <span>Connect</span>
                                        <div className="d-flex mt-2 cursor-pointer">
                                          {collectionInfo &&
                                          collectionInfo.Discord_link ? (
                                            <h4
                                              className="text-discord mr-4"
                                              onClick={() =>
                                                openUrl(
                                                  collectionInfo.Discord_link
                                                )
                                              }
                                            >
                                              <i className="fab fa-discord"></i>
                                            </h4>
                                          ) : (
                                            ''
                                          )}
                                          {collectionInfo &&
                                          collectionInfo.ENS ? (
                                            <h4
                                              className="mr-4"
                                              onClick={() =>
                                                openUrl(collectionInfo.ENS)
                                              }
                                            >
                                              <img
                                                src={ens_icon}
                                                width="16"
                                                style={{ marginTop: '-5px' }}
                                              />
                                            </h4>
                                          ) : (
                                            ''
                                          )}
                                          {collectionInfo &&
                                          collectionInfo.Twitter_link ? (
                                            <h4
                                              className="text-twitter mr-4"
                                              onClick={() =>
                                                openUrl(
                                                  collectionInfo.Twitter_link
                                                )
                                              }
                                            >
                                              <i className="fab fa-twitter"></i>
                                            </h4>
                                          ) : (
                                            ''
                                          )}
                                          {collectionInfo &&
                                          collectionInfo.Telegram_link ? (
                                            <h4
                                              className="text-telegram"
                                              onClick={() =>
                                                openUrl(
                                                  collectionInfo.Telegram_link
                                                )
                                              }
                                            >
                                              <i className="fab fa-telegram-plane"></i>
                                            </h4>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="meta-info">
                                    <div className="author">
                                      <div className="avatar">
                                        <img src={imgEth} alt="Floor Price" />
                                      </div>
                                      <div className="info">
                                        <span>Floor Price</span>
                                        <h6>
                                          <FaEthereum />
                                          {obj ? obj.floor_price : ''}
                                        </h6>
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="meta-info">
                                    <div className="author">
                                      <div className="avatar">
                                        <img src={imgEth} alt="Volume Traded" />
                                      </div>
                                      <div className="info">
                                        <span>Volume Traded</span>
                                        <h6>
                                          {' '}
                                          {collectionInfo
                                            ? collectionInfo.volume_traded
                                            : ''}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="client-infor sc-card-product mt-0 mt-sm-5">
                                  <div className="meta-info">
                                    <div className="author">
                                      <div className="avatar">
                                        <img src={imgTrade} alt="Owners" />
                                      </div>
                                      <div className="info">
                                        <span>Owners</span>
                                        <h6>
                                          {' '}
                                          {collectionInfo
                                            ? collectionInfo.owners
                                            : ''}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="meta-info">
                                    <div className="author">
                                      <div className="avatar">
                                        <img src={imgCube} alt="items" />
                                      </div>
                                      <div className="info">
                                        <span>Items</span>
                                        <h6>
                                          {' '}
                                          {collectionInfo
                                            ? collectionInfo.items
                                            : ''}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                            ) : (
                              ''
                            )}
                            {tabsData && tabsData.announcements_tab_enabled ? (
                              <TabPanel>
                                <Announcements
                                  collection_id={
                                    collectionInfo
                                      ? collectionInfo.collection_id
                                      : ''
                                  }
                                  authorization={authorization}
                                  facebookLoader={
                                    <FacebookLoader
                                      value={4}
                                      coloum={1}
                                      is_mobile={1}
                                    />
                                  }
                                  apicallback={apicallback}
                                />
                              </TabPanel>
                            ) : (
                              ''
                            )}
                            {tabsData && tabsData.events_tab_enabled ? (
                              <TabPanel>
                                <EventList
                                  collection_id={
                                    collectionInfo
                                      ? collectionInfo.collection_id
                                      : ''
                                  }
                                  authorization={authorization}
                                  facebookLoader={
                                    <FacebookLoader
                                      value={4}
                                      coloum={1}
                                      is_mobile={1}
                                    />
                                  }
                                  apicallback={apicallback}
                                />
                              </TabPanel>
                            ) : (
                              ''
                            )}
                            {tabsData && tabsData.utility_tab_enabled ? (
                              <TabPanel>
                                <Utility
                                  collection_id={
                                    collectionInfo
                                      ? collectionInfo.collection_id
                                      : ''
                                  }
                                  userNfts={userNfts}
                                  // verifyStauts={verifyStauts}
                                  authorization={authorization}
                                  openUrl={openUrl}
                                  facebookLoader={
                                    <FacebookLoader
                                      value={4}
                                      coloum={1}
                                      is_mobile={1}
                                    />
                                  }
                                  apicallback={apicallback}
                                  handelTierId={setDeployedTierId}
                                  handelTierName={setTierName}
                                />
                              </TabPanel>
                            ) : (
                              ''
                            )}
                            {tabsData && tabsData.proof_tab_enabled ? (
                              <TabPanel>
                                <Verification
                                  collection_id={
                                    collectionInfo
                                      ? collectionInfo.collection_id
                                      : ''
                                  }
                                  message={verifyMessage}
                                  userNfts={userNfts}
                                  verifyStauts={verifyStauts}
                                  authorization={authorization}
                                  facebookLoader={
                                    <FacebookLoader
                                      value={4}
                                      coloum={1}
                                      is_mobile={1}
                                    />
                                  }
                                  apicallback={apicallback}
                                  deployed_tier_id={deployed_tier_id}
                                  tier_name={tier_name}
                                />
                              </TabPanel>
                            ) : (
                              ''
                            )}
                          </Tabs>
                        </div>
                      ) : (
                        <div className="row">
                          <InstagramLoader value={4} coloum={3} is_mobile={2} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </section>
  );
};

export default Index;

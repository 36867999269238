import React, { useState } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import moment from 'moment';
import { useEffect } from "react";
//components
import OhlcVolumeChart from "../../components/Chart/OhlcVolumeChart";
import { getValueWithDecimal } from "../../utility/commonUtitlity";
import { LoadingContainer } from "./helper";

export default function TradingActivity({ isLoading }) {
    const collectionInfoTabsData = useStoreState((state) => state.collectionInfoTabsData);
    const [chartState, setChartState] = useState({
        data: []
    });

    useEffect(() => {
        const { tradingData } = collectionInfoTabsData || {};
        if (Array.isArray(tradingData)) {
            const chartData = [];
            tradingData.forEach((item) => {
                const { open, high, low, close, timestamp } = item,
                    updatedDate = moment(timestamp).utc().format('MMM\' DD hh:mm A');
                chartData.push({
                    x: updatedDate,
                    y: [getValueWithDecimal(open),
                    getValueWithDecimal(high),
                    getValueWithDecimal(low), getValueWithDecimal(close)]
                });
            });
            setChartState(prevState => ({ ...prevState, data: chartData }));
        } else {
            setChartState({ data: [] })
        }
    }, [collectionInfoTabsData]);

    return (
        <section>
            {isLoading ? <LoadingContainer
                containerClassName="min-h-350px"
            /> :
                <OhlcVolumeChart
                    title="Trading Chart"
                    candleStickData={chartState.data}
                />
            }
        </section>
    )
}
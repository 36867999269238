import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import { Bars } from 'react-loader-spinner';
import moment from 'moment';
import { apicallback } from '../../callbacks/index';
import NoDataComponent from "../../components/NoDataComponent";
import { opensea_logo } from '../../assets/Index';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useEffect } from "react";
import LineChart from "../../components/Chart/LineChart";
//components
import { LoadingValue, LoadingContainer, COLLECTION_DETAIL_API_RETRY_COUNT, COLLECTION_DETAIL_RETRY_SECOND } from "./helper";
import { getNumberWithComma, getValueWithDecimal } from "../../utility/commonUtitlity";

export default function OpenSea({ walletAddress }) {
    const collectionData = useStoreState((state) => state.collectionData);
    const authorization = useStoreState((state) => state.authorization);
    const setCollectionDataAct = useStoreActions((actions) => actions.setCollectionDataAct);

    const collectionFloorPriceChartResp = useStoreState((state) => state.collectionFloorPriceChartResp);
    const setCollectionFloorPriceChartAct = useStoreActions((actions) => actions.setCollectionFloorPriceChartAct);

    const collectionHolderChartResp = useStoreState((state) => state.collectionHolderChartResp);
    const setCollectionHolderChartAct = useStoreActions((actions) => actions.setCollectionHolderChartAct);


    const [openSeaInfoState, setOpenSeaInfoState] = useState({});
    const [floorPriceChartData, setFloorPriceChartData] = useState({
        categories: [],
        data: []
    });
    const [holderChartData, setHolderChartData] = useState({
        categories: [],
        data: []
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingChart, setIsLoadingChart] = useState(true);
    const [isLoadingHoldingChart, setIsLoadingHoldingChart] = useState(true);

    const collectionApiIntervalRef = useRef(null);
    const retryCountRef = useRef(0);

    useEffect(() => {
        if (collectionData) {
            const { open_sea_data } = collectionData,
                { collection } = open_sea_data || {}, { stats } = collection || {};
            setOpenSeaInfoState({
                totalNft: stats?.count,
                floorPrice: stats?.floor_price,
                averagePrice: stats?.average_price,
                marketCap: stats?.market_cap,
                holders: stats?.num_owners,
                totalSales: stats?.total_sales,
                totalSupply: stats?.total_supply,
                totalVolume: stats?.total_volume
            });
            setIsLoading(false);
        } else if (!collectionData && authorization && walletAddress) {
            getCollectionsApi();
            collectionApiIntervalRef.current = setInterval(async () => {
                getCollectionsApi();
            }, COLLECTION_DETAIL_RETRY_SECOND);
        }
        return () => {
            clearInterval(collectionApiIntervalRef.current);
            collectionApiIntervalRef.current = null;
        }
    }, [collectionData, authorization, walletAddress]);

    useEffect(() => {
        if (collectionFloorPriceChartResp) {
            const { OpenSea } = collectionFloorPriceChartResp;
            if (Array.isArray(OpenSea)) {
                const categories = [],
                    data = [];
                OpenSea.forEach((item) => {
                    const { created_at, floor_price } = item,
                        updatedDate = moment(created_at).utc().format('MMM DD, YYYY hh:mm A'),
                        updatedValue = getValueWithDecimal(floor_price);
                    categories.push(updatedDate);
                    data.push(updatedValue);
                });
                setFloorPriceChartData({
                    categories,
                    data: [{ name: "Floor Price", data: data }]
                })
            }
            setIsLoadingChart(false);
        } else if (!collectionFloorPriceChartResp && collectionData) {
            getFloorChartApiCall();
        }
    }, [collectionData, collectionFloorPriceChartResp]);

    useEffect(() => {
        if (collectionHolderChartResp) {
            const { OpenSea } = collectionHolderChartResp;
            if (Array.isArray(OpenSea)) {
                const categories = [],
                    data = [];
                OpenSea.forEach((item) => {
                    const { created_at, holders } = item,
                        updatedDate = moment(created_at).utc().format('MMM DD, YYYY hh:mm A'),
                        updatedValue = getValueWithDecimal(holders);
                    categories.push(updatedDate);
                    data.push(updatedValue);
                });
                setHolderChartData({
                    categories,
                    data: [{ name: "Holder", data: data }]
                })
            }
            setIsLoadingHoldingChart(false);
        } else if (collectionData && !collectionHolderChartResp) {
            getHolderChartApiCall();
        }
    }, [collectionHolderChartResp, collectionData]);


    async function getCollectionsApi() {
        try {
            setIsLoading(true);
            const response = await apicallback(null, '/fetchCollectionData', { collection_address: walletAddress, }, 'private', authorization.token);
            if (response.status === 'success') {
                if (response?.data?.is_loading) {
                    if (retryCountRef.current === COLLECTION_DETAIL_API_RETRY_COUNT) {
                        clearInterval(collectionApiIntervalRef.current);
                        collectionApiIntervalRef.current = null;
                        return;
                    }
                    retryCountRef.current = +retryCountRef.current + 1;
                    return;
                }
                clearInterval(collectionApiIntervalRef.current);
                collectionApiIntervalRef.current = null;
                setCollectionDataAct(response.data);
                setIsLoading(false);
            }
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong");
            clearInterval(collectionApiIntervalRef.current);
            collectionApiIntervalRef.current = null;
            setIsLoading(false);
        }
    }
    
    async function getFloorChartApiCall() {
        try {
            setIsLoadingChart(true);
            const response = await apicallback(
                null,
                '/getCharts',
                {
                    collection_address: walletAddress,
                    type: 1,
                },
                'private',
                authorization.token
            );
            if (response.status === 'success') {
                setCollectionFloorPriceChartAct(response.data);
            }
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong");
        } finally {
            setIsLoadingChart(false);
        }
    }
    async function getHolderChartApiCall() {
        try {
            setIsLoadingHoldingChart(true);
            const response = await apicallback(
                null,
                '/getCharts',
                {
                    collection_address: walletAddress,
                    type: 0,
                },
                'private',
                authorization.token
            );
            if (response.status === 'success') {
                setCollectionHolderChartAct(response.data);
            }
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong");
        } finally {
            setIsLoadingHoldingChart(false);
        }
    }


    const showNoDataComponent = !isLoading && !(collectionData?.open_sea_data);
    return (
        <section className="opensea-container">
            {
                showNoDataComponent ?
                    <NoDataComponent
                        title="No OpenSea Data Found."
                    /> :
                    <React.Fragment>
                        <div className="row custom-row labelinfo-cardcontainer mb-4">
                            <div className="col-6 col-lg-3 custom-column">
                                <div className="labelinfo-card">
                                    <h6 className="label-info">Total NFTs</h6>
                                    <LoadingValue isLoading={isLoading}>
                                        <p className="value-info">
                                            {getNumberWithComma(openSeaInfoState.totalNft)}
                                        </p>
                                    </LoadingValue>

                                </div>
                            </div>
                            <div className="col-6 col-lg-3 custom-column">
                                <div className="labelinfo-card">
                                    <h6 className="label-info">
                                        Floor Price
                                    </h6>
                                    <LoadingValue isLoading={isLoading}>
                                        <p className="value-info">
                                            {getValueWithDecimal(openSeaInfoState.floorPrice)} ETH
                                        </p>
                                    </LoadingValue>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 custom-column">
                                <div className="labelinfo-card">
                                    <h6 className="label-info">Average Price</h6>
                                    <LoadingValue isLoading={isLoading}>
                                        <p className="value-info">
                                            {getValueWithDecimal(openSeaInfoState.averagePrice)}  ETH
                                        </p>
                                    </LoadingValue>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 custom-column">
                                <div className="labelinfo-card">
                                    <h6 className="label-info">Market Cap</h6>
                                    <LoadingValue isLoading={isLoading}>
                                        <p className="value-info">
                                            {getNumberWithComma(openSeaInfoState.marketCap)}  ETH
                                        </p>
                                    </LoadingValue>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 custom-column">
                                <div className="labelinfo-card">
                                    <h6 className="label-info">Holders</h6>
                                    <LoadingValue isLoading={isLoading}>
                                        <p className="value-info">
                                            {getNumberWithComma(openSeaInfoState.holders)}
                                        </p>
                                    </LoadingValue>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 custom-column">
                                <div className="labelinfo-card">
                                    <h6 className="label-info">Total Sales</h6>
                                    <LoadingValue isLoading={isLoading}>
                                        <p className="value-info">
                                            {getNumberWithComma(openSeaInfoState.totalSales)}
                                        </p>
                                    </LoadingValue>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 custom-column">
                                <div className="labelinfo-card">
                                    <h6 className="label-info">Total Supply</h6>
                                    <LoadingValue isLoading={isLoading}>
                                        <p className="value-info">
                                            {getNumberWithComma(openSeaInfoState.totalSupply)}
                                        </p>
                                    </LoadingValue>
                                </div>
                            </div>
                            <div className="col-6 col-lg-3 custom-column">
                                <div className="labelinfo-card">
                                    <h6 className="label-info">Total Volume</h6>
                                    <LoadingValue isLoading={isLoading}>
                                        <p className="value-info">
                                            {getValueWithDecimal(openSeaInfoState.totalVolume)} ETH
                                        </p>
                                    </LoadingValue>
                                </div>
                            </div>
                        </div>
                        <div className="row custom-row labelinfo-cardcontainer mb-5">
                            <div className="col-12 col-lg-6 custom-column">
                                <div className="chart-container">
                                    {isLoadingChart ? <LoadingContainer
                                        containerClassName="min-h-350px"
                                    /> :
                                        <LineChart
                                            title="Floor Price Chart"
                                            xAxisTitle="Date"
                                            yAxisTitle="Floor price (ETH)"
                                            categories={floorPriceChartData.categories}
                                            seriesData={floorPriceChartData.data}
                                        />
                                    }


                                </div>
                            </div>
                            <div className="col-12 col-lg-6 custom-column">
                                <div className="chart-container">
                                    {isLoadingHoldingChart ? <LoadingContainer
                                        containerClassName="min-h-350px"
                                    /> :
                                        <LineChart
                                            title="Holders Chart"
                                            xAxisTitle="Date"
                                            yAxisTitle="Holders"
                                            categories={holderChartData.categories}
                                            seriesData={holderChartData.data}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="poweredby-container">
                            <span className="mr-2">Floor Prices Powered by</span>
                            <img className="logo-icon" src={opensea_logo} />
                        </div>
                    </React.Fragment>
            }

        </section >
    )
}


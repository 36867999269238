import classNames from 'classnames';
import React from 'react';
import { MdOutlineArrowBackIos } from '../../../../src/components/icons/Index';
export default function RequestHeader({
  activeStepIndex = 1,
  backBtnHndlr,
  closeModalHndlr,
}) {
  return (
    <div className="request-header">
      <button
        onClick={backBtnHndlr}
        className="custom-text-button btn-white fw-normal"
      >
        <MdOutlineArrowBackIos className="mr-1" /> Back
      </button>
      <div className="stepper-container">
        {[1, 2, 3, 4].map((item, index) => (
          <div
            key={`tabindex-${index}`}
            className={classNames('step-options', {
              active: item <= activeStepIndex,
            })}
          ></div>
        ))}
      </div>
      <button
        onClick={closeModalHndlr}
        className="custom-text-button btn-white fw-normal"
      >
        Cancel
      </button>
    </div>
  );
}

import React, { useState } from "react";
import { useEffect } from "react";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Accordion } from "react-bootstrap";
import { TfiArrowCircleDown } from 'react-icons/tfi';
import NoDataComponent from "../../components/NoDataComponent";
import { LoadingContainer } from "./helper";

export default function Traits({ isLoading }) {
    const collectionInfoTabsData = useStoreState((state) => state.collectionInfoTabsData);
    const [traitState, setTraitState] = useState({});

    useEffect(() => {
        const { traits } = collectionInfoTabsData || {};
        if (Array.isArray(traits)) {
            const updatedTraits = traits.reduce((data, item) => {
                if (data[item.name]) {
                    data[item.name].push(item);
                } else {
                    data[item.name] = [item];
                }
                return data;
            }, {});
            setTraitState(updatedTraits);
        } else {
            setTraitState([]);
        }
    }, [collectionInfoTabsData]);

    const traitKeys = Object.keys(traitState),
        showNoData = ((traitKeys.length === 0) && !isLoading) || false;
    return (
        <section id="traits" className="traits-container">
            <div className="row row-gap-md row traits-row">
                {
                    Array.isArray(traitKeys) && traitKeys.map((item, index) => {
                        const traitsData = traitState[item];
                        return (
                            <div key={`traits-${index}`} className="column-gap-md col-12 col-lg-6">
                                <Accordion className="accordian-container ">
                                    <Accordion.Header className="accordian-header">
                                        <div className="trait-header">
                                            <h5 className="text-capitalize">{item}</h5>
                                            <span>
                                                <TfiArrowCircleDown />
                                            </span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="trait-items">
                                            {Array.isArray(traitsData) && traitsData.map((info, childIndex) => (
                                                <div key={`traitchild-${index}-${childIndex}`} className="trait-item">
                                                    <span className="item-label">
                                                        {info?.value}
                                                    </span>
                                                    <div className="item-value">
                                                        <span className="text-white mr-1">{info?.valueCount}</span>
                                                        <span className="text-custom-primary">({info?.rarity}%)</span>
                                                    </div>
                                                </div>))}
                                        </div>
                                    </Accordion.Body>
                                </Accordion>
                            </div>
                        )
                    })
                }

                {showNoData &&
                    <div className="column-gap-md col-12 ">
                        <NoDataComponent
                            title="No Traits Found."
                        />
                    </div>}
                {isLoading &&
                    <div className="column-gap-md col-12 ">
                        <LoadingContainer
                        />
                    </div>}

            </div>
        </section>
    )
}
import {
  home_icon,
  home_icon_yellow,
  track_icon_yellow,
  portfolio_icon_yellow,
  collection_icon,
  collection_icon_yellow,
} from '../../assets/Index';

import airdrops from '../../assets/images/menu/airdrops.svg';
import airdrops_yellow from '../../assets/images/menu/airdrops-1.svg';
//rewards.svg
import rewards from '../../assets/images/menu/rewards.svg';
import rewards_yellow from '../../assets/images/menu/rewards-1.svg';
//NFT-Tools.svg
import nft_tools from '../../assets/images/menu/NFT-Tools.svg';
///nft-portfolio.svg
import portfolio from '../../assets/images/menu/nft-portfolio.svg';
////wallet-tracker.svg
import tracker from '../../assets/images/menu/wallet-tracker.svg';
////vote.svg
import vote from '../../assets/images/menu/vote.svg';
import vote_y from '../../assets/images/menu/vote-1.svg';
////Resources.svg
import resources from '../../assets/images/menu/Resources.svg';
////WEB3-LEARNING.svg
import learning from '../../assets/images/menu/WEB3-LEARNING.svg';
import learning_y from '../../assets/images/menu/WEB3_LEARNING-1.svg';
///blog.svg
import blog from '../../assets/images/menu/blog.svg';
///News.svg
import news from '../../assets/images/menu/News.svg';
import news_y from '../../assets/images/menu/News-1.svg';

export const menuOptions = [
  {
    id: 1,
    name: 'Home',
    mobile: 'Home',
    links: '/',
    icon: home_icon,
    icon_y: home_icon_yellow,
    children: [],
  },
  {
    id: 2,
    name: 'Collections',
    mobile: 'Collections',
    links: '/nft-collections',
    icon: collection_icon,
    icon_y: collection_icon_yellow,
    children: [],
  },
  {
    id: 3,
    name: 'Airdrops',
    mobile: 'Airdrops',
    links: '/airdrops',
    icon: airdrops,
    icon_y: airdrops_yellow,
    children: [],
  },
  // {
  //   id: 4,
  //   name: 'Rewards',
  //   mobile: 'Rewards',
  //   links: '/rewards',
  //   icon: rewards,
  //   icon_y: rewards_yellow,
  //   children: [],
  // },
  {
    id: 5,
    name: 'NFT Tools',
    mobile: 'NFT Tools',
    links: '#',
    icon: nft_tools,
    icon_y: nft_tools,
    children: [
      {
        id: 1,
        name: 'Wallet Tracker',
        mobile: 'Wallet Tracker',
        links: '/nft-wallet-tracker',
        icon: tracker,
        icon_y: track_icon_yellow,
      },
      {
        id: 2,
        name: 'NFT Portfolio',
        mobile: 'NFT Portfolio',
        links: '/nft-portfolio-tracker',
        icon: portfolio,
        icon_y: portfolio_icon_yellow,
      },
      {
        id: 2,
        name: 'Web3 Voting',
        mobile: 'Web3 Voting',
        links: '#',
        icon: vote,
        icon_y: vote_y,
      },
    ],
  },

  {
    id: 6,
    name: 'Resources',
    mobile: 'Resources',
    links: '#',
    icon: resources,
    icon_y: resources,
    children: [
      {
        id: 1,
        name: 'Web3 Learning',
        mobile: 'Web3 Learning',
        links: '/resources',
        icon: learning,
        icon_y: learning_y,
      },
      {
        id: 1,
        name: 'Blog',
        mobile: 'Blog',
        links: '/blog',
        icon: blog,
        icon_y: blog,
      },
      {
        id: 1,
        name: 'NFT News',
        mobile: 'NFT News',
        links: '/nft-news',
        icon: news,
        icon_y: news_y,
        labelCountKey: true,
      },
    ],
  },
];

import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const Zoom = ({ isOpen, images, photoIndex, setIsOpen }) => {
  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          enableZoom={false}
          onMovePrevRequest={
            () => {}
            //   this.setState({
            //     photoIndex: (photoIndex + images.length - 1) % images.length
            //   })
          }
          onMoveNextRequest={
            () => {}
            //   this.setState({
            //     photoIndex: (photoIndex + 1) % images.length
            //   })
          }
        />
      )}
    </>
  );
};

export default Zoom;

import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { apicallback } from '../../callbacks/index';
import { useStoreState } from 'easy-peasy';
import Countdown from 'react-countdown';
import PageTitle from '../../components/pagetitle/PageTitle';
import { img1 } from '../../assets/Index';
import moment from 'moment/moment';
import { openToasterAlert } from '../../components/alert/Index';
import SmallImg from '../../components/Image/small';
import { IoArrowBackSharp } from '../../components/icons/Index';
import FacebookLoader from '../../components/content_loader/FacebookLoader';
import axios from 'axios';
const UtilityDetail = () => {
  const authorization = useStoreState((actions) => actions.authorization);
  const [dataBox, setAnnouncement] = useState({});
  const [isRedemable, setisRedemable] = useState(false);
  const [selectedNft, setselectedNft] = useState(null);
  const [remainingtime, setRemainingTime] = useState(0);
  const [remainingtimeFormate, setRemainingtimeFormate] = useState('');
  const [loader, setLoader] = useState(false);
  const [loaderNft, setLoaderNft] = useState(true);
  const [userNfts, setUserNfts] = useState([]);
  const [alertInfo, setAlertInfo] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  useEffect(async () => {
    await getUtilities();
  }, []);
  var interval;

  async function getNftByTeirId(nft) {
    try {
      if (nft.length) {
        let address = '';
        const getIds = nft.map((v) => {
          address = v.token_address;
          return v.token_id;
        });
        const response = await axios.get(
          `https://business-api-stage.anzali.io/api/getTierbyNft?nft_ids=${getIds}&collection_address=${address}`,
          {
            headers: {
              'content-type': 'application/json',
            },
          }
        );
        if (response.data.status == 'success') {
          const filteredArray = nft.filter((item) => {
            // Check if the item's token_id exists in the second array
            return response.data.data.some(
              (secondItem) => secondItem.token_id == item.token_id
            );
          });
          setUserNfts(filteredArray);
        }
      } else {
        setUserNfts(nft);
      }
      //deployed_tier_id
    } catch {
      setUserNfts(nft);
    }
  }

  const getVerificationStatus = async (collection) => {
    const response = await apicallback(
      null,
      '/getVerificationStatus',
      {
        collection_id: collection,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      if (interval != null) {
        if (response.verification_status != 'pending') {
          clearInterval(interval);
        }
      }
      if (dataBox.deployed_tier_id && response.verification_status == 'true') {
        await getNftByTeirId(response.data.nfts);
      } else {
        setUserNfts(
          response.verification_status == 'true' ? response.data.nfts : []
        );
      }

      if (response.data.nfts.length > 0) {
        setLoaderNft(false);
        document
          .getElementById(`checknft-${response.data.nfts[0].token_id}`)
          .click();
      }
    }
  };

  const verifyUser = async (collection) => {
    const response = await apicallback(
      null,
      '/verifyUser',
      {
        collection_id: collection,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      interval = setInterval(async () => {
        await getVerificationStatus(collection);
      }, 5000);
    }
  };
  const getUtilities = async () => {
    const response = await apicallback(
      null,
      '/getUtilityById',
      {
        utility_id: params.id,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      setAnnouncement(response.data);
      await verifyUser(response.data.collection);
    }
  };

  const Redeam = async () => {
    setLoader(true);
    const response = await apicallback(
      null,
      '/redeamUtility',
      {
        utitlity_id: params.id,
        token_id: selectedNft,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      await getRedemptionStatus(selectedNft);
      setAlertInfo(true);
      openToasterAlert(
        'success',
        'Hooray!! you have redeemed the free coffee.',
        {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        }
      );
    } else {
      openToasterAlert('error', response.message, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
    setLoader(false);
  };

  const getRedemptionStatus = async (nft_id) => {
    const response = await apicallback(
      null,
      '/getRedemptionStatus',
      {
        utitlity_id: params.id,
        token_id: nft_id,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      setisRedemable(true);
    } else {
      setisRedemable(false);
      if (response.message == 'Already Redeemed Please wait for cooldown.') {
        setRemainingtimeFormate(
          'Redeem again on ' +
            moment(parseInt(response.data.remaining_time)).format(
              'dddd, MMMM Do, YYYY h:mm A'
            )
        );
        setRemainingTime(response.data.remaining_time);
      } else if (response.message == 'Already Redeemed .') {
        setRemainingTime('-1');
      } else {
        setRemainingTime(null);
      }
    }
  };

  return (
    <div className="item-details">
      <PageTitle
        meta={{
          title: dataBox ? dataBox.title : '',
          description: dataBox.description,
          image: dataBox ? dataBox.image : '',
        }}
      />
      {alertInfo ? (
        <div className="alertUIModal">
          <div className="position-relative mb-4">
            <h3 className="text-center mb-3">Redeemed</h3>
            <button
              onClick={() => setAlertInfo(false)}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <hr />
          <p className="text-center mt-3">
            All set! You have successfully redeemed this utility. Show your
            phone to redeem.
          </p>
          {remainingtime && remainingtime != 0 ? (
            <>
              <div className="item count-down flex-column justify-content-center align-items-center col-sm-12 mt-5">
                <div className="d-flex justify-content-center ">
                  <Countdown date={new Date(parseInt(remainingtime))} />
                </div>
                <div className="mt-3">
                  <h6 style={{ lineHeight: '1.5' }}>
                    {remainingtimeFormate ? remainingtimeFormate + ' ET' : ''}
                  </h6>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
          <button
            className="sc-button style-4 btn-block mt-4"
            onClick={() => setAlertInfo(false)}
          >
            Close
          </button>
        </div>
      ) : (
        ''
      )}
      <div className="tf-section tf-item-details">
        <div className="themesflat-container">
          <div className="mx-auto mb-4">
            <Link
              to={dataBox ? `/rewards/${dataBox.collection_slug}` : '/rewards'}
              className="h4 d-flex"
            >
              <IoArrowBackSharp className="mr-2" /> Go back
            </Link>
          </div>
          <div className="row">
            <div className="col-md-5 col-sm-12 d-none d-md-block">
              <div className="content-left">
                <div className="mb-4">
                  <img
                    loading="lazy"
                    src={dataBox ? dataBox.image : ''}
                    alt={dataBox ? dataBox.title : ''}
                    style={{
                      width: '100%',
                      borderRadius: '10px',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-7 col-sm-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <div className="d-flex flex-row utility-media">
                    {dataBox && dataBox.title ? (
                      <>
                        <img
                          src={dataBox ? dataBox.image : ''}
                          alt={dataBox ? dataBox.title : ''}
                          style={{
                            width: '80px',
                            height: '80px',
                            borderRadius: '10px',
                          }}
                          className="mr-3 d-block d-md-none"
                        />
                        <h2>{dataBox.title} </h2>
                      </>
                    ) : null}
                  </div>
                  <p>{dataBox.description}</p>

                  <div className="flat-tabs themesflat-tabs mt-5">
                    <h5 className="mb-4"> Choose Your NFT to Redeem</h5>

                    {loaderNft ? (
                      <div className="row">
                        <FacebookLoader value={1} coloum={12} is_mobile={1} />
                        <div className="col-12">
                          <h2>Loading...</h2>
                        </div>
                      </div>
                    ) : (
                      <ul className="bid-history-list">
                        {userNfts.map((item, index) => (
                          <li
                            key={index}
                            data-aos="flip-up"
                            data-aos-duration={1000}
                            data-aos-offset={index}
                          >
                            <div className="content">
                              <div
                                className="client"
                                onClick={() =>
                                  document
                                    .getElementById(`checknft-${item.token_id}`)
                                    .click()
                                }
                              >
                                <div className="sc-author-box style-2">
                                  <div className="author-avatar">
                                    <Link to="#">
                                      <SmallImg
                                        image={{
                                          src: item.image_url
                                            ? item.image_url
                                            : img1,
                                          cls: 'avatar',
                                          alt: item.name,
                                          width: '44px',
                                          height: '44px!important',
                                        }}
                                      />
                                    </Link>
                                  </div>
                                  <div className="author-infor">
                                    <div className="name">
                                      <h6>
                                        <Link to="#">
                                          {item && item.token_id
                                            ? `#${item.token_id} `
                                            : ' '}
                                          {item.name}
                                        </Link>
                                      </h6>
                                    </div>
                                    <span className="time">
                                      {item && dataBox.tier_name
                                        ? dataBox.tier_name
                                        : ''}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="price">
                                <input
                                  id={`checknft-${item.token_id}`}
                                  type="radio"
                                  name="my-nft"
                                  onClick={() => {
                                    setselectedNft(item.token_id);
                                    getRedemptionStatus(item.token_id);
                                  }}
                                />
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>

                  <div className="meta-item-details style2">
                    {isRedemable ? (
                      <button
                        onClick={() => {
                          Redeam();
                        }}
                        className="sc-button style-4"
                      >
                        <span>{loader ? 'Please wait' : 'Redeem'}</span>
                      </button>
                    ) : remainingtime == '-1' ? (
                      <div className="item count-down">
                        <span className="heading style-2">
                          Already Redeemed.
                        </span>
                      </div>
                    ) : remainingtime && remainingtime != 0 ? (
                      <>
                        <div className="item count-down flex-column justify-content-start align-items-start col-sm-8">
                          <div className="d-flex justify-content-start ">
                            <Countdown
                              date={new Date(parseInt(remainingtime))}
                            />
                          </div>
                          <div className="mt-3">
                            <h6 style={{ lineHeight: '1.5' }}>
                              {remainingtimeFormate
                                ? remainingtimeFormate + ' ET'
                                : ''}
                            </h6>
                          </div>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UtilityDetail;

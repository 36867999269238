import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import {
    apicallback,
} from '../../callbacks/index';
import { openToasterAlert } from '../../components/alert/Index';
import { Bars } from 'react-loader-spinner';

const AddWalletModal = (props) => {
    const [loadingState, setLoadingState] = useState(false);
    const { walletAddress, authorization } = props;
    const history = useNavigate();
    const handelSubmit = async (e) => {
        e.preventDefault();
        setLoadingState(true);
        try {
            const response = await apicallback(
                null,
                '/createWalletsUpdated',
                {
                    name: `Wallet`,
                    user_id: authorization.data.id.toString(),
                    address: walletAddress,
                    chain_id: '1',
                    hexCode: '#fec60d',
                },
                'private',
                authorization.token
            );
            if (response && response.status == 'success') {
                history(
                    `/wallet-info/${response.data.wallet_id}/${response.data.address}`
                );
                props.onHide();
            } else {
                openToasterAlert(
                    'error',
                    response.message ? response.message : 'Wallet addres is not valid.'
                );
            }
            setLoadingState(false);
        } catch (error) {
            console.log(error);
            setLoadingState(false);
            openToasterAlert('error', 'Wallet addres is not valid.');
        }
    };

    return (
        <Modal show={props.show} onHide={props.onHide} centered>
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body">
                <h3 style={{ lineHeight: 1.2 }}>Save this wallet to your tracker</h3>
                <div className='d-flex justify-content-center mt-4 mb-2'>
                    <button
                        className="sc-button style-4 text-dark"
                        id="try-now"
                        disabled={loadingState}
                        onClick={handelSubmit}
                    >
                        {loadingState ? <Bars color='#ffffff' height={22} /> : "Save Wallet"}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddWalletModal;

import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  verify_icon,
  by_token,
  noImageFound,
  ens_white,
} from '../../assets/Index';
import { getValueWithDecimal } from '../../utility/commonUtitlity';
import moment from 'moment';

export default function AssetCard(props) {
  function replaceImage(error) {
    //replacement of broken Image
    error.target.src = noImageFound;
  }

  const {
    assetName = '-',
    visibleTitle = '-',
    tokenDisplayValue = '',
  } = useMemo(() => {
    const { tokenTitle, tokenId } = props;
    let tokenDisplayValue = '';
    if (tokenId !== null || tokenId !== undefined) {
      tokenDisplayValue =
        tokenId.length > 6
          ? `#${tokenId.toString().substring(0, 6)}` + '...'
          : `#${tokenId}`;
    }
    if (tokenTitle) {
      return {
        assetName: tokenTitle,
        visibleTitle: tokenTitle,
        tokenDisplayValue,
      };
    } else if (tokenId !== null || tokenId !== undefined) {
      let assetNameByTokenId =
        tokenId.length > 7
          ? `#${tokenId.toString().substring(0, 7)}` + '...'
          : `#${tokenId}`;
      return {
        assetName: assetNameByTokenId,
        visibleTitle: tokenId,
        tokenDisplayValue,
      };
    }
    return { tokenDisplayValue };
  }, [props.tokenTitle, props.tokenId]);

  const isEnsCard =
    props.contractAddress === '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85';
  return (
    <div className="tokencard-container">
      <Link to={props.redirectLink ? props.redirectLink : '/#'}>
        <div
          className={classNames(
            'ensimage-container',
            isEnsCard ? 'custom-img' : ''
          )}
          style={
            isEnsCard
              ? {
                  background:
                    'linear-gradient(330.4deg, rgb(68, 188, 240) 4.54%, rgb(114, 152, 248) 59.2%, rgb(160, 153, 255) 148.85%)',
                }
              : { backgroundImage: '' }
          }
        >
          {isEnsCard ? (
            <>
              <img src={ens_white} width="50" />
              <h5
                className="text-nowrap-full"
                title={visibleTitle}
                style={{ maxWidth: '120px' }}
              >
                {assetName}
              </h5>
            </>
          ) : (
            <>
              <img
                src={props.assetImage || noImageFound}
                className="ens-image"
                onError={replaceImage}
              />
            </>
          )}
        </div>
      </Link>
      <div className="token-title-container">
        <h2 className="title" title={visibleTitle}>
          {assetName}
        </h2>
        {props.isVerified && (
          <img src={verify_icon} className="verify-icon" alt="verify-icon" />
        )}
      </div>
      <div className="token-price-container">
        <div className="row">
          <div className="col-6 pr-2">
            <div className="price-container">
              <span className="price-label">Floor Price</span>
              <span className="price-value">
                {props?.circulatingValue ? (
                  <>
                    {getValueWithDecimal(props?.circulatingValue)}{' '}
                    <span className="text-dark-light">
                      {props?.chain || 'ETH'}
                    </span>
                  </>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </div>
          <div className="col-6 pl-2">
            {props?.is_last_sold ? (
              <div className="traded-container">
                <span className="price-label">Last Sold</span>
                <span className="price-value" title={props.tokenId}>
                  {props?.last_sold
                    ? moment(props?.last_sold).format('MM/DD/YYYY')
                    : '-'}
                </span>
              </div>
            ) : (
              <div className="traded-container">
                <span className="price-label">Token</span>
                <span className="price-value" title={props.tokenId}>
                  {tokenDisplayValue}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <Link
        to={props.redirectLink ? props.redirectLink : '/#'}
        state={{ obj: props.itemDetails }}
        className="view-btn"
      >
        View Now
      </Link>
    </div>
  );
}

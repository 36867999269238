import React, { useState, useEffect, useMemo, useRef } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";
import moment from 'moment';
import { apicallback, copyClipboard } from '../../../callbacks/index';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Table from '../../../components/table/Table';
import {
    BiCheckShield,
    BiErrorAlt,
    FaRegCopy,
} from '../../../components/icons/Index';
import { generateColumns, auditInfo } from "./helper";
import { COLLECTION_DETAIL_API_RETRY_COUNT, COLLECTION_DETAIL_RETRY_SECOND } from "../helper";
//components
import { getCollectionsApiCall } from "./helper";
import { getNumberWithComma, getValueWithDecimal, getPriceValue } from "../../../utility/commonUtitlity";
import { LoadingValue } from "../helper";
import NoDataComponent from "../../../components/NoDataComponent";

export default function Audit({ walletAddress }) {
    const collectionData = useStoreState((state) => state.collectionData);
    const authorization = useStoreState((state) => state.authorization);
    const [isLoading, setIsLoading] = useState(false);
    const setCollectionDataAct = useStoreActions((actions) => actions.setCollectionDataAct);
    const [auditState, setAuditState] = useState({});
    const collectionApiIntervalRef = useRef(null);
    const retryCountRef = useRef(0);

    useEffect(() => {
        if (collectionData) {
            const { audit_data } = collectionData;
            if (audit_data?.[walletAddress]) {
                setAuditState(audit_data[walletAddress]);
            }
        } else if (!collectionData && authorization && walletAddress) {
            getCollectionsApi();
            collectionApiIntervalRef.current = setInterval(async () => {
                getCollectionsApi();
            }, COLLECTION_DETAIL_RETRY_SECOND);
        }
        return () => {
            clearInterval(collectionApiIntervalRef.current);
            collectionApiIntervalRef.current = null;
        }
    }, [collectionData, authorization, walletAddress]);

    async function getCollectionsApi() {
        try {
            setIsLoading(true);
            const response = await apicallback(null, '/fetchCollectionData', { collection_address: walletAddress, }, 'private', authorization.token);
            if (response.status === 'success') {
                if (response?.data?.is_loading) {
                    if (retryCountRef.current === COLLECTION_DETAIL_API_RETRY_COUNT) {
                        clearInterval(collectionApiIntervalRef.current);
                        collectionApiIntervalRef.current = null;
                        return;
                    }
                    retryCountRef.current = +retryCountRef.current + 1;
                    return;
                }
                clearInterval(collectionApiIntervalRef.current);
                collectionApiIntervalRef.current = null;
                setCollectionDataAct(response.data);
                setIsLoading(false);
            }
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong");
            setIsLoading(false);
        }
    }

    function copyAddressHandler(event) {
        const walletAddress = event.currentTarget.getAttribute("data-id");
        if (walletAddress) {
            copyClipboard(walletAddress);
        }
    }


    const columns = useMemo(() => generateColumns(copyAddressHandler)),
        { holders } = auditState || {},
        showNoDataComponent = !isLoading && !(collectionData?.audit_data);
    return (
        <section className="audit-container">
            {showNoDataComponent ? <NoDataComponent
                title="No Audit Data Found."
            /> :
                <React.Fragment>
                    <div className="row row-gap-md details-container mb-5">
                        {
                            auditInfo.map((item, i) => {
                                const { id, name, trueValue, reversed } = item,
                                    isVerified = (trueValue === auditState[id]);
                                let verifyText = "";
                                if (reversed) {
                                    verifyText = isVerified ? "No" : "Yes";
                                } else {
                                    verifyText = isVerified ? "Yes" : "No";
                                }

                                return (
                                    <div key={item.id} className="column-gap-md col-12 col-lg-3">
                                        <div className="audit-detail">
                                            <div className="audit-title">
                                                {name}
                                            </div>
                                            <LoadingValue isLoading={isLoading} wrapperPadding={0} height={20}>
                                                <div className={classNames("audit-status", (isVerified ? "text-success" : "text-danger"))}>
                                                    <span className="mr-1">
                                                        {verifyText}
                                                    </span>
                                                    {isVerified ?
                                                        <BiCheckShield className="font-20" /> :
                                                        <BiErrorAlt className="font-20" />
                                                    }
                                                </div>
                                            </LoadingValue>


                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div>
                        <Table
                            columns={columns}
                            data={Array.isArray(holders) ? holders : []}
                            isDataLoading={isLoading}
                            loadingMessage="Loading Audit..."
                        />
                    </div>
                </React.Fragment>}
        </section >
    )
}


import { Link } from 'react-router-dom';
import {
  home_section10,
  home_start_premium,
  home_section_sheild,
  home_section_fire,
  home_section_heart,
  home_section_notify,
} from '../../assets/Index';
import { openUrl } from '../../callbacks';
export default function Section9() {
  function List(value) {
    const { img, text, cls } = value;
    return (
      <div className={cls}>
        <div className="list d-flex align-items-center">
          <div className="image mr-3">
            <img src={img} />
          </div>
          <h5>{text}</h5>
        </div>
      </div>
    );
  }
  return (
    <section className="section-9">
      <div className="home-custom-container">
        <div className="row">
          <div className="col-md-6">
            <img src={home_section10} className="w-100 d-none d-md-block" />
          </div>
          <div className="col-md-6 first d-flex flex-column justify-content-center">
            <div className="first mb-4 d-flex align-items-center">
              <img src={home_start_premium} className="mr-2" />
              <span>Proposals</span>
            </div>
            <h4>Create Proposals & Build Community with Blockchain Voting</h4>
            <img
              src={home_section10}
              className="w-100 d-block d-md-none my-3"
            />
            <p>
              Proposals is a voting platform that allows DAOs, DeFi protocols,
              or NFT communities to vote easily and without gas fees.
            </p>
            <div className="mt-0 mt-md-5">
              <p className="d-none d-md-block">Benefits of ANZALI Proposals</p>
              <div className="row mt-4 mx-0 mx-md-1">
                <List
                  img={home_section_heart}
                  cls="col-6 mb-3 px-1"
                  text="Free to Use All Features"
                />
                <List
                  img={home_section_sheild}
                  cls="col-6 mb-3 px-1"
                  text="Build with Blockchain Transparency"
                />
                <List
                  img={home_section_notify}
                  cls="col-6 mb-3 px-1"
                  text="Build Your Awesome Community"
                />
                <List
                  img={home_section_fire}
                  cls="col-6 mb-3 px-1"
                  text="Work With Zero Gas or Other Fees"
                />
              </div>
              <Link
                to="#"
                onClick={() => openUrl(process.env.REACT_APP_PROPOSAL)}
                className="sc-button style-4 text-dark mt-md-5 mt-3"
              >
                Create Your First Proposal
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

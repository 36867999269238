import {
    condenseAddress,
} from '../../../callbacks/index';
import {
    BiCheckShield,
    BiErrorAlt,
    FaRegCopy,
} from '../../../components/icons/Index';
import { document_copy_img } from '../../../assets/Index';
import classNames from "classnames";
import { getValueWithDecimal } from '../../../utility/commonUtitlity';

export function generateColumns(copyClipboard) {
    return [
        {
            id: 'id',
            Header: '#',
            accessor: 'id',
            disableSortBy: true,
            Cell: ({ row: { index } }) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            id: 'wallet_address',
            Header: 'Address',
            disableSortBy: true,
            // columnClassName: "w-180px min-w-180px",
            Cell: ({ row: { index, original } }) => {
                const { address } = original;
                return <div className='d-flex align-items-center' data-id={address} onClick={copyClipboard}>
                    <span className='mr-1'>{condenseAddress(address)}</span>
                    <img
                        src={document_copy_img}
                        style={{ width: '20px', height: '20px' }}
                    />
                </div>;
            },
        },
        {
            id: 'balance',
            Header: 'Balance',
            accessor: 'balance',
            disableSortBy: true,
            Cell: ({ value }) => {
                return (<span className="fw-bold ml-2">{getValueWithDecimal(value)}
                </span>)
            },
        },
        {
            id: 'percent',
            Header: 'Percent',
            accessor: 'percent',
            disableSortBy: true,
            Cell: ({ value }) => {
                return (<span className="fw-bold ml-2">{getValueWithDecimal(value)}
                </span>)
            },
        },
        {
            id: 'is_locked',
            Header: 'Is Blocked',
            accessor: 'is_locked',
            disableSortBy: true,
            Cell: ({ row: { index, original } }) => {
                const { isLocked } = original;
                return <div className={classNames("d-flex align-items-center", (isLocked ? "text-success" : "text-danger"))}>
                    {isLocked ? <><span className='mr-1'>Yes</span>
                        <BiCheckShield className='font-20' /></>
                        : <><span className='mr-1'>No</span>
                            <BiErrorAlt className='font-20' /></>
                    }
                </div >;
            },
        },
        {
            id: 'isContract',
            Header: 'Is Contract',
            accessor: 'isContract',
            disableSortBy: true,
            Cell: ({ row: { index, original } }) => {
                const { isContract } = original;
                return <div className={classNames("d-flex align-items-center", (isContract ? "text-success" : "text-danger"))}>
                    {isContract ? <><span className='mr-1'>Yes</span>
                        <BiCheckShield className='font-20' /></>
                        : <><span className='mr-1'>No</span>
                            <BiErrorAlt className='font-20' /></>
                    }
                </div >;
            },
        }
    ];
}

export const auditInfo = [
    {
        id: "is_open_source",
        name: "Verified",
        trueValue: "1"
    },
    {
        id: "is_honeypot",
        name: "Honeypot",
        trueValue: "0",
        reversed: true,
    },
    {
        id: "is_mintable",
        name: "Mintable",
        trueValue: "1"
    },
    {
        id: "is_proxy",
        name: "Proxy Contract",
        trueValue: "1"
    },
    {
        id: "selfdestruct",
        name: "Self Destruct",
        trueValue: "1"
    },
    {
        id: "external_call",
        name: "External Call",
        trueValue: "1"
    },
    {
        id: "is_anti_whale",
        name: "Anti Whale",
        trueValue: "1"
    },
    {
        id: "is_blacklisted",
        name: "Blacklisted",
        trueValue: "0",
        reversed: true,
    },
    {
        id: "is_whitelisted",
        name: "Whitelisted",
        trueValue: "1"
    },
    {
        id: "trading_cooldown",
        name: "Trading Cooldown",
        trueValue: "1"
    },
    {
        id: "transfer_pausable",
        name: "Transfer Pausable",
        trueValue: "1"
    },
    {
        id: "slippage_modifiable",
        name: "Slippage Modifiable",
        trueValue: "1"
    },
    {
        id: "personal_slippage_modifiable",
        name: "Personal Slippage Modifiable",
        trueValue: "1"
    },
    {
        id: "can_take_back_ownership",
        name: "Can Take Back Ownership",
        trueValue: "1"
    }
]

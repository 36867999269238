import { wallet_front, eth_front, gift_dynamic } from '../../assets/Index';
import { Link } from 'react-router-dom';
export default function SectionSecond() {
  function Cards(values) {
    const { cls, icon, title, desc, link, btn_text } = values;
    return (
      <div className={cls}>
        <div className="section-2-card p-4">
          <div className="icon">
            <img src={icon} />
          </div>
          <div className="mt-5 section-2-card-body">
            <h4>{title}</h4>
            <p dangerouslySetInnerHTML={{ __html: desc }} className="mt-4"></p>
          </div>
          <div className="section-2-card-footer mt-4">
            <Link to={link} className="sc-button style-4 w-100">
              {btn_text}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="section-2 mt-5">
      <div className="home-custom-container">
        <div className="text-center">
          <h4 className="head-title">Top NFT Tools and Frameworks</h4>
        </div>
        <div className="row card-list">
          <Cards
            cls="col-md-6 col-sm-6 mb-4"
            icon={wallet_front}
            title="Web3 Wallet Tracker"
            desc="The best wallet analysis tool on the market. Track any wallet from BTC, Solana or EVM-based supported chains instantly to visualize its value, history and trading performance."
            link="/nft-wallet-tracker"
            btn_text="Try It Now"
          />
          <Cards
            cls="col-md-6 col-sm-6 mb-4"
            icon={eth_front}
            title="NFT Portfolio Tracker"
            desc="Track your NFT portfolio’s worth in real-time with rarity considerations, cross-market floor prices and historical value."
            link="/nft-portfolio-tracker"
            btn_text="Create Your Portfolio"
          />
          {/* <Cards
            cls="col-md-4 col-sm-6 mb-4"
            icon={gift_dynamic}
            title="NFTs with Rewards"
            desc="Unlock rewards and perks from participating collections and businesses. Contact us if you’d like to explore Web3 rewards powered by ANZALI for your business."
            link="/rewards"
            btn_text="Explore"
          /> */}
        </div>
      </div>
    </section>
  );
}

import classNames from "classnames";
import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

export function TooltipIconClick({ children, iconId, toolTipName, tooltipClassName }) {
    return (
        <>
            {children}
            <ReactTooltip
                anchorSelect={`#${iconId}`}
                // place="bottom"
                // className={classNames("text-tooltip", tooltipClassName)}
                className="airdrop-tooltip"
                // classNameArrow="airdrop-tooltiparrow"
                border="2px solid #45464B"
                html={toolTipName}
                openOnClick={true}
            />
        </>
    )
}

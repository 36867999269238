import { mintImageDefault, noImageFound } from '../../assets/Index';
import { numFormatter } from '../../callbacks';
import { verify_icon } from '../../assets/Index';
import {
  chainList,
  getValueWithDecimal,
  getValueWithFourDecimal,
} from '../../utility/commonUtitlity';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import moment from 'moment';
function MintInfo({
  mintImg,
  mintName,
  redirectUrl,
  createdDate,
  verfied = null,
  noRediect = null,
}) {
  return (
    <div className="mint-details">
      <a
        className="text-decoration-none"
        target="_blank"
        aria-label={mintName}
        href={redirectUrl || '#'}
      >
        <LazyLoadImage
          alt={mintName}
          src={mintImg || mintImageDefault}
          wrapperClassName="mint-img"
          effect="blur"
        />
      </a>

      <div className={`d-flex flex-column overflow-hidden`}>
        {noRediect ? (
          <Link
            to={redirectUrl}
            className={`mb-2 fw-bold mint-name ${
              verfied ? 'justify-content-center d-flex' : ''
            }`}
          >
            <span> {mintName}</span>
            <div>
              {' '}
              {verfied ? <img src={verfied} className="verfied" /> : ''}
            </div>
          </Link>
        ) : (
          <a
            target="_blank"
            href={redirectUrl || '#'}
            title={mintName}
            className={`mb-2 fw-bold mint-name ${
              verfied ? 'justify-content-center d-flex' : ''
            }`}
          >
            <span> {mintName}</span>
            <div>
              {' '}
              {verfied ? <img src={verfied} className="verfied" /> : ''}
            </div>
          </a>
        )}

        {createdDate ? (
          <span className="created-date font-14">{createdDate}</span>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
export function generateColumns(minNowHndlr) {
  return [
    {
      id: 'id',
      Header: '#',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row: { index } }) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      id: 'collection_name',
      Header: 'Collections',
      accessor: 'collection_name',
      columnClassName: 'w-250px min-w-250px',
      Cell: ({ row: { original } }) => {
        return (
          <MintInfo
            mintImg={original.collection_image}
            redirectUrl={original.collection_mint_url}
            mintName={original.collection_name}
            createdDate={`${getValueWithDecimal(original.floor)} ETH`}
          />
        );
      },
    },
    {
      id: 'mints',
      Header: 'Mints 10Min',
      accessor: 'mints',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        return (
          <div className="d-flex  align-items-center">
            <span className="ml-3 pr-2">{original?.mints}</span>
            <span
              style={{
                color: Number(original?.mint_change) > 0 ? 'green' : 'red',
                fontSize: '11px',
              }}
            >
              {original?.mint_change ? `(${original?.mint_change})` : ''}
            </span>
          </div>
        );
      },
      // Cell: ({ row: { original } }) => {
      //     const isProfitMint = original.mintInfoCount <= 20;
      //     return (
      //         <div className="d-flex flex-column">
      //             <span className="mb-2 fw-bold">{original?.mintInfoCount}</span>
      //             <span className={classNames("d-flex align-items-center", (isProfitMint ? "text-danger" : "text-success"))}>
      //                 <span>{original.mintInfoCount}%</span>   {isProfitMint ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
      //             </span>
      //         </div>
      //     )
      // }
    },
    {
      id: 'mintingPercent',
      Header: 'Unique Minters',
      accessor: 'mintingPercent',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        return (
          <div className="d-flex flex-column">
            <span className="mb-3 fw-bold">
              {original?.unique_minters_value}
              <span className="font-12 ml-1 text-light-grey">
                ({Math.round(original?.unique_minters)}%)
              </span>
            </span>
            <div className="progress progress-unique-minters">
              <div
                className="progress-bar"
                aria-label={original.collection_name}
                style={{ width: `${original?.unique_minters}%` }}
                role="progressbar"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'price',
      Header: 'Mint Price',
      accessor: 'price',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        const priceValue = !isNaN(original.price) ? Number(original.price) : 0;
        return (
          <span>
            {priceValue != null
              ? getValueWithFourDecimal(priceValue) + ' ETH'
              : ''}{' '}
          </span>
        );
      },
    },
    {
      id: 'allTime',
      Header: 'Total Mints',
      accessor: 'allTime',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        // const profitTotalMint =
        //   !isNaN(original?.all_time) &&
        //     !isNaN(original?.collection_totalsupply)
        //     ? (Number(original?.all_time) /
        //       Number(original?.collection_totalsupply)) *
        //     100
        //     : getValueWithDecimal(),
        //   profitTotalMintPerc = Math.ceil(profitTotalMint);

        return (
          <span className="ml-3 fw-bold">{Math.round(original?.all_time)}</span>
        );
      },
    },
    {
      id: 'holders',
      Header: 'Holders',
      accessor: 'holders',
      disableSortBy: true,
      Cell: ({ value }) => {
        return <span className="ml-3">{value}</span>;
      },
    },
    {
      id: 'fomo',
      Header: 'FOMO',
      accessor: 'fomo',
      disableSortBy: true,
      Cell: ({ value }) => {
        return <span className="ml-3">{value}</span>;
      },
    },
    {
      id: 'notable_minters',
      Header: 'Notable Minters',
      accessor: 'notable_minters',
      disableSortBy: true,
      Cell: ({ value }) => {
        return <span className="ml-3">{value}</span>;
      },
    },
    {
      id: 'first_mint_time',
      Header: 'First Minted',
      accessor: 'first_mint_time',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <span className="ml-3">
            {value ? moment(Number(value)).format('MM-DD-YYYY h:mm A') : ''}
          </span>
        );
      },
    },
    // {
    //     id: "mintNow",
    //     Header: "",
    //     accessor: "mintNow",
    //     Cell: ({ row: { original } }) => {
    //         const isProfitMint = original.mintingPercent <= 20;
    //         return (
    //             <button
    //                 className="sc-button mintnow-btn style-4"
    //                 id="mint-now"
    //                 onClick={undefined}
    //             >
    //                 <img className="mr-1" src={mintIconDefault} />
    //                 <span>Mint Now</span>
    //             </button>
    //         )
    //     }
    // },
  ];
}

const getVolumeTraded = (item) => {
  if (item != null) {
    try {
      let js = JSON.parse(item);
      if (js.total_volume != null) return numFormatter(js.total_volume, false);
      else return '-';
    } catch (error) {
      return '0.00';
    }
  } else {
    return '-';
  }
};

export function generateColumnsCollection() {
  return [
    {
      id: 'name',
      Header: 'Collections',
      accessor: 'name',
      columnClassName: 'w-250px min-w-250px',
      Cell: ({ row: { original } }) => {
        return (
          <MintInfo
            mintImg={original.image}
            mintName={original.name}
            verfied={verify_icon}
            redirectUrl={`/collection-info/${original.created_address}/1`}
            noRediect={true}
          />
        );
      },
    },

    {
      id: 'floor_price',
      Header: 'Floor Price',
      accessor: 'floor_price',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        const priceValue = !isNaN(original.floor_price)
          ? Number(original.floor_price)
          : 0;
        return (
          <span>
            {original.floor_price} {}ETH
          </span>
        );
      },
    },
    {
      id: 'price2',
      Header: 'Volume Traded',
      accessor: 'price2',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        const priceValue = !isNaN(original.json_collection)
          ? Object(original.json_collection)
          : 0;
        return <span>{getVolumeTraded(original.json_collection)}</span>;
      },
    },

    // {
    //     id: "mintNow",
    //     Header: "",
    //     accessor: "mintNow",
    //     Cell: ({ row: { original } }) => {
    //         const isProfitMint = original.mintingPercent <= 20;
    //         return (
    //             <button
    //                 className="sc-button mintnow-btn style-4"
    //                 id="mint-now"
    //                 onClick={undefined}
    //             >
    //                 <img className="mr-1" src={mintIconDefault} />
    //                 <span>Mint Now</span>
    //             </button>
    //         )
    //     }
    // },
  ];
}
export function generateColumnsTrendingCollection() {
  return [
    {
      id: 'name',
      Header: 'Collections',
      accessor: 'name',
      columnClassName: 'w-250px min-w-250px',
      Cell: ({ row: { original } }) => {
        return (
          <MintInfo
            mintImg={original.logo ? original.logo : null}
            redirectUrl={`/collection-info/${
              original.contract_address ? original.contract_address : ''
            }/${original.chain_id}`}
            mintName={original.name}
            verfied={verify_icon}
            noRediect={true}
          />
        );
      },
    },

    {
      id: 'floor_price',
      Header: 'Floor Price',
      accessor: 'floor_price',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        return (
          <span>
            {original.floor_price
              ? Number(original.floor_price.value).toFixed(
                  original.chain_id == 1 ? 2 : 6
                )
              : 0}{' '}
            {chainList(original.chain_id)}
          </span>
        );
      },
    },
    {
      id: 'price2',
      Header: 'Volume Traded',
      accessor: 'price2',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        return (
          <span>
            {original.total_supply
              ? Number(original.volume.value).toFixed(
                  original.chain_id == 1 ? 2 : 6
                )
              : 0}{' '}
            {chainList(original.chain_id)}
          </span>
        );
      },
    },

    // {
    //     id: "mintNow",
    //     Header: "",
    //     accessor: "mintNow",
    //     Cell: ({ row: { original } }) => {
    //         const isProfitMint = original.mintingPercent <= 20;
    //         return (
    //             <button
    //                 className="sc-button mintnow-btn style-4"
    //                 id="mint-now"
    //                 onClick={undefined}
    //             >
    //                 <img className="mr-1" src={mintIconDefault} />
    //                 <span>Mint Now</span>
    //             </button>
    //         )
    //     }
    // },
  ];
}

import React from "react";
import {
    noImageFound
} from '../../assets/Index';

function replaceImage(error) {
    //replacement of broken Image
    error.target.src = noImageFound;
}


export default function AirdropChainCard({ chainId, chainImage, chainName, onClickHanlder }) {
    return (
        <div data-id={chainId} onClick={onClickHanlder} className="airdropchain-container">
            <div className="chainimage-container">
                <img alt="chainimage" src={chainImage || noImageFound} className="chainimage"
                    onError={replaceImage}
                />
            </div>
            <div className="chainname font-semibold">
                {chainName}
            </div>
        </div>
    )
}
import React from "react";
import ReactApexChart from "react-apexcharts";

export default function LineChart({ xAxisTitle = "", yAxisTitle = "", title = "Data", categories, seriesData = [] }) {
    const chartData = {
        series: seriesData,
        options: {
            noData: {
                text: 'No data available',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: "#ffffff"
                }
            },
            // colors: ['#EC6929', '#4FD18E', '#EB5757'],
            colors: ['#f6c946'],

            legend: {
                show: true,
            },
            chart: {
                id: 'basic-line-with-dots',
                toolbar: {
                    tools: {
                        download: false
                    }
                }
            },
            dataLabels: {
                enabled: false,
            },
            markers: {
                size: 4,
                strokeColor: 'transparent',
                // colors: ['#3863B9', '#E03613', '#FF9702', '#0D961A', '#95039B', '#009BC7'],
                hover: {
                    size: 4,
                }
            },
            stroke: {
                curve: 'smooth',
                width: 2,
                // colors: ['#3863B9', '#E03613', '#FF9702', '#0D961A', '#95039B', '#009BC7'],
            },
            title: {
                show: true,
                text: title,
                style: {
                    fontSize: '16px',
                    color: "#ffffff",
                    fontWeight: 600
                },
            },
            grid: {
                strokeDashArray: 8,
                position: 'back',
                borderColor: '#272B30',
                xaxis: {
                    lines: {
                        show: false
                    }
                },
            },
            yaxis: {
                show: true,
                axisBorder: {
                    show: true
                }, axisTicks: {
                    show: false
                },
                labels: {
                    style: {
                        colors: "#B1B1B1",
                        fontSize: "12px",
                    }
                },
                title: {
                    text: yAxisTitle,
                    style: {
                        fontSize: "14px",
                        color: "#B1B1B1",
                        fontWeight: 600
                    }
                }
            },
            xaxis: {
                title: {
                    text: xAxisTitle,
                    style: {
                        fontSize: "14px",
                        color: "#B1B1B1",
                        fontWeight: 600
                    }
                },
                labels: {
                    style: {
                        colors: "#B1B1B1",
                        fontSize: "12px"
                    }
                },
                axisBorder: {
                    show: true
                }, axisTicks: {
                    show: true
                },
                categories: categories || [],
            }
        },


    }
    return (
        <div id="chart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="line"
                height={400} />
        </div>
    )
}
import { logodark } from '../assets/Index';
const AnzaliLoader = () => {
  return (
    // <div className="loading-container">
    //   <div className="loading-pulse">
    //     <img src={logodark} alt="logo" />
    //   </div>
    // </div> 
    ""
  );
};

export default AnzaliLoader;

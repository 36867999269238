import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { noImageFound, document_copy_img } from '../../../assets/Index';
import {
  getDisplayAmountClassName,
  getDisplayValueWithNA,
  getTwoDigitValue,
  getValueWithDecimal,
} from '../../../utility/commonUtitlity';
import { condenseAddress } from '../../../callbacks/index';
import classNames from 'classnames';

function replaceImage(error) {
  error.target.src = noImageFound;
}
function AssetInfo({ imageUrl, title, subTitle, redirectHandler }) {
  return (
    <div className="asset-details">
      <img
        src={imageUrl || noImageFound}
        className="avatar-img"
        onError={replaceImage}
      />
      <div className="d-flex flex-column overflow-hidden">
        <span title={title} className="mb-0 fw-bold title">
          {title}
        </span>
        <span title={subTitle} className="subtitle fw-bold">
          {subTitle}
        </span>
      </div>
    </div>
  );
}

export function MenuOptions(props) {
  if (!Array.isArray(props.actionOptions)) {
    return '';
  }
  return (
    <ul className="horizontal-menu-options-container">
      {props.actionOptions.map((item, index) => (
        <React.Fragment key={item.id}>
          <li
            className="list-inline-item cursor-pointer"
            data-id={props.rowId}
            onClick={item.onClickHndlr}
            id={`${item.id}-${props.rowId}`}
          >
            {item.actionIcon}
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
}

function truncate(str, n) {
  if (!str) {
    return '';
  }
  return str.length > n ? str.slice(0, n - 1) : str;
}

export function generateColumns(menuOptions) {
  return [
    {
      id: 'id',
      Header: '#',
      accessor: 'id',
      disableSortBy: true,
      columnClassName: 'w-48px max-w-48px',
      Cell: ({ row: { index } }) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      id: 'name',
      Header: 'Assets',
      accessor: 'collection_name',
      columnClassName: 'w-300px max-w-300px ',
      Cell: ({ row: { original } }) => {
        return (
          <Link
            to={`/collection-info/${original?.collection_address}/1`}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <AssetInfo
              title={original.collection_name}
              subTitle={original.collection_symbol}
              imageUrl={original.collection_image_url}
            />
          </Link>
        );
      },
    },
    {
      id: 'contract_circulating_supply',
      Header: 'Supply',
      accessor: 'contract_circulating_supply',
      disableSortBy: true,
    },
    {
      id: 'contract_standard',
      Header: 'Standard',
      accessor: 'contract_standard',
      disableSortBy: true,
    },
    {
      id: 'item_sold',
      Header: 'Sold',
      accessor: 'item_sold',
      disableSortBy: true,
      Cell: ({ value }) => {
        return <span>{getTwoDigitValue(value)}</span>;
      },
    },
    {
      id: 'collection_cost_bias',
      Header: 'Cost Basis',
      accessor: 'collection_cost_bias',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <span className="fw-bold">
            <span className="mr-1">{getValueWithDecimal(value)}</span>
            <span className="text-muted">ETH</span>
          </span>
        );
      },
    },
    {
      id: 'collection_realized_profit',
      Header: 'Realized Profit',
      accessor: 'collection_realized_profit',
      disableSortBy: true,
      Cell: ({ value }) => {
        const profitClassName = getDisplayAmountClassName(value);
        return (
          <span className="fw-bold">
            <span className={classNames('mr-1', profitClassName)}>
              {getValueWithDecimal(value)}
            </span>
            <span className="text-muted">ETH</span>
          </span>
        );
      },
    },
    {
      id: 'menuActions',
      Header: 'Actions',
      accessor: 'menuActions',
      disableSortBy: true,
      columnClassName: 'w-120px min-w-120px',
      Cell: ({ row: { original } }) => {
        return (
          <MenuOptions
            actionOptions={menuOptions}
            rowId={original?.collection_address}
          />
        );
      },
    },
  ];
}

export function generateItemColumns(collectionAddress = '', copyClipboard) {
  return [
    {
      id: 'id',
      Header: '#',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row: { index } }) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      id: 'collection_name',
      Header: 'Assets',
      accessor: 'collection_name',
      columnClassName: 'w-300px max-w-300px ',
      Cell: ({ row: { original } }) => {
        const imageUrl = original?.nftimage ? original.nftimage['100'] : null;
        return (
          <Link
            to={`/item-info/${collectionAddress}/${original.token_id}/1`}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <AssetInfo
              title={original.collection_name}
              subTitle={`#${original.token_id}`}
              imageUrl={imageUrl}
            />
          </Link>
        );
      },
    },
    {
      id: 'marketPlace',
      Header: 'Marketplace',
      accessor: 'marketPlace',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        return (
          <div className="d-flex flex-column">
            <span className="d-flex font-14 mb-2">
              <span className="mr-1">Bought:</span>
              <span className="text-custom-primary">
                {getDisplayValueWithNA(truncate(original?.bought_at))}
              </span>
            </span>
            <span className="d-flex font-14">
              <span className="mr-1">Sold:</span>
              <span className="text-custom-primary">
                {getDisplayValueWithNA(truncate(original?.sold_at))}
              </span>
            </span>
          </div>
        );
      },
    },
    {
      id: 'standard',
      Header: 'Transaction',
      accessor: 'standard',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        return (
          <div className="d-flex flex-column">
            <span className="d-flex align-items-center font-14 mb-1">
              <span className="mr-1">Bought:</span>
              {original.bought_transcation ? (
                <span className="text-custom-primary d-flex align-items-center">
                  {condenseAddress(original.bought_transcation)}
                  <img
                    src={document_copy_img}
                    data-id={original.bought_transcation}
                    onClick={copyClipboard}
                    className="ml-1"
                    style={{
                      width: '20px',
                      height: '20px',
                      maxWidth: 'initial',
                    }}
                  />
                </span>
              ) : (
                <span className="text-custom-primary">N/A</span>
              )}
            </span>
            <span className="d-flex align-items-center font-14">
              <span className="mr-1">Sold:</span>
              {original.sold_transcation ? (
                <span className="text-custom-primary d-flex align-items-center">
                  {condenseAddress(original.sold_transcation)}
                  <img
                    src={document_copy_img}
                    data-id={original.sold_transcation}
                    onClick={copyClipboard}
                    className="ml-1"
                    style={{
                      width: '20px',
                      height: '20px',
                      maxWidth: 'initial',
                    }}
                  />
                </span>
              ) : (
                <span className="text-custom-primary">N/A</span>
              )}
            </span>
          </div>
        );
      },
    },
    {
      id: 'sold',
      Header: 'Sold',
      accessor: 'sold',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        return (
          <div className="d-flex flex-column ">
            <span className="d-flex font-14 mb-2 text-nowrap max-w-initial">
              {original.sold_for
                ? `${truncate(original.sold_for, 6)} ETH`
                : 'N/A'}
            </span>
            <span className="d-flex font-14 text-nowrap max-w-initial">
              {original.sold_ConfirmedAt
                ? `${moment(Number(original.sold_ConfirmedAt)).format(
                    'MM/DD/YY h:mm A'
                  )}+UTC`
                : 'N/A'}
            </span>
          </div>
        );
      },
    },
    {
      id: 'cost_basis',
      Header: 'Cost Basis',
      accessor: 'cost_basis',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <span className="fw-bold">
            <span className="mr-1">{getValueWithDecimal(value)}</span>
            <span className="text-muted">ETH</span>
          </span>
        );
      },
    },
    {
      id: 'realized_profit',
      Header: 'Realized Profit',
      accessor: 'realized_profit',
      disableSortBy: true,
      Cell: ({ value }) => {
        const profitClassName = getDisplayAmountClassName(value);
        return (
          <span className="fw-bold">
            <span className={classNames('mr-1', profitClassName)}>
              {getValueWithDecimal(value)}
            </span>
            <span className="text-muted">ETH</span>
          </span>
        );
      },
    },
  ];
}

import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { Bars } from 'react-loader-spinner';
import classNames from 'classnames';
import { BsArrowUp, BsArrowDown } from "react-icons/bs";
import NoDataComponent from '../NoDataComponent';


function SortingContainer({ disableSortBy, isSorted, isSortedDesc }) {
  if (disableSortBy) {
    return null;
  }
  return (
    <span className='sortingcontainer'>
      <BsArrowUp className={classNames("arrow-up", (isSorted && !isSortedDesc) ? "active" : "")} /> <BsArrowDown
        className={classNames({ "active": (isSorted && isSortedDesc) })}
      />
    </span>
  )
}
export default function Table({
  columns,
  data = [],
  tableClass,
  tableHeadClass,
  trClass,
  thClass,
  divClassName,
  hiddenColumns = [],
  isDataLoading = false,
  onClickHandler = null,
  rowDataInfo = null,
  noDataTitle = "",
  loadingMessage = ""
}) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        onClickHandler,
        rowDataInfo,
        initialState: { hiddenColumns },
      },
      useSortBy
    );

  return (
    <React.Fragment>
      <div className={classNames('table-container bordered', divClassName)}>
        <table
          {...getTableProps()}
          className={classNames('custom-table align-middle', tableClass)}
        >
          <thead className={classNames("table-head", tableHeadClass)}>
            {headerGroups.map((headerGroup) => (
              <tr
                className={trClass}
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => {
                  return (
                    <th
                      key={column.id}
                      className={classNames(
                        'text-nowrap',
                        column.columnClassName
                      )}
                      {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}
                    >
                      <span className='d-flex align-items-center'>{column.render('Header')}
                        <SortingContainer
                          disableSortBy={column.disableSortBy}
                          isSorted={column.isSorted}
                          isSortedDesc={column.isSortedDesc}
                        />
                      </span>

                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {!isDataLoading && (
              <>
                {rows.length > 0 ? (
                  <>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <React.Fragment key={row.getRowProps().key}>
                          <tr>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  key={cell.id}
                                  className={classNames(
                                    cell?.column?.columnClassName
                                  )}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td className="no_hover p-0" colSpan={columns.length}>
                      <NoDataComponent
                        title={noDataTitle || "No Result Found"}
                      />
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
        {isDataLoading && (
          <div className="loading-component">
            <Bars color="#8a8aa0" wrapperClass="mb-3" height={30} width={30} />
            <span className="mb-2">{loadingMessage || "Loading..."}</span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

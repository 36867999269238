import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
const DeletePortfolio = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered
      className="modal-delete"
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="modal-body space-y-20 pd-40">
        <h3>Remove NFT</h3>
        <div className="form-inner">
          <h4 className="text-center">
            Are you sure you want to remove this NFT from your portfolio?
          </h4>
          <div className="d-flex flex-column justify-content-between">
            <button
              className="style-4 my-4 d-flex justify-content-center align-item-center"
              onClick={() => {
                props.onHandelDelete();
                setLoading(true);
              }}
              disabled={loading}
            >
              {loading ? props.bar : <span>Confirm</span>}
            </button>
            <button
              className="sc-button header-slider style style-1  fl-button pri-1 mt-3 "
              onClick={props.onHide}
            >
              <span className="pl-0">Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePortfolio;

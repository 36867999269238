import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { apicallback } from '../../callbacks/index';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';
import { portfolioWallet, banner_bg_mobile } from '../../assets/Index';
const PageTitle = ({ meta }) => {
  const loc = useLocation();
  const authorization = useStoreState((actions) => actions.authorization);

  useEffect(() => {
    //  if (process.env.REACT_APP_SERVER == 'prod') {
    const check = localStorage.getItem('session_key');
    if (check == null) {
      localStorage.setItem(
        'session_key',
        Math.floor(10000000000 + Math.random() * 90000000000) + moment().unix()
      );
    }
    const getIp = localStorage.getItem('session_ip');
    if (getIp) {
      apicallback(
        null,
        '/createSessionRecord',
        {
          session_key: localStorage.getItem('session_key'),
          page_name: loc.pathname,
          page_key: loc.key,
          type: 1,
          time: moment.tz(moment(), 'America/New_York').format(),
          session_type: navigator.platform,
          ip_address: JSON.parse(getIp).ip,
          user_session: authorization ? 1 : 0,
          ipjson: JSON.parse(getIp),
        },
        authorization ? 'private' : null,
        authorization && authorization.token
      );
      return () => {
        apicallback(
          null,
          '/createSessionRecord',
          {
            session_key: localStorage.getItem('session_key'),
            page_name: loc.pathname,
            page_key: loc.key,
            type: 0,
            time: moment.tz(moment(), 'America/New_York').format(),
            session_type: navigator.platform,
            ip_address: JSON.parse(getIp).ip,
            user_session: authorization ? 1 : 0,
            ipjson: JSON.parse(getIp),
          },
          authorization ? 'private' : null,
          authorization && authorization.token
        );
      };
    }
    // }
  }, []);

  return (
    <Helmet prioritizeSeoTags>
     
      <link
        rel="preload"
        href={banner_bg_mobile}
        as="image"
        importance="high"
      />
      <title>
        {meta && meta.title ? meta.title : 'Explore NFT Collections and Analyze Web3 Wallets | ANZALI'}
      </title>
      <meta
        name="description"
        content={
          meta && meta.description
            ? meta.description
            : 'Explore our advanced Web3 wallet tracker. Stay in the loop with real-time email alerts, effortlessly manage your portfolio, and check rarity for your NFTs.'
        }
      />
      <link rel="canonical" href={window.location.origin} />
      <meta
        property="og:title"
        content={
          meta && meta.title ? meta.title : 'Explore NFT Collections and Analyze Web3 Wallets | ANZALI'
        }
      />
      <meta
        property="og:image"
        content={
          meta && meta.image
            ? meta.image
            : 'https://secure-assets-s3.s3.amazonaws.com/seo/Home+Page-Signin%26out.webp'
        }
      />
      <meta
        property="og:description"
        content={
          meta && meta.description
            ? meta.description
            : 'Explore our advanced Web3 wallet tracker. Stay in the loop with real-time email alerts, effortlessly manage your portfolio, and check rarity for your NFTs.'
        }
      />
      <meta
        name="twitter:title"
        content={
          meta && meta.title ? meta.title : 'Explore NFT Collections and Analyze Web3 Wallets | ANZALI'
        }
      />
      <meta
        name="twitter:description"
        content={
          meta && meta.description
            ? meta.description
            : 'Explore our advanced Web3 wallet tracker. Stay in the loop with real-time email alerts, effortlessly manage your portfolio, and check rarity for your NFTs.'
        }
      />
      <meta
        name="twitter:image"
        content={
          meta && meta.image
            ? meta.image
            : 'https://secure-assets-s3.s3.amazonaws.com/seo/Home+Page-Signin%26out.webp'
        }
      />
    </Helmet>
  );
};
export default React.memo(PageTitle);

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { img1 } from '../../../assets/Index';
const EventList = (props) => {
  const [events, setEvents] = useState([]);
  const [loader, setLoader] = useState(true);
  const [visible, setVisible] = useState(8);
  useEffect(async () => {
    await getEventsByCollection();
  }, []);
  const getEventsByCollection = async () => {
    const response = await props.apicallback(
      null,
      '/getEventsByCollection',
      {
        collection_id: props.collection_id,
        type: 0,
      },
      'private',
      props.authorization && props.authorization.token
    );
    if (response && response.status == 'success') {
      setEvents(response.data);
    }
    setLoader(false);
  };

  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 8);
  };

  return (
    <>
      {loader ? (
        props.facebookLoader
      ) : events.length > 0 ? (
        <ul className="bid-history-list">
          {events.slice(0, visible).map((item, index) => (
            <li key={index}>
              <div className="content">
                <div className="client">
                  <div className="sc-author-box style-2">
                    <div className="author-avatar">
                      <Link to={`/event/detail/${item.id}`}>
                        <img
                          src={item.image ? item.image : img1}
                          alt={item.title}
                          className="avatar"
                        />
                      </Link>
                      {/* <div className="badge"></div> */}
                    </div>
                    <div className="author-infor">
                      <div className="name">
                        <h6>
                          {' '}
                          <Link to={`/event/detail/${item.id}`}>
                            {' '}
                            {item.title}{' '}
                          </Link>
                        </h6>
                      </div>
                      <span className="time">{item.sub_title}</span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No Event found!</p>
      )}
      {visible < events.length && (
        <div className="btn-activity mg-t-10 center">
          <Link
            to="#"
            id="load-more"
            className="sc-button loadmore fl-button pri-3"
            onClick={showMoreItems}
          >
            <span>Load More</span>
          </Link>
        </div>
      )}
    </>
  );
};

export default React.memo(EventList);

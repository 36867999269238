import React, { useState } from "react";
import Subscribe from "../../assets/images/airdrops/Subscribe_icon.png";
import User1Img from "../../assets/images/airdrops/user1.png";
import { toast } from "react-toastify";
//API Actions
import { apicallback } from '../../callbacks/index';

export default function SubscribeNewsletter() {
    const [email, setEmail] = useState('');

    function onChangeHndlr(event) {
        const { name, value } = event.target;
        setEmail(value);
    }

    async function subscribeHndlr() {
        const response = await apicallback(
            null,
            `/subscribeNewsLetter`,
            {
                email
            }, null, null);
        if (response.status === 'success') {
            toast.success('Subscribed successfully.');
            setEmail('');
        } else {
            toast.error('Something went wrong.');
        }
    }
    return (
        <div className="jointelegram-container h-100">
            <div className="iconcontainer">
                <img src={Subscribe} className="subscribe" />
            </div>
            <h3 className="title">Subscribe to Our Newsletter</h3>
            <p className="description">Receive email notification about upcoming airdrops, news and other NFT and Crypto events.</p>
            <div className="actioncontainer">

                <input type="text" value={email}
                    onChange={onChangeHndlr}
                    className="emailinput" placeholder="Enter your email address" />
                <button
                    className="sc-button style-4 text-dark w-100"
                    onClick={subscribeHndlr}
                >
                    Subscribe Now
                </button>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';
import ShareUi from '../../components/share/Index';
import { useStoreState } from 'easy-peasy';
import { Link } from 'react-router-dom';
import { apicallback, copyClipboard } from '../../callbacks/index';
import { noimageselected, nobg } from '../../assets/Index';
import PageTitle from '../../components/pagetitle/PageTitle';
import {
  AiOutlineEdit,
  FiEdit2,
  FaRegCopy,
} from '../../components/icons/Index';
const CardModal = loadable(() => import('../../components/layouts/CardModal'));
const ProfileNftItem = loadable(() =>
  import('../../components/layouts/profile/ProfileNftItem')
);
const FacebookLoader = loadable(() =>
  import('../../components/content_loader/FacebookLoader')
);
const InstagramLoader = loadable(() =>
  import('../../components/content_loader/InstagramLoader')
);
const UserProfile = () => {
  const authorization = useStoreState((actions) => actions.authorization);
  const [nfts, setnfts] = useState([]);
  const [profile, setprofile] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [currentPageUrl, setCurrentPageUrl] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    if (authorization != null) {
      getData();
    } else {
      setLoaded(true);
    }
  }, []);
  const getData = async (id) => {
    const response = await apicallback(
      null,
      '/getUserInfoOnlyAdmin',
      { user_id: authorization && authorization.data.id },
      null,
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      setCurrentPageUrl(
        window.location.origin +
          '/profile/' +
          (response.data.username ? response.data.username : response.data.id)
      );
      setprofile(response.data);
      await getNFTs(
        response.data.token,
        authorization && authorization.data.id
      );
    }
  };

  const getNFTs = async () => {
    const response = await apicallback(
      null,
      '/getUserCustomProfileNft',
      {},
      'private',
      authorization && authorization.token,
      'get'
    );
    if (response && response.status == 'success') {
      setnfts(response.data);
      setLoaded(true);
    }
  };

  return (
    <div className="authors-2">
      <PageTitle
        meta={{
          title: 'My Profile',
          image: profile.banner_image ? profile.banner_image : nobg,
        }}
      />
      <section className="tf-section authors">
        <div className="themesflat-container">
          <div className="flat-tabs tab-authors">
            {loaded ? (
              <div
                className="new-profile-header-bg"
                style={{
                  backgroundImage: `url(${
                    profile.banner_image ? profile.banner_image : nobg
                  })`,
                }}
              >
                {profile && profile.banner_image ? (
                  ''
                ) : (
                  <div id="overlayBg"></div>
                )}
                <div
                  className="text-right p-2 position-absolute"
                  style={{ fontSize: '2rem', right: '15px', zIndex: '9' }}
                >
                  {authorization ? (
                    <Link to="/edit-profile">
                      <FiEdit2 />
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
                <div className="new-profile-header">
                  <div className="position-relative">
                    <img
                      src={
                        profile && profile.profile_image
                          ? profile.profile_image
                          : noimageselected
                      }
                      style={{
                        borderRadius: '50%',
                        border:
                          profile && profile.profile_image
                            ? '4px solid #f6c946'
                            : 'none',
                      }}
                      width="120"
                      alt={
                        profile && profile.username
                          ? profile.username
                          : 'username'
                      }
                      className="avatar"
                    />
                    {authorization ? (
                      <Link to="/edit-profile">
                        <AiOutlineEdit />
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                  <h3 className="title mt-3 text-lowercase">
                    @
                    {profile && profile.username
                      ? profile.username
                      : 'username'}
                  </h3>
                  {authorization ? (
                    <div className="widget-social style-3 mt-4">
                      <ShareUi
                        url={currentPageUrl}
                        title={'Check out my profile on Anzali: '}
                        logo=""
                        copyClipboard={copyClipboard}
                        cls="d-flex justify-content-between align-content-between"
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <FacebookLoader
                value={1}
                coloum={8}
                center={true}
                fallback={<div>Loading...</div>}
              />
            )}
          </div>
        </div>
        {loaded ? (
          <ProfileNftItem
            data={nfts}
            showanim={loaded}
            isCheck={true}
            fallback={<div>Loading...</div>}
            instagramLoader={
              <InstagramLoader
                value={6}
                coloum={4}
                is_mobile={2}
                fallback={<div>Loading...</div>}
              />
            }
          />
        ) : (
          ''
        )}
      </section>
      <CardModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        fallback={<div>Loading...</div>}
      />
    </div>
  );
};

export default UserProfile;

import React, { useMemo, useState } from 'react';
import { openToasterAlert } from '../../../components/alert/Index';
import {
  ServiceTab,
  StartRequestTab,
  TimelineTab,
  ContactInformationTab,
  DescriptionTab,
  ThankyouTab,
} from './TabsComponent';
import { useRef } from 'react';
import { useCallback } from 'react';
import { tabsOptions, createUserServicePayload } from './helper';
//API Services
import { submitUserService } from '../../../apis/Index';

function getActiveMenuComponent(tabId) {
  switch (tabId) {
    case 'start-request':
      return StartRequestTab;
    case 'service-request':
      return ServiceTab;
    case 'timeline':
      return TimelineTab;
    case 'contact-information':
      return ContactInformationTab;
    case 'description':
      return DescriptionTab;
    case 'thankyou':
      return ThankyouTab;
  }
}

export default function MembershipRequestModal({ closeModalHndlr }) {
  const [activeTabIdState, setActiveTabIdState] = useState('start-request');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tabsDataRef = useRef({});

  async function submitUserServiceApiCall() {
    try {
      setIsSubmitting(true);
      const payload = createUserServicePayload(tabsDataRef.current);
      const response = await submitUserService(payload);
      setIsSubmitting(false);
      if (response.status === 'success') {
        tabsDataRef.current = {};
        nextTabHndlr(undefined, true);
        openToasterAlert('success', 'Successfully submitted your request.');
      } else {
        openToasterAlert(
          'error',
          'Error while submitting request. Try after some time.'
        );
      }
    } catch (e) {
      setIsSubmitting(false);
      openToasterAlert('error', 'Something went wrong.');
    }
  }

  const nextTabHndlr = useCallback(
    (tabsData, isSubmitted = false) => {
      tabsDataRef.current[activeTabIdState] = tabsData;
      if (activeTabIdState === 'contact-information' && !isSubmitted) {
        submitUserServiceApiCall();
        return;
      }
      const activeTabIndex = tabsOptions.findIndex(
        (data) => data.tabId === activeTabIdState
      );
      if (activeTabIndex > -1) {
        const { tabId } = tabsOptions[activeTabIndex + 1];
        setActiveTabIdState(tabId);
      }
    },
    [activeTabIdState]
  );

  const backTabHndlr = useCallback(
    (tabsData) => {
      tabsDataRef.current[activeTabIdState] = tabsData;
      const activeTabIndex = tabsOptions.findIndex(
        (data) => data.tabId === activeTabIdState
      );
      if (activeTabIndex > 0) {
        const { tabId } = tabsOptions[activeTabIndex - 1];
        setActiveTabIdState(tabId);
      }
    },
    [activeTabIdState]
  );

  const activeTabComponent = useMemo(() => {
    const TabComponent = getActiveMenuComponent(activeTabIdState),
      activeTabData = tabsDataRef.current[activeTabIdState];
    if (TabComponent) {
      return (
        <TabComponent
          activeTabId={activeTabIdState}
          nextTabHndlr={nextTabHndlr}
          backTabHndlr={backTabHndlr}
          tabData={activeTabData}
          closeModalHndlr={closeModalHndlr}
          isSubmitting={isSubmitting}
        />
      );
    } else {
      return <></>;
    }
  }, [
    activeTabIdState,
    nextTabHndlr,
    backTabHndlr,
    closeModalHndlr,
    isSubmitting,
  ]);

  return (
    <div>
      {activeTabComponent}
      {/* <div className="request-container">
                <RequestHeader />
            </div> */}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { apicallback } from '../../callbacks/index';
import { useStoreState } from 'easy-peasy';
import Fullwidth from '../../components/Image/fullwidth';
import { timerimg, userimg } from '../../assets/Index';
import PageTitle from '../../components/pagetitle/PageTitle';
const Details = () => {
  const authorization = useStoreState((actions) => actions.authorization);
  const params = useParams();
  const [details, setDetails] = useState({});
  useEffect(async () => {
    await getEventsById(params.id);
  }, []);

  const getEventsById = async (id) => {
    const response = await apicallback(
      null,
      '/getEventsById',
      {
        event_id: params.id,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      setDetails(response.data);
    }
  };
  return (
    <div className="item-details">
      <PageTitle
        meta={{
          title: details ? details.title : '',
          description: details.description,
          image: details ? details.image : '',
        }}
      />
      <div className="tf-section tf-item-details">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-xl-5 col-md-12">
              <div className="content-left">
                <div className="media">
                  <Fullwidth
                    image={{
                      src: details ? details.image : '',
                      height: '100%',
                      width: '100%',
                      alt: details ? details.title : '',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <h2 className="style2">{details.title} </h2>
                  <p>{details.sub_title}</p>
                  <div className="client-infor sc-card-product mt-4">
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={userimg} alt="Created By" />
                        </div>
                        <div className="info">
                          <span>Created By</span>
                          <h6>
                            {' '}
                            <Link to="#">{details.username}</Link>{' '}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={timerimg} alt="Created At" />
                        </div>
                        <div className="info">
                          <span>Created At</span>
                          <h6>
                            {' '}
                            <Link to="#">
                              {moment(details.created_at).format('MMMM Do YY')}
                            </Link>{' '}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="client-infor sc-card-product mt-4">
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={timerimg} alt="Start Date" />
                        </div>
                        <div className="info">
                          <span>Start Date</span>
                          <h6>
                            <Link to="#">
                              {moment(details.start_date).format(
                                'MMMM Do YY, h:mm:ss A'
                              )}
                            </Link>
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={timerimg} alt="End Date" />
                        </div>
                        <div className="info">
                          <span>End Date</span>
                          <h6>
                            {moment(details.end_date).format(
                              'MMMM Do YY, h:mm:ss A'
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-10 mz-auto mt-5">
              <div dangerouslySetInnerHTML={{ __html: details.description }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;

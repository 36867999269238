import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { img1, lock } from '../../../assets/Index';
import { BulletList } from 'react-content-loader';
import moment from 'moment-timezone';
import axios from 'axios';
const Utility = (props) => {
  const [events, setEvents] = useState([]);
  const [verifyStauts, setVerifyStauts] = useState(props.verifyStauts);
  const [message, setMessage] = useState(props.message);
  const [loading, setLoading] = useState(false);
  const [contentLoader, setContentLoader] = useState(false);
  const [showText, setshowText] = useState(false);
  var interval;
  const verifyUser = async () => {
    setLoading(true);
    const response = await props.apicallback(
      null,
      '/verifyUser',
      {
        collection_id: props.collection_id,
        page: 'proof',
        type: 'proof',
      },
      'private',
      props.authorization && props.authorization.token
    );
    if (response && response.status == 'success') {
      setMessage(response.data.message);
      setContentLoader(true);
      interval = setInterval(async () => {
        await getVerificationStatus();
      }, 10000);
    }
    setLoading(false);
  };

  const getVerificationStatus = async () => {
    const response = await props.apicallback(
      null,
      '/getVerificationStatus',
      {
        collection_id: props.collection_id,
      },
      'private',
      props.authorization && props.authorization.token
    );
    if (response && response.status == 'success') {
      if (response.verification_status == 'true') {
        setshowText(true);
        if (props.deployed_tier_id && response.verification_status == 'true') {
          await getNftByTeirId(response.data.nfts);
        } else {
          setEvents(
            response.verification_status == 'true' ? response.data.nfts : []
          );
        }
      }
      if (interval != null) {
        if (response.verification_status != 'pending') {
          clearInterval(interval);
        }
      }
    }
  };

  async function getNftByTeirId(nft) {
    try {
      if (nft.length) {
        let address = '';
        const getIds = nft.map((v) => {
          address = v.token_address;
          return v.token_id;
        });

        const response = await axios.get(
          `https://business-api-stage.anzali.io/api/getTierbyNft?nft_ids=${getIds}&collection_address=${address}`,
          {
            headers: {
              'content-type': 'application/json',
            },
          }
        );
        if (response.data.status == 'success') {
          const filteredArray = nft.filter((item) => {
            // Check if the item's token_id exists in the second array
            return response.data.data.some(
              (secondItem) => secondItem.token_id == item.token_id
            );
          });
          setEvents(filteredArray);
        }
      } else {
        setEvents(nft);
      }
      //deployed_tier_id
    } catch {
      setEvents(nft);
    }
  }

  return (
    <>
      {contentLoader ? (
        <>
          {events.length <= 0 ? (
            <div className="col-sm-4">
              <BulletList speed={3} foregroundColor={'#ccc'} />
            </div>
          ) : (
            <>
              <div className="div">
                {showText ? (
                  <p>
                    The below assets are confirmed in this user's wallet as of{' '}
                    <span className="text-main">
                      {moment
                        .tz(
                          moment().format('YYYY-MM-DD H:mm:ss'),
                          'America/New_York'
                        )
                        .format('MM/DD/YYYY h:mm A')}
                    </span>
                  </p>
                ) : (
                  ''
                )}
              </div>
              <ul className="bid-history-list">
                {events.map((item, index) => (
                  <li
                    key={index}
                    data-aos="flip-up"
                    data-aos-duration={1000 * index}
                  >
                    <div className="content">
                      <div className="client">
                        <div className="sc-author-box style-2">
                          <div className="author-avatar">
                            <Link to="#">
                              <img
                                src={item.image_url ? item.image_url : img1}
                                alt={item.name}
                                className="avatar"
                              />
                            </Link>
                            {/* <div className="badge"></div> */}
                          </div>
                          <div className="author-infor">
                            <div className="name">
                              <h6>
                                <Link to="#">
                                  {item && item.token_id
                                    ? `#${item.token_id} `
                                    : ' '}
                                  {item.name}
                                </Link>
                              </h6>
                            </div>
                            <span className="time">
                              {item.contract_type == 'ERC-20' ? (
                                <span>{item?.amount} </span>
                              ) : (
                                ''
                              )}

                              {props?.tier_name || ''}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      ) : (
        <>
          {verifyStauts == true ? (
            <>
              {message ? (
                <p className="mt-5 text-center">{message}</p>
              ) : (
                <div className="col-md-6 mx-auto text-center">
                  <img
                    src={lock}
                    width="250"
                    className="mt-5"
                    style={{ margin: '0 auto' }}
                    alt="Lock"
                  />
                  {message ? (
                    <p className="mt-5">{message}</p>
                  ) : (
                    <button
                      className="sc-button style-4 mt-5"
                      style={{ margin: '0 auto' }}
                      onClick={() => verifyUser()}
                      disabled={loading}
                    >
                      {loading ? 'Please Wait...' : ' Prove Ownership'}
                    </button>
                  )}
                </div>
              )}
            </>
          ) : (
            <div className="col-md-6 mx-auto text-center">
              <img
                src={lock}
                width="250"
                className="mt-5"
                style={{ margin: '0 auto' }}
                alt="lock"
              />
              {message ? (
                <p className="mt-5">{message}</p>
              ) : (
                <button
                  className="sc-button style-4 mt-5"
                  style={{ margin: '0 auto' }}
                  onClick={() => verifyUser()}
                  disabled={loading}
                >
                  {loading ? 'Please Wait...' : 'Prove Ownership'}
                </button>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(Utility);

import React, { useEffect } from 'react';
import { mainLogo } from '../../assets/Index';
const DisconnectWalletConnect = () => {
  useEffect(() => {
    setTimeout(
      function () {
        document.getElementById('header_main').remove();
        document.getElementById('footer').remove();
      }.bind(this),
      1000
    );
    localStorage.clear();
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      var apns = {
        launchApp: function () {
          window.location = `com.nftsapp://wallet-connect?wallet=`;
          this.timer = setTimeout(this.openWebApp, 500);
        },
      };
    } else {
      var apns = {
        launchApp: function () {
          window.location = `app://com.nftchills?wallet=`;
          this.timer = setTimeout(this.openWebApp, 500);
        },
      };
    }
    apns.launchApp();
  }, []);

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-item-center py-5 my-5 px-5 text-center">
        <img
          src={mainLogo}
          className="mb-4"
          width="250"
          style={{ margin: '0 auto' }}
          alt="Wallet Disconnected Successfully Redirecting to app ..."
        />
        <button className="style-4 sc-button">
          Wallet Disconnected Successfully Redirecting to app ...
        </button>
      </div>
    </>
  );
};

export default DisconnectWalletConnect;

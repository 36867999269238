import { useState } from 'react';
import { apicallback } from '../callbacks/index';
import { openToasterAlert } from '../components/alert/Index';

export default function useSearchWallet(authorization) {
  const [isLoadingSearch, setIsLoadingSearchState] = useState(false);
  const [redirectUrlState, setRedirectUrlState] = useState('');
  const searchWalletHandler = async (walletAddress) => {
    if (!walletAddress) {
      return;
    }
    setIsLoadingSearchState(true);
    if (authorization) {
      try {
        const response = await apicallback(
          null,
          '/createWalletsV2',
          {
            name: `Wallet`,
            user_id: authorization.data.id.toString(),
            address: walletAddress,
            chain_id: '1',
            hexCode: '#fec60d',
          },
          'private',
          authorization.token
        );
        if (response && response.status == 'success') {
          localStorage.setItem('is_recent_search', 1);
          setRedirectUrlState(
            `/wallet-info/${response.data.wallet_id}/${response.data.address}`
          );
        } else {
          setIsLoadingSearchState(false);
          openToasterAlert(
            'error',
            response.message ? response.message : 'Wallet address is not valid.'
          );
        }
      } catch (error) {
        setIsLoadingSearchState(false);
        openToasterAlert('error', 'Wallet address is not valid.');
      }
    } else {
      handelPublicWalletSubmit(walletAddress);
    }
  };

  const handelPublicWalletSubmit = async (walletAddress) => {
    const response = await apicallback(
      null,
      `/convertAddress?address=${walletAddress}`,
      {},
      null,
      null,
      'get'
    );

    if (response && response.status == 'success') {
      localStorage.setItem('is_recent_search', 1);
      let c = {};
      const address = response?.data?.address;
      c[address] = {
        wallet_id: Object.keys(c).length + 1,
        name: `Wallet ${Object.keys(c).length + 1}`,
        address: address,
        collection_count: 0,
        nft_count: 0,
        ens_count: 0,
      };
      localStorage.setItem('open_wallets', JSON.stringify(c));
      setIsLoadingSearchState(false);
      setRedirectUrlState(`/wallet-info/${c[address].wallet_id}/${address}`);
    } else {
      setIsLoadingSearchState(false);
      openToasterAlert('error', 'Wallet address is not valid.');
    }
  };

  return {
    isLoadingSearch,
    redirectUrl: redirectUrlState,
    searchWalletHandler,
  };
}

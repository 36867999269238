import React from 'react';
import classNames from 'classnames';

export default function InputText(props) {
  return (
    <div className={props.containerClassName}>
      <label className={classNames('form-label', props.labelClassName)}>
        {props.labelName}
        {props.isOptional && (
          <span className="helper-label font-14"> (Optional)</span>
        )}
      </label>
      <input
        className={classNames('custom-input-control', {
          'in-valid': props.invalid,
        })}
        id={props.name}
        name={props.name}
        type={props.type || 'text'}
        placeholder={props.placeHolder}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        autoComplete="off"
      />
      {props.invalid && (
        <div className="custom-invalid-error">{props.error}</div>
      )}
    </div>
  );
}

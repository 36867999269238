import React from 'react';
import { ImCheckmark } from '../../../src/components/icons/Index';
import classNames from 'classnames';

export default function InputCheckbox(props) {
  return (
    <div className={props.containerClassName}>
      <div className={classNames("checkbox-control", props.checkboxClassName)}>
        <input
          type="checkbox"
          id={props.name}
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
        />
        <label className="checkbox-label" htmlFor={props.name}>
          <span className="checkmark">
            <ImCheckmark className="check-icon" />
          </span>
          {props.children}
        </label>
      </div>
      {props.invalid && (
        <div className="custom-invalid-error">{props.error}</div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { apicallback } from '../../callbacks/index';
const Terms = () => {
  const [terms, setterms] = useState(null);
  useEffect(async () => {
    const response = await apicallback(
      null,
      '/getTermsAndConditions',
      {},
      null,
      null,
      'get'
    );
    if (response && response.status == 'success') {
      setterms(response.data.terms_and_conditions);
    }
  });
  return (
    <section
      className="tf-section our-creater dark-style2"
      style={{ padding: '50px 0 59px' }}
    >
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="tf-title style4 mg-bt-38">Terms of Use</h2>
            <br></br>
            <div dangerouslySetInnerHTML={{ __html: terms }} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Terms;

import { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import loadable from '@loadable/component';
import { apicallback } from '../../callbacks/index';
import PageTitle from '../../components/pagetitle/PageTitle';
import { Bars } from 'react-loader-spinner';
import { openToasterAlert } from '../../components/alert/Index';
import moment from 'moment';
const LoginSignUp = loadable(() =>
  import('../../components/models/LoginSignUp')
);
const Events = () => {
  const authorization = useStoreState((state) => state.authorization);
  const [showModal, setModalShowSignup] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [rsvp, setRsvp] = useState(null);
  const [btnLoader, setBtnLoader] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    const response = await apicallback(
      null,
      `/getRsvpEvents`,
      {
        collection_address: '0x5ac30d58f6c62775bc9e67e65e35aa29c1d82c44',
      },
      null,
      null
    );

    if (response && response.status == 'success') {
      setEventList(response.data);
      if (authorization) checkUserRsvp(response.data[0].id);
    } else {
    }
  };

  const checkUserRsvp = async (_id) => {
    const response = await apicallback(
      null,
      '/getUserRsvp',
      {
        event_id: _id,
      },
      'private',
      authorization.token
    );
    if (response && response.status == 'success') {
      setRsvp(response.data);
      setBtnLoader(false);
    }
  };
  const handelRsvp = async (_id, undo, enabled) => {
    if (authorization) {
      setBtnLoader(true);
      if (enabled) {
        if (token != '' || undo == true) {
          const response = await apicallback(
            null,
            '/updateUserRsvp',
            {
              event_id: _id,
              token_id: token,
            },
            'private',
            authorization.token
          );
          if (response && response.status == 'success') {
            checkUserRsvp(eventList[0].id);
          }
        } else {
          setBtnLoader(false);
          openToasterAlert(
            'error',
            'Please fill out the required field and try again.'
          );
        }
      } else {
        const response = await apicallback(
          null,
          '/updateUserRsvp',
          {
            event_id: _id,
            token_id: token,
          },
          'private',
          authorization.token
        );
        if (response && response.status == 'success') {
          checkUserRsvp(eventList[0].id);
        }
      }
    } else {
      setModalShowSignup(true);
    }
  };

  return (
    <>
      <PageTitle
        meta={{
          title: "Lion's Milk Event for NFT.NYC on 4/13",
          description:
            "We're hosting an event for all Ape holders at our cafe in Williamsburg, Brooklyn located at 104 Roebling St, Brooklyn, NY.",
          image:
            'https://i.seadn.io/gcs/files/7b336b0f5138c84c3d8d8cc8cd6d5158.jpg?w=500&auto=format',
        }}
      />
      <div className="item-details">
        <div className="tf-section tf-item-details">
          <div className="container">
            <div className="row">
              {eventList.length > 0
                ? eventList.map((val, index) => (
                    <div
                      className="col-lg-4 col-md-8 col-12 mx-auto text-center"
                      key={index}
                    >
                      <h4 className="text-center mb-3 text-main">
                        {val?.title}
                      </h4>
                      <p className="text-center mb-4">{val?.description}</p>
                      <img
                        src="https://i.seadn.io/gcs/files/7b336b0f5138c84c3d8d8cc8cd6d5158.jpg?w=500&auto=format"
                        style={{ borderRadius: '20px', width: '250px' }}
                      />

                      {moment().format('YYYY-MM-DD') >
                      moment(val.end_date).format('YYYY-MM-DD') ? (
                        <h2 className="mt-5 text-main ">
                          This event has ended
                        </h2>
                      ) : (
                        <>
                          {' '}
                          {val.enabled ? (
                            rsvp && rsvp?.attending ? (
                              <h6 className="mt-4">
                                Token Number: {rsvp?.token_id}
                              </h6>
                            ) : (
                              <input
                                id="wallet-address"
                                className="mt-4 w-100 px-3 py-4"
                                placeholder={val.placeholder}
                                onChange={(e) =>
                                  setToken(e.target.value.replace(' ', ''))
                                }
                                value={token}
                              />
                            )
                          ) : (
                            ''
                          )}
                          <div>
                            <button
                              className="sc-button styl-5 mt-4"
                              onClick={() =>
                                handelRsvp(
                                  val.id,
                                  rsvp && rsvp.attending,
                                  val.enabled
                                )
                              }
                            >
                              {btnLoader ? (
                                <Bars color="#ffffff" height={30} width={80} />
                              ) : rsvp && rsvp.attending ? (
                                'Undo'
                              ) : (
                                'RSVP Now'
                              )}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ))
                : ''}
            </div>
          </div>
        </div>
      </div>
      <LoginSignUp
        show={showModal}
        onHide={() => setModalShowSignup(false)}
        text={'Sign up or sign in to RSVP to this event.'}
        url={false}
      />
    </>
  );
};

export default Events;

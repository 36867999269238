import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { apicallback } from '../../callbacks/index';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {
  news_calendar,
  news_user,
  news_read_more_arrow_right,
} from '../../assets/Index';
import './_articles.scss';
const Articles = () => {
  const list = useStoreState((state) => state.articles);
  const setlist = useStoreActions((actions) => actions.setarticles);
  useEffect(() => {
    if (list.length == 0) {
      getTopCollections();
    }
  }, []);
  const getTopCollections = async (slug) => {
    const response = await apicallback(
      'content',
      '/articles',
      { limit: 8 },
      null,
      null
    );
    if (response && response.status == 'success') {
      setlist(response.data);
    }
  };
  return (
    <section className="section-top-padding">
      <div className="row">
        <div className="col-md-12">
          <div className="heading-live-auctions mg-bt-21">
            <h2 className="tf-title pad-l-7">Latest News</h2>
            <Link
              className="sc-button style-4 text-dark font-weight-bold fs-16 d-none d-md-block"
              to="/nft-news"
            >
              View All
            </Link>
          </div>
        </div>
        {list.length > 0 ? (
          list.map((item, index) => (
            <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-12 mb-4">
              <div className={`articles-card`}>
                <Link
                  to={`/news/${item.slug ? item.slug : item.article_id}`}
                  aria-label={item.title ? `${item.title}` : `#`}
                >
                  <LazyLoadImage
                    alt={item.title}
                    src={item.image}
                    //wrapperClassName="mint-img"
                    effect="blur"
                  />
                </Link>
                <div className="news-body p-4">
                  <h5>
                    <Link
                      to={`/news/${item.slug ? item.slug : item.article_id}`}
                      alt={item.title ? `${item.title}` : `#`}
                    >
                      {item.title ? `${item.title}` : `#`}
                    </Link>
                  </h5>
                  <p className="mt-4">
                    {item.content_2}{' '}
                    <Link
                      className="read-more"
                      to={`/news/${item.slug ? item.slug : item.article_id}`}
                    >
                      Read more <img src={news_read_more_arrow_right} />
                    </Link>
                  </p>
                  <div className="tags mt-5 d-flex">
                    <span className="mr-3 d-flex align-items-center">
                      <img src={news_calendar} className="mr-1" />
                      {item.date ? item.date : '-'}
                    </span>
                    <span className="d-flex align-items-center">
                      <img src={news_user} className="mr-1" />
                      {item.author}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h4 className="mt-5 text-center w-100">
            No articles to see at the moment.
          </h4>
        )}
      </div>
      <Link
        className="sc-button style-4 text-dark font-weight-bold fs-16 d-block d-md-none w-100 text-center"
        to="/nft-news"
      >
        View All
      </Link>
    </section>
  );
};

export default Articles;

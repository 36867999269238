import React from 'react';
import moment from 'moment';
import classNames from 'classnames';
import {
  getValueWithDecimal,
  getTwoDigitValue,
  getDisplayAmountClassName,
  chainList,
} from '../../utility/commonUtitlity';
import { condenseAddress } from '../../callbacks/index';
import { document_copy_img } from '../../assets/Index';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export function MenuOptions(props) {
  if (!Array.isArray(props.actionOptions)) {
    return '';
  }
  return (
    <ul className="horizontal-menu-options-container">
      {props.actionOptions.map((item, index) => (
        <React.Fragment key={item.id}>
          <li
            className="list-inline-item cursor-pointer"
            data-id={props.rowId}
            onClick={item.onClickHndlr}
            id={`${item.id}-${props.rowId}`}
          >
            {item.actionIcon}
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
}
export function MenuOptions2(props) {
  if (!Array.isArray(props.actionOptions)) {
    return '';
  }
  return (
    <ul className="horizontal-menu-options-container">
      {props.actionOptions.map((item, index) => (
        <React.Fragment key={item.id}>
          <li
            className="list-inline-item cursor-pointer"
            data-id={props.rowId}
            onClick={() => item.onClickHndlr(props.address)}
            id={`${item.id}-${props.rowId}`}
          >
            {item.actionIcon}
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
}

// ({ row: { original, index } }) => {
//     const { listening = false, wallet_id } = original || {};
//     return (

function SubscriptionButton({
  row: { original, index },
  rowDataInfo,
  onClickHandler,
}) {
  const { email_alerts = false, wallet_id, chain_id } = original || {};
  const [checkedState, setCheckedState] = useState(email_alerts);
  const { isAuthorize } = rowDataInfo || {};
  useEffect(() => {
    setCheckedState(email_alerts);
  }, [email_alerts]);

  function btnHandler(event) {
    if (isAuthorize) {
      setCheckedState(!checkedState);
    }
    onClickHandler(event);
  }
  const supportedChainIds = [1, 2, 6];
  return supportedChainIds.includes(parseInt(chain_id)) ? (
    <div>
      <input
        className="tgl tgl-skewed"
        id={`cb3-${wallet_id}${index}`}
        data-id={wallet_id}
        type="checkbox"
        checked={checkedState}
        onChange={btnHandler}
      />
      <label
        className="tgl-btn"
        data-tg-off="Subscribe"
        data-tg-on="Unsubscribe"
        htmlFor={`cb3-${wallet_id}${index}`}
      />
    </div>
  ) : (
    ''
  );
}

export function generateColumns(
  menuOptions = [],
  copyClipboard = () => {},
  redirectHandlr = () => {},
  subscribeHandler = () => {}
) {
  return [
    {
      id: 'id',
      Header: '#',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row: { index } }) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      id: 'name',
      Header: 'Wallet Name',
      accessor: 'name',
      columnClassName: 'max-w-180px',
      // columnClassName: "w-180px max-w-180px",
      Cell: ({ row: { index, original } }) => {
        const { wallet_id, hexCode, name } = original;
        return (
          <div className="d-flex">
            <a
              data-id={wallet_id}
              href="#"
              onClick={redirectHandlr}
              className="wallet-details"
            >
              <span
                className="wallet-icon"
                style={{ backgroundColor: hexCode || '#FEC60D' }}
              ></span>
              <span title={name} className="wallet-name">
                {name}
              </span>
            </a>
          </div>
        );
      },
    },
    {
      id: 'wallet_address',
      Header: 'Wallet Address',
      disableSortBy: true,
      // columnClassName: "w-180px min-w-180px",
      Cell: ({ row: { index, original } }) => {
        const { address } = original;
        return (
          <div
            className="d-flex align-items-center"
            data-id={address}
            onClick={copyClipboard}
          >
            <span className="mr-1">{condenseAddress(address)}</span>
            <img
              src={document_copy_img}
              style={{ width: '20px', height: '20px' }}
            />
          </div>
        );
      },
    },
    {
      id: 'chain_id',
      Header: 'Chain',
      disableSortBy: true,
      // columnClassName: "w-180px min-w-180px",
      Cell: ({ row: { index, original } }) => {
        const { chain_id } = original;
        return (
          <div className="d-flex align-items-center">
            <span className="mr-1">
              {chain_id == 6 ? 'BASE' : chainList(chain_id)}
            </span>
          </div>
        );
      },
    },
    {
      id: 'collection_count',
      Header: 'Collections',
      accessor: 'collection_count',
      disableSortBy: true,
      Cell: ({ value }) => {
        return <span className="fw-bold ml-2">{getTwoDigitValue(value)}</span>;
      },
    },
    {
      id: 'nft_count',
      Header: 'NFTs',
      accessor: 'nft_count',
      disableSortBy: true,
      columnClassName: 'w-100px min-w-100px',
      Cell: ({ value }) => {
        return <span className="fw-bold ml-2">{getTwoDigitValue(value)}</span>;
      },
    },
    {
      id: 'ens_count',
      Header: 'ENS',
      accessor: 'ens_count',
      disableSortBy: true,
      columnClassName: 'w-100px min-w-100px',
      Cell: ({ value }) => {
        return <span className="fw-bold ml-2">{getTwoDigitValue(value)}</span>;
      },
    },
    {
      id: 'profit',
      Header: 'Realized Profits',
      disableSortBy: true,
      accessor: 'profit',
      Cell: ({ row: { index, original } }) => {
        const { chain_id, profit } = original;
        const profitClassName = getDisplayAmountClassName(profit);
        return (
          <span className="fw-bold">
            <span className={classNames('mr-1', profitClassName)}>
              {chain_id == 1 ? getValueWithDecimal(profit) : '-'}
            </span>
          </span>
        );
      },
    },
    {
      id: 'latest_activity',
      Header: 'Last Activity',
      disableSortBy: true,
      accessor: 'latest_activity',
      Cell: ({ value }) => {
        const dateInfo = value
            ? `${moment(value).utc().format('MMM DD, YYYY | hh:mm A')} +UTC`
            : '-',
          timeInfo = value ? moment(value).utc().fromNow(true) : '-';
        return (
          <div className="d-flex flex-column text-nowrap max-w-none">
            <span className="lh-title">{timeInfo}</span>
            <span className="font-14 lh-title text-muted">{dateInfo}</span>
          </div>
        );
      },
      // columnClassName: "w-220px min-w-220px",
      // Cell: ({ value }) => {
      //     return <span className="d-flex flex-column">-
      //         {/* <span className="mb-2">25 sec ago</span>
      //         <span className="font-14 text-neutral">{value}</span> */}
      //     </span>;
      // },
      // Cell: ({ value }) => {
      //     const dateInfo = value ? moment(value).format('MM-DD-YYYY') : "-",
      //         timeInfo = value ? moment(value).format('h:mm A') : "-";
      //     return <div className='d-flex flex-column text-nowrap max-w-none'>
      //         <span className='mb-1'>
      //             {timeInfo}
      //         </span>
      //         <span>{dateInfo}</span>
      //     </div>
      // },
    },
    {
      id: 'subscription',
      Header: 'Email Alerts',
      accessor: 'subscription',
      disableSortBy: true,
      columnClassName: 'overflow-hidden',
      Cell: SubscriptionButton,
    },
    {
      id: 'menuActions',
      Header: 'Actions',
      accessor: 'menuActions',
      disableSortBy: true,
      columnClassName: 'w-120px min-w-120px',
      Cell: ({ row: { original } }) => {
        return (
          <MenuOptions
            actionOptions={menuOptions}
            rowId={original?.wallet_id}
          />
        );
      },
    },
  ];
}

export function generateSuggestedColumns(
  menuOptions = [],
  copyClipboard = () => {},
  handleSubmitForSuggested
) {
  return [
    {
      id: 'name',
      Header: 'Wallet Name',
      accessor: 'name',
      columnClassName: 'max-w-180px',
      // columnClassName: "w-180px max-w-180px",
      Cell: ({ row: { index, original } }) => {
        const { id, image, name, address } = original;
        return (
          <div className="d-flex">
            <Link
              data-id={id}
              to="#"
              onClick={() => handleSubmitForSuggested(address, 1)}
              className="wallet-details"
            >
              <img src={image} style={{ width: '30px', borderRadius: '5px' }} />
              <span title={name} className="wallet-name ml-2">
                {name}
              </span>
            </Link>
          </div>
        );
      },
    },
    {
      id: 'wallet_address',
      Header: 'Wallet Address',
      disableSortBy: true,
      // columnClassName: "w-180px min-w-180px",
      Cell: ({ row: { index, original } }) => {
        const { address } = original;
        return (
          <div
            className="d-flex align-items-center"
            data-id={address}
            onClick={copyClipboard}
          >
            <span className="mr-1">{condenseAddress(address)}</span>
            <img
              src={document_copy_img}
              style={{ width: '20px', height: '20px' }}
            />
          </div>
        );
      },
    },
    {
      id: 'menuActions',
      Header: 'Actions',
      accessor: 'menuActions',
      disableSortBy: true,
      columnClassName: 'w-120px min-w-120px',
      Cell: ({ row: { original } }) => {
        return (
          <MenuOptions2
            actionOptions={menuOptions}
            rowId={original?.id}
            address={original.address}
          />
        );
      },
    },
  ];
}

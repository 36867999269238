import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Bars } from 'react-loader-spinner';
const DeleteModel = (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <Modal.Header closeButton></Modal.Header>
      <div className="modal-body space-y-20 pd-40">
        <h3>{props.title}</h3>
        <div className="form-inner">
          <h4 className="text-center">{props.text}</h4>
          <div className="d-flex flex-column justify-content-between">
            <button
              className="style-4 my-4 d-flex justify-content-center align-item-center"
              onClick={() => {
                props.delete();
                setLoading(true);
                setTimeout(() => {
                  props.onHide();
                  setLoading(false);
                }, 1000);
              }}
              disabled={loading}
            >
              {loading ? (
                <Bars color="#ffffff" height={30} width={80} />
              ) : (
                <span>Confirm</span>
              )}
            </button>
            <button
              className="sc-button header-slider style style-1  fl-button pri-1 mt-3 "
              onClick={props.onHide}
            >
              <span className="pl-0">Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModel;

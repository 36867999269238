import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import AssetCard from '../../components/card/assetscard';
import { BsArrowRightShort } from '../../components/icons/Index';
import LoadingCard from '../../components/card/LoadingCard';
import { getValueWithDecimal } from '../../utility/commonUtitlity';
import { noImageFound } from '../../assets/Index';
//Helper
import NoDataComponent from '../../components/NoDataComponent';
import { openUrl } from '../../callbacks/index';

export default function EnsAssets({ walletCountInfo }) {
  const { wallet_address: walletAddress, chain_id: chainIdFromUrl } =
    useParams();
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    action: '',
  });
  ////V2
  const [ensAssetsStateV2, setEnsAssetsStateV2] = useState([]);
  const [assetsOffsetStateV2, setAssetsOffsetStateV2] = useState(null);
  const [noDataFoundStateV2, setNoDataFoundStateV2] = useState(false);

  useEffect(() => {
    if (walletAddress) {
      getEnsAssetsApiCall();
    }
  }, [walletAddress]);

  async function getEnsAssetsApiCall(cursor = null) {
    setLoadingState({
      isLoading: true,
    });
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_NODE_ENDPOINT_V2
        }/getEns?address=${walletAddress}${cursor ? `&pagekey=${cursor}` : ''}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      if (response.data.status === 200) {
        const res = response.data.data;
        if (res.ownedNfts.length === 0 && ensAssetsStateV2.length === 0) {
          setNoDataFoundStateV2(true);
        }
        setEnsAssetsStateV2([...ensAssetsStateV2, ...res.ownedNfts]);
        setAssetsOffsetStateV2(res.pageKey);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingState({
        isLoading: false,
        action: '',
      });
    }
  }

  function loadMoreHandler() {
    getEnsAssetsApiCall(assetsOffsetStateV2);
  }

  const isloadMoreLoading = false;
  return (
    <div className="walletcollections-container">
      <div className="row">
        <div className="col-12">
          <div className="tabbody-headercontainer">
            <div className="d-flex align-items-center justify-content-between ">
              <h4 className="mb-0">ENS Assets</h4>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="feature-card-container">
            {ensAssetsStateV2.map((item, index) => {
              const { image } = item;
              let imgUrl = null;
              if (image) {
                imgUrl = image.originalUrl
                  ? image.originalUrl
                  : item?.collection?.bannerImageUrl || noImageFound;
              }
              return (
                <div key={`feature-token=${index}`} className="card-container">
                  <AssetCard
                    tokenTitle={item?.name}
                    tokenId={item?.tokenId}
                    assetImage={imgUrl}
                    isVerified={item?.contract?.isSpam}
                    circulatingValue={
                      item?.contract?.openSeaMetadata
                        ? getValueWithDecimal(
                            item?.contract?.openSeaMetadata?.floorPrice
                          )
                        : ''
                    }
                    contractAddress={item?.contract?.address}
                    last_sold={item?.timeLastUpdated}
                    is_last_sold={true}
                    redirectLink={`/item-info/${item.contract.address}/${item.tokenId}/${chainIdFromUrl}`}
                  />
                </div>
              );
            })}
            {loadingState.isLoading &&
              Array(5)
                .fill(0)
                .map((item, index) => (
                  <div
                    key={`dummy-feature-token=${index}`}
                    className="card-container"
                  >
                    <LoadingCard />
                  </div>
                ))}
            {noDataFoundStateV2 && (
              <NoDataComponent
                title="No ENS Collection Owned by This Wallet"
                subTitle="The Ethereum Name Service (ENS) is a distributed,
                            open, and extensible naming system based on the
                            Ethereum blockchain."
              >
                <Link
                  onClick={() => openUrl(`https://ens.domains/`)}
                  to="#"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  rel="noopener noreferrer"
                >
                  <button
                    className="sc-button d-flex style-4 text-dark mt-4"
                    id="verify-on-etherscan"
                  >
                    Buy ENS Name
                    <BsArrowRightShort className="font-20 ml-1 fw-bold" />
                  </button>
                </Link>
              </NoDataComponent>
            )}
          </div>
        </div>
        <div className="col-12 mt-3 d-flex justify-content-center">
          {assetsOffsetStateV2 && (
            <button onClick={loadMoreHandler} className="sc-button style-4">
              {isloadMoreLoading ? (
                <Bars color="#ffffff" height={30} width={80} />
              ) : (
                'Load More'
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

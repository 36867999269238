import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { useNavigate } from 'react-router-dom';
//Components
import CardModal from '../../components/layouts/CardModal';
import ProfileNftItem from '../../components/layouts/profile/ProfileNftItem';
import FacebookLoader from '../../components/content_loader/FacebookLoader';
import { apicallback } from '../../callbacks/index';
import PageTitle from '../../components/pagetitle/PageTitle';
//Images
import { noimageselected, nobg } from '../../assets/Index';
const UserProfile = () => {
  const history = useNavigate();
  const currentPageUrl = window.location.href;
  const [nfts, setnfts] = useState([]);
  const [profile, setprofile] = useState({});
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const id = window.location.pathname.replace('/profile/', '');
    getData(id);
  }, []);

  const getData = async (id) => {
    const response = await apicallback(
      null,
      '/getUserInfoOnlyAdmin',
      {
        user_id: id,
      },
      null,
      null
    );
    if (response && response.status == 'success') {
      await getNFTs(response.data.token, id);
      setprofile(response.data);
    } else {
      history('/search');
    }
  };

  const getNFTs = async (token) => {
    const response = await apicallback(
      null,
      '/getUserCustomProfileNft',
      {},
      'private',
      token,
      'get'
    );
    if (response && response.status == 'success') {
      setnfts(response.data);
      setLoaded(true);
    }
  };

  const [modalShow, setModalShow] = useState(false);
  return (
    <div className="authors-2">
      <section className="tf-section authors mt-5">
        <div className="themesflat-container">
          <div className="flat-tabs tab-authors">
            {profile && profile.id ? (
              <>
                <PageTitle
                  meta={{
                    title: '@' + profile.username,
                    image: profile.profile_image
                      ? profile.profile_image
                      : noimageselected,
                  }}
                />
                <div
                  className="new-profile-header-bg"
                  style={{
                    backgroundImage: `url(${
                      profile.banner_image ? profile.banner_image : nobg
                    })`,
                  }}
                >
                  <div id="overlayBg"></div>
                  <div className="new-profile-header">
                    <img
                      src={
                        profile.profile_image
                          ? profile.profile_image
                          : noimageselected
                      }
                      style={{
                        borderRadius: '50%',
                        border:
                          profile && profile.profile_image
                            ? '4px solid #f6c946'
                            : 'none',
                      }}
                      width="120"
                      alt={profile.username}
                      className="avatar"
                    />
                    <h3 className="title text-lowercase mt-2">
                      @{profile.username}
                    </h3>
                    <div className="widget-social style-3 mt-4">
                      <ul className="d-flex justify-content-between align-content-between">
                        <li>
                          <Link to="#">
                            <TwitterShareButton
                              url={currentPageUrl}
                              title={'@' + profile.username}
                            >
                              <i className="fab fa-twitter"></i>
                            </TwitterShareButton>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <FacebookShareButton
                              url={currentPageUrl}
                              title={'@' + profile.username}
                            >
                              <i className="fab fa-facebook"></i>
                            </FacebookShareButton>
                          </Link>
                        </li>
                        <li className="style-2">
                          <Link to="#">
                            <TelegramShareButton
                              url={currentPageUrl}
                              title={'@' + profile.username}
                            >
                              <i className="fab fa-telegram-plane"></i>
                            </TelegramShareButton>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <WhatsappShareButton
                              url={currentPageUrl}
                              title={'@' + profile.username}
                            >
                              <i className="fab fa-whatsapp"></i>
                            </WhatsappShareButton>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <FacebookLoader value={1} coloum={6} center={true} />
            )}
          </div>
        </div>
        {nfts.length > 0 ? (
          <ProfileNftItem data={nfts} showanim={loaded} />
        ) : (
          ''
        )}
      </section>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default UserProfile;

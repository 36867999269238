import React from 'react';
import { mintImageDefault, noImageFound } from '../../assets/Index';
import classNames from 'classnames';
import moment from 'moment';
import {
  getDisplayValueWithNA,
  getPriceValue,
  getTransactionClassName,
  getTransactionStatus,
} from '../../utility/commonUtitlity';
import { condenseAddress } from '../../callbacks/index';
import { document_copy_img } from '../../assets/Index';
import { Link } from 'react-router-dom';

function replaceImage(error) {
  error.target.src = noImageFound;
}

export function MenuOptions(props) {
  if (!Array.isArray(props.actionOptions)) {
    return '';
  }
  return (
    <ul className="horizontal-menu-options-container">
      {props.actionOptions.map((item, index) => (
        <React.Fragment key={item.id}>
          <li
            className="list-inline-item cursor-pointer"
            data-id={props.rowId}
            onClick={item.onClickHndlr}
            id={`${item.id}-${props.rowId}`}
          >
            {item.actionIcon}
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
}

export function generateColumns(
  walletAddress,
  copyClipboard,
  menuOptions = [],
  chainId
) {
  return chainId == 1
    ? [
        {
          id: 'id',
          Header: '#',
          accessor: 'id',
          disableSortBy: true,
          Cell: ({ row: { index } }) => {
            return <span>{index + 1}</span>;
          },
        },
        {
          id: 'marketplace',
          Header: 'Marketplace',
          accessor: 'marketplace',
          disableSortBy: true,
          Cell: ({ value }) => {
            return <div className="text-uppercase text-main">{value}</div>;
          },
        },
        {
          id: 'taker',
          Header: 'Taker',
          accessor: 'taker',
          disableSortBy: true,
        },
        {
          id: 'blockNumber',
          Header: 'Block',
          accessor: 'blockNumber',
          disableSortBy: true,
        },
        {
          id: 'sellerAddress',
          Header: 'From Address',
          accessor: 'sellerAddress',
          disableSortBy: true,
          Cell: ({ row: { index, original } }) => {
            const { sellerAddress } = original;
            return (
              <div
                className="d-flex align-items-center"
                data-id={sellerAddress}
                onClick={copyClipboard}
              >
                <span className="mr-1">{condenseAddress(sellerAddress)}</span>
                <img
                  src={document_copy_img}
                  style={{ width: '20px', height: '20px', maxWidth: 'initial' }}
                />
              </div>
            );
          },
        },
        {
          id: 'buyerAddress',
          Header: 'To Address',
          accessor: 'buyerAddress',
          disableSortBy: true,
          Cell: ({ row: { index, original } }) => {
            const { buyerAddress } = original;
            return (
              <div
                className="d-flex align-items-center"
                data-id={buyerAddress}
                onClick={copyClipboard}
              >
                <span className="mr-1">{condenseAddress(buyerAddress)}</span>
                <img
                  src={document_copy_img}
                  style={{ width: '20px', height: '20px', maxWidth: 'initial' }}
                />
              </div>
            );
          },
        },

        {
          id: 'menuActions',
          Header: 'Actions',
          accessor: 'menuActions',
          disableSortBy: true,
          columnClassName: 'w-120px min-w-120px',
          Cell: ({ row: { original } }) => {
            return (
              <MenuOptions
                rowId={original?.transactionHash}
                actionOptions={menuOptions}
              />
            );
          },
        },
      ]
    : [
        {
          id: 'id',
          Header: '#',
          accessor: 'id',
          disableSortBy: true,
          Cell: ({ row: { index } }) => {
            return <span>{index + 1}</span>;
          },
        },
        {
          id: 'blockNumber',
          Header: 'Block',
          accessor: 'blockNumber',
          disableSortBy: true,
        },
        {
          id: 'sellerAddress',
          Header: 'From Address',
          accessor: 'sellerAddress',
          disableSortBy: true,
          Cell: ({ row: { index, original } }) => {
            const { sellerAddress } = original;
            return (
              <div
                className="d-flex align-items-center"
                data-id={sellerAddress}
                onClick={copyClipboard}
              >
                <span className="mr-1">{condenseAddress(sellerAddress)}</span>
                <img
                  src={document_copy_img}
                  style={{ width: '20px', height: '20px', maxWidth: 'initial' }}
                />
              </div>
            );
          },
        },
        {
          id: 'buyerAddress',
          Header: 'To Address',
          accessor: 'buyerAddress',
          disableSortBy: true,
          Cell: ({ row: { index, original } }) => {
            const { buyerAddress } = original;
            return (
              <div
                className="d-flex align-items-center"
                data-id={buyerAddress}
                onClick={copyClipboard}
              >
                <span className="mr-1">{condenseAddress(buyerAddress)}</span>
                <img
                  src={document_copy_img}
                  style={{ width: '20px', height: '20px', maxWidth: 'initial' }}
                />
              </div>
            );
          },
        },

        {
          id: 'menuActions',
          Header: 'Actions',
          accessor: 'menuActions',
          disableSortBy: true,
          columnClassName: 'w-120px min-w-120px',
          Cell: ({ row: { original } }) => {
            return (
              <MenuOptions
                rowId={original?.transactionHash}
                actionOptions={menuOptions}
              />
            );
          },
        },
      ];
}

export function getEnsImages(isEnsItem, ensDetails) {
  let bannerImage = '',
    cardImage = '',
    { nft_images, collection_banner_image, collection_image } = ensDetails;
  //bannerImage
  if (!isEnsItem) {
    if (Array.isArray(collection_banner_image)) {
      bannerImage =
        collection_banner_image[collection_banner_image.length - 1]?.url;
    } else if (Array.isArray(collection_image)) {
      bannerImage = collection_image[collection_image.length - 1]?.url;
    }
  }
  //Card Image
  if (isEnsItem) {
    if (Array.isArray(collection_image)) {
      cardImage = collection_image[0]?.url;
    }
  } else {
    if (Array.isArray(nft_images)) {
      cardImage = nft_images[nft_images.length - 1]?.url;
    } else if (Array.isArray(collection_image)) {
      cardImage = collection_image[collection_image.length - 1]?.url;
    }
  }
  return { bannerImage: bannerImage, cardImage: cardImage };
}

export function getEnsDisplayName(ensDetails) {
  let displayName = '',
    visibleTitle = '';
  const { name, collection_name, token_id } = ensDetails;
  if (name) {
    displayName = name;
    visibleTitle = name;
  } else if (collection_name && token_id) {
    const trimTokenId =
      (token_id && `${token_id.toString().substring(0, 6)}`) || '';
    displayName = `${collection_name} #${trimTokenId}`;
    visibleTitle = `${collection_name} #${token_id}`;
  } else {
    const trimTokenId =
      (token_id && `${token_id.toString().substring(0, 6)}`) || '';
    displayName = `#${trimTokenId}`;
    visibleTitle = `#${token_id}`;
  }
  return { ensName: displayName, ensNameTitle: visibleTitle };
}

export function getTransactions(ensDetails) {
  const transactionList = [],
    { transactions } = ensDetails || {},
    { edges } = transactions || {};
  if (Array.isArray(edges)) {
    for (let i = 0; i < edges.length; i++) {
      if (edges[i]) transactionList.push(edges[i]?.node);
    }
  }
  return transactionList;
}

export function getTraits(ensDetails) {
  const { nft_metdata, traits } = ensDetails,
    { attributes } = nft_metdata || {};
  if (Array.isArray(traits) && Array.isArray(attributes)) {
    const traitTypeKeys = {};
    attributes.forEach((item) => {
      const { trait_type, value } = item;

      traitTypeKeys[trait_type?.toString().toLowerCase()] = value
        ?.toString()
        .toLowerCase();
    });
    return traits.filter((item) => {
      const { name, value } = item;
      if (
        traitTypeKeys[name?.toString().toLowerCase()] &&
        traitTypeKeys[name?.toString().toLowerCase()] ===
          value?.toString().toLowerCase()
      ) {
        return true;
      }
      return false;
    });
  } else if (Array.isArray(attributes)) {
    return attributes.map((item) => ({
      name: item?.trait_type,
      value: item?.value,
      rarity: null,
      valueCount: null,
    }));
  } else {
    return [];
  }
}

export function getTopRanking(value, totalValue) {
  if (isNaN(value) || isNaN(totalValue)) {
    return null;
  }
  const numValue = Number(value),
    numTotalValue = Number(totalValue),
    percentageValue = Math.floor(
      (Number(numValue) / Number(numTotalValue)) * 100
    ),
    divisibleValue = Math.floor(percentageValue / 10),
    remainderValue = Math.floor(percentageValue % 10);

  if (divisibleValue <= 0 || percentageValue === 0) {
    return remainderValue === 0 ? 1 : remainderValue;
  } else if (remainderValue > 0) {
    return (divisibleValue + 1) * 10;
  } else {
    return divisibleValue * 10;
  }
}

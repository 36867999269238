import { useRef } from 'react';
export default function usePagination(limit) {
  const paginationRef = useRef({
    offset: 0,
    canLoadMore: false,
    currentPageNo: 0,
    limit,
  });

  function nextPageHandler() {
    const { currentPageNo } = paginationRef.current;
    const newPageNo = currentPageNo + 1;
    paginationRef.current.currentPageNo = newPageNo;
    paginationRef.current.offset = newPageNo * paginationRef.current.limit;
  }

  function updatePagination(paginationInfo) {
    paginationRef.current = { ...paginationRef.current, ...paginationInfo };
  }

  return { paginationRef, updatePagination, nextPageHandler };
}

import PageTitle from '../../../components/pagetitle/PageTitle';
import { apicallback, copyClipboard, saveMeta } from '../../../callbacks/index';
import { FaRegCopy } from '../../../components/icons/Index';
import { twitterx_icon } from '../../../assets/Index';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import { CopyBlock, dracula } from 'react-code-blocks';
import './details.scss';
import AnzaliLoader from '../../../components/AnzaliLoader';
export default function Blog() {
  const currentPageUrl = window.location.href;
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [isBlogLoading, setIsBlogLoading] = useState(true);
  useEffect(() => {
    getBlog();
  }, [slug]);

  async function getBlogs(blog_id) {
    const response = await apicallback(
      'content',
      '/blogs',
      {
        offset: 0,
        limit: 8,
        blog_id: blog_id,
      },
      null,
      null
    );
    if (response && response.status == 'success') {
      setBlogs(response.data);
      setIsBlogLoading(false);
    }
  }

  async function getBlog() {
    const response = await apicallback(
      'content',
      '/blogDetails',
      {
        slug: slug,
      },
      null,
      null
    );
    if (response && response.status === 'success') {
      saveMeta({
        title: response?.data?.seo_title + '' || null,
        description: response?.data?.meta_description || null,
        images: response?.data?.image || null,
        url: `/blog/${response?.data?.slug}`,
        file: 'blogs',
        keywords: `${response?.data?.seo_title}, Anzali Blog, Trending Blog, ${
          response?.data?.keyword || ''
        }`,
      });
      getBlogs(response?.data?.blog_id || 0);
      setBlog(response.data);
      setIsBlogLoading(false);
    }
  }

  const TopPost = () => {
    return (
      <ul>
        {blogs.length ? (
          blogs.map((item, index) => (
            <li key={index} className="box-recent-post">
              <div className="box-feature">
                <Link to={`/blog/${item.slug}`}>
                  <img
                    src={item.image}
                    alt={item.image_alt_text || item.title}
                  />
                </Link>
              </div>
              <div className="box-content">
                <Link to={`/blog/${item.slug}`} className="title-recent-post">
                  {item.title}
                </Link>
                <span>
                  <span className="sub-recent-post"></span>
                  <Link to={`/blog/${item.slug}`} className="day-recent-post">
                    {item.date}
                  </Link>
                </span>
              </div>
            </li>
          ))
        ) : (
          <p className="text-">No Recent Blog!</p>
        )}
      </ul>
    );
  };

  const splitHtmlFragments = (htmlString) => {
    const fragments = [];
    const regex = /<code>(.*?)<\/code>/gs;
    let lastIndex = 0;
    let match;
    while ((match = regex.exec(htmlString)) !== null) {
      const textFragment = htmlString.slice(lastIndex, match.index);
      if (textFragment) {
        fragments.push({ type: 'text', content: textFragment });
      }
      fragments.push({ type: 'code', content: match[1].trim() });
      lastIndex = match.index + match[0].length;
    }

    const remainingText = htmlString.slice(lastIndex);
    if (remainingText) {
      fragments.push({ type: 'text', content: remainingText });
    }

    return fragments;
  };

  return (
    <section className="tf-section tf-rank">
      {isBlogLoading && <AnzaliLoader />}
      <PageTitle
        meta={{
          title: blog?.seo_title,
          description: blog?.meta_description,
          image: blog?.image,
        }}
      />

      <div className="themesflat-container">
        <div className="wrap-flex-box style">
          <div className="post">
            <div className="inner-content">
              <h1 className="title-post">{blog?.title}</h1>
              <div className="divider"></div>
              <div className="meta-post flex mg-bt-31">
                <div className="box">
                  <div className="inner">
                    <h6 className="desc">Posted</h6>
                    <p>{blog?.timestamp}</p>
                  </div>
                </div>
                <div className="box left">
                  <div className="inner boder pad-r-50">
                    <h6 className="desc">Author</h6>
                    <p>{blog?.author}</p>
                  </div>
                  <div className="inner mg-l-39 mg-r-1">
                    <h6 className="desc">DATE</h6>
                    <p>{blog?.date}</p>
                  </div>
                </div>
              </div>
              <div className="image">
                <img
                  src={blog?.image}
                  alt={blog?.image_alt_text || blog?.title}
                />
              </div>
              <div className="inner-post mg-t-40">
                {blog && blog.description
                  ? splitHtmlFragments(blog?.description).map(
                      (fragment, index) => {
                        if (fragment.type === 'code') {
                          return (
                            <CopyBlock
                              key={index}
                              text={fragment.content}
                              className="mb-4"
                              language={'js'}
                              showLineNumbers={true}
                              theme={dracula}
                              wrapLines={true}
                              codeBlock
                            />
                          );
                        } else {
                          return (
                            <div
                              key={index}
                              dangerouslySetInnerHTML={{
                                __html: fragment.content,
                              }}
                            />
                          );
                        }
                      }
                    )
                  : ''}
              </div>
              <div className="sc-widget style-1">
                <div className="widget widget-tag style-2">
                  <h4 className="title-widget">Tags</h4>
                  <ul>
                    <li>
                      <a href="#">{blog?.keyword}</a>
                    </li>
                  </ul>
                </div>
                <div className="widget widget-social style-2">
                  <h4 className="title-widget">Share:</h4>
                  <ul>
                    <li>
                      <Link to="#">
                        <TwitterShareButton
                          url={currentPageUrl}
                          title={blog?.title}
                          // via={obj.content}
                        >
                          <img src={twitterx_icon} width="15" />
                        </TwitterShareButton>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FacebookShareButton
                          url={currentPageUrl}
                          title={blog?.title}
                        >
                          <i className="fab fa-facebook"></i>
                        </FacebookShareButton>
                      </Link>
                    </li>
                    <li className="style-2">
                      <Link to="#">
                        <TelegramShareButton
                          url={currentPageUrl}
                          title={blog?.title}
                        >
                          <i className="fab fa-telegram-plane"></i>
                        </TelegramShareButton>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <RedditShareButton
                          url={currentPageUrl}
                          title={blog?.title}
                        >
                          <i className="fab fa-reddit"></i>
                        </RedditShareButton>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <WhatsappShareButton url={currentPageUrl}>
                          <i className="fab fa-whatsapp"></i>
                        </WhatsappShareButton>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <FaRegCopy
                          className="copyBtn"
                          onClick={() => copyClipboard(currentPageUrl)}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="side-bar details">
            <div className="widget widget-recent-post mg-bt-43">
              <h3 className="title-widget mg-bt-23">Recent Posts</h3>
              <TopPost />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

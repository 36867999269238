import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { noImageFound, rank_icon } from '../../assets/Index';
import Zoom from '../../components/zoomImage/Zoom';
import { MdZoomOutMap } from '../../components/icons/Index';
import { getTopRanking } from './helper';

export default function RarityCard(props) {
  const [isOpen, setisOpen] = useState(false);
  function replaceImage(error) {
    //replacement of broken Image
    error.target.src = noImageFound;
  }

  const { supplyValue, totalSupply } = props,
    topRankingValue = getTopRanking(supplyValue, totalSupply);
  return (
    <>
      <div className="tokencard-container">
        <Link className="img-fluid token-img position-relative" to="#" onClick={setisOpen}>
          <img
            onError={replaceImage}
            src={props?.tokenImage || noImageFound}
            alt={props?.displayTitle}
          />
                <MdZoomOutMap
                  style={{
                    borderRadius: '4px',
                    color: '#000000',
                    fontSize: '2.5rem',
                    cursor: 'pointer',
      
                    right:'10px'
                  }}
                  className="mt-3 position-absolute"
                />
        </Link>
        <div className="title-rank-container">
          <h2 className="title" title={props?.displayTitle}>
            {props?.displayName}
          </h2>
          {props?.supplyValue && (
            <div className="rank-container">Top {topRankingValue}%</div>
          )}
        </div>

        {props?.supplyValue && (
          <div className="rarity-value-container">
            <img src={rank_icon} className="rank-icon" />
            <span className="text-white">{props?.supplyValue} /</span>&nbsp;
            <span className="total-value">{props?.totalSupply}</span>
          </div>
        )}
      </div>
      <Zoom
        isOpen={isOpen}
        images={[props?.tokenImage || noImageFound]}
        photoIndex={0}
        setIsOpen={setisOpen}
      ></Zoom>
    </>
  );
}

import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { loadingGif } from '../../assets/Index';
const SmallImg = ({ image }) => (
  <LazyLoadImage
    alt={image.alt}
    height={image.height}
    src={image.src} // use normal <img > attributes as props
    width={image.width}
    effect="blur"
    placeholderSrc={loadingGif}
    className={image && image.cls ? image.cls : ''}
  />
);

export default React.memo(SmallImg);

import React from "react";
import ContentLoader from 'react-content-loader';

export default function LoadingCard({ containerClassName }) {
    return (
        <div className={`loadingcard-container ${containerClassName}`}>
            <div className="loading-img">
                <ContentLoader className="vertical-middle" backgroundColor="#ccc" width="100%" height="100%">
                    <rect x="0" y="0" rx="8" ry="8" width="100%" height="100%"
                    />
                </ContentLoader>
            </div>
            <div className="loading-title">
                <ContentLoader className="vertical-middle" backgroundColor="#ccc" width="100%" height="18">
                    <rect x="0" y="0" rx="4" ry="4" width="80%"
                        height="100%" />
                </ContentLoader>
            </div>
            <div className="loading-value">
                <div className="mb-3">
                    <ContentLoader className="vertical-middle" backgroundColor="#ccc" width="100%" height="58" >
                        <rect x="0" y="0" rx="6" ry="6" width="100%"
                            height="100%" />
                    </ContentLoader>
                </div>
                <div className="mb-3">
                    <ContentLoader className="vertical-middle" backgroundColor="#ccc" width="100%" height="58">
                        <rect x="0" y="0" rx="6" ry="6" width="100%"
                            height="100%" />
                    </ContentLoader>
                </div>
            </div>
            <div className="btn-wrapper">
                <ContentLoader className="vertical-middle" backgroundColor="#ccc" width="100%" height="36">
                    <rect x="0" y="0" rx="6" ry="6" width="100%"
                        height="100%" />
                </ContentLoader>
            </div>
        </div>
    )
}

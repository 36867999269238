import React, { useState, useRef, useEffect, useCallback } from "react";
import { Bars } from 'react-loader-spinner';
import axios from 'axios';
import { toast } from "react-toastify";
import { Instagram } from 'react-content-loader';
import TokenCard from '../../components/card/tokencard';
import { getValueWithDecimal, debounce } from "../../utility/commonUtitlity";
import { useStoreState } from 'easy-peasy';
import NoDataComponent from "../../components/NoDataComponent";
import { FiSearch } from "../../components/icons/Index";
import { walletActions } from "viem";

const LOAD_MORE_ACTION = "LOADMORE",
    SEARCH_ACTION = "SEARCH",
    MOUNT_ACTION = "MOUNT";

const initialPageInfoRef = {
    totalCount: 0,
    hasNextPage: false,
    endCursor: null
};
export default function Items({ walletAddress }) {
    const collectionInfoTabsData = useStoreState((state) => state.collectionInfoTabsData);
    const [loadingState, setLoadingState] = useState({
        isLoading: false,
        action: ""
    })
    const [filterState, setFilterState] = useState({
        searchString: ""
    })
    const [itemsState, setItemsState] = useState([]);
    const [rarityState, setRarityState] = useState(null);
    const pageInfoRef = useRef(initialPageInfoRef);
    useEffect(() => {
        if (walletAddress) {
            getCollectionItemsApiCall(MOUNT_ACTION, null, true);
        }
        return () => {
            setItemsState([]);
            pageInfoRef.current = initialPageInfoRef;
        }
    }, [walletAddress]);

    useEffect(() => {
        const { rarityData } = collectionInfoTabsData || {};
        if (rarityData) {
            setRarityState(rarityData);
        } else {
            setRarityState(null);
        }
    }, [collectionInfoTabsData]);

    async function getCollectionItemsApiCall(action, otherProps, resetItems = false) {
        setLoadingState({
            isLoading: true,
            action
        });
        try {
            const { endCursor } = pageInfoRef.current;
            let assetNamePayload = filterState?.searchString;
            if (otherProps) {
                if (otherProps?.searchString) {
                    assetNamePayload = otherProps?.searchString;
                }
            }
            const response = await axios.post(`${process.env.REACT_APP_NODE_ENDPOINT}/artblock-detail`,
                {
                    contract_address: walletAddress,
                    after: endCursor,
                    filter: assetNamePayload ? {
                        contractTokens: [{
                            contractAddress:
                                walletAddress
                        }],
                        nftName: {
                            contains: assetNamePayload
                        }
                    } : null,
                    type: 'nfts'
                });
            const { data } = response || {},
                { nfts } = data || {},
                { edges, pageInfo, totalCount } = nfts || {};

            if (Array.isArray(edges)) {
                const previousItemsState = resetItems ? [] : itemsState;
                const updatedItems = [].concat(previousItemsState, edges);
                setItemsState(updatedItems);
                pageInfoRef.current = {
                    totalCount: totalCount,
                    hasNextPage: pageInfo?.hasNextPage,
                    endCursor: pageInfo?.endCursor
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoadingState({
                isLoading: false,
                action: ""
            });
        }
    }
    function loadMoreHandler() {
        getCollectionItemsApiCall(LOAD_MORE_ACTION);
    }

    const debouncedHandler = useCallback(debounce(function (keyName, value) {
        getCollectionItemsApiCall(SEARCH_ACTION, { [keyName]: value }, true);
    }, 200), [])

    function onSearchHandler(event) {
        const { name, value } = event.target;
        setFilterState((prevState) => ({ ...prevState, [name]: value }));
        debouncedHandler(name, value);
        pageInfoRef.current = {
            totalCount: 0,
            hasNextPage: false,
            endCursor: null
        };
    }

    const { collectionInfo } = collectionInfoTabsData || {};
    const { isLoading } = loadingState,
        showDummyCard = (itemsState.length === 0 && isLoading) || false,
        showNoData = ((itemsState.length === 0) && !isLoading) || false;
    const isloadMoreLoading = isLoading && loadingState.action === LOAD_MORE_ACTION,
        isSearchLoading = isLoading && loadingState.action === SEARCH_ACTION;
    const itemAddress = walletAddress && walletAddress?.substring(0, walletAddress.indexOf("-")) || "";
    return (
        <section>
            {/* <div className="d-flex justify-content-end mb-4">
                <div className="searchwallet-container">
                    <input
                        type="text"
                        id="searchString"
                        name="searchString"
                        className="search-input size-small"
                        value={filterState.searchString}
                        onChange={onSearchHandler}
                        placeholder="Search Items"
                    />
                    <button className="searching-btn-container">
                        {isSearchLoading ? (
                            <Bars color="#ffffff" height={20} width={20} />
                        ) : (
                            <FiSearch />
                        )}
                    </button>
                </div>
            </div> */}
            <div className="feature-card-container">
                {
                    Array.isArray(itemsState) && itemsState.map((info, index) => {
                        const { node: collectionData } = info,
                            { uploads } = collectionData || {},
                            collectionImgUrl = Array.isArray(uploads) ? uploads[uploads.length - 1]?.url : "",
                            displayName = collectionData?.name ? collectionData?.name : `#${collectionData?.tokenId}`,
                            tokenId = collectionData?.tokenId,
                            rarityInfo = rarityState?.[tokenId] || null;
                        return (
                            <div
                                key={`item-${index}`}
                                className="card-container"
                            >
                                <TokenCard
                                    tokenTitle={displayName}
                                    tokenImage={collectionImgUrl}
                                    showPriceInfo={true}
                                    hideVerified={!collectionInfo?.isVerified}
                                    contractAddress={walletAddress}
                                    isTwentyFourHourVolume={true}
                                    showRarity={!!rarityInfo}
                                    rank={rarityInfo?.rank}
                                    floorPrice={collectionInfo?.floorPrice ? getValueWithDecimal(collectionInfo?.floorPrice) : null}
                                    volumeTraded={collectionInfo?.volumeTraded ? getValueWithDecimal(collectionInfo?.volumeTraded) : null}
                                    redirectLink={`/item-info/${itemAddress}/${collectionData?.tokenId}?artblock=${walletAddress.split('-')[1]}`}
                                />
                            </div>
                        )
                    })
                }

                {showDummyCard &&
                    Array(5)
                        .fill(0)
                        .map((item, index) => (
                            <div
                                key={`dummy-feature-token=${index}`}
                                className="card-container"
                            >
                                <Instagram speed={3} foregroundColor={'#ccc'} />
                            </div>
                        ))}

                {showNoData && <NoDataComponent
                    title="No Items Found."
                >
                </NoDataComponent>}
            </div>

            <div className="col-12 mt-3 d-flex justify-content-center">
                {(pageInfoRef.current.hasNextPage) && (
                    <button
                        onClick={loadMoreHandler}
                        className="sc-button style-4"
                    >
                        {isloadMoreLoading ? (
                            <Bars color="#ffffff" height={30} width={80} />
                        ) : (
                            'Load More'
                        )}
                    </button>
                )}
            </div>
        </section>
    )
}
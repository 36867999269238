import React, { useEffect, useRef, useCallback } from 'react';
import { noImageFound, noSearchResults } from '../../assets/Index';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useSearchWallet from '../../hooks/useSearchWallet';
import { search_icon } from './../../assets/Index';
import { searchCollectionsUsersWallets } from '../../apis/Index';
import { useStoreActions } from 'easy-peasy';
import { Bars } from 'react-loader-spinner';
import { useState } from 'react';
import { nanoid } from 'nanoid';
import { debounce } from '../../utility/commonUtitlity';
import classNames from 'classnames';

function replaceImage(error) {
  error.target.src = noImageFound;
}

export default function SearchCollections(props) {
  const [searchStringState, setSearchStringState] = useState('');
  const [usersListState, setUsersListSate] = useState([]);
  const [collectionListState, setCollectionListState] = useState([]);
  const [walletListState, setWalletListState] = useState([]);
  const [apiCallingState, setApiCallingState] = useState(false);
  const { isLoadingSearch, redirectUrl, searchWalletHandler } = useSearchWallet(
    props.authorization
  );
  const history = useNavigate();
  const searchInputRef = useRef(null);
  const abortControllerRef = useRef(null);
  const setWalletSummaryActiveTabId = useStoreActions(
    (actions) => actions.setWalletSummaryActiveTabId
  );

  useEffect(() => {
    if (props.isModalOpen) {
      searchInputRef.current.focus();
    }
  }, [props.isModalOpen]);

  useEffect(() => {
    if (redirectUrl) {
      onClickLinkHndlr();
      setWalletSummaryActiveTabId();
      history(redirectUrl);
    }
  }, [redirectUrl]);

  async function searchApiCall(searchKey) {
    let response = null;
    setApiCallingState(true);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;
    response = await searchCollectionsUsersWallets(
      {
        name: searchKey,
      },
      {
        signal: controller.signal,
      }
    );
    if (response && response.status == 'success') {
      const { data } = response;
      setApiCallingState(false);
      if (Array.isArray(data.user_list)) {
        setUsersListSate(data.user_list);
      }
      if (Array.isArray(data.collection_list)) {
        setCollectionListState(data.collection_list);
      }
    } else {
      setApiCallingState(false);
    }
  }

  function onClickLinkHndlr() {
    setSearchStringState('');
    props.closeHndlr();
  }

  const debouncedHandler = useCallback(
    debounce(function (keyName, value) {
      searchApiCall(value);
    }, 200),
    []
  );

  function onChangeHndlr(event) {
    const { name, value } = event.target;
    if ((value && value.includes('.eth')) || value.substr(0, 2) === '0x') {
      const uniqueId = nanoid();
      const walletResults = {
        id: uniqueId,
        name: value,
      };
      setWalletListState([walletResults]);
    } else {
      setWalletListState([]);
    }

    debouncedHandler(name, value);
    setSearchStringState(value);
  }

  function onClickWalletHandler(event) {
    event.preventDefault();
    const walletAddress = event.currentTarget.getAttribute('data-id');
    if (walletAddress) {
      searchWalletHandler(walletAddress);
    }
  }

  const anySearchResult =
    usersListState.length + collectionListState.length + walletListState.length;
  return (
    <div
      className={classNames('search-collection-container', {
        'pb-16': anySearchResult === 0,
      })}
    >
      <div className="search-bar">
        <img src={search_icon} className="search-img" />
        {/* <RiArrowDropLeftLine onClick={props.closeSearchModalHndlr} size={48} className="arrow-back" /> */}
        <input
          type="text"
          className="search-input-header"
          value={searchStringState}
          ref={searchInputRef}
          onChange={onChangeHndlr}
          placeholder="Search any collection or wallet"
        />
        {(apiCallingState || isLoadingSearch) && (
          <Bars
            wrapperClass="search-loading"
            color="#ffffff"
            height={20}
            width={20}
          />
        )}
        {/* <button
                    onClick={() => {}}
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                ></button> */}
      </div>
      <div
        className={classNames({
          'mt-16': anySearchResult > 0,
        })}
      >
        {searchStringState && !apiCallingState && anySearchResult === 0 && (
          <div className="d-flex justify-content-center">
            <div style={{ width: '60%' }}>
              <img src={noSearchResults} alt="No Search Result" />
            </div>
          </div>
        )}
        {usersListState.length > 0 && (
          <div className="search-collection">
            <div className="search-title">User</div>
            <div className="search-results">
              {usersListState.map((item) => {
                return (
                  <Link onClick={onClickLinkHndlr} to={`/profile/${item.name}`}>
                    <div
                      key={`users-${item.id}`}
                      className="search-item-container"
                    >
                      <div className="search-info">
                        <img
                          src={item?.image || noImageFound}
                          onError={replaceImage}
                          className="search-img"
                        />
                        <div className="d-flex flex-column">
                          <span className="token-name mb-0">@{item?.name}</span>
                          {/* <span className="token-info">
                                                Tetsing Data
                                            </span> */}
                        </div>
                      </div>
                      {/* <RiCloseLine className="search-result-cross" /> */}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        )}
        {collectionListState.length > 0 && (
          <div className="search-collection">
            <div className="search-title">Collections</div>
            <div className="search-results">
              {collectionListState.map((item) => {
                return (
                  <Link
                    onClick={onClickLinkHndlr}
                    to={
                      item?.is_artblock
                        ? `/artblock-collection/${item.address}`
                        : `/collection-info/${item.address}/${item.chain}`
                    }
                  >
                    <div
                      key={`collections-${item.id}`}
                      className="search-item-container"
                    >
                      <div className="search-info">
                        <img
                          src={item?.image || noImageFound}
                          onError={replaceImage}
                          className="search-img"
                        />
                        <div className="d-flex flex-column">
                          <span className="token-name mb-0">{item?.name}</span>
                          {/* <span className="token-info">
                                                Tetsing Data
                                            </span> */}
                        </div>
                      </div>
                      {/* <RiCloseLine className="search-result-cross" /> */}
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        )}
        {/* {walletListState.length > 0 && (
          <div className="search-collection">
            <div className="search-title">Wallets</div>
            <div className="search-results">
              {walletListState.map((item) => {
                return (
                  <a
                    onClick={onClickWalletHandler}
                    href="#"
                    data-id={item.name}
                  >
                    <div
                      key={`wallet-${item.id}`}
                      className="search-item-container"
                    >
                      <div className="search-info">
                        <span
                          style={{
                            backgroundColor: item.hex_code
                              ? item.hex_code
                              : '#fec60d',
                          }}
                          className="search-img d-block"
                        ></span>
                        <div className="d-flex flex-column">
                          <span className="token-name mb-0">{item?.name}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}

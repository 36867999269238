import React from 'react';
import {down_arrow} from "../../assets/Index"
export default function SideNav(props) {
  const { list, active, handleCategoryClick } = props;

  return (
    <div className="categories">
      <div className='position-relative selectMenu d-block d-md-none'>
        <img src={down_arrow}/>
        <select
          name="categories"
          onChange={(e) => {
            handleCategoryClick(e.target.value);
          }}
          className="d-block d-md-none custom-select mb-5"
        >
          <option value=""> All Resources</option>
          {list.map((value, key) => (
            <optgroup label={value.title} key={key}>
              {Array.isArray(value.categories) && value.categories.length > 0
                ? value.categories.map((v, k) => (
                    <option value={v.id}> {v.name}</option>
                  ))
                : ''}
            </optgroup>
          ))}
        </select>
      </div>
      <div className="d-none d-md-block">
        <div className={`main ${active == '' ? 'active' : ''}`}>
          <a href="#" onClick={() => handleCategoryClick('')}>
            All Resources
          </a>
        </div>
        {list.map((value, key) => (
          <div className="sub" key={key}>
            <div className="position-relative w-100">
              <div className="parent">{value.title}</div>
              <div className="border-bottom"></div>
            </div>
            {Array.isArray(value.categories) && value.categories.length > 0
              ? value.categories.map((v, k) => (
                  <div className="child" key={k}>
                    <div
                      className={`sub-menu ${active == v.id ? 'active' : ''}`}
                      onClick={() => handleCategoryClick(v.id)}
                    >
                      <a href="#" aria-label={v.name} alt={v.name}>
                        {v.name}
                      </a>
                    </div>
                  </div>
                ))
              : ''}
          </div>
        ))}
      </div>
    </div>
  );
}

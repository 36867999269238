import React, { Fragment, useEffect, useState } from 'react';
import { apicallback } from '../../callbacks/index';
import loadable from '@loadable/component';
import { TfiMapAlt } from '../../components/icons/Index';
import InstagramLoader from '../../components/content_loader/InstagramLoader';
import PageTitle from '../../components/pagetitle/PageTitle';
import { useJsApiLoader } from '@react-google-maps/api';
const CardView = loadable(() => import('../../components/card/Index'));
const MapView = loadable(() => import('../../components/maps/MapView'));
const google = window.google;
const Index = () => {
  const [list, setlist] = useState([]);
  const [loader, setLoader] = useState(true);
  const [mapActive, setMapActive] = useState(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB64tknpa0WEmedDKWpaQ24ajJg4pspaOg',
  });

  useEffect(() => {
    getTopCollections();
  }, []);

  const getTopCollections = async () => {
    const response = await apicallback(
      'content',
      '/conferenceList',
      {},
      null,
      null
    );
    if (response && response.status == 'success') {
      setlist(response.data);
      setLoader(false);
    }
  };

  return (
    <Fragment>
      <section className="tf-section tf-rank">
        <PageTitle
          meta={{
            title: 'Web3 & NFT Conferences and Events | ANZALI',
            description:"View an interactive list of conferences and events happening in the Web3 and NFT space. See event details, visualize events on a map and indicate you're going to events you plan on attending."
          }}
        />
        <div className="themesflat-container mb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-live-auctions mg-bt-21 d-flex">
                <div>
                  <h2 className="text-left  mb-2">Conferences</h2>
                </div>
                <div className="position-relative">
                  <TfiMapAlt
                    style={{
                      color: mapActive ? '#f6c946' : '#ffffff',
                      fontSize: mapActive ? '3rem' : '2.5rem',
                    }}
                    onClick={() => setMapActive(mapActive ? false : true)}
                  />
                </div>
              </div>
            </div>

            {loader ? (
              <InstagramLoader value={4} coloum={3} />
            ) : list ? (
              <>
                {mapActive ? (
                  <MapView
                    events={list}
                    type="events"
                    data={null}
                    google={google}
                  />
                ) : (
                  list.map((item, index) => (
                    <CardView key={index} item={item} />
                  ))
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Index;

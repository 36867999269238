import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import ContentLoader from 'react-content-loader';
import { Bars } from 'react-loader-spinner';
import { useStoreState } from 'easy-peasy';
import { toast } from "react-toastify";
//Images
import {
    dummy_wallet_img, document_copy_img
} from '../../assets/Index';
//components
import {
    copyClipboard, condenseAddress, apicallback
} from '../../callbacks/index';
import LoginSignUp from '../../components/models/LoginSignUp';

import { getDisplayValueWithDash } from "../../utility/commonUtitlity";

const GET_WALLET_INFO = "GET_WALLET_INFO",
    UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export default function Unsubscribe() {
    const { walletAddress } = useParams();
    const authorization = useStoreState((state) => state.authorization);
    const [showLoginModalState, setShowLoginModalState] = useState(false);
    const [notInWalletState, setNotInWalletState] = useState(false);
    const [isErrorState, setIsErrorState] = useState(false);
    const location = useLocation();
    const [loadingState, setLoadingState] = useState({
        isLoading: false,
        action: ""
    });
    const history = useNavigate();
    const [subscriptionState, setSubscriptionState] = useState({
        isSubscribed: undefined,
        walletName: undefined
    });

    useEffect(() => {
        if (walletAddress && authorization) {
            getSubscriptionByAddressApiCall();
        } else if (!authorization) {
            setShowLoginModalState(true);
        }
    }, [walletAddress, authorization]);

    async function getSubscriptionByAddressApiCall(action) {
        setLoadingState(prevState => ({
            ...prevState,
            isLoading: true,
            action: GET_WALLET_INFO
        }));
        const response = await apicallback(
            null,
            `/getSubscriptionbyaddress?address=${walletAddress}`,
            {},
            'private',
            authorization.token, 'get'
        );
        if (response && response.status == 'success') {
            const { data } = response;
            if (data) {
                setSubscriptionState({
                    isSubscribed: data?.listening,
                    walletName: data?.wallet_details?.name
                });
            }
        } else if (response.status === "fail") {
            if (!response?.exists) {
                setNotInWalletState(true);
            } else {
                setIsErrorState(true);
            }
            toast.error(response?.message || 'Something went wrong.');
        }
        setLoadingState(prevState => ({
            isLoading: false
        }))
    }

    function hideAuthorizeModal() {
        history('/');
        setShowLoginModalState(false);

    }


    async function updateSubscriptionHndlr(event) {
        if (!authorization) {
            setShowLoginModalState(true);
            return;
        }
        setLoadingState(prevState => ({
            ...prevState,
            isLoading: true,
            action: UPDATE_SUBSCRIPTION
        }));
        const response = await apicallback(null, '/updateListeningWallet',
            {
                address: walletAddress,
            },
            'private',
            authorization.token
        );
        if (response.status === 'success') {
            toast.success("Wallet updated successfully.");
            history('/');
        } else if (response.status === 'fail') {
            toast.error('Something went wrong.');
        }
        setLoadingState(prevState => ({ ...prevState, isLoading: false }));
    }

    const urlPathName = location?.pathname;
    const { isLoading, action } = loadingState,
        isLoadingInfo = isLoading && action === GET_WALLET_INFO,
        isLoadingSubscription = isLoading && action === UPDATE_SUBSCRIPTION;
    return (
        <React.Fragment>
            <section id="unsubscribe-container" className="tf-section tf-rank">
                <div className="page-container">
                    <div className="row justify-content-center">
                        {(authorization && !isLoadingInfo) ? (<div className="col-12 col-md-7 col-lg-5 col-xl-4">
                            <div className="unsubscribe-container">
                                {isErrorState && <div className="px-5 py-5 w-100">
                                    <h2 className="text-white font-20 mb-5">
                                        Some error occured. Kindly contact to the administrator.
                                    </h2>
                                    <Link
                                        to="/"
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <button
                                            className="sc-button style-4 text-dark w-100 mb-4"
                                            id="notnow"
                                        >
                                            Home
                                        </button>
                                    </Link>
                                </div>}
                                {notInWalletState && <div className="px-5 py-5 w-100">
                                    <h2 className="text-white font-20 mb-5">
                                        This wallet address does not exist in your account.
                                        Go to your Tracker page to view all wallets that you're tracking.
                                    </h2>
                                    <Link
                                        to="/nft-wallet-tracker"
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                        <button
                                            className="sc-button style-4 text-dark w-100 mb-4"
                                            id="notnow"
                                        >
                                            Tracker
                                        </button>
                                    </Link>
                                </div>}
                                {!isErrorState && !notInWalletState && <>
                                    <div className="bg-header">
                                        <div className="walletdetails-container">
                                            <div className="walletdetails">
                                                <img src={dummy_wallet_img} className="wallet-img" />
                                                <div className="walletname mb-2 fw-bold font-18">
                                                    {getDisplayValueWithDash(subscriptionState.walletName)}
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <span className="mr-1 walletaddress">
                                                        {condenseAddress(walletAddress)}
                                                    </span>
                                                    <img
                                                        onClick={() => copyClipboard(walletAddress)}
                                                        src={document_copy_img}
                                                        style={{ width: '20px', height: '20px' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="body-container">

                                        <h2 className="mb-5">
                                            {subscriptionState?.isSubscribed ? "Unsubscribe" : "Subscribe"} from email alerts for this wallet?
                                        </h2>
                                        <button
                                            className="sc-button style-4 text-dark w-100 mb-4"
                                            id="wallet-state"
                                            onClick={updateSubscriptionHndlr}
                                            disabled={isLoadingSubscription}
                                        >
                                            {isLoadingSubscription ?
                                                <Bars
                                                    wrapperClass="justify-content-center"
                                                    color="#ffffff" height={20} width={30} /> :
                                                <>{subscriptionState?.isSubscribed ? "Unsubscribe" : "Subscribe"} </>
                                            }
                                        </button>
                                        <Link
                                            to="/"
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            <button
                                                className="custom-button border-btn w-100"
                                                id="notnow"
                                            >
                                                Not Now
                                            </button>
                                        </Link>
                                    </div>
                                </>}
                            </div>
                        </div>) : <div className="col-12 col-md-7 col-lg-5 col-xl-4">
                            <ContentLoader speed={3} foregroundColor={'#ccc'} height="420" width="100%">
                                <rect x="0" y="0" rx="12" ry="12" width="100%" height="100%" />
                            </ContentLoader>
                        </div>}
                    </div>
                </div>
            </section>
            <LoginSignUp
                show={showLoginModalState}
                onHide={hideAuthorizeModal}
                text={"Sign up or sign in to peform action on wallet."}
                url={urlPathName}
            />
        </React.Fragment >

    )
}
import React, { useEffect, useState } from 'react';
import { iconAvatar } from '../../assets/Index';
import {
  apicallback,
  openUrl,
  copyClipboard,
  saveMeta,
} from '../../callbacks/index';
import { useParams, Link } from 'react-router-dom';
import FacebookLoader from '../../components/content_loader/FacebookLoader';
import loadable from '@loadable/component';
import PageTitle from '../../components/pagetitle/PageTitle';
import ShareUi from '../../components/share/Index';
import moment from 'moment';
import { MdClose, TfiMapAlt } from '../../components/icons/Index';
import Dropdown from 'react-bootstrap/Dropdown';
import InfiniteScroll from 'react-infinite-scroller';
import Switch from 'react-switch';
import { useStoreState } from 'easy-peasy';
import { useJsApiLoader } from '@react-google-maps/api';
const MapView = loadable(() => import('../../components/maps/MapView'));
const List = () => {
  const authorization = useStoreState((actions) => actions.authorization);
  const [list, setlist] = useState({});
  const [loader, setLoader] = useState(true);
  const [alertInfo, setAlertInfo] = useState(false);
  const [attendees, setAttendees] = useState(false);
  const [attendeesList, setAttendeesList] = useState([]);
  const [eventInfo, setEventInfo] = useState({});
  const { conference_id } = useParams();
  const [mapActive, setMapActive] = useState(false);
  const [searchInput, setInputSearch] = useState('');
  const [conference_events, setConferenceEvents] = useState([]);
  const [search, setSearch] = useState([]);
  const [dropDown, setDropDown] = useState({});
  const [dropDownValue, setDropDownValue] = useState('Select Day');
  const currentPageUrl = window.location.href;
  useEffect(() => {
    conferenceDetails();
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyB64tknpa0WEmedDKWpaQ24ajJg4pspaOg',
  });

  const conferenceDetails = async () => {
    const response = await apicallback(
      'content',
      '/conferenceDetails',
      {
        conference_id: conference_id,
        user_id: authorization ? authorization.data.id.toString() : 0,
      },
      null,
      null
    );
    if (response && response.status == 'success') {
      setlist(response.data);
      setConferenceEvents(response.data.conference_events);
      setSearch(response.data.conference_events);
      setLoader(false);

      saveMeta({
        title: response?.data?.title + ' | ANZALI Conference' || null,
        description: response?.data?.title || null,
        images: response?.data?.meta_graphic || null,
        url: `/conferences/${conference_id}`,
        file: 'conferences',
      });

      let uniqueArray = {};
      response.conference_date.forEach((element) => {
        uniqueArray[element] = element;
      });
      setDropDown(uniqueArray);
    }
  };

  const handelSearch = (value) => {
    setInputSearch(value);
    if (value != '') {
      const results = search.filter((alert) => {
        return alert.title.toLowerCase().match(value.toLowerCase());
        // Use the toLowerCase() method to make it case-insensitive
      });
      setConferenceEvents(results);
    } else {
      setConferenceEvents(search);
    }
  };
  const filterByDate = (value) => {
    if (value != 'all') {
      setDropDownValue(value);
      const results = search.filter((element) => {
        return value == element.start_date || value == element.end_date;
        // Use the toLowerCase() method to make it case-insensitive
      });
      setConferenceEvents(results);
    } else {
      setConferenceEvents(search);
      setDropDownValue('Select Day');
    }
  };

  const handelUpdateStatus = async (id, type) => {
    const response = await apicallback(
      null,
      '/updateAttendeeStatus',
      {
        concert_id: id,
        status: type ? false : true,
        user_id: authorization.data.id.toString(),
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      conferenceDetails();
    }
  };
  const handelAttendees = async (id, type) => {
    const response = await apicallback(
      null,
      '/getAttendedsByConcert',
      {
        concert_id: id,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      setAttendeesList(response.data);
    }
  };

  return (
    <div className="item-details mb-5" style={{ padding: '0px 0 0px' }}>
      <PageTitle
        meta={{
          title: `${list ? list.title : ''} Conference Events - ANZALI`,
        }}
      />
      {alertInfo ? (
        <div className="alertUIModal">
          <div className="position-relative mb-4">
            <h3 className="text-center mt-3">Event Info</h3>
            <button
              onClick={() => setAlertInfo(false)}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <hr />
          <img src={eventInfo.cover_image} className="w-100" />
          <h3 className="my-3">{eventInfo.title}</h3>
          <h6
            className="mt-4 cursor-pointer"
            onClick={() =>
              openUrl(
                `https://maps.google.com/?q=${eventInfo.location_address}`
              )
            }
          >
            <span className="text-main">Address:</span>{' '}
            {eventInfo.location_address}
          </h6>
          <h6 className="mt-4">
            <span className="text-main">Venue:</span> {eventInfo.location_venue}
          </h6>
          <h6 className="mt-4">
            <span className="text-main">Start Date:</span>{' '}
            {moment(eventInfo.start_date).format('MMMM Do YYYY') + ' '}
            {eventInfo.start_time}
          </h6>
          <h6 className="mt-4">
            <span className="text-main">End Date:</span>{' '}
            {moment(eventInfo.end_date).format('MMMM Do YYYY') + ' '}
            {eventInfo.end_time}
          </h6>
          {eventInfo.description ? (
            <p
              className="mt-4"
              style={{
                lineHeight: 'normal',
                fontSize: '16px',
                height: '150px',
                overflow: 'auto',
              }}
            >
              {eventInfo.description}
            </p>
          ) : (
            ''
          )}
          {eventInfo.website_url ? (
            <button
              className="sc-button style-4 btn-block mt-4"
              onClick={() => openUrl(eventInfo.website_url)}
            >
              Visit Website
            </button>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
      {attendees ? (
        <div className="alertUIModal">
          <div className="position-relative mb-4">
            <h3 className="text-center mt-3">Attendees</h3>
            <button
              onClick={() => {
                setAttendees(false);
                setAttendeesList([]);
              }}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <hr />
          <img src={eventInfo.cover_image} className="w-100" />
          {attendeesList.length <= 0 ? (
            <FacebookLoader value={3} coloum={12} />
          ) : (
            <div
              id="nft-portfolio"
              style={{ height: '250px', overflow: 'auto' }}
            >
              <table className={`table table-striped`}>
                <tbody>
                  {attendeesList.map((value, index) => (
                    <tr className="cursor-pointer" key={index}>
                      <td className="d-flex justify-content-start align-items-center cursor-pointer onhover">
                        <img
                          src={
                            value.user_detail.profile_image
                              ? value.user_detail.profile_image
                              : iconAvatar
                          }
                          style={{ width: '35px', height: '35px' }}
                          className="rounded-circle"
                        />
                        <h5 className="pl-3 text-white">
                          @
                          {value.user_detail.username
                            ? value.user_detail.username
                            : 'username'}
                        </h5>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ) : (
        ''
      )}
      {loader ? (
        <div className="container">
          <div className="row">
            <FacebookLoader value={4} coloum={3} />
          </div>
        </div>
      ) : list ? (
        <>
          <section
            className="flat-title-page inner position-relative"
            style={{
              backgroundImage: `url(${list ? list.cover_image : ''})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <div className="container">
              <img
                id="collection-floting-img"
                src={list ? list.logo : ''}
                width="120"
                height="100%"
                alt={list ? list.title : ''}
              />
            </div>
          </section>
          <div className="tf-section tf-item-details">
            <div className="container" id="collection-deatils">
              <div className="sc-item-details">
                <div className="d-flex justify-content-between">
                  <h2 className="style2 mb-4">{list ? list.title : ''}</h2>
                  <div className="position-relative">
                    <TfiMapAlt
                      style={{
                        color: mapActive ? '#f6c946' : '#ffffff',
                        fontSize: mapActive ? '3rem' : '2.5rem',
                      }}
                      onClick={() => setMapActive(mapActive ? false : true)}
                    />
                  </div>
                </div>
                <div className="widget-social style-2 my-4">
                  <h4 className="title-widget">Share:</h4>
                  <ShareUi
                    url={currentPageUrl}
                    title={list ? list.title : ''}
                    logo={list ? list.logo : ''}
                    copyClipboard={copyClipboard}
                  />
                </div>
                <div className="position-relative">
                  <p className="item-description mb-4">
                    {list ? list.sub_title : ''}
                  </p>
                  <p className="item-description mb-4">
                    {list ? list.short_description : ''}
                  </p>
                  <Link
                    to="#"
                    className="text-main mt-2 h4 fw-bold"
                    onClick={() => openUrl(list.url)}
                  >
                    Submit an Event
                  </Link>
                </div>
              </div>

              {mapActive ? (
                <div className="mt-5 w-100">
                  <MapView
                    events={conference_events}
                    type="conferences"
                    data={{
                      location_lat: list.location_lat,
                      location_lng: list.location_lng,
                      map_zoom: list.map_zoom,
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="d-flex flex-row align-items-end justify-content-between mt-5">
                    <div className="position-relative">
                      {searchInput ? (
                        <MdClose
                          className="search-ico-alert"
                          onClick={() => {
                            setInputSearch('');
                            setConferenceEvents(search);
                          }}
                        />
                      ) : (
                        ''
                      )}
                      <input
                        onChange={(e) => handelSearch(e.target.value)}
                        placeholder="Search…"
                        className="comman-input mt-2 mt-sm-3"
                        value={searchInput}
                      />
                    </div>
                    <Dropdown className="custom-dropdown style-1">
                      <Dropdown.Toggle>
                        <h6 className="text-uppercase">
                          {dropDownValue === 'Select Day'
                            ? dropDownValue
                            : moment(dropDownValue).format('MMM Do YYYY')}
                        </h6>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item eventKey="0">
                          <div onClick={() => filterByDate('all')}>All</div>
                        </Dropdown.Item>
                        {Object.entries(dropDown).map(([index, item]) => (
                          <Dropdown.Item eventKey={index}>
                            <div onClick={() => filterByDate(item)}>
                              {moment(item).format('MMM Do YYYY')}
                            </div>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <InfiniteScroll
                    //    loadMore={fetchItems}
                    //   hasMore={hasMoreItems}
                    loader={loader}
                  >
                    <div className="row mt-5">
                      {conference_events.length > 0
                        ? conference_events.map((item, index) => (
                            <div
                              key={index}
                              className="col-xl-3 col-lg-4 col-md-6 col-12 d-flex align-items-stretch"
                            >
                              <div className={`sc-card-product`}>
                                <div
                                  className="card-media"
                                  onClick={() => {
                                    setEventInfo(item);
                                    setAlertInfo(true);
                                  }}
                                >
                                  <Link to="#">
                                    <img
                                      src={item.cover_image}
                                      alt={item.title ? `${item.title}` : `#`}
                                    />
                                  </Link>
                                </div>
                                <div
                                  className="card-"
                                  onClick={() => {
                                    setEventInfo(item);
                                    setAlertInfo(true);
                                  }}
                                >
                                  <h5 className="style3 mb-0">
                                    <Link to="#">
                                      {item.title ? `${item.title}` : `#`}
                                    </Link>
                                  </h5>
                                </div>

                                <p
                                  className="item-description text-main my-3 cursor-pointer"
                                  style={{
                                    lineHeight: 'normal',
                                    fontSize: '17px',
                                  }}
                                  onClick={() => {
                                    openUrl(
                                      `https://maps.google.com/?q=${item.location_address}`
                                    );
                                    setEventInfo({});
                                    setAlertInfo(false);
                                  }}
                                >
                                  {item.location_address}
                                </p>

                                <div
                                  className="meta-info"
                                  onClick={() => {
                                    setEventInfo(item);
                                    setAlertInfo(true);
                                  }}
                                >
                                  <div className="author">
                                    <div className="info">
                                      <span>From</span>
                                      <h6>
                                        {moment(item.start_date).format(
                                          'MMM Do YYYY'
                                        ) + ' '}
                                        {item.start_time}
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="author">
                                    <div className="info">
                                      <span>To</span>
                                      <h6>
                                        {moment(item.end_date).format(
                                          'MMM Do YYYY'
                                        ) + ' '}
                                        {item.end_time}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="meta-info"
                                  onClick={() => {
                                    setEventInfo(item);
                                    setAlertInfo(true);
                                  }}
                                >
                                  <div className="author">
                                    <div className="info">
                                      <span>Type</span>
                                      <h6>{item.type}</h6>
                                    </div>
                                  </div>
                                </div>
                                <div className="meta-info">
                                  <div className="author">
                                    <div className="info">
                                      <span>Going</span>
                                      <div>
                                        <Switch
                                          onColor="#f6c946"
                                          className="react-switch"
                                          disabled={
                                            authorization ? false : true
                                          }
                                          checked={
                                            item.going_status ? true : false
                                          }
                                          onChange={() =>
                                            handelUpdateStatus(
                                              item.event_id,
                                              item.going_status
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="author">
                                    <div className="info">
                                      <span>Attendees</span>
                                      <div
                                        className="avatarsstack"
                                        onClick={() => {
                                          setEventInfo(item);
                                          setAttendees(true);
                                          handelAttendees(item.event_id);
                                        }}
                                      >
                                        {item.event_users &&
                                          item.event_users
                                            .slice(0, 3)
                                            .map((val, key) => (
                                              <span
                                                className="avatartack"
                                                key={key}
                                              >
                                                <img
                                                  src={
                                                    val.profile_mage
                                                      ? process.env
                                                          .REACT_APP_BASE_URL +
                                                        '/static/images/' +
                                                        val.profile_mage
                                                      : iconAvatar
                                                  }
                                                />
                                              </span>
                                            ))}
                                      </div>
                                      {item && item.event_users ? (
                                        <div
                                          className="text-center cursor-pointer"
                                          onClick={() => {
                                            setEventInfo(item);
                                            setAttendees(true);
                                            handelAttendees(item.event_id);
                                          }}
                                        >
                                          View All
                                        </div>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : ''}
                    </div>
                  </InfiniteScroll>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default List;

import { useNavigate } from 'react-router-dom';
export default function SectionFourth() {
  const history = useNavigate();
  return (
    <section className="section-4 w-100">
      <div className="home-custom-container">
        <div className="row">
          <div className="col-sm-8 first">
            <h4>
              Explore NFT Collections <br className="d-none d-md-block" />
              with In-Depth Analytics
            </h4>
            <p className="mt-4">
              Track any collection and delve deep into individual NFTs. Access
              endless data points,
              <br className="d-none d-md-block" /> comprehensive charts, floor
              prices, and rarity scores for any NFT collection.
            </p>
            <div className="row mt-5 group">
              <div className="col-3 d-flex justify-content-between">
                <div>
                  <h5>Rarity</h5>
                  <span>Calculations</span>
                </div>
                <div className="_border_left"></div>
              </div>
              <div className="col-3 d-flex justify-content-around px-0">
                <div>
                  <h5>Historical</h5>
                  <span>Performance</span>
                </div>
                <div className="_border_left"></div>
              </div>
              <div className="col-3 d-flex justify-content-around px-0">
                <div>
                  <h5>Audit</h5>
                  <span>Data Points</span>
                </div>
                <div className="_border_left"></div>
              </div>
              <div className="col-3 px-0">
                <h5>Marketplace</h5>
                <span>Data & Insights</span>
              </div>
            </div>
          </div>
          <div
            className="col-sm-4 second cursor-pointer"
            onClick={() =>
              history(
                '/collection-info/0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d'
              )
            }
          ></div>
        </div>
      </div>
    </section>
  );
}

import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import AnzaliLoader from '../../../components/AnzaliLoader';
import PageTitle from '../../../components/pagetitle/PageTitle';
import {
  apicallback,
  saveMeta,
  openUrl,
  copyClipboard,
} from '../../../callbacks/index';
import { useParams, useNavigate } from 'react-router-dom';
import Player from '../../../components/video/Index';
import './detail.scss';
import {
  circle_clock,
  circle_eye,
  thumb_like,
  video_lock,
  share_icon,
  like_fill,
} from '../../../assets/Index';
import loadable from '@loadable/component';
import { Bars } from 'react-loader-spinner';
import { BsFacebook } from 'react-icons/bs';
import { AiFillRedditCircle, AiOutlineLink } from 'react-icons/ai';
import { ReactComponent as TwitterXIcon } from '../../../assets/images/icon/twitterx_icon.svg';
const LoginSignUp = loadable(() =>
  import('../../../components/models/LoginSignUp')
);
export default function Index() {
  const currentPageUrl = window.location.href;
  const { id } = useParams();
  const history = useNavigate();
  const [isLoadingState, setIsLoadingState] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [videoInformation, setVideoInformation] = useState(null);
  const [moreLikeVideos, setMoreLikeVideos] = useState([]);
  const authorization = useStoreState((state) => state.authorization);
  const [showModal, setModalShowSignup] = useState(false);
  const [showSocial, setShowSocial] = useState(false);
  useEffect(() => {
    getResource();
  }, [id]);

  async function getResource() {
    const response = await apicallback(
      null,
      `getVideosbyid`,
      {
        id: id,
      },
      'private',
      authorization?.token || process.env.REACT_APP_DJANGO_TOKEN
    );
    if (response && response.status === 'success') {
      if (response.data.login_required && authorization == null) {
        history(`/sign-in`, {
          state: {
            prevPath: window.location.pathname,
          },
        });
      }
      setIsLoading(false);
      saveMeta({
        title: response?.data?.title + ' | ANZALI Web3 Re' || null,
        description: response?.data?.description || null,
        images: response?.data?.cover_image || null,
        url: `/resource/${response?.data?.id}`,
        file: 'resources',
        keywords: `${response?.data?.title}, Anzali Blog, Trending Blog, ${
          response?.data?.keywords || ''
        }`,
      });
      setVideoInformation(response?.data || null);
      getMoreLike(response?.data?.categorie);
    }
  }

  async function getMoreLike(categord) {
    const response = await apicallback(
      null,
      '/getVideosbyCategory',
      { category_id: categord, limit: 5, offset: 0 },
      'private',
      authorization?.token || process.env.REACT_APP_DJANGO_TOKEN
    );
    if (response && response.status === 'success') {
      const res = response.data.filter((v) => {
        console.log(id, v.id);
        return id != v.id;
      });

      setMoreLikeVideos(res);
      setIsLoadingState(false);
    }
  }

  function handelClick(value, id) {
    if (value) {
      setIsLoadingState(true);
      history(`/resource/${id}`);
    } else {
      setModalShowSignup(true);
    }
  }

  async function updateVideoStats(type) {
    const response = await apicallback(
      null,
      '/updateVideoStats',
      { id: id, type: type },
      'private',
      authorization?.token || process.env.REACT_APP_DJANGO_TOKEN
    );
    if (response && response.status === 'success') {
      getResource();
    }
  }

  function handelSocial(value) {
    setShowSocial(value ? false : true);
  }

  return (
    <React.Fragment>
      <PageTitle
        meta={{
          title: 'Resources | ANZALI',
          description: '',
        }}
      />
      {isLoadingState && <AnzaliLoader />}
      <div className="page-container">
        <section className="tf-section tf-item-details video-details">
          <div className="resources">
            <div className="row">
              <div className="col-sm-12 col-md-7 col-lg-8 mb-5">
                <Player
                  url={videoInformation?.video_url}
                  cover_image={videoInformation?.cover_image}
                />
                <div className="mt-4 d-block d-sm-flex justify-content-between _first">
                  <div>
                    <h4>{videoInformation?.title}</h4>
                    <p>{videoInformation?.subtitle}</p>
                  </div>
                  <div className="my-4 my-sm-0 d-flex">
                    <div>
                      <button
                        className="mr-2"
                        onClick={() => {
                          if (authorization) {
                            updateVideoStats(
                              videoInformation?.likes_status ? 0 : 1
                            );
                            setIsLoading(true);
                          } else {
                            setModalShowSignup(true);
                          }
                        }}
                      >
                        {isLoading ? (
                          <Bars color="#ffffff" height={20} width={30} />
                        ) : (
                          <>
                            <img
                              src={
                                videoInformation?.likes_status
                                  ? like_fill
                                  : thumb_like
                              }
                              style={{
                                filter: videoInformation?.likes_status
                                  ? 'unset'
                                  : 'brightness(100)',
                              }}
                            />
                            Like Video
                          </>
                        )}
                      </button>
                    </div>
                    <div className="position-relative">
                      <button onClick={() => handelSocial(showSocial)}>
                        <img src={share_icon} />
                        Share Video
                      </button>
                      {showSocial ? (
                        <div className="header-actions-share mb-4">
                          <a
                            href="#"
                            onClick={() =>
                              openUrl(
                                `https://www.facebook.com/sharer/sharer.php?u=${currentPageUrl}`
                              )
                            }
                            className="share-icon"
                          >
                            <BsFacebook />
                          </a>
                          <a
                            href="#"
                            onClick={() =>
                              openUrl(
                                `http://www.reddit.com/submit?url=${currentPageUrl}`
                              )
                            }
                            className="share-icon"
                          >
                            <AiFillRedditCircle />
                          </a>
                          <a
                            href="#"
                            onClick={() =>
                              openUrl(
                                `https://twitter.com/intent/tweet?url=${currentPageUrl}`
                              )
                            }
                            className="share-icon twitter-icon"
                          >
                            <TwitterXIcon />
                          </a>
                          <a
                            href="#"
                            onClick={() => copyClipboard(currentPageUrl)}
                            className="share-icon"
                          >
                            <AiOutlineLink />
                          </a>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <p className="mb-4">{videoInformation?.description}</p>
                <div className="_second d-flex">
                  <p className="mr-3">{videoInformation?.category_name}</p>
                  <div className="count">
                    <span className="pr-3 boder-right">
                      <img
                        src={
                          videoInformation?.likes_status
                            ? like_fill
                            : thumb_like
                        }
                      />
                      {videoInformation?.likes}
                    </span>
                    <span className="pl-3">
                      <img src={circle_eye} />
                      {videoInformation?.views}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-4">
                <div className="_more_like">
                  <h4 className="head mb-4">More Like This</h4>
                  <hr />
                  {moreLikeVideos.length
                    ? moreLikeVideos.map((value, key) => (
                        <div
                          className="w-100 list-card mb-3"
                          key={key}
                          onClick={() =>
                            handelClick(
                              value.login_required && authorization == null
                                ? false
                                : true,
                              value.id
                            )
                          }
                        >
                          <div className="d-flex">
                            <div className="position-relative">
                              {value.login_required && authorization == null ? (
                                <div className="lock-check">
                                  <img
                                    src={video_lock}
                                    alt={value.title}
                                    className="mb-3"
                                  />
                                  <span>
                                    Sign Up or Sign
                                    <br /> In to Watch this Video
                                  </span>
                                </div>
                              ) : (
                                ''
                              )}
                              <img
                                src={value.cover_image}
                                alt={value.title}
                                className="main_img"
                              />
                            </div>
                            <div className="ml-4">
                              <h4>{value.title}</h4>
                              <p>{value.subtitle}</p>
                              <div className="count">
                                <span className="pr-3 boder-right">
                                  <img
                                    src={
                                      value?.likes_status
                                        ? like_fill
                                        : thumb_like
                                    }
                                  />
                                  {value?.likes}
                                </span>
                                <span className="px-3  boder-right">
                                  <img src={circle_eye} />
                                  {value?.views}
                                </span>
                                <span className="pl-3">
                                  <img src={circle_clock} />
                                  {value?.duration}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <LoginSignUp
        show={showModal}
        onHide={() => setModalShowSignup(false)}
        text={'Sign Up or Sign In to Watch this Video'}
        url={true}
      />
    </React.Fragment>
  );
}

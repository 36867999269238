import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';
// Import Swiper styles
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import Card from './Card';
export default function SliderList(props) {
  const { list, authorization } = props;
  return (
    <Swiper
      spaceBetween={30}
      breakpoints={{
        0: {
          slidesPerView: 2,
        },
        767: {
          slidesPerView: 2,
        },
        991: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 4,
        },
        1350: {
          slidesPerView: 4,
        },
      }}
      scrollbar={{ draggable: true }}
      modules={[Navigation, Scrollbar, A11y]}
    >
      {Array.isArray(list) &&
        list.map((data, key) => (
          <SwiperSlide>
            <Card
              key={key}
              data={data}
              authorization={authorization ? true : false}
            />
          </SwiperSlide>
        ))}
    </Swiper>
  );
}

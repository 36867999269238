import * as Yup from 'yup';

export const tabsOptions = [
  {
    tabId: 'start-request',
    tabName: 'Start Request',
  },
  {
    tabId: 'service-request',
    tabName: 'Service Request',
  },
  {
    tabId: 'timeline',
    tabName: 'Timeline',
  },
  {
    tabId: 'description',
    tabName: 'Description',
  },
  {
    tabId: 'contact-information',
    tabName: 'Contact Information',
  },
  {
    tabId: 'thankyou',
    tabName: 'Thank You',
  },
];

export const serviceTabInitialValue = {
  service_id: '',
};
export const serviceTabValidationSchema = Yup.object().shape({
  service_id: Yup.string().required('Select service from the options.'),
});

export const timelineInitialValue = {
  start_date: '',
  end_date: '',
};
export const timelineValidationSchema = Yup.object().shape({
  start_date: Yup.string().test(
    'startDateCheck',
    'Start date is required if end date is provided.',
    (value, schema) => {
      if (!value && schema.parent?.end_date) {
        return false;
      }
      return true;
    }
  ),
  end_date: Yup.string().test(
    'endDateCheck',
    'End date is required if start date is provided.',
    (value, schema) => {
      if (!value && schema.parent?.start_date) {
        return false;
      }
      return true;
    }
  ),
});

// start_date: Yup.string().when("end_date", {
//     is: (value) => !!value,
//     then: () => Yup.string()
//         .required('Start date is required.'),
//     otherwise: () => Yup.string()
// }),
// end_date: Yup.string()

export const descriptionInitialValue = {
  description: '',
};
export const descriptionValidationSchema = Yup.object().shape({
  description: Yup.string().required('Description is required.'),
});

export const projectInfoInitialValue = {
  name: '',
  company_name: '',
  email: '',
  mobile_number: '',
  terms_condition: false,
};
export const projectInfoValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  email: Yup.string()
    .email('Enter valid email.')
    .required('Email is required.'),
  mobile_number: Yup.string()
    .trim()
    .matches(/^[0-9]{10,10}$/, 'Mobile number with 10 digit is valid.')
    .required('Mobile number required.')
    .nullable(),
  terms_condition: Yup.bool().oneOf(
    [true],
    'Please accept our terms and privacy policy.'
  ),
});

export function createUserServicePayload(formData) {
  const payload = Object.keys(formData).reduce((data, item) => {
    if (formData[item]) {
      Object.assign(data, { ...formData[item] });
    }
    return data;
  }, {});
  payload.service_description = 'description';
  delete payload.terms_condition;
  return payload;
}

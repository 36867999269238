import React, { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { share_icon } from '../../assets/Index';
import { ReactComponent as AwardsIcon } from '../../assets/images/airdrops/award.svg';
import { ReactComponent as ThunmbsIcon } from '../../assets/images/airdrops/thumbs.svg';
import { ReactComponent as ClipboardIcon } from '../../assets/images/airdrops/clipboard.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { noImageFound } from '../../assets/Index';
import { extractContentFromHtmlString } from '../../utility/commonUtitlity';
import classNames from 'classnames';
import { copyClipboard } from '../../callbacks';
function replaceImage(error) {
  //replacement of broken Image
  error.target.src = noImageFound;
}

export default function AirdropCard({
  slug,
  title,
  description,
  likes = '0',
  tasks = '0',
  tokens = '0',
  airdropImage,
  redirectHandler,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function onClickHandler(event) {
    const sectionId = event.currentTarget.getAttribute('data-id');
    if (sectionId) {
      event.stopPropagation();
    }
    redirectHandler(slug, sectionId);
  }

  const baseLocation = window.location.origin;
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="airdropcard-container"
    >
      {/* <div className="airdropimage-container" style={{ backgroundColor: bgColor }}>
                <img src={airdropImage} className="airdrop-image" />
            </div> */}
      <div onClick={onClickHandler} className="project-container">
        <img
          src={airdropImage || noImageFound}
          className="projectimage"
          onError={replaceImage}
        />
      </div>
      <div className="body-container">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <h3 className="dropname cursor-pointer" onClick={onClickHandler}>
            {title}
          </h3>
          <button
            className="sharebtn"
            onClick={() => {
              copyClipboard(`${baseLocation}/airdrops/${slug}`);
            }}
          >
            <img src={share_icon} />
          </button>
        </div>
        <p className="dropdescription cursor-pointer" onClick={onClickHandler}>
          {extractContentFromHtmlString(description)}
        </p>
      </div>
      <div className="footer-container">
        <div
          className={classNames(
            'marquee-content',
            isHovered ? 'marquee-play' : ''
          )}
        >
          <button
            data-id="likes"
            onClick={onClickHandler}
            className="footeraction-btn"
          >
            <div className="icon-container">
              {/* <img src={share_icon} /> */}
              <ThunmbsIcon height="16px" width="16px" />
            </div>
            <span className="btntext">{likes || '0'} Likes</span>
          </button>
          <button
            data-id="tasks"
            onClick={onClickHandler}
            className="footeraction-btn"
          >
            <div className="icon-container">
              <ClipboardIcon height="16px" width="16px" />
            </div>
            <span className="btntext">{tasks || '0'} Tasks</span>
          </button>
          {/* <button
            data-id="tokens"
            onClick={onClickHandler}
            className="footeraction-btn"
          >
            <div className="icon-container">
              <AwardsIcon height="16px" width="16px" />
            </div>
            <span className="btntext">{tokens || '0'} Tokens</span>
          </button> */}
          <button
            data-id="likes"
            onClick={onClickHandler}
            className="footeraction-btn"
          >
            <div className="icon-container">
              {/* <img src={share_icon} /> */}
              <ThunmbsIcon height="16px" width="16px" />
            </div>
            <span className="btntext">{likes || '0'} Likes</span>
          </button>
          <button
            data-id="tasks"
            onClick={onClickHandler}
            className="footeraction-btn"
          >
            <div className="icon-container">
              <ClipboardIcon height="16px" width="16px" />
            </div>
            <span className="btntext">{tasks || '0'} Tasks</span>
          </button>
          {/* <button
            data-id="tokens"
            onClick={onClickHandler}
            className="footeraction-btn"
          >
            <div className="icon-container">
              <AwardsIcon height="16px" width="16px" />
            </div>
            <span className="btntext">{tokens || '0'} Tokens</span>
          </button> */}
        </div>
      </div>
    </div>
  );
}

export function AirdropLoadingCard({ containerClassName }) {
  return (
    <div
      className={classNames('airdroploadingcard-container', containerClassName)}
    >
      <div className="loading-img">
        <ContentLoader
          className="vertical-middle"
          foregroundColor="#595959"
          backgroundColor="#3E3E3E"
          width="100%"
          height="100%"
        >
          <rect x="0" y="0" width="100%" height="100%" />
        </ContentLoader>
      </div>
      <div className="p-3">
        <div className="loading-title">
          <ContentLoader
            className="vertical-middle"
            foregroundColor="#595959"
            backgroundColor="#3E3E3E"
            width="100%"
            height="18"
          >
            <rect x="0" y="0" rx="4" ry="4" width="80%" height="100%" />
          </ContentLoader>
        </div>
        <div className="loading-description">
          <ContentLoader
            className="vertical-middle"
            foregroundColor="#595959"
            backgroundColor="#3E3E3E"
            width="100%"
            height="18"
          >
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
          </ContentLoader>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <ContentLoader
            className="vertical-middle"
            foregroundColor="#595959"
            backgroundColor="#3E3E3E"
            width="30%"
            height="30"
          >
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
          </ContentLoader>
          <ContentLoader
            className="vertical-middle"
            foregroundColor="#595959"
            backgroundColor="#3E3E3E"
            width="30%"
            height="30"
          >
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
          </ContentLoader>
          <ContentLoader
            className="vertical-middle"
            foregroundColor="#595959"
            backgroundColor="#3E3E3E"
            width="30%"
            height="30"
          >
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="100%" />
          </ContentLoader>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Table from '../../../components/table/Table';
import { Bars } from 'react-loader-spinner';
import {
  generateColumns,
  generateItemColumns,
  transactionData,
} from './helper';
import { frame_view_icon } from '../../../assets/Index';
import { RiCloseLine } from 'react-icons/ri';
import { copyClipboard } from '../../../callbacks/index';
//API Actions
import { apicallback } from '../../../callbacks/index';

//Assets
import { auth_required_img } from '../../../assets/Index';

//Utility
import {
  getDisplayAmountClassName,
  getTwoDigitValue,
  getValueWithDecimal,
} from '../../../utility/commonUtitlity';
import { toast } from 'react-toastify';

export default function Performance({
  walletInfo,
  walletCountInfo,
  authorizeCallback,
  supportModalCallback,
}) {
  const { id: walletId } = useParams();
  const authorization = useStoreState((state) => state.authorization);
  const [itemsModalState, setItemsModalState] = useState({
    showModal: false,
    itemsList: [],
    collectionAddress: '',
  });
  const [isLoadingPerformance, setIsLoadingPerformance] = useState(true);
  const [performanceState, setPerformanceState] = useState({});
  const performanceIntervalRef = useRef(null);

  useEffect(() => {
    if (authorization && walletId) {
      calculateProfit();
      performanceIntervalRef.current = setInterval(async () => {
        await calculateProfit();
      }, 10000);
    }

    return () => {
      clearInterval(performanceIntervalRef.current);
      performanceIntervalRef.current = null;
    };
  }, [authorization, walletId]);

  async function calculateProfit() {
    try {
      const response = await apicallback(
        null,
        '/calculate_profitv2',
        {
          wallet_id: walletId,
          type: 0,
        },
        'private',
        authorization.token
      );
      if (response && response.status == 'success') {
        const { data } = response || {},
          { loading } = data || {};
        if (loading) {
          return;
        }
        clearInterval(performanceIntervalRef.current);
        performanceIntervalRef.current = null;
        setPerformanceState(data);
        setIsLoadingPerformance(false);
      } else {
        toast.error('Something went wrong.');
      }
    } catch (e) {
      console.log(e);
      toast.error('Something went wrong');
    }
  }

  function showModalItemHndlr(event) {
    const collectionAddress = event.currentTarget.getAttribute('data-id'),
      { assets } = performanceState;
    if (collectionAddress && Array.isArray(assets)) {
      const addressInfo = assets.find(
        (item) => item.collection_address === collectionAddress
      );
      if (addressInfo) {
        const { items } = addressInfo;
        setItemsModalState((prevState) => ({
          ...prevState,
          showModal: true,
          itemsList: items,
          collectionAddress,
        }));
      }
    }
  }

  const menuOptions = useMemo(() => {
    return [
      {
        id: 'view',
        actionIcon: (
          <img
            src={frame_view_icon}
            style={{ width: '28px', height: '30px' }}
          />
        ),
        actionName: 'View',
        onClickHndlr: showModalItemHndlr,
      },
    ];
  }, [performanceState]);

  function toggleModalHndlr() {
    setItemsModalState(false);
  }

  function copyAddressHandler(event) {
    const walletAddress = event.currentTarget.getAttribute('data-id');
    if (walletAddress) {
      copyClipboard(walletAddress);
    }
  }

  const mintingColumns = useMemo(
      () => generateColumns(menuOptions, showModalItemHndlr),
      [performanceState]
    ),
    itemsColumns = useMemo(
      () =>
        generateItemColumns(
          itemsModalState.collectionAddress,
          copyAddressHandler
        ),
      [itemsModalState]
    ),
    { assets: performanceTableData, exceeding_count = false } =
      performanceState;
  return (
    <React.Fragment>
      {authorization ? (
        <div className="walletcollections-container">
          <div className="row">
            <div className="col-12 mb-5">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between ">
                  <h4 className="mb-0">Performance</h4>
                </div>
              </div>
            </div>
            <div className="col-12 mb-4">
              <div className="row custom-row blanaceinfo-cardcontainer ">
                <div className="col-6 col-lg-3 col-xl-2 custom-column">
                  <div className="balanceinfo-card">
                    <h6 className="label-info">ETH Balance</h6>
                    <p className="value-info text-success-dark">
                      {walletCountInfo?.ether_balance} ETH
                    </p>
                  </div>
                </div>
                <div className="col-6 col-lg-3 col-xl-2 custom-column">
                  <div className="balanceinfo-card">
                    <h6 className="label-info">Total Bought</h6>
                    {isLoadingPerformance ? (
                      <Bars
                        color="#ffffff"
                        wrapperStyle={{ padding: '5px' }}
                        height={22}
                        width={50}
                      />
                    ) : exceeding_count ? (
                      <p
                        className="value-info display-blur"
                        onClick={() => supportModalCallback()}
                      >
                        00.000 ETH
                      </p>
                    ) : (
                      <p className="value-info">
                        {getValueWithDecimal(performanceState?.total_bought)}{' '}
                        ETH
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-6 col-lg-3 col-xl-2 custom-column">
                  <div className="balanceinfo-card">
                    <h6 className="label-info">Total Sold</h6>
                    {isLoadingPerformance ? (
                      <Bars
                        color="#ffffff"
                        wrapperStyle={{ padding: '5px' }}
                        height={22}
                        width={50}
                      />
                    ) : exceeding_count ? (
                      <p
                        className="value-info display-blur"
                        onClick={() => supportModalCallback()}
                      >
                        00.000 ETH
                      </p>
                    ) : (
                      <p className="value-info">
                        {getValueWithDecimal(performanceState?.total_sold)} ETH
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-6 col-lg-3 col-xl-2 custom-column">
                  <div className="balanceinfo-card">
                    <h6 className="label-info">Realized Profits</h6>
                    {isLoadingPerformance ? (
                      <Bars
                        color="#ffffff"
                        wrapperStyle={{ padding: '5px' }}
                        height={22}
                        width={50}
                      />
                    ) : exceeding_count ? (
                      <p
                        className="value-info display-blur"
                        onClick={() => supportModalCallback()}
                      >
                        00.000 ETH
                      </p>
                    ) : (
                      <p
                        className={classNames(
                          'value-info',
                          getDisplayAmountClassName(walletInfo?.profit)
                        )}
                      >
                        {getValueWithDecimal(walletInfo?.profit)} ETH
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-6 col-lg-3 col-xl-2 custom-column">
                  <div className="balanceinfo-card">
                    <h6 className="label-info">Cost Basis</h6>
                    {isLoadingPerformance ? (
                      <Bars
                        color="#ffffff"
                        wrapperStyle={{ padding: '5px' }}
                        height={22}
                        width={50}
                      />
                    ) : exceeding_count ? (
                      <p
                        className="value-info display-blur"
                        onClick={() => supportModalCallback()}
                      >
                        00.000 ETH
                      </p>
                    ) : (
                      <p className="value-info">
                        {getValueWithDecimal(performanceState?.cost_basis)} ETH
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <Table
                columns={mintingColumns}
                data={
                  Array.isArray(performanceTableData)
                    ? performanceTableData
                    : []
                }
                noDataTitle={
                  exceeding_count
                    ? "We currently support wallets with less than 25,000 transactions. We're working on improving this limit for future versions."
                    : ''
                }
                isDataLoading={isLoadingPerformance}
                loadingMessage="Loading Performance."
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          onClick={() => authorizeCallback(true)}
        >
          <h4 className="my-4 text-main">
            Sign up or sign in to see performance.
          </h4>
          <img src={auth_required_img} width="250" />
        </div>
      )}
      <Modal
        show={itemsModalState.showModal}
        onHide={toggleModalHndlr}
        centered
        dialogClassName="performance-items-modal"
        contentClassName="performance-items-modalcontainer"
        className="performance-backdrop"
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Title className="modal-header">
          <h4 className="mb-0">Items</h4>
          <span className="headerclose-icon">
            <RiCloseLine
              onClick={toggleModalHndlr}
              className="text-neutral"
              style={{ height: '20px', width: '20px' }}
            />
          </span>
        </Modal.Title>
        <Modal.Body>
          {/* <ItemsModal
                        itemsList={itemsModalState.itemsList}
                        closeModal={toggleModalHndlr} /> */}
          <Table
            columns={itemsColumns}
            data={
              Array.isArray(itemsModalState.itemsList)
                ? itemsModalState.itemsList
                : []
            }
            isDataLoading={false}
            loadingMessage="Loading Items."
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

import React from 'react';
import classNames from 'classnames';

export default function InputTextArea(props) {
  return (
    <div className={props.containerClassName}>
      <label className={classNames('form-label', props.labelClassName)}>
        {props.labelName}
        {props.isOptional && (
          <span className="helper-label font-14"> (Optional)</span>
        )}
      </label>
      <textarea
        id={props.id}
        name={props.name}
        rows={props.rows || 3}
        className={classNames('custom-input-control', {
          'in-valid': props.invalid,
        })}
        placeholder={props.placeHolder}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        style={{ resize: 'none' }}
      ></textarea>
      {props.invalid && (
        <div className="custom-invalid-error">{props.error}</div>
      )}
    </div>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Bars } from 'react-loader-spinner';
import AssetCard from '../../components/card/assetscard';
import NoDataComponent from '../../components/NoDataComponent';
import LoadingCard from '../../components/card/LoadingCard';
import { chainList, getValueWithDecimal } from '../../utility/commonUtitlity';
import { noImageFound } from '../../assets/Index';

export default function Assets({ walletInfo }) {
  const { wallet_address: walletAddress, chain_id: chainIdFromUrl } =
    useParams();
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    action: '',
  });
  ///V2
  const [assetsStateV2, setAssetsStateV2] = useState([]);
  const [assetsOffsetStateV2, setAssetsOffsetStateV2] = useState(null);
  const [noDataFoundStateV2, setNoDataFoundStateV2] = useState(false);

  useEffect(() => {
    if (walletAddress) {
      getAssetsApiCall();
    }
  }, [walletAddress]);

  function loadMoreHandler() {
    getAssetsApiCall(assetsOffsetStateV2);
  }
  async function getAssetsApiCall(cursor = null) {
    setLoadingState({
      isLoading: true,
    });
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_NODE_ENDPOINT_V2
        }/getNfts?address=${walletAddress}${
          cursor ? `&pagekey=${cursor}` : ''
        }&chain=${chainIdFromUrl}`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      if (response.data.status === 200) {
        const res = response.data.data;
        if (res.ownedNfts.length === 0 && assetsStateV2.length === 0) {
          setNoDataFoundStateV2(true);
        }
        setAssetsStateV2([...assetsStateV2, ...res.ownedNfts]);
        setAssetsOffsetStateV2(res.pageKey);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingState({
        isLoading: false,
        action: '',
      });
    }
  }
  const isloadMoreLoading = false;
  return (
    <div className="walletcollections-container">
      <div className="row">
        <div className="col-12">
          <div className="tabbody-headercontainer">
            <div className="d-flex align-items-center justify-content-between">
              <h4 className="mb-0">Assets</h4>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="feature-card-container">
            {assetsStateV2.map((item, index) => {
              const { image } = item;
              let imgUrl = null;
              if (image) {
                imgUrl = image.originalUrl
                  ? image.originalUrl
                  : image.cachedUrl
                  ? image.cachedUrl
                  : item?.collection?.bannerImageUrl || noImageFound;
              }
              return (
                <div key={`feature-token=${index}`} className="card-container">
                  <AssetCard
                    tokenTitle={item?.name}
                    tokenId={item?.tokenId}
                    assetImage={imgUrl}
                    isVerified={item?.contract?.isSpam}
                    circulatingValue={
                      item?.contract?.openSeaMetadata
                        ? getValueWithDecimal(
                            item?.contract?.openSeaMetadata?.floorPrice
                          )
                        : ''
                    }
                    contractAddress={item?.contract?.address}
                    last_sold={item?.timeLastUpdated}
                    is_last_sold={true}
                    redirectLink={`/item-info/${item.contract.address}/${item.tokenId}/${chainIdFromUrl}`}
                    chain={chainList(chainIdFromUrl)}
                  />
                </div>
              );
            })}
            {loadingState.isLoading &&
              Array(5)
                .fill(0)
                .map((item, index) => (
                  <div
                    key={`dummy-feature-token=${index}`}
                    className="card-container"
                  >
                    <LoadingCard />
                  </div>
                ))}

            {noDataFoundStateV2 && (
              <NoDataComponent title="No Assets Found."></NoDataComponent>
            )}
          </div>
        </div>
        <div className="col-12 mt-3 d-flex justify-content-center">
          {assetsOffsetStateV2 && (
            <button onClick={loadMoreHandler} className="sc-button style-4">
              {isloadMoreLoading ? (
                <Bars color="#ffffff" height={30} width={80} />
              ) : (
                'Load More'
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import { useWeb3Modal } from '@web3modal/react';
//Connect Etherium Functionality
import { useAccount, useDisconnect } from 'wagmi';
import TodayPicks from './TodayPicks';
import { apicallback, copyClipboard } from '../../callbacks/index';
import PageTitle from '../../components/pagetitle/PageTitle';
import QrModel from '../../components/QrModel/Index';
import { useStoreState } from 'easy-peasy';
import { Bars } from 'react-loader-spinner';
import { openToasterAlert } from '../../components/alert/Index';
import {
  MdInfo,
  HiOutlineQrcode,
  FaRegCopy,
} from '../../components/icons/Index';

const Index = () => {
  const authorization = useStoreState((actions) => actions.authorization);
  const [wallets, setwallets] = useState(null);
  const { disconnect } = useDisconnect();
  const [isAnzaliActive, setIsAnzaliActive] = useState(false);
  const [collectedCollections, setcollectedCollections] = useState({});
  const [alertInfo, setAlertInfo] = useState(false);
  const [disconectLoader, setDisconectLoader] = useState(false);
  const [qrCameraAlert, setQrCameraAlert] = useState(false);
  const [ethPrice, setEthPrice] = useState(null);
  //Address
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  //On page mount.
  useEffect(async () => {
    await getCurrentPrice();
    await getMembershipCollections();
  }, [wallets]);

  useEffect(async () => {
    if (authorization) {
      await getUserWallet();
    }
  }, [authorization]);

  useEffect(() => {
    async function waitForAddressUpdate(walletAddress) {
      await setUserWallet(walletAddress);
    }
    if (address && isConnected) {
      waitForAddressUpdate(address);
    }
  }, [address, isConnected]);

  const getCurrentPrice = async (value) => {
    try {
      await fetch(
        'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD'
      )
        .then((response) => response.text())
        .then((data) => setEthPrice(JSON.parse(data).USD));
    } catch (error) { }
    return;
  };

  const getUserWallet = async () => {
    if (authorization) {
      const response = await apicallback(
        null,
        '/getWalletAddress',
        {},
        'private',
        authorization && authorization.token,
        'get'
      );
      if (response && response.status == 'success') {
        if (response.data.wallet_address != null) {
          setwallets(response.data.wallet_address);
        }
      }
    }
    return;
  };

  const getMembershipCollections = async () => {
    setcollectedCollections([]);
    const response = await apicallback(
      null,
      '/getPremiumCollections',
      {},
      'private',
      authorization && authorization.token,
      'get'
    );
    if (response && response.status == 'success') {
      const res = response.data;
      let arr = {};
      for (let i = 0; i < res.length; i++) {
        res[i].select = false;
        arr[res[i].id] = res[i];
      }
      if (authorization && wallets) {
        await getUserPremiumCollection(arr);
      } else {
        setcollectedCollections(arr);
      }
      setIsAnzaliActive(response.isAnzaliActive);
    }
    return;
  };

  const getUserPremiumCollection = async (data) => {
    if (wallets) {
      const response = await apicallback(
        null,
        '/getUserPremiumCollection',
        {
          address: wallets,
          chain: 'eth',
        },
        'private',
        authorization.token
      );
      if (response && response.status == 'success') {
        const res = response.data;
        let arr = data;
        for (let i = 0; i < res.length; i++) {
          arr[res[i]].select = true;
        }
        setcollectedCollections(arr);
      } else {
        setcollectedCollections(data);
      }
      return false;
    }
    return;
  };

  const connect = async () => {
    if (authorization) {
      await open({ route: 'ConnectWallet' });
      return;
    }
    openToasterAlert('error', 'Sign up or sign in to enter Rewards.');
  };

  const setUserWallet = async (address) => {
    const response = await apicallback(
      null,
      '/updateWalletAddress',
      {
        wallet_address: address,
      },
      'private',
      authorization.token
    );
    if (response && response.status == 'success') {
      setwallets(address);
      disconnect();
    }
  };

  const removeUserWallet = async () => {
    setDisconectLoader(true);
    const response = await apicallback(
      null,
      '/removeWalletAddress',
      {
        wallet_address: wallets,
      },
      'private',
      authorization.token,
      'get'
    );
    if (response && response.status == 'success') {
      await getMembershipCollections();
      setwallets(null);
    }
    setDisconectLoader(false);
  };

  const condenseAddress = (address) => {
    return (
      address.substring(0, 4) +
      '....' +
      address.substring(address.length - 4, address.length)
    );
  };
  const handelModelClose = () => {
    setQrCameraAlert(false);
  };

  return (
    <section className="tf-section tf-rank">
      <PageTitle
        meta={{
          title: 'Redeem Exclusive Web3 Rewards & Perks | ANZALI',
          description:
            'Get special Web3 rewards for certain NFTs. Explore businesses that give rewards to their Web3 NFT holders. Enjoy instant access to perks and rewards.',
        }}
      />
      {qrCameraAlert ? <QrModel closeModel={handelModelClose} /> : ''}
      {alertInfo ? (
        <div className="alertUIModal">
          <div className="position-relative mb-4">
            <h3 className="text-center mb-3">Rewards</h3>
            <button
              onClick={() => setAlertInfo(false)}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <hr />
          <p className="text-center mt-3 mb-3">
            Purchase digital memberships to access rewards from participating businesses.
            Already have a membership? Simply connect your wallet and gain immediate access.
          </p>
          <p className="text-center">
            We're looking to actively grow this section. Email us at &nbsp;
            <a style={{ color: "#e9b618" }} href="mailto:support@anzali.io" className="text-decoration-underline cursor-pointer">
              support@anzali.io
            </a>&nbsp;with questions on how to join.
          </p>
          <button
            className="sc-button style-4 btn-block mt-4"
            onClick={() => setAlertInfo(false)}
          >
            Got it
          </button>
        </div>
      ) : (
        ''
      )}
      <div className="container">
        <div className="heading-live-auctions mg-bt-21 d-flex">
          <div>
            <h2 className="text-left  mb-2">Rewards</h2>
            <p
              className="h3 mt-0 mb-5 text-main cursor-pointer"
              onClick={() => setAlertInfo(true)}
            >
              Get rewards at participating businesses
              <MdInfo style={{ fontSize: '2rem', marginLeft: "0.25rem", verticalAlign: 'bottom' }} />
            </p>
          </div>
          {/* <div
            className="text-center mb-4"
            onClick={() => {
              if (wallets) {
                setQrCameraAlert(true);
              } else {
                openToasterAlert('error', 'Connect your wallet to continue.');
              }
            }}
          >
            <h2>
              <HiOutlineQrcode />
            </h2>
            <small>Scan QR</small>
          </div> */}
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button
              className="sc-button header-slider style style-1 wallet fl-button pri-1  wallet-sm-btn"
              onClick={() => {
                connect();
              }}
              disabled={disconectLoader}
            >
              <span>
                {wallets ? condenseAddress(wallets) : 'Connect Wallet'}{' '}
              </span>
            </button>
            {wallets ? (
              <h5
                className="text-main ml-3"
                onClick={() => copyClipboard(wallets)}
              >
                <FaRegCopy />
              </h5>
            ) : (
              ''
            )}
          </div>
          {wallets ? (
            <button
              className="sc-button header-slider style style-1 wallet fl-button pri-1  ml-3 wallet-sm-btn"
              onClick={() => {
                removeUserWallet();
              }}
              disabled={disconectLoader}
            >
              {disconectLoader ? (
                <Bars color="#ffffff" height={30} width={80} />
              ) : (
                <span>Disconnect Wallet</span>
              )}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
      <TodayPicks
        isAnzaliActive={isAnzaliActive}
        data={collectedCollections}
        wallets={wallets}
        authorization={authorization}
        openToasterAlert={openToasterAlert}
        handelClose={() => getMembershipCollections()}
        ethPrice={ethPrice}
        apicallback={apicallback}
      />
    </section>
  );
};

export default Index;

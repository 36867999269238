import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { verifyLogin, signup, createWallet } from '../../apis/Index';
import PageTitle from '../../components/pagetitle/PageTitle';
const SignUp = () => {
  const [emailAddress, setemailAddress] = useState('');
  const [emailCheck, setemailCheck] = useState(false);
  const [emailOtp, setemailOtp] = useState('');
  const [errorMessage, seterrorMessage] = useState('');
  const [signUpToken, setsignUpToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const loc = useLocation();
  const location =
    loc.state && loc.state.prevPath
      ? loc.state.prevPath
      : '/nft-wallet-tracker';
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (emailCheck) {
      await checkLogin();
      setLoading(false);
    } else {
      await setotp();
      setLoading(false);
    }
  };
  const checkLogin = async () => {
    const response = await verifyLogin(
      {
        email: emailAddress,
        otp: emailOtp,
      },
      signUpToken
    );
    if (response && response.status == 'success') {
      if (localStorage.getItem('public_wallet') != null) {
        await addWallet(response);
      } else {
        localStorage.clear();
        localStorage.setItem('NewAuthorization', true);
        localStorage.setItem('Authorization', JSON.stringify(response));
        window.location.href = location;
      }
    } else {
      seterrorMessage(response.message);
    }
  };
  const setotp = async () => {
    const response = await signup({
      username: '',
      email: emailAddress,
    });
    if (response.status === 'success') {
      setemailCheck(true);
      setsignUpToken(response.token);
    } else {
      seterrorMessage(response.message);
    }
    setResendLoading(false);
  };

  const addWallet = async (auth) => {
    const wallet = JSON.parse(localStorage.getItem('public_wallet'));
    const response = await createWallet(
      {
        name: wallet.name,
        user_id: auth.data.id.toString(),
        address: wallet.address,
        chain_id: wallet.chain_id,
        hexCode: wallet.hexCode,
      },
      auth.token
    );
    localStorage.clear();
    localStorage.setItem('NewAuthorization', true);
    localStorage.setItem('Authorization', JSON.stringify(auth));
    if (response && response.status == 'success') {
      window.location.href = '/nft-wallet-tracker';
    } else {
      window.location.href = location;
    }
  };

  return (
    <>
      <PageTitle
        meta={{
          title: 'ANZALI Sign Up',
          description:
            'Sign up for full access to NFT analytics and performance data. Manage your NFT Portfolio and keep track of multiple Web3 wallets.',
        }}
      />
      <div className="py-5 my-5">
        <section className="tf-login tf-section">
          <div className="themesflat-container">
            <div className="row mt-5">
              <div className="col-12">
                <h2 className="tf-title-heading ct style-1">Sign Up</h2>

                <div className="flat-form box-login-email">
                  <div className="box-title-login box-title-signup">
                    <h5>
                      Sign up to track wallets, receive alerts and manage your
                      portfolio
                    </h5>
                  </div>

                  <div className="form-inner">
                    {errorMessage ? (
                      <div className="pb-4">
                        <h5 className="text-danger">{errorMessage}</h5>
                      </div>
                    ) : (
                      ''
                    )}
                    <form id="contactform" onSubmit={handleSubmit}>
                      <input
                        name="email"
                        tabIndex="2"
                        aria-required="true"
                        type="email"
                        placeholder="Your Email Address"
                        required
                        disabled={emailCheck}
                        value={emailAddress}
                        onChange={(e) => {
                          setemailAddress(e.target.value);
                          seterrorMessage('');
                        }}
                      />
                      {emailCheck ? (
                        <input
                          style={{ border: '4px solid #FEC808' }}
                          id="otpinput"
                          name="otp"
                          tabIndex="2"
                          aria-required="true"
                          type="number"
                          placeholder="Enter 6-digit code sent to your email address."
                          required
                          onChange={(e) => {
                            setemailOtp(e.target.value);
                            seterrorMessage('');
                          }}
                          value={emailOtp}
                        />
                      ) : (
                        ''
                      )}
                      {emailCheck ? (
                        <p
                          className="text-center text-main d-flex justify-content-center"
                          onClick={() => {
                            setotp();
                            setResendLoading(true);
                          }}
                        >
                          <u>
                            {resendLoading ? (
                              <Link className="text-main" to="#">
                                <Bars color="#ffffff" height={30} width={80} />
                              </Link>
                            ) : (
                              <Link className="text-main" to="#">
                                Resend Code
                              </Link>
                            )}
                          </u>
                        </p>
                      ) : (
                        ''
                      )}
                      <div className="text-center my-4">
                        <p className="signup-text">
                          By signing in, you agree to ANZALI's{' '}
                          <Link
                            className="text-main"
                            to="/terms-of-use"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms of Use
                          </Link>{' '}
                          and
                          <Link
                            className="text-main"
                            to="/privacy-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {' '}
                            Privacy Policy
                          </Link>
                          .
                        </p>
                      </div>
                      <button
                        className="submit d-flex justify-content-center align-item-center"
                        type="submit"
                        style={{ border: '2px solid #FEC808' }}
                        disabled={loading}
                      >
                        {loading ? (
                          <Bars color="#ffffff" height={30} width={80} />
                        ) : (
                          'Sign Up'
                        )}
                      </button>
                      <div className="row-form text-center mt-5">
                        <Link to="/sign-in" className="forgot-pass">
                          Existing User? Sign In
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default SignUp;

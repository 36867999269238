import React, { useState, useRef, useEffect } from 'react';
import { Bars } from 'react-loader-spinner';
import TokenCard from '../../components/card/tokencard';
import { getValueWithDecimal } from '../../utility/commonUtitlity';
import LoadingCard from '../../components/card/LoadingCard';
import { useStoreState } from 'easy-peasy';
import axios from 'axios';
import { useParams } from 'react-router-dom';
export default function Items({ walletAddress }) {
  const collectionInfoTabsData = useStoreState(
    (state) => state.collectionInfoTabsData
  );
  const { chain: chainIdFromUrl } = useParams();
  const [loadingState, setLoadingState] = useState(true);
  const [itemsState, setItemsState] = useState([]);
  const [extraData, setExtraData] = useState(null);
  const [pagekeyState, setPagekey] = useState(null);
  const [isloadMoreLoading, setIsloadMoreLoading] = useState(false);

  useEffect(() => {
    return () => {
      setItemsState([]);
    };
  }, [walletAddress]);

  useEffect(() => {
    const { rarityData, extraData, items, pagekey } =
      collectionInfoTabsData || {};
    if (extraData) {
      setExtraData(extraData);
    } else {
      setExtraData(null);
    }
    setPagekey(pagekey);
    if (items) {
      const newArray = items.map((index, key) => {
        index.rarity_new =
          chainIdFromUrl == 1
            ? rarityData
              ? rarityData[Number(index.tokenId)]?.rank || 0
              : 0
            : index?.rarity?.rank;
        return index;
      });

      setItemsState(newArray);
     // setLoadingState(false);
    } else {
     // setLoadingState(false);
      setItemsState([]);
    }
  }, [collectionInfoTabsData]);
  async function loadMoreHandler() {
    setIsloadMoreLoading(true);
    try {
      const [response] = await Promise.allSettled([
        await axios.get(
          `${process.env.REACT_APP_NODE_ENDPOINT_V2}/getCollectionDetails?address=${walletAddress}&pagekey=${pagekeyState}&chain=${chainIdFromUrl}`,
          {
            headers: {
              'content-type': 'application/json',
            },
          }
        ),
      ]);
      if (response.status == 'fulfilled') {
        if (response.value.data.status === 200) {
          const res = response.value.data.data;
          const newArray = res.nfts.map((index, key) => {
            index.rarity = res.rarity
              ? res.rarity[Number(index.tokenId)]?.rank || 0
              : 0;
            return index;
          });
          setPagekey(res.pagekey);
          setItemsState([...itemsState, ...newArray]);
        }
      }
      setIsloadMoreLoading(false);
    } catch (e) {
      setIsloadMoreLoading(false);
    } finally {
      setIsloadMoreLoading(false);
    }
  }
  return (
    <section>
      <div className="feature-card-container">
        {Array.isArray(itemsState) &&
          itemsState.map((info, index) => {
            const { image, contract } = info;
            let imgUrl = null;
            if (image) {
              imgUrl = image.cachedUrl
                ? image.cachedUrl
                : image.originalUrl
                ? image.originalUrl
                : info?.collection?.bannerImageUrl || null;
            }
            return (
              <div key={`item-${index}`} className="card-container">
                <TokenCard
                  tokenTitle={`${info?.name || info?.tokenId}`}
                  tokenImage={imgUrl}
                  showPriceInfo={true}
                  hideVerified={contract?.isSpam ? contract?.isSpam : false}
                  contractAddress={walletAddress}
                  isTwentyFourHourVolume={false}
                  isTotalSupply={chainIdFromUrl == 1 ? false : true}
                  totalSupply={contract?.totalSupply || 0}
                  showRarity={true}
                  rank={info?.rarity_new || 0}
                  floorPrice={
                    contract?.openSeaMetadata
                      ? getValueWithDecimal(
                          contract?.openSeaMetadata?.floorPrice
                        )
                      : extraData?.floor_price != null
                      ? getValueWithDecimal(extraData?.floor_price)
                      : null
                  }
                  volumeTraded={
                    extraData?.volume24 != null
                      ? getValueWithDecimal(extraData?.volume24)
                      : null
                  }
                  redirectLink={`/item-info/${walletAddress}/${info?.tokenId}/${chainIdFromUrl}`}
                />
              </div>
            );
          })}

        {loadingState && !itemsState.length
          ? Array(5)
              .fill(0)
              .map((item, index) => (
                <div
                  key={`dummy-feature-token=${index}`}
                  className="card-container"
                >
                  <LoadingCard />
                </div>
              ))
          : ''}

        {/* {showNoData && (
          <NoDataComponent title="No Items Found."></NoDataComponent>
        )} */}
      </div>

      <div className="col-12 mt-3 d-flex justify-content-center">
        <div className="col-12 mt-3 d-flex justify-content-center">
          {pagekeyState && (
            <button onClick={loadMoreHandler} className="sc-button style-4">
              {isloadMoreLoading ? (
                <Bars color="#ffffff" height={30} width={80} />
              ) : (
                'Load More'
              )}
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

import { burn_icon, mint_icon, sale_icon, swap_icon, transfer_icon } from "../../assets/Index";

export function getTabOptions(totalCountInfo = {}) {
    return ([
        {
            id: "overview",
            name: "Overview",
            count: 0,
        },
        {
            id: "collections",
            name: "Collections",
            count: totalCountInfo?.collection_count || 0,
        },
        {
            id: "assets",
            name: "Assets",
            count: totalCountInfo?.nft_count || 0,
        },
        {
            id: "ens-assets",
            name: "ENS Assets",
            count: totalCountInfo?.ens_count || 0,
        },
        {
            id: "nft-transactions",
            name: "NFT Events",
            count: totalCountInfo?.nft_tx_count || 0,
        },
        {
            id: "all-transactions",
            name: "All Transactions",
            count: totalCountInfo?.tx_count || 0,
        },
        {
            id: "performance",
            name: "Performance",
            count: 0,
        },
        {
            id: "blue-chips-collections",
            name: "Blue Chips Collections",
            count: totalCountInfo?.blue_chip_count || 0,
        }
    ])
}

export function getSummaryPayload(walletAddress) {
    return {
        address: walletAddress,
        filter: {
            type: {
                eq: 'MINT'
            },
            contractStandard: {
                in: ["ERC721", "ERC1155"]
            }
        },
        tokenEventsFirst2: 100,
        orderDirection: 'ASC',
        transactionsFirst2: 1,
        ens: {
            address: walletAddress,
            filter: {
                contractTokens: [
                    {
                        contractAddress: "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85"
                    }
                ]
            }
        },
        first: 20,
        after: null
    }
}

export function getTransactionPayload(walletAddress, endCursor, itemCount = 20) {
    return ({
        address: walletAddress,
        filter: {
            contractStandard: {
                in: ["ERC721", "ERC1155"]
            }
        },
        first: itemCount,
        after: endCursor
    })
}

export function getAllTransactionPayload(walletAddress, endCursor) {
    return ({
        wallet_address: walletAddress,
        after: endCursor
    })
}

export function getEnsAssetsPayload(walletAddress, endCursor, searchValue, itemCount = 20) {
    const nftSearchPayload = searchValue ? { nftName: { contains: searchValue } } : null;
    return (
        {
            address: walletAddress,
            filter: {
                contractTokens: [
                    {
                        "contractAddress": "0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85"
                    }
                ],
                ...nftSearchPayload

            },
            first: itemCount,
            after: endCursor
        }
    )
}


export function getTransactionIcon(status) {
    const updatedStatus = status ? status.toString().toLowerCase() : "";
    switch (updatedStatus) {
        case "burn":
            return burn_icon;
        case "transfer":
            return transfer_icon;
        case "sale":
            return sale_icon;
        case "swap":
            return swap_icon;
        case "mint":
            return mint_icon;
        default:
            return transfer_icon;
    }
}

export function getTransactionClassName(status) {
    const updatedStatus = status ? status.toString().toLowerCase().replace(/\s/g, "") : "";
    switch (updatedStatus) {
        case "burn":
            return "burn";
        case "transferin":
            return "transferin";
        case "transferout":
            return "transferout";
        case "sale":
            return "sale";
        case "swap":
            return "swap";
        case "mint":
            return "mint";
        default:
            return "";
    }
}
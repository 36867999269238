import React, { useEffect, useState } from 'react';
import AirdropCard, {
  AirdropLoadingCard,
} from '../../components/card/airdropcard';
import Airdrop1 from '../../assets/images/airdrops/airdrop1.png';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './_swiper.scss';
import PageTitle from '../../components/pagetitle/PageTitle';
//Assets
import {
  IoIosArrowBack,
  IoIosArrowForward,
} from '../../components/icons/Index';

import AirdropProjectCard from '../../components/card/airdropprojectcard';
import JoinTelegram from './JoinTelegram';
import SubscribeNewsletter from './SubscribeNewsLetter';
import AirdropChainCard from '../../components/card/airdropchaincard';
import { share_icon } from '../../assets/Index';
//API Actions
import { apicallback } from '../../callbacks/index';
import { Bars } from 'react-loader-spinner';
import { ReactComponent as AwardsIcon } from '../../assets/images/airdrops/award.svg';
import { ReactComponent as ThunmbsIcon } from '../../assets/images/airdrops/thumbs.svg';
import { ReactComponent as ClipboardIcon } from '../../assets/images/airdrops/clipboard.svg';
import { useNavigate } from 'react-router-dom';
import { noImageFound } from '../../assets/Index';
import { extractContentFromHtmlString } from '../../utility/commonUtitlity';
import AnzaliLoader from '../../components/AnzaliLoader';

import Articles from '../../components/layouts/Articles';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import moment from 'moment';
function replaceImage(error) {
  //replacement of broken Image
  error.target.src = noImageFound;
}

export default function Airdrops() {
  const [chainState, setChainState] = useState([]);
  const [featuredState, setFeaturedState] = useState([]);
  const [airdropTypeState, setAirdropTypeState] = useState([]);
  const [latestAirdropState, setLatestAirdropState] = useState([]);
  const [trendingOnSolanaState, setTrendingOnSolanaState] = useState([]);
  const [stakingProjectState, setStakingProjectState] = useState([]);
  const [loadingState, setLoadingState] = useState({
    isFetchingChains: true,
    isFetchingFeatured: true,
    isFetchingAirdropType: true,
    isFetchingLatestAirdrop: true,
    isFetchingTrendingSolana: true,
    isFetchingStaking: true,
  });
  const history = useNavigate();

  useEffect(() => {
    getChainsApiCall();
    getFeaturedContentApiCall();
    getAirdropTypesApiCall();
    getLatestAirdopApiCall();
  }, []);

  useEffect(() => {
    if (Array.isArray(chainState)) {
      const { id } =
        chainState.find((item) => item.name?.toLowerCase() === 'solana') || {};
      if (id) {
        getSolanaAirdropApiCall(id);
      }
    }
  }, [chainState]);

  useEffect(() => {
    if (Array.isArray(airdropTypeState)) {
      const { id } =
        airdropTypeState.find((item) => item.name?.includes('staking')) || {};
      if (id) {
        getStakingAirdropApiCall(id);
      }
    }
  }, [airdropTypeState]);

  async function getChainsApiCall() {
    const response = await apicallback(
      null,
      `/getContentChains`,
      null,
      null,
      null,
      'get'
    );
    setChainState(response?.data);
    setLoadingState((prevState) => ({ ...prevState, isFetchingChains: false }));
  }
  async function getFeaturedContentApiCall() {
    const response = await apicallback(
      null,
      `/getFeaturedContent`,
      null,
      null,
      null,
      'get',
      {
        params: { limit: 5, offset: 0 },
      }
    );
    setFeaturedState(response?.data);
    setLoadingState((prevState) => ({
      ...prevState,
      isFetchingFeatured: false,
    }));
  }

  async function getAirdropTypesApiCall() {
    const response = await apicallback(
      null,
      `/getAllTypes`,
      null,
      null,
      null,
      'get'
    );
    setAirdropTypeState(response?.data);
    setLoadingState((prevState) => ({
      ...prevState,
      isFetchingAirdropType: false,
    }));
  }

  async function getLatestAirdopApiCall(params) {
    const response = await apicallback(
      null,
      `/getallContent`,
      null,
      null,
      null,
      'get'
    );
    setLatestAirdropState(response?.data);
    setLoadingState((prevState) => ({
      ...prevState,
      isFetchingLatestAirdrop: false,
    }));
  }
  async function getSolanaAirdropApiCall(solanaChainId) {
    const response = await apicallback(
      null,
      `/getallContent`,
      null,
      null,
      null,
      'get',
      { params: { chain: solanaChainId } }
    );
    setTrendingOnSolanaState(response?.data);
    setLoadingState((prevState) => ({
      ...prevState,
      isFetchingTrendingSolana: false,
    }));
  }
  async function getStakingAirdropApiCall(stakingId) {
    const response = await apicallback(
      null,
      `/getallContent`,
      null,
      null,
      null,
      'get',
      { params: { content_type: stakingId } }
    );
    setStakingProjectState(response?.data);
    setLoadingState((prevState) => ({
      ...prevState,
      isFetchingStaking: false,
    }));
  }

  function redirectHandler(slug, keyName) {
    if (!slug) {
      return;
    }
    if (keyName) {
      // history(`/airdrops/${slug}?section=${keyName}`);
      history(`/airdrops/${slug}`);
      return;
    }
    history(`/airdrops/${slug}`);
  }

  function viewAllLatestAirdropHndlr() {
    history(`/explore-airdrops`);
  }

  function viewAllSolanaHndlr() {
    const { id: chainId } =
      chainState.find((item) => item.name?.toLowerCase() === 'solana') || {};
    if (chainId) {
      history(`/explore-airdrops?chainId=${chainId}`);
    }
  }

  function viewAllStakingProjectHndlr() {
    const { id } =
      airdropTypeState.find((item) => item.name?.includes('staking')) || {};
    if (id) {
      history(`/explore-airdrops?type=${id}`);
    } else {
      history(`/explore-airdrops`);
    }
  }

  function getThisNowHandler(item) {
    const { slug } = item;
    if (slug) {
      history(`/airdrops/${slug}`);
    }
  }

  function likesHandler(item) {
    const { slug } = item;
    if (slug) {
      // history(`/airdrops/${slug}?section=likes`);
      history(`/airdrops/${slug}`);
    }
  }

  function taskHandler(item) {
    const { slug } = item;
    if (slug) {
      // history(`/airdrops/${slug}?section=tasks`);
      history(`/airdrops/${slug}`);
    }
  }

  function awardsHandler(item) {
    const { slug } = item;
    if (slug) {
      // history(`/airdrops/${slug}?section=awards`);
      history(`/airdrops/${slug}`);
    }
  }

  function exploreByChainsHandler(event) {
    const chainId = event.currentTarget.getAttribute('data-id');
    if (chainId) {
      history(`/explore-airdrops?chainId=${chainId}`);
    }
  }

  const {
    isFetchingFeatured,
    isFetchingLatestAirdrop,
    isFetchingTrendingSolana,
    isFetchingStaking,
  } = loadingState;
  return (
    <React.Fragment>
      {isFetchingFeatured && <AnzaliLoader />}
      <PageTitle
        meta={{
          title: 'Free Crypto Airdrops | Top & Latest Projects - ANZALI',
          description:
            'Claim Free Crypto! Explore Latest Airdrops on ANZALI. Discover Top Staking Projects & Earn Rewards on Multiple Chains. Learn More!',
        }}
      />
      <div className="airdrops-container">
        <div className="airdropbanner-container">
          {Array.isArray(featuredState) && featuredState.length ? (
            <Swiper
              modules={[Autoplay, Navigation]}
              direction={'horizontal'}
              spaceBetween={0}
              slidesPerView={1}
              className="bannerswiper"
              navigation={true}
              loop
              autoplay={{
                delay: 8000,
              }}
              disableOnInteraction={false}
              pauseOnMouseEnter={true}
              speed={2000}
            >
              {Array.isArray(featuredState) &&
                featuredState.map((item) => {
                  return (
                    <SwiperSlide>
                      <div className="bannercontent">
                        <div
                          className="imagecontainer"
                          style={{ backgroundColor: item.background }}
                        >
                          <img
                            src={item.cover_image || noImageFound}
                            onError={replaceImage}
                            className="airdropimage"
                          />
                        </div>
                        <div className="contentwrapper">
                          {item.live && (
                            <button className="livebtn mb-4">Live</button>
                          )}
                          {!item.live && (
                            <button className="nolivebtn mb-4">Not Live</button>
                          )}
                          <h1 className="bannertitle mb-2">{item.name}</h1>
                          <span className="descritpion">
                            {item.custom_created_date
                              ? 'Added ' +
                                moment(item.custom_created_date).fromNow()
                              : ''}
                          </span>
                          <p className="descritpion mb-4 mt-4">
                            {extractContentFromHtmlString(item?.description)}
                          </p>
                          <div className="actionwrapper mb-5">
                            <button
                              onClick={() => likesHandler(item)}
                              className="airdropaction-btn"
                            >
                              <div className="icon-container">
                                <ThunmbsIcon height="18px" width="18px" />
                              </div>
                              <span className="btntext">
                                {item.likes || '0'} Likes
                              </span>
                            </button>
                            <button
                              onClick={() => taskHandler(item)}
                              className="airdropaction-btn"
                            >
                              <div className="icon-container">
                                <ClipboardIcon height="18px" width="18px" />
                              </div>
                              <span className="btntext">
                                {item.tasks || '0'} Tasks
                              </span>
                            </button>
                            {/* <button
                            onClick={() => awardsHandler(item)}
                            className="airdropaction-btn"
                          >
                            <div className="icon-container">
                              <AwardsIcon height="18px" width="18px" />
                            </div>
                            <span className="btntext">
                              {item.rewards || '0'} Tokens
                            </span>
                          </button> */}
                          </div>
                          <button
                            onClick={() => getThisNowHandler(item)}
                            className="sc-button style-4 text-dark"
                          >
                            Get This Now
                          </button>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          ) : (
            ''
          )}
        </div>

        <div className="page-container">
          <div className="mb-5">
            <div className="sectionaction">
              <h2 className="section-title">Latest Airdrops</h2>
              <div>
                <button
                  className="sc-button style-4 text-dark py-8"
                  onClick={viewAllLatestAirdropHndlr}
                >
                  View All
                </button>
              </div>
            </div>
            <Swiper
              spaceBetween={16}
              // slidesPerView={5}
              breakpoints={{
                0: {
                  slidesPerView: 1.5,
                },
                576: {
                  slidesPerView: 2.5,
                },
                768: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 3.5,
                },
                1200: {
                  slidesPerView: 4.5,
                },
                1400: {
                  slidesPerView: 5,
                },
                1500: {
                  slidesPerView: 6,
                },
              }}
              className="swipercontainer"
              modules={[Navigation]}
              navigation
            >
              {isFetchingLatestAirdrop &&
                [1, 2, 3, 4, 5].map((item) => (
                  <SwiperSlide key={`latestairdrop-${item}`}>
                    <AirdropLoadingCard />
                  </SwiperSlide>
                ))}
              {!isFetchingLatestAirdrop &&
                Array.isArray(latestAirdropState) &&
                latestAirdropState.map((item) => (
                  <SwiperSlide key={`latestairdrop-${item.id}`}>
                    <AirdropCard
                      title={item?.name}
                      description={item?.introduction}
                      shareUrl=""
                      likes={item?.likes}
                      tasks={item?.task_count}
                      tokens={item?.rewards}
                      airdropImage={item?.image}
                      bgColor={item?.banner_color}
                      slug={item?.slug}
                      redirectHandler={redirectHandler}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>

          <div className="mb-5">
            <div className="sectionaction">
              <h2 className="section-title">Trending on Solana</h2>
              <div>
                <button
                  className="sc-button style-4 text-dark py-8"
                  onClick={viewAllSolanaHndlr}
                >
                  View All
                </button>
              </div>
            </div>
            <Swiper
              spaceBetween={16}
              // slidesPerView={5}
              breakpoints={{
                0: {
                  slidesPerView: 1.5,
                },
                576: {
                  slidesPerView: 2.5,
                },
                768: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 3.5,
                },
                1200: {
                  slidesPerView: 4.5,
                },
                1400: {
                  slidesPerView: 5,
                },
                1500: {
                  slidesPerView: 6,
                },
              }}
              className="swipercontainer"
              modules={[Navigation]}
              navigation
            >
              {isFetchingTrendingSolana &&
                [1, 2, 3, 4, 5].map((item) => (
                  <SwiperSlide key={`trendingsolana-${item}`}>
                    <AirdropLoadingCard />
                  </SwiperSlide>
                ))}
              {!isFetchingTrendingSolana &&
                Array.isArray(trendingOnSolanaState) &&
                trendingOnSolanaState.map((item) => (
                  <SwiperSlide key={`tradinonsolana-${item.id}`}>
                    <AirdropCard
                      title={item?.name}
                      description={item?.introduction}
                      shareUrl=""
                      likes={item?.likes}
                      tasks={item?.task_count}
                      tokens={item?.rewards}
                      airdropImage={item?.image}
                      bgColor={item?.banner_color}
                      slug={item?.slug}
                      redirectHandler={redirectHandler}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>

          <div className="mb-5">
            <h2 className="section-title">Explore by Chains</h2>
            <Swiper
              spaceBetween={16}
              // slidesPerView={2}
              breakpoints={{
                0: {
                  slidesPerView: 1.5,
                },
                576: {
                  slidesPerView: 2.5,
                },
                768: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 3.5,
                },
                1200: {
                  slidesPerView: 4.5,
                },
                1400: {
                  slidesPerView: 5,
                },
                1500: {
                  slidesPerView: 6,
                },
              }}
              className="swipercontainer"
              modules={[Navigation]}
              navigation
            >
              {Array.isArray(chainState) &&
                chainState.map((item) => (
                  <SwiperSlide key={`explorechain-${item?.id}`}>
                    <AirdropChainCard
                      chainId={item.id}
                      chainImage={item?.chain_banner_image}
                      chainName={item?.name}
                      onClickHanlder={exploreByChainsHandler}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>

          <div className="mb-5">
            <div className="sectionaction">
              <h2 className="section-title">Top Staking Projects</h2>
              <div>
                <button
                  className="sc-button style-4 text-dark py-8"
                  onClick={viewAllStakingProjectHndlr}
                >
                  View All
                </button>
              </div>
            </div>
            <Swiper
              spaceBetween={16}
              // slidesPerView={5}
              breakpoints={{
                0: {
                  slidesPerView: 1.5,
                },
                576: {
                  slidesPerView: 2.5,
                },
                768: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 3.5,
                },
                1200: {
                  slidesPerView: 4.5,
                },
                1400: {
                  slidesPerView: 5,
                },
                1500: {
                  slidesPerView: 6,
                },
              }}
              className="swipercontainer"
              modules={[Navigation]}
              navigation
            >
              {isFetchingStaking &&
                [1, 2, 3, 4, 5].map((item) => (
                  <SwiperSlide key={`stakingproject-${item}`}>
                    <AirdropLoadingCard />
                  </SwiperSlide>
                ))}
              {!isFetchingStaking &&
                Array.isArray(stakingProjectState) &&
                stakingProjectState.map((item) => (
                  <SwiperSlide key={`latestairdrop-${item.id}`}>
                    <AirdropCard
                      title={item?.name}
                      description={item?.introduction}
                      shareUrl=""
                      likes={item?.likes}
                      tasks={item?.task_count}
                      tokens={item?.rewards}
                      airdropImage={item?.image}
                      bgColor={item?.banner_color}
                      slug={item?.slug}
                      redirectHandler={redirectHandler}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
          <div className="mb-5">
            <div className="row custom-row">
              <div className="col-12 col-md-6 col-lg-4 custom-column mb-4">
                <JoinTelegram />
              </div>
              <div className="col-12 col-md-6 col-lg-4 custom-column mb-4">
                <SubscribeNewsletter />
              </div>
            </div>
          </div>
          <div style={{ marginTop: '-75px' }}>
            <Articles />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import { Bars } from 'react-loader-spinner';
import classNames from 'classnames';

export const tabsList = [
    {
        id: "items",
        name: "Items",
    },
    {
        id: "traits",
        name: "Traits",
    },
    {
        id: "tradingActivity",
        name: "Trading Activity",
    },
    {
        id: "opensea",
        name: "OpenSea",
    },
    {
        id: "looksRare",
        name: "LooksRare",
    },
    {
        id: "audit",
        name: "Audit",
    }
]

export function getCollectionData(collectionInfo) {
    const { collection_stats, collection_details } = collectionInfo || {},
        { bannerImage, image } = collection_details || {};
    let bannerUrl = "", cardImgUrl = "";
    if (Array.isArray(bannerImage)) {
        bannerUrl = bannerImage[bannerImage.length - 1]?.url;
    }
    if (Array.isArray(image)) {
        cardImgUrl = image[image.length - 1]?.url;
    }
    return {
        address: collection_details?.address,
        name: collection_details?.name,
        bannerUrl,
        cardImgUrl,
        description: collection_details?.description,
        externalUrl: collection_details?.externalUrl,
        isVerified: collection_details?.openseaMetadata?.isVerified || false,
        floorPrice: collection_stats?.floorInNativeToken,
        volumeTraded: collection_stats?.volumeInNativeToken
    }
}


export function LoadingValue({ isLoading = false, wrapperPadding = 5, height = 22, children }) {
    const wrapperStyle = { padding: `${wrapperPadding}px` };
    return (
        <>
            {isLoading ? <Bars
                color="#ffffff"
                wrapperStyle={wrapperStyle}
                height={height}
                width={50}
            /> : <>{children}</>}
        </>
    )
}

export function LoadingContainer({ containerClassName = 'min-h-150px' }) {
    return (
        <div className={classNames(`d-flex flex-column justify-content-center align-items-center`, containerClassName)} >
            <Bars color="#8a8aa0" wrapperClass="mb-3" height={30} width={30} />
            <span className="mb-2 font-16">Loading...</span>
        </ div>
    )
}

export const COLLECTION_DETAIL_API_RETRY_COUNT = 3;
export const COLLECTION_DETAIL_RETRY_SECOND = 10000;
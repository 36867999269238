import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function OhlcVolumeChart({ candleStickData, categories, title,
    containerClassName = "", xAxisTitle, yAxisTitle, }) {
    const chartData = {
        series: [{
            data: candleStickData || []
        }],
        options: {
            noData: {
                text: 'No data available',
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: "#ffffff"
                }
            },
            chart: {
                id: "candles",
                type: 'candlestick',
                height: 400,
                toolbar: {
                    tools: {
                        download: false
                    }
                }
            },
            title: {
                show: true,
                text: title,
                style: {
                    fontSize: '16px',
                    color: "#ffffff",
                    fontWeight: 600
                },
            },
            grid: {
                strokeDashArray: 8,
                position: 'back',
                borderColor: '#272B30',
                xaxis: {
                    lines: {
                        show: false
                    }
                },
            },
            yaxis: {
                show: true,
                axisBorder: {
                    show: true
                }, axisTicks: {
                    show: true
                },
                labels: {
                    style: {
                        colors: "#B1B1B1",
                        fontSize: "12px",
                    }
                },
                title: {
                    text: yAxisTitle || "",
                    style: {
                        fontSize: "14px",
                        color: "#B1B1B1",
                        fontWeight: 600
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            xaxis: {
                title: {
                    text: xAxisTitle || "",
                    style: {
                        fontSize: "14px",
                        color: "#B1B1B1",
                        fontWeight: 600
                    }
                },
                labels: {
                    style: {
                        colors: "#B1B1B1",
                        fontSize: "12px"
                    }
                },
                axisBorder: {
                    show: true
                }, axisTicks: {
                    show: true
                },
                categories: categories || [],
            }
        }
    };
    return (
        <React.Fragment>
            <div id="chart">
                <ReactApexChart options={chartData.options} series={chartData.series} type="candlestick" height={400} />
            </div>
        </React.Fragment>
    )
}
import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Table from '../../../components/table/Table';
import { Bars } from 'react-loader-spinner';
import { generateColumns } from './helper';
import { copyClipboard, openUrl } from '../../../callbacks/index';
//Api Payload
import { frame_view_icon } from '../../../assets/Index';

export default function AllTransactions() {
  const { wallet_address: walletAddress } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [transactionStateV2, setTransactionStateV2] = useState([]);
  const [transactionOffsetStateV2, setTransactionOffsetStateV2] =
    useState(null);
  const [noDataFoundStateV2, setNoDataFoundStateV2] = useState(true);
  useEffect(() => {
    if (walletAddress) {
      getTransactionsApiCall();
    }
  }, [walletAddress]);

  async function getTransactionsApiCall(cursor = null) {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_NODE_ENDPOINT_V2
        }/getAllTransactions?address=${walletAddress}${
          cursor ? `&pagekey=${cursor}` : ''
        }&type=from`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      if (response.data.status === 200) {
        const res = response.data.data;

        setTransactionStateV2([...transactionStateV2, ...res.transfers]);
        setTransactionOffsetStateV2(res.pageKey);
        setNoDataFoundStateV2(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setNoDataFoundStateV2(false);
    }
  }

  function copyAddressHandler(event) {
    const walletAddress = event.currentTarget.getAttribute('data-id');
    if (walletAddress) {
      copyClipboard(walletAddress);
    }
  }

  function loadMoreHandler() {
    getTransactionsApiCall(transactionOffsetStateV2);
  }

  function redirectHandler(event) {
    const transactionHash = event.currentTarget.getAttribute('data-id');
    if (transactionHash) {
      openUrl(`https://etherscan.io/tx/${transactionHash}`);
    }
  }

  const menuOptions = useMemo(() => {
    return [
      {
        id: 'view',
        actionIcon: (
          <img
            src={frame_view_icon}
            style={{ width: '28px', height: '30px' }}
          />
        ),
        actionName: 'View',
        onClickHndlr: redirectHandler,
      },
    ];
  }, [transactionStateV2]);

  const transactionsColumns = useMemo(
    () => generateColumns(walletAddress, copyAddressHandler, menuOptions),
    [transactionStateV2]
  );
  return (
    <div className="walletcollections-container">
      <div className="row">
        <div className="col-12">
          <div className="tabbody-headercontainer">
            <div className="d-flex align-items-center justify-content-between ">
              <h4 className="mb-0">All Transactions</h4>
            </div>
          </div>
        </div>
        <div className="col-12">
          <Table
            columns={transactionsColumns}
            data={Array.isArray(transactionStateV2) ? transactionStateV2 : []}
            isDataLoading={noDataFoundStateV2}
            loadingMessage="Loading All Transactions."
          />
        </div>
        <div className="col-12 mt-3 d-flex justify-content-center">
          {transactionOffsetStateV2 ? (
            <button onClick={loadMoreHandler} className="sc-button style-4">
              {isLoading ? (
                <Bars color="#ffffff" height={30} width={80} />
              ) : (
                'Load More'
              )}
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Bars } from 'react-loader-spinner';
import { Line } from 'rc-progress';
import { openToasterAlert } from '../../components/alert/Index';
import {
  chat_gpt_icon,
  chat_close_icon,
  chat_down_arrow_icon,
  send_message_icon,
  chat_check_circle_icon,
} from '../../assets/Index';
import { useState } from 'react';
import axios from 'axios';

const baseUrl = 'https://chat.anzali.io';

export default function ChatModal(props) {
  const [showChadBodyState, setShowChatBodyState] = useState(true);
  const [startChatting, setStartChatting] = useState(false);
  const [loadingPercentState, setLoadingPercentState] = useState(1);
  const [userInputState, setUserInputState] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [chatHistoryState, setChatHistoryState] = useState([]);
  const intervalIdRef = useRef('');
  const inputRef = useRef(null);
  const prevAddressForChatRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    async function syncingWalletApiCall(token, walletAddress) {
      try {
        const statusResponse = await axios.get(
          `${baseUrl}/getWalletStatus?wallet_address=${walletAddress}`,
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        if (statusResponse.status === 200) {
          const { data: statusResponseData } = statusResponse.data,
            { is_loading, loading_percent } = statusResponseData || {};
          if (is_loading) {
            if (!isNaN(loading_percent)) {
              setLoadingPercentState(+loading_percent);
            }
          } else {
            clearInterval(intervalIdRef.current);
            setLoadingPercentState(100);
            // openToasterAlert('success', 'Wallet synced successfully.');
          }
        }
      } catch (e) {
        openToasterAlert('error', 'Error in syncing wallet.');
        clearInterval(intervalIdRef.current);
        console.log(e);
      }
    }
    async function startSessionApiCall(payload, token, wallet_address) {
      try {
        const response = await axios.post(`${baseUrl}/startSession`, payload, {
          headers: { Authorization: `Token ${token}` },
        });
        if (response.status === 200) {
          const { data: startSessionResp } = response || {};
          if (startSessionResp?.status === 'success') {
            //  openToasterAlert('success', 'Session started successfully.');
            setStartChatting(true);
          }
          syncingWalletApiCall(token, wallet_address);
          const intervalId = setInterval(async () => {
            syncingWalletApiCall(token, wallet_address);
          }, 4000);
          intervalIdRef.current = intervalId;
        }
      } catch (e) {}
    }
    if (props.summary && props.authorization) {
      const { token } = props?.authorization || {},
        { wallet_address } = props?.summary || {};
      if (prevAddressForChatRef.current !== wallet_address) {
        startSessionApiCall(props.summary, token, wallet_address);
        prevAddressForChatRef.current = wallet_address;
      } else if (prevAddressForChatRef.current === wallet_address) {
        syncingWalletApiCall(token, wallet_address);
        const intervalId = setInterval(async () => {
          syncingWalletApiCall(token, wallet_address);
        }, 4000);
        intervalIdRef.current = intervalId;
      }
    }
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [props.summary]);

  useEffect(() => {
    if (showChadBodyState) {
      inputRef.current.focus();
    }
  }, [showChadBodyState]);

  function toggleChatBodySectionHndlr() {
    setShowChatBodyState(!showChadBodyState);
  }

  function closeChatModalHndlr() {
    props.onClose();
  }

  function onChangeHndlr(event) {
    const { name, value } = event.target;
    setUserInputState(value);
  }

  async function sendChatHandler() {
    if (!userInputState) {
      return;
    }
    const { token } = props?.authorization || {},
      { wallet_address } = props?.summary || {};
    setIsLoading(true);
    setUserInputState('');
    setChatHistoryState((prevState) => {
      const updatedChatHistory = [...prevState];
      updatedChatHistory.push({
        id: new Date().getTime(),
        message: userInputState,
        fromUser: true,
      });
      return updatedChatHistory;
    });

    setTimeout(async () => {
      try {
        chatHistoryRefDiv.current?.scrollIntoView({ behavior: 'smooth' });
        const chatResponse = await axios.post(
          `${baseUrl}/chat`,
          {
            wallet_address: wallet_address,
            question: userInputState,
            chain_id: props.chain_id,
          },
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
        if (chatResponse.status === 200) {
          const { data: chatGptResponse } = chatResponse;
          if (chatGptResponse) {
            const { data } = chatGptResponse,
              { answer } = data;

            setChatHistoryState((prevState) => {
              const updatedChatHistory = [...prevState];
              updatedChatHistory.push({
                id: new Date().getTime(),
                message: answer,
                fromUser: false,
              });
              return updatedChatHistory;
            });
            setIsLoading(false);
            setTimeout(() => {
              chatHistoryRefDiv.current?.scrollIntoView({ behavior: 'smooth' });
            }, 0);
          }
        }
      } catch (e) {
        console.log(e);
        setIsLoading(false);
      }
    }, 0);
  }

  const chatHistoryRefDiv = useRef(null);
  const isWalletSyncing = loadingPercentState < 100;
  return (
    <div className="chat-container">
      <div className="main-container">
        <div className="chatgpt-header-container">
          <div className="chapgpt-userinfo">
            <img src={chat_gpt_icon} className="chatgpt-icon" />
            <div className="d-flex flex-column">
              <span className="font-16 mb-1 fw-bold text-uppercase">
                ANZALI | Chat GPT
              </span>
              <span className="font-14 text-grey">
                Get answered with power of Chat GPT
              </span>
            </div>
          </div>
          <div className="chatgpt-header-action">
            <img
              src={chat_down_arrow_icon}
              onClick={toggleChatBodySectionHndlr}
              className={classNames(
                'header-icon mr-3',
                showChadBodyState ? 'toggle-down' : 'toggle-up'
              )}
            />

            <span className="close-icon">
              <img
                onClick={closeChatModalHndlr}
                src={chat_close_icon}
                className="header-icon"
              />
            </span>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          {!(loadingPercentState >= 100) && (
            <Line
              percent={loadingPercentState}
              strokeWidth={1}
              trailColor="#555555"
              strokeLinecap="square"
              strokeColor="#07bc0c"
            />
          )}
        </div>
        {showChadBodyState && (
          <div className={classNames('chtagpt-body-container')}>
            <div className="chathistory-container">
              {Array.isArray(chatHistoryState) &&
                chatHistoryState.map((chatInfo) => {
                  const { fromUser } = chatInfo;
                  if (fromUser) {
                    return (
                      <div key={chatInfo?.id} className="leftchat-container">
                        <div className="message-section">
                          {chatInfo?.message}
                        </div>
                        <img
                          src={chat_check_circle_icon}
                          className="message-icon"
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={chatInfo?.id} className="rightchat-container">
                        <img src={chat_gpt_icon} className="message-icon" />
                        <div className="message-section">
                          {chatInfo?.message}
                        </div>
                      </div>
                    );
                  }
                })}

              {isLoading && (
                <div
                  style={{
                    marginLeft: '16px',
                    marginTop: '12px',
                    marginBottom: '0px',
                  }}
                >
                  <div className="dot-pulse"></div>
                </div>
              )}
              <div ref={chatHistoryRefDiv}></div>
              {isWalletSyncing && (
                <div className="loading-transactions">
                  <Bars
                    color="#8a8aa0"
                    wrapperClass="mb-3"
                    height={20}
                    width={20}
                  />
                  <span className="mb-2">Loading</span>
                  <span>Initializing wallet data.</span>
                </div>
              )}
            </div>
            <div className="send-message-container">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className={isWalletSyncing ? 'chat-disabled-form' : ''}
              >
                <input
                  type="text"
                  ref={inputRef}
                  value={userInputState}
                  onChange={onChangeHndlr}
                  placeholder="Type your message here..."
                  className="chatgpt-input-container"
                  disabled={isWalletSyncing}
                />
                <div className="send-btn-container">
                  <button
                    onClick={sendChatHandler}
                    className="send-message-btn"
                    disabled={isWalletSyncing}
                  >
                    <img src={send_message_icon} />
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

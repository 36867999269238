import React from 'react';
import { useForm } from 'react-hook-form';
import { contactUs } from '../../apis/Index';
import { toast } from 'react-toastify';
import PageTitle from '../../components/pagetitle/PageTitle';
const Index = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  async function onSubmit(data, e) {
    //

    const response = await contactUs(data);
  
    if (response.status == 'success') {
      e.target.reset();
      toast.success('Thank you for contacting us');
    } else {
      toast.error('Error, please retry. Your message has not been sent.');
    }
  }
  return (
    <section className="tf-section tf-rank">
      <PageTitle
        meta={{
          title: 'Contact Us',
          description: '',
        }}
      />
      <div className="container">
        <div className="mt-4 form-inner mb-0">
          <div className="col-lg-4 col-md-6 col-12 mx-auto">
            <div className="text-center mt-19">
              <h1>Contact Us</h1>
            </div>
            <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
              <div className="">
                <label className="p-0">Full Name</label>
                <input
                  style={{ backgroundColor: '#252333!important' }}
                  className={`comman-textarea w-100 mt-2 ${
                    errors.email?.type === 'required' ? 'mb-0' : 'mb-3'
                  }`}
                  {...register('fullname', { required: true })}
                />
                {errors.fullname?.type === 'required' && (
                  <p
                    role="alert"
                    className="text-danger mb-3"
                    style={{ fontSize: '13px' }}
                  >
                    Full Name Required
                  </p>
                )}
              </div>
              <div className="">
                <label className="p-0">Email address</label>
                <input
                  style={{ backgroundColor: '#252333!important' }}
                  className={`comman-textarea w-100 mt-2 ${
                    errors.email?.type === 'required' ? 'mb-0' : 'mb-3'
                  }`}
                  {...register('email', { required: true })}
                />
                {errors.email?.type === 'required' && (
                  <p
                    role="alert"
                    className="text-danger mb-3"
                    style={{ fontSize: '13px' }}
                  >
                    Email Address Required
                  </p>
                )}
              </div>
              <div className="">
                <label className="p-0">Mobile Number (optional)</label>
                <input
                  style={{ backgroundColor: '#252333!important' }}
                  className={`comman-textarea w-100 mt-2 ${
                    errors.phone?.type === 'required' ? 'mb-0' : 'mb-3'
                  }`}
                  {...register('phone', { required: false })}
                />
              </div>
              <div className="">
                <label className="p-0">Message</label>
                <textarea
                  className={`comman-textarea ${
                    errors.message?.type === 'required' ? 'mb-1' : 'mb-5'
                  }`}
                  rows="5"
                  {...register('message', { required: true })}
                />
                {errors.message?.type === 'required' && (
                  <p
                    role="alert"
                    className="text-danger mb-3"
                    style={{ fontSize: '13px' }}
                  >
                    Message Required
                  </p>
                )}
              </div>
              <button
                type="submit"
                className="sc-button style-3 btn-block  d-flex justify-content-center"
                aria-label="Submit"
              >
                Submit
                {/* {loader ? (
                  <Bars color="#ffffff" height={30} width={80} />
                ) : (
                  'Submit'
                )} */}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;

import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { Instagram } from 'react-content-loader';
import TokenCard from '../../components/card/tokencard';
import { Bars } from 'react-loader-spinner';
import { numFormatter } from '../../callbacks/index';
import { searchAllCollectionsUpdatedMultichain } from '../../apis/Index';
import PageTitle from '../../components/pagetitle/PageTitle';
import { MdClose } from '../../components/icons/Index';
//Utility
import {
  getValueWithDecimal,
  debounce,
  collectionChains,
  chainList,
  chainListById,
} from '../../utility/commonUtitlity';
import LoadingCard from '../../components/card/LoadingCard';
import { apicallback, saveMeta } from '../../callbacks/index';
const Index = () => {
  const [list, setlist] = useState([]);
  const [loadMore, setLoaderMore] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchValueState, setSearchValueState] = useState('');
  const [canLoadMoreState, setCanLoadMoreState] = useState(false);
  const [currentPageState, setCurrentPageState] = useState(0);
  const [offsetValue, setOffset] = useState(0);
  let { chain: chainIdFromUrl } = useParams();
  chainIdFromUrl = chainListById(chainIdFromUrl.toLocaleUpperCase());
  const paginationRef = useRef({
    offset: 0,
    limit: 15,
  });
  useEffect(() => {
    getCollectionsApiCallback(true, 0, searchValueState);
  }, []);

  async function getCollectionsApiCallback(
    clearPrevCollections = false,
    offset,
    name
  ) {
    setLoader(true);
    const response = await apicallback(
      null,
      `/searchAllCollectionsUpdatedMultichain?chain=${chainIdFromUrl}${
        name ? `&name=${name}` : ''
      }${offset ? `&offset=${offset}` : ''}`,
      {},
      'private',
      null,
      'get'
    );

    if (response && response.status == 'success') {
      if (!response.offset) {
        setCanLoadMoreState(false);
      } else {
        setCanLoadMoreState(true);
      }
      if (clearPrevCollections) {
        setlist(response.data);
      } else {
        const updatadListState = [...list, ...response.data];
        setlist(updatadListState);
      }
      setOffset(response.offset);
      setLoaderMore(false);
      setLoader(false);
    }
  }

  function loadMoreHandler() {
    getCollectionsApiCallback(false, offsetValue, searchValueState);
    setLoaderMore(true);
  }

  const debouncedHandler = useCallback(
    debounce(function (searchValue) {
      getCollectionsApiCallback(true, 0, searchValue);
    }, 200),
    []
  );

  function onSearchHandler(event) {
    const { value } = event.target;
    paginationRef.current.offset = 0;
    setSearchValueState(value);
    debouncedHandler(value);
    setCurrentPageState(0);
  }

  function clearSearchHandler() {
    setSearchValueState('');
    paginationRef.current.offset = 0;
    setCurrentPageState(0);
    getCollectionsApiCallback({ name: '' }, true);
  }

  return (
    <Fragment>
      <section className="tf-section tf-rank">
        <PageTitle
          meta={{
            title:
              'Explore NFT Collections With In-Depth Data and Analytics | ANZALI',
            description:
              'Dive deep into collections and individual NFTs. Explore endless data, charts, floor prices, and rarity scores. Make informed decisions about the NFTs you love.',
          }}
        />
        <div className="page-container mb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-live-auctions mg-bt-21 d-inline d-sm-flex">
                <div>
                  <h2 className="text-left mb-2 style2">
                    {collectionChains(chainIdFromUrl)}
                  </h2>
                </div>
                <div className="position-relative mt-5 mt-sm-3 col-md-3 col-sm-5 col-12 mb-5 mb-sm-0 px-0 search-container portfolio-search">
                  {searchValueState ? (
                    <MdClose
                      className="search-ico"
                      onClick={clearSearchHandler}
                    />
                  ) : (
                    ''
                  )}
                  <input
                    className={`comman-input float-none float-sm-right`}
                    placeholder="Search…"
                    onChange={onSearchHandler}
                    value={searchValueState}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="feature-card-containe row">
                {/* <div className='card-container'>
                  <LoadingCard />
                </div> */}
                {list.map((item, index) => (
                  <div
                    key={`feature-token=${index}`}
                    className="card-container col-sm-6 col-6 col-md-4 col-lg-2 px-2"
                  >
                    <TokenCard
                      tokenTitle={
                        item.name ? `${item.name} ` : `#${item.token_id}`
                      }
                      tokenImage={item.image}
                      showPriceInfo={true}
                      floorPrice={
                        item.floor_price
                          ? getValueWithDecimal(item.floor_price)
                          : null
                      }
                      volumeTraded={
                        item?.volume_traded
                          ? numFormatter(item?.volume_traded, false)
                          : null
                      }
                      redirectLink={
                        item?.is_artblock
                          ? `/artblock-collection/${item.address}/${item.chain}`
                          : `/collection-info/${item.address}/${item.chain}`
                      }
                      itemDetails={item}
                      chain={item.chain}
                    />
                  </div>
                ))}
                {loader &&
                  Array(5)
                    .fill(0)
                    .map((item, index) => (
                      <div
                        className="card-container col-sm-6 col-6 col-md-4 col-lg-2 px-2"
                        key={`dummy-feature-token=${index}`}
                      >
                        <LoadingCard />
                      </div>
                    ))}
              </div>
            </div>
          </div>
          <div className="center">
            {canLoadMoreState ? (
              <button onClick={loadMoreHandler} className="sc-button style-4">
                {loadMore ? (
                  <Bars color="#ffffff" height={30} width={80} />
                ) : (
                  'Load More'
                )}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Index;

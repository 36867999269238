import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CardModal from '../components/layouts/CardModal';
import { useLocation } from 'react-router-dom';
import {
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { apicallback } from '../callbacks/index';
import { useParams } from 'react-router-dom';
import PageTitle from '../components/pagetitle/PageTitle';
const PeopleProfile = () => {
  const currentPageUrl = window.location.href;
  const [obj, setobj] = useState('');
  const location = useLocation();
  const { id } = useParams();
  const { type } = useParams();
  const content_url = process.env.REACT_APP_TOP_URL;
  useEffect(() => {
    if (location.state && location.state.obj && location.state.head) {
      setobj(location.state.obj);
    } else {
      getPeopleDetail();
    }
  }, []);

  const getPeopleDetail = async () => {
    let endpoint = null;
    var ob = {};
    switch (type) {
      case 'people':
        endpoint = 'nftDetails';
        ob.nft_id = id;
        break;
      case 'solana':
        endpoint = 'solanaNftDetails';
        ob.solana_nft_id = id;
        break;
      case 'metaverse':
        endpoint = 'metaverseDetails';
        ob.metaverse_id = id;
        break;
      default:
        endpoint = null;
        break;
    }
    if (endpoint != null && ob != null) {
      let url = content_url + endpoint;

      const response = await apicallback(
        'content',
        '/' + endpoint,
        ob,
        null,
        null
      );
      if (response && response.status == 'success') {
        setobj(response.data);
      }
    }
  };

  const [visible, setVisible] = useState(8);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 8);
  };

  const [modalShow, setModalShow] = useState(false);

  return (
    <div className="authors-2">
      <section className="tf-section authors">
        <PageTitle
          meta={{
            title: obj.name,
            description: obj.description,
            image: obj.image,
          }}
        />
        <div className="themesflat-container">
          <div className="flat-tabs tab-authors">
            <div className="author-profile flex">
              <div className="feature-profile">
                <img src={obj.image} alt={obj.name} className={obj.name} />
              </div>
              <div className="infor-profile">
                <span>Profile</span>
                <h2 className="title">{obj.name}</h2>
                <p className="content">{obj.description}</p>
                <form>
                  <Link href={obj.url}>
                    <input
                      type="text"
                      className="inputcopy"
                      defaultValue={obj.username}
                      readOnly
                    />
                    <button
                      type="button"
                      className="btn-copycode"
                      aria-label="twitter"
                    >
                      <i className="fab fa-twitter"></i>
                    </button>
                  </Link>
                </form>
              </div>
              <div className="widget-social style-3 flex-column justify-content-end align-items-end">
                <h5>Share:</h5>

                <ul>
                  <li>
                    <Link to="#">
                      <TwitterShareButton
                        url={currentPageUrl}
                        title={obj.name}
                        summary={obj.description}
                      >
                        <i className="fab fa-twitter"></i>
                      </TwitterShareButton>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <FacebookShareButton
                        quote={obj.name + obj.description}
                        url={currentPageUrl}
                      >
                        <i className="fab fa-facebook"></i>
                      </FacebookShareButton>
                    </Link>
                  </li>
                  <li className="style-2">
                    <Link to="#">
                      <TelegramShareButton
                        url={currentPageUrl}
                        title={obj.name}
                        summary={obj.description}
                      >
                        <i className="fab fa-telegram-plane"></i>
                      </TelegramShareButton>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <RedditShareButton
                        url={currentPageUrl}
                        title={obj.name}
                        summary={obj.description}
                      >
                        <i className="fab fa-reddit"></i>
                      </RedditShareButton>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <WhatsappShareButton
                        url={currentPageUrl}
                        title={obj.name}
                        summary={obj.description}
                      >
                        <i className="fab fa-whatsapp"></i>
                      </WhatsappShareButton>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CardModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default PeopleProfile;

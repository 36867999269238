import React from "react";
import Telegram from "../../assets/images/airdrops/telegram_icon.png";
import TwitterUser1 from "../../assets/images/airdrops/twitteruser1.png";
import TwitterUser2 from "../../assets/images/airdrops/twitteruser2.png";
import TwitterUser3 from "../../assets/images/airdrops/twitteruser3.png";
import TwitterUser4 from "../../assets/images/airdrops/twitteruser4.png";
import { openUrl } from "../../callbacks";

export default function JoinTelegram() {
    return (
        <div className="jointelegram-container h-100">
            <div className="iconcontainer">
                <img src={Telegram} />
            </div>
            <h3 className="title"> Join Us On Telegram</h3>
            <p className="description">Stay up-to-date about latest News, Airdrops & Staking details on
                our Telegram channel.</p>
            <div className="actioncontainer">
                <h4 className="font-14 mb-4">ANZALI | Telegram</h4>
                <div className="avatar-group mb-4">
                    <div
                        className="avatar-group-item"
                    >
                        <img
                            src={TwitterUser1}
                            alt="twitteruser1"
                            className="rounded-circle avatar-sm"
                        />
                    </div>
                    <div
                        className="avatar-group-item"
                    >
                        <img
                            src={TwitterUser2}
                            alt="twitteruser2"
                            className="rounded-circle avatar-sm"
                        />
                    </div>
                    <div
                        className="avatar-group-item"
                    >
                        <img
                            src={TwitterUser3}
                            alt="twitteruser3"
                            className="rounded-circle avatar-sm"
                        />
                    </div>
                    <div
                        className="avatar-group-item"
                    >
                        <img
                            src={TwitterUser4}
                            alt="twitteruser4"
                            className="rounded-circle avatar-sm"
                        />
                    </div>
                </div>
                <button
                    to="#"
                    onClick={()=>openUrl("https://t.me/+QP4SFdbIIpY4ZWY1")}
                    className="sc-button style-4 text-dark w-100"
                >
                    Join Telegram Channel
                </button>
            </div>
        </div>
    )
}
const Index = ({ url, cls = null }) => {
  return (
    <video
      className={`w-100 ${cls}`}
      autoplay=""
      muted
      playsInline
      loop
      src={url}
      controls
      poster="https://secure-assets-s3.s3.amazonaws.com/seo/Home+Page-Signin%26out.webp"
    ></video>
  );
};
export default Index;

import { createStore, action } from 'easy-peasy';
//import Cryptr from "cryptr";
//const cryptr = new Cryptr(process.env.REACT_APP_CRYPT);
const cryptr = null;
const MainStore = createStore({
  collections: [],
  addCollections: action((state, payload) => {
    state.collections.push(payload);
  }),
  topcol: [],
  settopcol: action((state, payload) => {
    state.topcol = payload;
  }),
  newdrop: [],
  setnewdrop: action((state, payload) => {
    state.newdrop = payload;
  }),
  solanaNfts: [],
  setsolanaNfts: action((state, payload) => {
    state.solanaNfts = payload;
  }),
  peopleInNfts: [],
  setpeopleInNfts: action((state, payload) => {
    state.peopleInNfts = payload;
  }),
  theMetaverse: [],
  settheMetaverse: action((state, payload) => {
    state.theMetaverse = payload;
  }),
  articles: [],
  setarticles: action((state, payload) => {
    state.articles = payload;
  }),
  selectedTab: 0,
  setselectedTab: action((state, payload) => {
    state.selectedTab = payload;
  }),
  homePageImage: null,
  sethomePageImage: action((state, payload) => {
    state.homePageImage = payload;
  }),
  filterView: true,
  setFilterView: action((state, payload) => {
    state.filterView = payload;
  }),
  authorization: localStorage.getItem('Authorization')
    ? JSON.parse(localStorage.getItem('Authorization'))
    : null,
  useCryptr: cryptr,
  streamChatKey: process.env.REACT_APP_STEAM_CHAT_KEY,
  streamToken: null,
  setStreamToken: action((state, payload) => {
    state.streamToken = payload;
  }),
  trackData: [],
  setTrackData: action((state, payload) => {
    state.trackData = payload;
  }),
  collectionTopStatus: 0,
  setCollectionTopStatus: action((state, payload) => {
    state.collectionTopStatus = payload;
  }),
  collectionNewStatus: 0,
  setCollectionNewStatus: action((state, payload) => {
    state.collectionNewStatus = payload;
  }),
  filterTrakView: 1,
  setFilterTrakView: action((state, payload) => {
    state.filterView = payload;
  }),
  publicWalletInfo: null,
  setPublicWalletInfo: action((state, payload) => {
    state.publicWalletInfo = payload;
  }),
  publicWalletNft: [],
  setPublicWalletNft: action((state, payload) => {
    state.publicWalletNft = payload;
  }),
  trackerCurrentTab: 'summaryTab',
  setTrackerCurrentTab: action((state, payload) => {
    state.trackerCurrentTab = payload;
  }),
  walletBalanceInfo: undefined,
  setWalletBalanceInfo: action((state, payload) => {
    state.walletBalanceInfo = payload;
  }),
  walletSummaryActiveTabId: 'overview',
  setWalletSummaryActiveTabId: action((state, payload) => {
    state.walletSummaryActiveTabId = payload || 'overview';
  }),
  overviewTabData: null,
  setOverviewTabDataAct: action((state, payload) => {
    state.overviewTabData = payload;
  }),
  storeWalletAddress: null,
  setStoreWalletAddress: action((state, payload) => {
    state.storeWalletAddress = payload;
  }),
  storeWalletId: null,
  setStoreWalletId: action((state, payload) => {
    state.storeWalletId = payload;
  }),

  storeWalletAddress: null,
  setStoreWalletAddress: action((state, payload) => {
    state.storeWalletAddress = payload;
  }),

  collectionInfoTabsData: null,
  setCollectionInfoTabAct: action((state, payload) => {
    state.collectionInfoTabsData = payload;
  }),

  collectionData: null,
  setCollectionDataAct: action((state, payload) => {
    state.collectionData = payload;
  }),

  collectionFloorPriceChartResp: null,
  setCollectionFloorPriceChartAct: action((state, payload) => {
    state.collectionFloorPriceChartResp = payload;
  }),
  collectionHolderChartResp: null,
  setCollectionHolderChartAct: action((state, payload) => {
    state.collectionHolderChartResp = payload;
  }),
  resourceCategory: [],
  setResourceCategory: action((state, payload) => {
    state.resourceCategory = payload;
  }),
  resourceCategoryId: '',
  setResourceCategoryId: action((state, payload) => {
    state.resourceCategoryId = payload;
  }),
});

export default MainStore;

import { apicallback } from '../callbacks/index';

export async function login(obj = {}, token = null) {
  const response = await apicallback(null, '/login', obj, 'private', token);
  return response;
}
export async function signup(obj = {}, token = null) {
  const response = await apicallback(null, '/signup', obj, 'private', token);
  return response;
}
export async function partnerEmployeeLogin(obj = {}, token = null) {
  const response = await apicallback(
    null,
    '/partnerEmployeeLogin',
    obj,
    'private',
    token
  );
  return response;
}
export async function verifyLogin(obj = {}, token = null) {
  const response = await apicallback(
    null,
    '/verifyLogin',
    obj,
    'private',
    token
  );
  return response;
}
export async function createWallet(obj = {}, token = null) {
  const response = await apicallback(
    null,
    '/createWallet',
    obj,
    'private',
    token
  );
  return response;
}
export async function topCollection(obj = {}, token = null) {
  const response = await apicallback(
    'content',
    '/topCollection',
    obj,
    null,
    token
  );
  return response;
}

export async function newDrops(obj = {}, token = null) {
  const response = await apicallback('content', '/newDrops', obj, null, token);
  return response;
}

export async function getNftofCollections(obj = {}, token = null) {
  const response = await apicallback(
    'content',
    '/getNftofCollections',
    obj,
    null,
    token
  );
  return response;
}

export async function getCollectionById(obj = {}, token = null) {
  const response = await apicallback(
    null,
    '/getCollectionById',
    obj,
    null,
    token
  );
  return response;
}

export async function searchCollectionsUsersWallets(
  obj = {},
  optionalConfig = null
) {
  const response = await apicallback(
    null,
    '/searchAll',
    obj,
    null,
    null,
    'post',
    optionalConfig
  );
  return response;
}

export async function searchCollections(obj = {}) {
  const response = await apicallback(
    null,
    '/searchAllCollectionsUpdated',
    obj,
    null,
    null,
    'post'
  );
  return response;
}

export async function collectionsMultichain(obj = {}) {
  const response = await apicallback(
    null,
    '/getCollectionsMultichain',
    obj,
    null,
    null,
    'get'
  );
  return response;
}
export async function searchAllCollectionsUpdatedMultichain(obj = {}) {
  const response = await apicallback(
    null,
    `/searchAllCollectionsUpdatedMultichain?chain=${obj.chain}&name=${obj.name}`,
    obj,
    null,
    null,
    'get'
  );
  return response;
}

export async function getMintCollectionAction(obj = {}, token = null) {
  const response = await apicallback(
    null,
    '/getMintingNow',
    obj,
    null,
    token,
    'get'
  );
  return response;
}

export async function getServices(token = null) {
  const response = await apicallback('content', '/services', null, null, token);
  return response;
}

export async function submitUserService(payload = null, token = null) {
  const response = await apicallback(
    'content',
    '/userRequestService',
    payload,
    null,
    token,
    'post'
  );
  return response;
}

export async function contactUs(obj = {}, token = null) {
  const response = await apicallback('content', '/contactUs', obj, null, token);
  return response;
}

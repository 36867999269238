import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import AreaChart from '../../components/Chart/AreaChart';
//components

export default function TradingActivity({ timerange, handelTimerange }) {
  const collectionInfoTabsData = useStoreState(
    (state) => state.collectionInfoTabsData
  );
  const [chartState, setChartState] = useState(null);

  useEffect(() => {
    const { tradingData } = collectionInfoTabsData || [];
    setChartState(tradingData);
  }, [collectionInfoTabsData]);

  return (
    <section>
      {chartState ? (
        <>
          <div className="d-flex justify-content-between">
            <h5 className="mb-2"></h5>
            <div className="chart-filter">
              {['24h', '7d', '30d'].map((value, key) => (
                <span
                  className={`text-uppercase ${
                    timerange === value ? 'text-main' : 'text-light'
                  }`}
                  key={key}
                  onClick={() => handelTimerange(value)}
                >
                  {value}
                </span>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <AreaChart
                title="Unique Buyers Chart"
                categories={chartState?.buyer?.x || []}
                data={[
                  {
                    name: 'Unique Buyers',
                    data: chartState?.buyer?.y || [],
                  },
                ]}
                epoc={false}
                time={true}
                filter={false}
              />
            </div>
            <div className="col-md-6">
              <AreaChart
                title="Unique Sellers Chart"
                categories={chartState?.seller?.x || []}
                data={[
                  {
                    name: 'Unique Sellers',
                    data: chartState?.seller?.y || [],
                  },
                ]}
                time={true}
                epoc={false}
                filter={false}
              />
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </section>
  );
}

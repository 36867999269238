import { useNavigate } from 'react-router-dom';
export default function SectionAirdrop() {
  const history = useNavigate();
  return (
    <section className="section-4 w-100 mb-5">
      <div className="home-custom-container">
        <div className="row">
          <div className="col-sm-8 first">
            <h4>
              Get Exclusive Rewards for Completing Easy{' '}
              <br className="d-none d-md-block" /> Tasks of Top & Well
              Researched Airdrops.
            </h4>
            <p className="mt-4">
              Earn exclusive rewards by completing simple and thoroughly vetted
              airdrop tasks. Get started now and enjoy the benefits!
            </p>
            <div className=" mt-5 group">
              <button
                className="sc-button style-4 text-dark"
                onClick={() => history('/airdrops')}
              >
                Explore Free Airdrops Now
              </button>
            </div>
          </div>
          <div
            className="col-sm-4 SectionAirdrop cursor-pointer"
            onClick={() => history('/airdrops')}
          ></div>
        </div>
      </div>
    </section>
  );
}

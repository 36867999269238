import React, { useState, useEffect, useRef } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import {
  apicallback,
  dataURLtoFile,
  copyClipboard,
} from '../../callbacks/index';
import { useStoreState } from 'easy-peasy';
import { Bars } from 'react-loader-spinner';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AvatarEditor from 'react-avatar-editor';
import {
  AiOutlineDelete,
  IoArrowBackSharp,
  FaRegCopy,
} from '../../components/icons/Index';
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import loadable from '@loadable/component';
import { loadingGif } from '../../assets/Index';

const VerifyDeleteModal = loadable(() =>
  import('../../components/models/VerifyDeleteModal')
);

const Post = () => {
  const editor = useRef(null);
  const location = useLocation();
  const history = useNavigate();
  const authorization = useStoreState((actions) => actions.authorization);
  const [bannerimage, setbannerimage] = useState(null);
  const [bannerimageObj, setbannerimageObj] = useState(null);
  const [selectedCollectionid, setCollectionid] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zoom, setZoom] = useState(null);
  const [error, setError] = useState('');
  const [alertInfo, setAlertInfo] = useState(false);
  const [postObj, setSelected] = useState(null);
  const mainProps = location.state;

  const [searchList, setSearchCollection] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const currentPageUrl =
    window.location.origin +
    '/profile/' +
    (authorization && authorization.data.username
      ? authorization && authorization.data.username
      : authorization && authorization.data.id);

  useEffect(() => {
    if (location.state == null) {
      history('/my-profile');
    } else {
      setSelected(location.state);
      if (location.state.selected != null) {
        setSearchValue(
          location.state.selected.collection
            ? location.state.selected.collection.collection_name
            : location.state.selected.collection_name
        );
      }
    }
  }, []);
  const getData = async (name = '') => {
    const response = await apicallback(
      null,
      '/searchAllCollections',
      {
        name: name,
        offset: 0,
        limit: '10',
      },
      '',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      return response.data;
    } else {
      return [];
    }
  };

  const handelForm = async (e) => {
    e.preventDefault();
    if (e.target.elements.name.value == '') {
      setError('NFT Name is required.');
    } else if (bannerimageObj == null && postObj == null) {
      setError('Post image is required.');
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', e.target.elements.name.value);
      formData.append(
        'order',
        postObj && postObj.selected ? postObj.selected.order : mainProps.order
      );

      formData.append('caption', e.target.elements.caption.value);
      if (postObj && postObj.selected != null && selectedCollectionid == null) {
        formData.append(
          'collection_id',
          postObj.selected.collection
            ? postObj.selected.collection.collection_id
            : ''
        );
      } else {
        formData.append('collection_id', selectedCollectionid);
      }

      if (
        postObj &&
        postObj.selected &&
        postObj.selected.isCollectionConnected == false
      ) {
        formData.append('collection_name', postObj.selected.collection_name);
      } else {
        formData.append('collection_name', searchValue);
      }

      formData.append('nft_image', bannerimageObj);
      if (postObj && postObj.selected != null) {
        formData.append('nft_id', postObj.selected.id);
      }
      const response = await apicallback(
        null,
        '/updateCreateUserCustomProfileNft',
        formData,
        'private',
        authorization && authorization.token
      );
      if (response && response.status == 'success') {
        if (postObj != null) {
          history('/my-profile');
        } else {
          setAlertInfo(true);
        }
      } else {
        setError(response.message);
        setLoading(false);
      }
    }
  };

  const handelDelete = async () => {
    setModalShow(true);
  };

  const onBannerChange = (event) => {
    setbannerimage(URL.createObjectURL(event.target.files[0]));
  };

  const imageDrag = () => {
    if (editor) {
      try {
        const canvas = editor.current.getImage();
        if (canvas) {
          const file = dataURLtoFile(canvas.toDataURL(), `image.png`);
          setbannerimageObj(file);
        }
      } catch (error) {}
    }
  };

  const handelSearch = async (name) => {
    const collection = await getData(name);

    setSearchCollection(collection);
    if (name !== '') {
      if (collection.length > 0) {
        if (collection.length > 5) {
          document.getElementById('search-dropdown').style.height = '320px';
          document.getElementById('search-dropdown').style.overflow = 'auto';
        } else {
          document.getElementById('search-dropdown').style.height = 'auto';
        }
      } else {
        setCollectionid('');
      }
    } else {
      setSearchCollection([]);
    }
  };

  return (
    <>
      <section className="tf-section tf-rank">
        <PageTitle
          meta={{
            title: postObj && postObj.selected ? 'Edit Post' : 'New Post',
          }}
        />
        {alertInfo ? (
          <div className="alertUIModal-main">
            <div className="alertUIModal position-fixed">
              <h3 className="text-center mb-3">Share Post</h3>
              <button
                onClick={() => history('/my-profile')}
                type="button"
                className="btn-close mt-4"
                aria-label="Close"
              ></button>
              <hr />

              <div className="widget-social style-3 mb-5 mt-4">
                <ul className="d-flex justify-content-between align-content-between">
                  <li>
                    <Link to="#">
                      <TwitterShareButton
                        url={currentPageUrl}
                        title={'Check out my profile on Anzali: '}
                      >
                        <i className="fab fa-twitter"></i>
                      </TwitterShareButton>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <FacebookShareButton
                        url={currentPageUrl}
                        title={'Check out my profile on Anzali: '}
                      >
                        <i className="fab fa-facebook"></i>
                      </FacebookShareButton>
                    </Link>
                  </li>
                  <li className="style-2">
                    <Link to="#">
                      <TelegramShareButton
                        url={currentPageUrl}
                        title={'Check out my profile on Anzali: '}
                      >
                        <i className="fab fa-telegram-plane"></i>
                      </TelegramShareButton>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <WhatsappShareButton
                        url={currentPageUrl}
                        title={'Check out my profile on Anzali: '}
                      >
                        <i className="fab fa-whatsapp"></i>
                      </WhatsappShareButton>
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <FaRegCopy
                        className="copyBtn"
                        onClick={() => copyClipboard(currentPageUrl)}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
              <button
                className="sc-button style-4 btn-block mt-4"
                onClick={() => history('/my-profile')}
              >
                Not Now
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-8 mx-auto">
              {authorization ? (
                <div className="modal-body">
                  <div className="d-flex justify-content-between mb-4">
                    <h3>
                      {postObj && postObj.selected ? (
                        <Link to="/my-profile" className="h4 d-flex">
                          <IoArrowBackSharp className="mr-2" /> Go back
                        </Link>
                      ) : (
                        'New Post'
                      )}
                    </h3>
                    {postObj && postObj.selected ? (
                      <div >
                        <button
                          to="#"
                          className="btn sc-button border-0 btn-danger btn"
                          onClick={() => handelDelete()}
                        >
                          <h5>
                            <AiOutlineDelete
                              style={{ verticalAlign: 'bottom' }}
                            />{' '}
                            Remove Post
                          </h5>
                        </button>
                      </div>
                    ) : (
                      <Link to="/my-profile" className="h4 d-flex">
                        <IoArrowBackSharp className="mr-2" /> Go back
                      </Link>
                    )}
                  </div>
                  <div className="form-inner">
                    <form
                      id="contactform"
                      noValidate="novalidate"
                      className="form-submit"
                      onSubmit={handelForm}
                    >
                      <div className="d-flex justify-content-center flex-column mb-4 align-items-center col-sm-8 mx-auto">
                        {bannerimage ? (
                          <>
                            <AvatarEditor
                              ref={editor}
                              image={bannerimage}
                              width={250}
                              height={250}
                              border={10}
                              color={[0, 0, 0, 0.5]} // RGBA
                              scale={zoom}
                              borderRadius={0}
                              rotate={0}
                              onImageChange={() => imageDrag()}
                              onImageReady={() => imageDrag()}
                            />
                            <input
                              name="scale"
                              type="range"
                              min="1"
                              max="10"
                              step="0.01"
                              value={zoom}
                              onChange={(e) => {
                                setZoom(e.target.value);
                              }}
                              style={{ width: '250px' }}
                            />
                          </>
                        ) : postObj && postObj.selected ? (
                          <img
                            src={
                              postObj && postObj.selected
                                ? postObj.selected.image
                                : ''
                            }
                            width="200"
                            alt="post image"
                          />
                        ) : (
                          ''
                        )}
                      </div>
                      <input
                        type="file"
                        className="inputfile form- w-100"
                        name="file"
                        onChange={(event) => {
                          onBannerChange(event);
                          setZoom(1.2);
                        }}
                      />
                      <textarea
                        className="form- mb-3"
                        rows="5"
                        placeholder="Write a caption..."
                        name="caption"
                        defaultValue={
                          postObj && postObj.selected
                            ? postObj.selected.caption
                            : ''
                        }
                      />
                      <input
                        type="text"
                        placeholder="NFT Name"
                        name="name"
                        required
                        className="mb-3"
                        defaultValue={
                          postObj && postObj.selected
                            ? postObj.selected.name
                            : ''
                        }
                      />
                      <div className="position-relative  mt-5 mb-4 mt-sm-0">
                        <input
                          className={`comman-input w-100`}
                          placeholder="NFT Collection (type to search)"
                          value={searchValue}
                          onChange={(e) => {
                            handelSearch(e.target.value, false);
                            setSearchValue(e.target.value);
                          }}
                        />
                        {searchList.length > 0 ? (
                          <ul className="search-dropdown" id="search-dropdown">
                            {searchList.length > 0
                              ? searchList.map((item, key) => (
                                  <li
                                    key={key}
                                    onClick={() => {
                                      setCollectionid(
                                        item ? item.collection_id : ''
                                      );
                                      setSearchValue(item.collection_name);
                                      setSearchCollection([]);
                                    }}
                                  >
                                    <img
                                      src={
                                        item.image_url
                                          ? item.image_url
                                          : loadingGif
                                      }
                                      alt={item.collection_name}
                                    />
                                    <h5 >
                                      <Link to="#">{item.collection_name}</Link>
                                    </h5>
                                  </li>
                                ))
                              : ''}
                          </ul>
                        ) : (
                          ''
                        )}
                      </div>
                      {error ? (
                        <div className="alert alert-danger mt-3" role="alert">
                          <b>{error}</b>
                        </div>
                      ) : (
                        ''
                      )}

                      <button
                        type="submit"
                        className="btn btn-primary mt-5 d-flex justify-content-center align-item-center"
                        disabled={loading}
                      >
                        {loading ? (
                          <Bars color="#ffffff" height={30} width={80} />
                        ) : (
                          'Save'
                        )}
                      </button>
                    </form>
                  </div>
                </div>
              ) : (
                <div className="modal-body space-y-20 pd-40">
                  <h3>
                    Sign up or sign in to <br /> update your profile.
                  </h3>
                  <div className="d-flex flex-column justify-content-between">
                    <Link
                      className="sc-button style-4 my-4 d-flex justify-content-center align-item-center"
                      to="/sign-up"
                      state={{ prevPath: window.location.pathname }}
                    >
                      <span className="pl-0">Sign Up</span>
                    </Link>
                    <Link
                      to="/sign-in"
                      state={{ prevPath: window.location.pathname }}
                      className="sc-button style-1 d-flex justify-content-center align-item-center"
                    >
                      <span className="pl-0">Sign In</span>
                    </Link>
                  </div>
                </div>
              )}
              {authorization ? (
                <VerifyDeleteModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  nftid={postObj && postObj.selected ? postObj.selected.id : ''}
                  bar={<Bars color="#ffffff" height={30} width={80} />}
                  apicallback={apicallback}
                  authorization={authorization && authorization.token}
                  history={history}
                  fallback={<div>Loading...</div>}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Post;

import {
  eth_front,
  home_section_6,
  home_clock_front,
  home_front_premium,
  home_start_premium,
  home_target_premium,
} from '../../assets/Index';
import { Link } from 'react-router-dom';
export default function Section6() {
  function List(value) {
    const { img, text } = value;
    return (
      <div className="list d-flex align-items-center">
        <div className="d-flex justify-content-center align-items-center">
          <img src={img} />
        </div>
        <p>{text}</p>
      </div>
    );
  }

  return (
    <section className="section-6">
      <div className="home-custom-container">
        <div className="row">
          <div className="col-md-5 d-flex flex-column justify-content-center">
            <div className="first mb-4 d-flex align-items-center">
              <img src={eth_front} className="mr-2" />
              <span>NFT Portfolio Tracker</span>
            </div>
            <h4>
              Track Your NFT Portfolio <br className="d-none d-md-block" /> with
              Rarity Considerations
            </h4>
            <img
              src="https://secure-assets-s3.s3.amazonaws.com/preload/Portfolio.gif"
              className="w-100 d-block d-md-none mt-3"
            />
            <div className="my-0 my-md-5">
              <List
                img={home_clock_front}
                text="Portfolio values with real-time floor prices across multiple marketplaces"
              />
              <List
                img={home_front_premium}
                text="Historical performance charts to track value over time"
              />
              <List
                img={home_target_premium}
                text="Asset rarity adjustments to track valuations more accurately"
              />
              <List
                img={home_start_premium}
                text="Simple to set up and free to use!"
              />
            </div>
            <div>
              <Link className="sc-button style-4 text-dark" to="/nft-portfolio-tracker">
                Create Your Portfolio
              </Link>
            </div>
          </div>
          <div className="col-md-1" />
          <div className="col-md-6 d-none d-md-block text-center">
            <img
              src="https://secure-assets-s3.s3.amazonaws.com/preload/Portfolio.gif"
              className="w-80 border-2"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

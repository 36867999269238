import React from 'react';
import moment from 'moment';
import { getTwoDigitValue } from '../../../utility/commonUtitlity';
import { condenseAddress } from '../../../callbacks/index';
import { document_copy_img } from '../../../assets/Index';

export function MenuOptions(props) {
  if (!Array.isArray(props.actionOptions)) {
    return '';
  }
  return (
    <ul className="horizontal-menu-options-container">
      {props.actionOptions.map((item, index) => (
        <React.Fragment key={item.id}>
          <li
            className="list-inline-item cursor-pointer"
            data-id={props.rowId}
            onClick={item.onClickHndlr}
            id={`${item.id}-${props.rowId}`}
          >
            {item.actionIcon}
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
}

export function generateColumns(
  walletAddress,
  copyClipboard,
  menuOptions = []
) {
  return [
    {
      id: 'id',
      Header: '#',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({ row: { index } }) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      id: 'hash',
      Header: 'Tranx Hash',
      accessor: 'hash',
      disableSortBy: true,
      Cell: ({ value }) => {
        return <span className="mr-1">{condenseAddress(value) || '-'}</span>;
      },
    },
    {
      id: 'value',
      Header: 'Value',
      accessor: 'value',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <span className="fw-bold">
            <span className="mr-1">{getTwoDigitValue(value)}</span>
            <span className="text-muted">ETH</span>
          </span>
        );
      },
    },
    {
      id: 'blockNum',
      Header: 'Block',
      accessor: 'blockNum',
      disableSortBy: true,
    },
    {
      id: 'from',
      Header: 'From Address',
      accessor: 'from',
      disableSortBy: true,
      Cell: ({ row: { index, original } }) => {
        const { from } = original;
        return (
          <div
            className="d-flex align-items-center"
            data-id={from}
            onClick={copyClipboard}
          >
            <span className="mr-1">{condenseAddress(from)}</span>
            <img
              src={document_copy_img}
              style={{ width: '20px', height: '20px', maxWidth: 'initial' }}
            />
          </div>
        );
      },
    },
    {
      id: 'to',
      Header: 'To Address',
      accessor: 'to',
      disableSortBy: true,
      Cell: ({ row: { index, original } }) => {
        const { to } = original;
        return (
          <div
            className="d-flex align-items-center"
            data-id={to}
            onClick={copyClipboard}
          >
            <span className="mr-1">{condenseAddress(to)}</span>
            <img
              src={document_copy_img}
              style={{ width: '20px', height: '20px', maxWidth: 'initial' }}
            />
          </div>
        );
      },
    },
    {
      id: 'blockTimestamp',
      Header: 'Block Date',
      accessor: 'blockTimestamp',
      disableSortBy: true,
      columnClassName: 'w-150px min-w-150px',
      Cell: ({ row: { original } }) => {
        const { metadata } = original;
        const dateInfo = metadata
            ? `${moment(metadata.blockTimestamp)
                .utc()
                .format('MMM DD, YYYY | hh:mm A')} +UTC`
            : '-',
          timeInfo = `${moment(metadata.blockTimestamp)
            .utc()
            .fromNow(true)} ago`;
        const capitalizedTimeFromNow = metadata
          ? timeInfo.charAt(0).toUpperCase() + timeInfo.slice(1)
          : '-';
        return (
          <div className="d-flex flex-column text-nowrap max-w-none">
            <span className="lh-title">{capitalizedTimeFromNow}</span>
            <span className="font-14 lh-title text-muted">{dateInfo}</span>
          </div>
        );
      },
    },
    {
      id: 'menuActions',
      Header: 'Actions',
      accessor: 'menuActions',
      disableSortBy: true,
      columnClassName: 'w-120px min-w-120px',
      Cell: ({ row: { original } }) => {
        return (
          <MenuOptions rowId={original?.hash} actionOptions={menuOptions} />
        );
      },
    },
  ];
}

import React, { useEffect, useRef, useState } from 'react';
import AirdropCard, {
  AirdropLoadingCard,
} from '../../components/card/airdropcard';
import JoinTelegram from '../Airdrops/JoinTelegram';
import SubscribeNewsletter from '../Airdrops/SubscribeNewsLetter';
import InputCheckbox from '../../components/inputcheckbox';
//API Actions
import { apicallback, saveMeta } from '../../callbacks/index';
import { Bars } from 'react-loader-spinner';
//Assets
import { FiSearch } from '../../components/icons/Index';
import { useLocation, useNavigate } from 'react-router-dom';
import AnzaliLoader from '../../components/AnzaliLoader';
import LoadingCard from '../../components/card/LoadingCard';
import NoDataComponent from '../../components/NoDataComponent';
import { FiFilter } from 'react-icons/fi';
import { RiEqualLine, RiEqualizerLine } from 'react-icons/ri';
import classNames from 'classnames';
import PageTitle from '../../components/pagetitle/PageTitle';

export default function ExploreAirdrops() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const chainId = queryParams.get('chainId');
  const airdroptype = queryParams.get('type');
  const [chainState, setChainState] = useState([]);
  const [currentChain, setCurrentChain] = useState({});
  const [airdropTypeState, setAirdropTypeState] = useState([]);
  const [airdropsState, setAirdropsState] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterState, setFilterState] = useState(() => ({
    name: '',
    content_type: airdroptype || '',
    chain: chainId ? [chainId] : [],
    offset: 0,
    limit: 15,
  }));

  const [loadingState, setLoadingState] = useState({
    filterAction: '',
    isFechingChains: true,
    isFetchingTypes: true,
    isFetchingAirdrops: true,
  });
  const abortControllerRef = useRef();
  const history = useNavigate();

  useEffect(() => {
    getChainsApiCall();
    getAirdropTypesApiCall();
    getAirdropsApiCall('INITIALLOAD');
  }, []);

  async function getChainsApiCall() {
    const response = await apicallback(
      null,
      `/getContentChains`,
      null,
      null,
      null,
      'get'
    );
    setChainState(response?.data);

    response?.data.map((value) => {
      if (value.id == chainId) {
        setCurrentChain(value);
        saveMeta({
          title: value.meta_title + '' || null,
          description: value?.meta_description || null,
          images: value?.image || null,
          url: `/explore-airdrops?chainId=${chainId}`,
          file: 'exploreAirdrops',
          keywords: `${value.meta_title}`,
        });
      }
    });
    if (!chainId) {
      setCurrentChain({
        meta_title: 'Explore Free Crypto Airdrops & Staking Rewards | ANZALI',
        meta_description:
          'Grab free crypto airdrops & earn staking rewards on ANZALI. Maximize your crypto earnings today. Join our community & start earning!',
      });
    }
    setLoadingState((prevState) => ({ ...prevState, isFechingChains: false }));
  }
  async function getAirdropTypesApiCall() {
    const response = await apicallback(
      null,
      `/getAllTypes`,
      null,
      null,
      null,
      'get'
    );
    setAirdropTypeState(response?.data);
    setLoadingState((prevState) => ({ ...prevState, isFetchingTypes: false }));
  }

  function filterByAirdropHndlr(event) {
    const { checked, name } = event.target,
      [, typeId] = name?.split('-');
    if (typeId) {
      setFilterState((prevState) => ({
        ...prevState,
        content_type: checked ? typeId : '',
        offset: 0,
      }));
      getAirdropsApiCall('FILTERS', {
        content_type: checked ? typeId : '',
        offset: 0,
      });
      setLoadingState((prevState) => ({
        ...prevState,
        filterAction: 'FILTERS',
        isFetchingAirdrops: true,
      }));
    }
  }

  function filterByChainHndlr(event) {
    const { checked, name } = event.target,
      [, chainId] = name?.split('-');
    if (chainId) {
      const { chain } = filterState;
      let updateChains = [];
      if (checked) {
        updateChains = [...chain];
        updateChains.push(chainId);
      } else {
        updateChains = chain.filter((item) => item !== chainId);
      }
      setFilterState((prevState) => ({
        ...prevState,
        chain: updateChains,
        offset: 0,
      }));
      getAirdropsApiCall('FILTERS', { chain: updateChains, offset: 0 });
      setLoadingState((prevState) => ({
        ...prevState,
        filterAction: 'FILTERS',
        isFetchingAirdrops: true,
      }));
    }
  }

  async function getAirdropsApiCall(actionType, filterInfo) {
    const updatedFilters = { ...filterState, ...filterInfo },
      { chain } = updatedFilters;
    updatedFilters.chain = chain.join(',');
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const controller = new AbortController();
    abortControllerRef.current = controller;
    const response = await apicallback(
      null,
      `/getallContent`,
      null,
      null,
      null,
      'get',
      {
        params: updatedFilters,
        signal: controller.signal,
      }
    );
    if (response.status === 'success') {
      const { offset = 0 } = response;
      if (Array.isArray(response?.data)) {
        if (actionType === 'LOADMORE') {
          setAirdropsState((prevState) => [...prevState, ...response?.data]);
        } else {
          setAirdropsState(response?.data || []);
        }
      }
      setFilterState((prevState) => ({ ...prevState, offset }));
    }
    if (response.message === 'canceled') {
      return;
    }
    // setLoadingState(prevState => ({ ...prevState, isFetchingAirdrops: false }));
    setLoadingState((prevState) => ({
      ...prevState,
      filterAction: '',
      isFetchingAirdrops: false,
    }));
  }

  function redirectHandler(airdropId, keyName) {
    if (!airdropId) {
      return;
    }
    if (keyName) {
      // history(`/airdrops/${airdropId}?section=${keyName}`);
      history(`/airdrops/${airdropId}`);
      return;
    }
    history(`/airdrops/${airdropId}`);
  }

  function onSearchHandler(event) {
    const { name, value } = event.target;
    setFilterState((prevState) => ({ ...prevState, [name]: value, offset: 0 }));
    getAirdropsApiCall('SEARCH', { name: value, offset: 0 });
    setLoadingState((prevState) => ({
      ...prevState,
      filterAction: 'SEARCH',
      isFetchingAirdrops: true,
    }));
  }

  function viewFilterModalHndlr() {
    const htmlElement = document.documentElement;
    htmlElement.style.overflow = 'hidden';
    setShowFilterModal(true);
  }
  function closeFilterModalHndlr() {
    const htmlElement = document.documentElement;
    htmlElement.style.overflow = '';
    setShowFilterModal(false);
  }

  function loadMoreHandler() {
    getAirdropsApiCall('LOADMORE');
    setLoadingState((prevState) => ({
      ...prevState,
      filterAction: 'LOADMORE',
      isFetchingAirdrops: true,
    }));
  }

  const { filterAction, isFetchingAirdrops, isFetchingTypes, isFechingChains } =
      loadingState,
    existingAirdrop =
      (Array.isArray(airdropsState) && airdropsState.length >= 0) || false;
  const { offset: enableLoaderMore } = filterState;
  return (
    <React.Fragment>
      <PageTitle
        meta={{
          title: currentChain?.meta_title,
          description: currentChain?.meta_description,
          image: currentChain?.image,
        }}
      />

      {(isFechingChains || isFetchingTypes) && <AnzaliLoader />}
      <div className="freeairdrops-container">
        <div className="page-container">
          <div className="mt-5 mb-5">
            <div className="headercontainer">
              <h1 className="pagetitle">Explore Free Airdrops</h1>
              <div className="headeractioncontainer">
                <div className="searchairdrop-container">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="search-input"
                    placeholder="Search Airdrops"
                    onChange={onSearchHandler}
                  />
                  <button className="searching-btn-container">
                    {filterAction === 'SEARCH' && isFetchingAirdrops ? (
                      <Bars color="#ffffff" height={20} width={20} />
                    ) : (
                      <FiSearch />
                    )}
                  </button>
                </div>

                <button
                  className="showfilterbutton"
                  onClick={viewFilterModalHndlr}
                >
                  <RiEqualizerLine />
                </button>
              </div>
            </div>
            <div className="row custom-row">
              {/*  */}
              <div
                className={classNames(
                  'col-12 col-md-4 col-lg-3 custom-column filtercontainer-column',
                  {
                    show: showFilterModal,
                  }
                )}
              >
                <div className="filters-container mb-3">
                  <div className="filterheader">
                    <h2 className="font-22 font-semibold mb-0">Filters</h2>
                    {filterAction === 'FILTERS' && isFetchingAirdrops && (
                      <Bars color="#ffffff" height={20} width={20} />
                    )}
                  </div>
                  <div className="filterbody">
                    <div className="mb-4">
                      <div className="borderlight-bottom pb-3 mb-4">
                        <h4 className="text-neutral font-16">Airdrop Type</h4>
                      </div>
                      {airdropTypeState.map((item, index) => (
                        <InputCheckbox
                          key={`airdroptype-${item.id}`}
                          name={`airdroptype-${item.id}`}
                          onChange={filterByAirdropHndlr}
                          checked={
                            filterState.content_type === item.id?.toString()
                          }
                          checkboxClassName="checbox-control-sm"
                          containerClassName="mb-10"
                        >
                          <span className="text-neutral font-16 font-semibold">
                            {item.name}
                          </span>
                        </InputCheckbox>
                      ))}
                    </div>

                    <div className="mb-3">
                      <div className="borderlight-bottom pb-3 mb-4">
                        <h4 className="text-neutral font-16">Chains</h4>
                      </div>
                      <div className="chainscontainer">
                        {chainState.map((item) => (
                          <InputCheckbox
                            key={`chain-${item.id}`}
                            name={`chain-${item.id}`}
                            onChange={filterByChainHndlr}
                            checked={filterState.chain?.includes(
                              item.id?.toString()
                            )}
                            checkboxClassName="checbox-control-sm"
                          >
                            <span className="text-neutral font-16 font-semibold">
                              {item.name}
                            </span>
                          </InputCheckbox>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="closefilterbtn-container">
                  <button
                    onClick={closeFilterModalHndlr}
                    className="closefilterbtn text-dark"
                  >
                    Close Filter Modal
                  </button>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-9 custom-column">
                <div className="airdropscard-container">
                  {airdropsState.length === 0 &&
                    isFetchingAirdrops &&
                    [1, 2, 3, 4].map((item) => (
                      <div key={`loading-${item}`} className="card-container">
                        <AirdropLoadingCard containerClassName="mb-4" />
                      </div>
                    ))}

                  {Array.isArray(airdropsState) &&
                    airdropsState.map((item) => (
                      <div
                        key={`airdrop-${item.id}`}
                        className="card-container"
                      >
                        <AirdropCard
                          title={item?.name}
                          description={item?.introduction}
                          shareUrl=""
                          likes={item?.likes}
                          tasks={item?.task_count}
                          tokens={item?.rewards}
                          airdropImage={item?.image}
                          bgColor={item?.banner_color}
                          slug={item?.slug}
                          redirectHandler={redirectHandler}
                        />
                      </div>
                    ))}
                </div>
                {!isFetchingAirdrops && airdropsState.length === 0 && (
                  <NoDataComponent
                    title="No data found."
                    subTitle="Adjust filters for Airdrop type and Chains to get the airdrops."
                  />
                )}
                <div className="center">
                  {enableLoaderMore > 0 && (
                    <button
                      disabled={isFetchingAirdrops}
                      onClick={loadMoreHandler}
                      className="sc-button style-4"
                    >
                      {filterAction === 'LOADMORE' ? (
                        <Bars color="#ffffff" height={30} width={80} />
                      ) : (
                        'Load More'
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="mb-5">
            <div className="row custom-row">
              <div className="col-12 col-sm-8 col-md-6 col-lg-4 custom-column mb-4">
                <JoinTelegram />
              </div>
              <div className="col-12 col-sm-8 col-md-6 col-lg-4 custom-column mb-4">
                <SubscribeNewsletter />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

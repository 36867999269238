import React from "react";
import { nodata_image } from '../assets/Index';

export default function NoDataComponent({ title, subTitle, children }) {
    return (
        <div className="nodata-container">
            <img src={nodata_image} className="empty-img" alt="nodata-img" />
            <h4 className="nodata-title">
                {title}
            </h4>
            <span className="nodata-message">
                {subTitle}
            </span>
            {children}
        </div>
    )
}
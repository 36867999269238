import classNames from 'classnames';
import { noImageFound } from '../../../assets/Index';
import { Link } from 'react-router-dom';
import {
  getDisplayValueWithNA,
  getTransactionClassName,
  getTransactionStatus,
} from '../../../utility/commonUtitlity';
import moment from 'moment';

function replaceImage(error) {
  error.target.src = noImageFound;
}

function WalletInfo({ walletImg, title, subTitle }) {
  return (
    <div className="asset-details">
      <img
        src={walletImg || noImageFound}
        className="avatar-img"
        onError={replaceImage}
      />
      <div className="name-container">
        <span title={title} className="mb-0 fw-bold title">
          {title}
        </span>
        {subTitle && (
          <span title={subTitle} className="subtitle">
            #{subTitle}
          </span>
        )}
      </div>
    </div>
  );
}
export function generateColumns(walletAddress, chainIdFromUrl) {
  return [
    {
      id: 'assets',
      Header: 'Assets',
      disableSortBy: true,
      columnClassName: 'max-w-250px',
      Cell: ({ row: { original } }) => {
        const { nft } = original,
          { name, collection, token_id, contract_address } = nft || {},
          displayName = name || collection?.name,
          walletImage = nft.image;
        return (
          <Link
            to={`/item-info/${contract_address}/${token_id}/${chainIdFromUrl}`}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <WalletInfo
              walletImg={walletImage}
              title={getDisplayValueWithNA(displayName)}
              subTitle={token_id}
            />
          </Link>
        );
      },
    },
    {
      id: 'type',
      Header: 'Type',
      accessor: 'type',
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        const { event, receiver, sender } = original;

        let statusText = getTransactionStatus(
            event,
            walletAddress,
            sender?.address || '',
            receiver?.addres || ''
          ),
          className = getTransactionClassName(statusText);
        return (
          <div className="d-flex">
            <span
              className={classNames(
                'transaction-label text-uppercase',
                className
              )}
            >
              {statusText}
            </span>
          </div>
        );
      },
    },
    {
      id: 'timestamp',
      Header: 'Date',
      accessor: 'timestamp',
      disableSortBy: true,
      // columnClassName: 'w-120px min-w-120px',

      Cell: ({ row: { original } }) => {
        const { time } = original;
        const dateInfo = time
            ? `${moment.unix(time).utc().format('MMM DD, YYYY | hh:mm A')} +UTC`
            : 'n/a',
          timeInfo = `${moment.unix(time).utc().fromNow(true)} ago`;
        const capitalizedTimeFromNow = time
          ? timeInfo.charAt(0).toUpperCase() + timeInfo.slice(1)
          : 'n/a';
        return (
          <div className="d-flex flex-column text-nowrap max-w-none">
            <span className="lh-title">{capitalizedTimeFromNow}</span>
            <span className="font-14 lh-title text-muted">{dateInfo}</span>
          </div>
        );
      },
    },
  ];
}

{
  /* <div className='d-flex'>
          <img src={icon} className='transaction-icon mr-1' />
          <span>{actionText}</span>
        </div>; */
}

export function getTotalCollectionData(summaryData) {
  const { walletCollections } = summaryData,
    { totalCount, edges } = walletCollections || {},
    images = [];
  if (Array.isArray(edges)) {
    for (let i = 0; i < edges.length; i++) {
      const { node } = edges[i] || {};
      if (images.length === 5) {
        break;
      }
      if (Array.isArray(node?.collection?.image)) {
        // images.push(node?.collection?.image[0]?.url);
        images.push({
          address: node?.collection?.address,
          imageUrl: node?.collection?.image[0]?.url,
        });
      } else {
        images.push({
          address: node?.collection?.address,
          imageUrl: null,
        });
      }
    }
  }
  const extraCollections =
    Number(totalCount) > images.length ? Number(totalCount) - images.length : 0;
  return { totalCount: totalCount || 0, images, extraCollections };
}

export function getTotalNFTData(summaryData) {
  const { walletNFTs } = summaryData,
    { totalCount, edges } = walletNFTs || {},
    images = [];
  if (Array.isArray(edges)) {
    for (let i = 0; i < edges.length; i++) {
      const { node } = edges[i] || {};
      if (images.length === 8) {
        break;
      }
      if (Array.isArray(node?.nft?.uploads)) {
        // images.push(node?.nft?.uploads[node?.nft?.uploads.length - 1]?.url);
        images.push({
          imageUrl: node?.nft?.uploads[node?.nft?.uploads.length - 1]?.url,
          contractAddress: node?.nft?.contractAddress,
          tokenId: node?.nft?.tokenId,
        });
      } else {
        images.push({
          imageUrl: null,
          contractAddress: node?.nft?.contractAddress,
          tokenId: node?.nft?.tokenId,
        });
      }
    }
  }
  return { totalCount: totalCount || 0, images };
}

export function getENSCollectionData(summaryData) {
  const { ens } = summaryData,
    { edges, pageInfo, totalCount } = ens || {};
  const ensList = [];
  if (Array.isArray(edges)) {
    for (let i = 0; i < edges.length; i++) {
      const { nft } = edges[i]?.node || {},
        { uploads } = nft;
      if (ensList.length === 3) {
        break;
      }
      const imageUrl = Array.isArray(uploads)
        ? uploads[uploads.length - 1]
        : {};
      ensList.push(Object.assign(nft, { imageUrl }));
    }
  }
  return { data: ensList, pageInfo, totalCount };
}

export function getRecentTransactionData(summaryData) {
  const { latest_transaction } = summaryData || {},
    { tokenEvents } = latest_transaction || {},
    { edges, pageInfo, totalCount } = tokenEvents || {};
  const transactionList = [];
  if (Array.isArray(edges)) {
    for (let i = 0; i < 7; i++) {
      if (edges[i]) transactionList.push(edges[i]?.node);
    }
  }
  return { data: transactionList, pageInfo, totalCount };
}

export const transactionData = [
  {
    walletName: 'Final Robbers',
    walletInfo: 'Ticket #103',
    transfer: true,
  },
  {
    walletName: 'Final Robbers',
    walletInfo: 'Ticket #103',
    transfer: true,
  },
  {
    walletName: 'Final Robbers',
    walletInfo: 'Ticket #103',
    transfer: true,
  },
  {
    walletName: 'Final Robbers',
    walletInfo: 'Ticket #103',
    transfer: true,
  },
  {
    walletName: 'Final Robbers',
    walletInfo: 'Ticket #103',
    transfer: true,
  },
  {
    walletName: 'Final Robbers',
    walletInfo: 'Ticket #103',
    transfer: true,
  },
  {
    walletName: 'Final Robbers',
    walletInfo: 'Ticket #103',
    transfer: true,
  },
];

import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import routes from './pages/index';
import CookieConsent from 'react-cookie-consent';
import { useStoreActions } from 'easy-peasy';
import loadable from '@loadable/component';
import { apicallback } from './callbacks/index';
import Header from './components/header/Header';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
const Footer = loadable(() => import('./components/footer/Footer'));

//ToDo: Routes can be used to handle wagmi.
function App() {
  const sethomePageImage = useStoreActions(
    (actions) => actions.sethomePageImage
  );
  const history = useNavigate();
  useEffect(() => {
    let url = window.location.href;
    if (url.includes('https://anzali.io/')) {
      const newurl = url.replace('https://anzali.io', '');
      window.location.href = 'https://www.anzali.io' + newurl;
    }
  }, []);

  useEffect(() => {
    redirectRule();
    try {
      // AOS.init({
      //   duration: 2000,
      // });
      setBeatMode();
      const check = localStorage.getItem('session_ip');
      if (check == null) {
        fetch('https://ipinfo.io/json?token=216f503c2baebd')
          .then((response) => response.json())
          .then((jsonResponse) =>
            localStorage.setItem('session_ip', JSON.stringify(jsonResponse))
          );
      }
      //   if (getCookieConsentValue()) {
      loadJsOnItrection();
      // }
    } catch (error) {}
  }, []);

  function redirectRule() {
    const getPathName = window.location.pathname;
    if (getPathName === '/tracker') {
      history('/nft-wallet-tracker');
    } else if (getPathName === '/news') {
      history('/nft-news');
    } else if (getPathName === '/collections') {
      history('/nft-collections');
    } else if (getPathName === '/portfolio') {
      history('/nft-portfolio-tracker');
    }
    return;
  }

  function loadJsOnItrection() {
    if (process.env.REACT_APP_SERVER == 'prod') {
      const firebaseConfig = {
        apiKey: 'AIzaSyBbUmd0IGbJgdB3m_cfAElxUx0wQHBJqHk',
        authDomain: 'anzali-79c2d.firebaseapp.com',
        projectId: 'anzali-79c2d',
        storageBucket: 'anzali-79c2d.appspot.com',
        messagingSenderId: '735175928062',
        appId: '1:735175928062:web:3262375440f40b60361daf',
        measurementId: 'G-K9Y6BBH586',
      };
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      // Initialize Firebase Analytics (optional)
      getAnalytics(app);
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script', 'hxqxae2x9s');
    }
  }

  const setBeatMode = async () => {
    const response = await apicallback('content', '/homepage', {}, null, null);
    if (response && response.status == 'success') {
      sethomePageImage(response.data);
    }
  };

  return (
    <>
      <Header />
      <div className="flex-div">
        <Routes>
          {routes.map((data, index) => (
            <Route
              onUpdate={() => window.scrollTo(0, 0)}
              exact={true}
              path={data.path}
              element={data.component}
              key={`index-${index}`}
            />
          ))}
        </Routes>
      </div>
      <Footer />
      <CookieConsent
        expires={150}
        enableDeclineButton
        buttonText="Accept All Cookies"
        onDecline={() => {
          window.location.href = '/privacy-policy';
          document.cookie =
            'CookieConsent=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }}
        onAccept={() => {
          loadJsOnItrection();
        }}
        declineButtonText="Cookies Settings"
        declineButtonStyle={{ background: '#000000' }}
        // style={{ background:"#000000bf" }}
      >
        <span style={{ fontSize: '1.5rem' }}>
          By clicking "Accept All Cookies", you agree to the storing of cookies
          on your device to enhance site <br /> navigation, analyze site usage
          and assist in improving your experience.
        </span>
      </CookieConsent>
    </>
  );
}

export default App;

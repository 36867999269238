import { home_iphone_14, app_store, play_store } from '../../assets/Index';
import { openUrl } from '../../callbacks';
export default function Section7() {
  return (
    <section className="section-8">
      <div className="home-custom-container">
        <div className="row">
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <h4>
              Download the ANZALI 
              <br /> Mobile Apps
            </h4>
            <p className="mt-4">Download now from</p>
            <div className="links mt-3">
              <img
                src={app_store}
                className="mr-3"
                onClick={() =>
                  openUrl(
                    'https://apps.apple.com/us/app/anzali-web3-wallet-tracker/id1604822464'
                  )
                }
              />
              <img
                src={play_store}
                onClick={() =>
                  openUrl(
                    'https://play.google.com/store/apps/details?id=com.nftchills&hl=en_IN&gl=US'
                  )
                }
              />
            </div>
          </div>
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <img src={home_iphone_14} className="w-100" />
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useMemo, useState } from 'react';
import { generateColumns } from './helper';
import Table from '../../components/table/Table';
import { useEffect } from 'react';
//API CALL
import { getMintCollectionAction } from '../../apis/Index';

export default function MinitingCollection() {
  const [mintCollectionState, setMintCollectionState] = useState([]);
  const [isLoadingState, setIsLoadingState] = useState(false);
  useEffect(() => {
    async function getMintCollectionApiCall() {
      try {
        let response = null;
        setIsLoadingState(true);
        response = await getMintCollectionAction();
        const { data } = response;
        if (Array.isArray(data)) {
          setMintCollectionState(data.slice(0, 10));
        }
        setIsLoadingState(false);
      } catch (e) {
        setIsLoadingState(false);
      }
    }
    getMintCollectionApiCall();
  }, []);
  const mintingColumns = useMemo(() => generateColumns(), []);
  return (
    <div className="home-custom-container">
      <section id="minting-collection" className="minting-section section-7">
        <div className="row">
          <div className="col-12">
            <h4 className="mb-3 mb-lg-5">Minting Collections</h4>
          </div>
          <div className="col-12">
            <Table
              columns={mintingColumns}
              data={
                Array.isArray(mintCollectionState) ? mintCollectionState : []
              }
              isDataLoading={isLoadingState}
              tableClass="card-table"
              loadingMessage="Loading Mint Collections."
            />
          </div>
        </div>
      </section>
    </div>
  );
}

import React from 'react';
import { Modal } from 'react-bootstrap';
const SupportWarningModal = (props) => {
    return (
        <Modal show={props.show} onHide={props.onHide} centered>
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body space-y-20 pd-40">
                <h3 style={{ lineHeight: 1.2 }}>We currently support wallets with less than 25,000 transactions.
                    We're working on improving this limit for future versions.</h3>
            </div>
        </Modal>
    );
};

export default SupportWarningModal;

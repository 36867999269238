import React, { useState, useRef } from 'react';
import { copyclipboard_icon } from '../../assets/Index';
import { copyFromClipboard } from '../../callbacks/index';
import { home_new_top, home_mobile } from '../../assets/Index';
import { FiSearch, MdHistory } from '../../components/icons/Index';
import { Bars } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { openToasterAlert } from '../../components/alert/Index';
import { apicallback } from '../../callbacks/index';
import WAValidator from 'multicoin-address-validator';
import { chainList } from '../../utility/commonUtitlity';
export default function SectionFirst() {
  const [chainId, setChainId] = useState('1');
  const [searchLoader, setSearchLoader] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const authorization = useStoreState((state) => state.authorization);
  function searchWalletHndlr(event) {
    const { value } = event.target;
    let updatedValue = value.toLowerCase();
    setWalletAddress(updatedValue.replace(' ', ''));
  }

  const formSubmit = useRef(null);
  const history = useNavigate();
  const setWalletSummaryActiveTabId = useStoreActions(
    (actions) => actions.setWalletSummaryActiveTabId
  );
  const copyFromClipboardNew = (id) => {
    navigator.clipboard.readText().then((copiedText) => {
      setWalletAddress(copiedText);
    });
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    let valid;
    let is_ens = false;
    if (walletAddress.endsWith('.eth')) {
      valid = is_ens = true;
    } else {
      valid = WAValidator.validate(
        walletAddress,
        chainList(chainId).toLowerCase()
      );
    }

    if (valid) {
    } else {
      openToasterAlert(
        'error',
        'Wallet address is not valid for selected chain. Please check the address and chain selected and try again.'
      );
      return;
    }
    setSearchLoader(true);
    formSubmit.current.click();
    if (authorization) {
      try {
        const response = await apicallback(
          null,
          '/createWalletsV2',
          {
            name: `Wallet`,
            user_id: authorization.data.id.toString(),
            address: walletAddress,
            hexCode: '#fec60d',
            chain_id: is_ens ? 1 : chainId,
          },
          'private',
          authorization.token
        );
        if (response && response.status == 'success') {
          localStorage.setItem('is_recent_search', 1);
          setWalletSummaryActiveTabId();
          history(
            `/wallet-info/${response.data.wallet_id}/${response.data.address}/${chainId}`
          );
        } else {
          setSearchLoader(false);
          openToasterAlert(
            'error',
            response.message ? response.message : 'Wallet address is not valid.'
          );
        }
      } catch (error) {
        setSearchLoader(false);
        openToasterAlert('error', 'Wallet address is not valid.');
      }
    } else {
      handelPublicWalletSubmit();
    }
  };

  const handelPublicWalletSubmit = async () => {
    if (walletAddress != '') {
      const response = await apicallback(
        null,
        `/convertAddress?address=${walletAddress}`,
        {},
        null,
        null,
        'get'
      );

      if (response && response.status == 'success') {
        localStorage.setItem('is_recent_search', 1);
        let c = {};
        const address = response?.data?.address;
        c[address] = {
          wallet_id: Object.keys(c).length + 1,
          name: `Wallet ${Object.keys(c).length + 1}`,
          address: address,
          collection_count: 0,
          nft_count: 0,
          ens_count: 0,
          chain_id: chainId,
        };
        localStorage.setItem('open_wallets', JSON.stringify(c));
        setSearchLoader(false);
        setWalletSummaryActiveTabId();
        history(`/wallet-info/${c[address].wallet_id}/${address}/${chainId}`);
      } else {
        setSearchLoader(false);
        openToasterAlert('error', 'Wallet address is not valid.');
      }
    } else {
      setSearchLoader(false);
    }
  };

  function searchWalletHndlr(event) {
    const { value } = event.target;
    let updatedValue = value;
    setWalletAddress(updatedValue.replace(' ', ''));
  }

  function redirectTrackerHandler() {
    history(`/nft-wallet-tracker`);
  }

  return (
    <section className="section-1">
      <div className="w-100">
        <div className="text-center w-100">
          {' '}
          <span className="button-1">#1 Wallet Tracker</span>
        </div>
        <div
          className="d-flex justify-content-center flex-column mt-md-5 mt-2 row"
          id="tracker-form"
        >
          <div className="col-lg-6 col-md-8 col-sm-12 mx-auto">
            <h3 className="text-center my-5">
              Web3 Wallet Tracker
              <br />
              Powered by <span>Chat GPT</span>
            </h3>
            <form
              noValidate="novalidate"
              className="w-100 search-"
              onSubmit={(e) => handelSubmit(e)}
            >
              <div className="mb-4 d-flex justify-content-center  chain-list">
                {Object.entries(chainList()).map(([k, v]) => (
                  <span
                    key={k}
                    className={`${chainId == k ? 'active' : ''} mr-2`}
                    onClick={() => setChainId(k)}
                  >
                    {v}
                  </span>
                ))}
              </div>
              <div className="searchwallet-container">
                <input
                  type="text"
                  id="walletAddress"
                  name="walletAddress"
                  className="search-input size-large"
                  placeholder="Enter any wallet address…"
                  value={walletAddress}
                  onChange={searchWalletHndlr}
                  required
                  spellCheck={false}
                />
                <button
                  type="submit"
                  ref={formSubmit}
                  className="searching-btn-container"
                  aria-label="search"
                >
                  {searchLoader ? (
                    <Bars color="#ffffff" height={20} width={20} />
                  ) : (
                    <FiSearch />
                  )}
                </button>
              </div>

              <div
                className={
                  localStorage.getItem('is_recent_search')
                    ? 'd-flex flex-column mt-4 w-100 justify-content-center'
                    : 'text-center w-100 mt-4'
                }
              >
                {localStorage.getItem('is_recent_search') ? (
                  <div className="w-100 d-flex justify-content-center">
                    <button
                      className="custom-button border-light-btn recentsearch-btn  mt-2"
                      onClick={redirectTrackerHandler}
                      aria-label="Recently"
                    >
                      <MdHistory className="text-white mr-1" /> Recently
                      Searched Wallets
                    </button>
                  </div>
                ) : (
                  ''
                )}
                <span
                  className="d-flex align-items-center justify-content-center cursor-pointer mt-4"
                  onClick={() => copyFromClipboardNew('wallet-address')}
                >
                  <img
                    src={copyclipboard_icon}
                    style={{ width: '15px' }}
                    alt="Anzali Search"
                  />{' '}
                  <span>Paste from clipboard</span>
                </span>
              </div>
            </form>
          </div>
          <div className=" col-md-8 col-sm-12 mx-auto">
            <img src={home_new_top} className="w-100 mt-5 d-none d-md-block" />
            <img src={home_mobile} className="w-100 mt-5 d-block d-md-none" />
          </div>
        </div>
      </div>
    </section>
  );
}

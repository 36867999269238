import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../assets/main/scss/component/_login_sign_up.scss';
import { video_lock } from '../../assets/Index';
const LoginSignUp = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      centered
      className="verify-login-modal"
    >
      <Modal.Header closeButton className="p-0"></Modal.Header>
      <div className="modal-body text-center">
        <img src={video_lock} />
        {/* <h3>{props.text}</h3> */}
        <h3>Sign Up or Sign In to Continue</h3>
        <div className="d-flex flex-column justify-content-between">
          <Link
            className="sc-button style-4 my-4 d-flex justify-content-center align-item-center"
            to="/sign-up"
            state={{ prevPath: props.url ? window.location.pathname : null }}
          >
            <span className="pl-0 text-dark">Sign Up</span>
          </Link>
          <Link
            to="/sign-in"
            state={{ prevPath: props.url ? window.location.pathname : null }}
            className="sc-button style-1 d-flex justify-content-center align-item-center"
          >
            <span className="pl-0">Sign In</span>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default LoginSignUp;

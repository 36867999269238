import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import ReactDatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";

function convertDate(inputFormat) {
  if (!inputFormat) {
    return undefined;
  }
  function pad(s) {
    return s < 10 ? '0' + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
}

export default function Datepicker(props) {
  const [startDate, setStartDate] = useState(undefined);
  const selectedDateFormat = useRef('');
  useEffect(() => {
    if (props.value !== selectedDateFormat.current) {
      let validDateObj = undefined;
      if (props.value) {
        const dateString = props.value;
        const dateParts = dateString.split('-');
        validDateObj = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      }
      setStartDate(validDateObj);
    }
    selectedDateFormat.current = props.value;
  }, [props.value]);

  function onChangeDateHndlr(dateSelected, event) {
    setStartDate(dateSelected);
    if (dateSelected) {
      const returnDateFormat = convertDate(dateSelected);
      selectedDateFormat.current = returnDateFormat;
      props.onChange(props.name, returnDateFormat, event);
    }
  }
  return (
    <div className={props.containerClassName}>
      <label className={classNames('form-label', props.labelClassName)}>
        {props.labelName}
        {props.isOptional && (
          <span className="helper-label font-14"> (Optional)</span>
        )}
      </label>
      <ReactDatePicker
        id={props.name}
        name={props.name}
        wrapperClassName="w-100"
        dateFormat="MM-dd-yyyy"
        isClearable={true}
        className={classNames('custom-input-control', {
          'in-valid': props.invalid,
        })}
        autoComplete="off"
        selected={startDate}
        onChange={onChangeDateHndlr}
        // onBlur={props.onBlur}
      />
      {props.invalid && (
        <div className="custom-invalid-error">{props.error}</div>
      )}
    </div>
  );
}

import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import moment from 'moment';
import { apicallback } from '../../callbacks/index';
import { useStoreState, useStoreActions } from 'easy-peasy';
import NoDataComponent from "../../components/NoDataComponent";
import LineChart from "../../components/Chart/LineChart";
import { LoadingValue, LoadingContainer, COLLECTION_DETAIL_API_RETRY_COUNT, COLLECTION_DETAIL_RETRY_SECOND } from "./helper";
//components
import { getCollectionsApiCall } from "./helper";
import { getNumberWithComma, getValueWithDecimal, getPriceValue } from "../../utility/commonUtitlity";
import { looksrare_logo } from '../../assets/Index';

export default function LooksRare({ walletAddress }) {
    const collectionData = useStoreState((state) => state.collectionData);
    const authorization = useStoreState((state) => state.authorization);
    const setCollectionDataAct = useStoreActions((actions) => actions.setCollectionDataAct);

    const collectionFloorPriceChartResp = useStoreState((state) => state.collectionFloorPriceChartResp);
    const setCollectionFloorPriceChartAct = useStoreActions((actions) => actions.setCollectionFloorPriceChartAct);

    const collectionHolderChartResp = useStoreState((state) => state.collectionHolderChartResp);
    const setCollectionHolderChartAct = useStoreActions((actions) => actions.setCollectionHolderChartAct);


    const [lookRareState, setLookRareState] = useState({});
    const [floorPriceChartData, setFloorPriceChartData] = useState({
        categories: [],
        data: []
    });
    const [holderChartData, setHolderChartData] = useState({
        categories: [],
        data: []
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingChart, setIsLoadingChart] = useState(true);
    const [isLoadingHoldingChart, setIsLoadingHoldingChart] = useState(true);

    const collectionApiIntervalRef = useRef(null);
    const retryCountRef = useRef(0);

    useEffect(() => {
        if (collectionData) {
            const { looks_rare_data } = collectionData,
                { json } = looks_rare_data || {};
            setLookRareState({
                holders: looks_rare_data?.holders,
                floorPrice: looks_rare_data?.floor_price,
                totalSupply: json?.data?.totalSupply,
                marketCap: json?.data?.marketCap,
                volume: json?.data?.volumeAll,
                averageAll: json?.data?.averageAll
            });
        } else if (!collectionData && authorization && walletAddress) {
            getCollectionsApi();
            collectionApiIntervalRef.current = setInterval(async () => {
                getCollectionsApi();
            }, COLLECTION_DETAIL_RETRY_SECOND);
        }

        return () => {
            clearInterval(collectionApiIntervalRef.current);
            collectionApiIntervalRef.current = null;
        }
    }, [collectionData, collectionFloorPriceChartResp, collectionHolderChartResp,
        authorization, walletAddress]);

    useEffect(() => {
        if (collectionFloorPriceChartResp) {
            const { LooksRare } = collectionFloorPriceChartResp;
            if (Array.isArray(LooksRare)) {
                const categories = [],
                    data = [];
                LooksRare.forEach((item) => {
                    const { created_at, floor_price } = item,
                        updatedDate = moment(created_at).utc().format('MMM DD, YYYY hh:mm A'),
                        updatedValue = getValueWithDecimal(floor_price);
                    categories.push(updatedDate);
                    data.push(updatedValue);
                });
                setFloorPriceChartData({
                    categories,
                    data: [{ name: "Floor Price", data: data }]
                })
            }
            setIsLoadingChart(false);
        } else if (collectionData && !collectionFloorPriceChartResp) {
            getFloorChartApiCall();
        }
    }, [collectionFloorPriceChartResp, collectionData]);

    useEffect(() => {
        if (collectionHolderChartResp) {
            const { LooksRare } = collectionHolderChartResp;
            if (Array.isArray(LooksRare)) {
                const categories = [],
                    data = [];
                LooksRare.forEach((item) => {
                    const { created_at, holders } = item,
                        updatedDate = moment(created_at).utc().format('MMM DD, YYYY hh:mm A'),
                        updatedValue = getValueWithDecimal(holders);
                    categories.push(updatedDate);
                    data.push(updatedValue);
                });
                setHolderChartData({
                    categories,
                    data: [{ name: "Holder", data: data }]
                })
            }
            setIsLoadingHoldingChart(false);
        } else if (!collectionHolderChartResp && collectionData) {
            getHolderChartApiCall();
        }
    }, [collectionHolderChartResp, collectionData]);

    async function getCollectionsApi() {
        try {
            setIsLoading(true);
            const response = await apicallback(null, '/fetchCollectionData', { collection_address: walletAddress, }, 'private', authorization.token);
            if (response.status === 'success') {
                if (response?.data?.is_loading) {
                    if (retryCountRef.current === COLLECTION_DETAIL_API_RETRY_COUNT) {
                        clearInterval(collectionApiIntervalRef.current);
                        collectionApiIntervalRef.current = null;
                        return;
                    }
                    retryCountRef.current = +retryCountRef.current + 1;
                    return;
                }
                clearInterval(collectionApiIntervalRef.current);
                collectionApiIntervalRef.current = null;
                setCollectionDataAct(response.data);
                setIsLoading(false);
            }
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong");
            clearInterval(collectionApiIntervalRef.current);
            collectionApiIntervalRef.current = null;
            setIsLoading(false);
        }
    }

    async function getFloorChartApiCall() {
        try {
            setIsLoadingChart(true);
            const response = await apicallback(
                null,
                '/getCharts',
                {
                    collection_address: walletAddress,
                    type: 1,
                },
                'private',
                authorization.token
            );
            if (response.status === 'success') {
                setCollectionFloorPriceChartAct(response.data);
            }
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong");
        } finally {
            setIsLoadingChart(false);
        }
    }

    async function getHolderChartApiCall() {
        try {
            setIsLoadingHoldingChart(true);
            const response = await apicallback(
                null,
                '/getCharts',
                {
                    collection_address: walletAddress,
                    type: 0,
                },
                'private',
                authorization.token
            );
            if (response.status === 'success') {
                setCollectionHolderChartAct(response.data);
            }
        } catch (e) {
            console.log(e);
            toast.error("Something went wrong");
        } finally {
            setIsLoadingHoldingChart(false);
        }
    }

    const showNoDataComponent = !isLoading && !(collectionData?.looks_rare_data);
    return (
        <section className="lockrare-container">
            {showNoDataComponent ? <NoDataComponent
                title="No LooksRare Data Found."
            /> :
                <React.Fragment>
                    <div className="row custom-row labelinfo-cardcontainer mb-4">
                        <div className="col-6 col-lg-3 custom-column">
                            <div className="labelinfo-card">
                                <h6 className="label-info">Holders</h6>
                                <LoadingValue isLoading={isLoading}>
                                    <p className="value-info">
                                        {getNumberWithComma(lookRareState.holders)}
                                    </p>
                                </LoadingValue>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 custom-column">
                            <div className="labelinfo-card">
                                <h6 className="label-info">
                                    Floor Price
                                </h6>
                                <LoadingValue isLoading={isLoading}>
                                    <p className="value-info">
                                        {getValueWithDecimal(lookRareState.floorPrice)} ETH
                                    </p>
                                </LoadingValue>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 custom-column">
                            <div className="labelinfo-card">
                                <h6 className="label-info">Total Supply</h6>
                                <LoadingValue isLoading={isLoading}>
                                    <p className="value-info">
                                        {getNumberWithComma(lookRareState.totalSupply)}
                                    </p>
                                </LoadingValue>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 custom-column">
                            <div className="labelinfo-card">
                                <h6 className="label-info">Market Cap</h6>
                                <LoadingValue isLoading={isLoading}>
                                    <p className="value-info">
                                        {getPriceValue(lookRareState.marketCap)}  ETH
                                    </p>
                                </LoadingValue>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 custom-column">
                            <div className="labelinfo-card">
                                <h6 className="label-info">Volume</h6>
                                <LoadingValue isLoading={isLoading}>
                                    <p className="value-info">
                                        {getPriceValue(lookRareState.volume)} ETH
                                    </p>
                                </LoadingValue>
                            </div>
                        </div>
                        <div className="col-6 col-lg-3 custom-column">
                            <div className="labelinfo-card">
                                <h6 className="label-info">Average All</h6>
                                <LoadingValue isLoading={isLoading}>
                                    <p className="value-info">
                                        {getPriceValue(lookRareState.averageAll)} ETH
                                    </p>
                                </LoadingValue>
                            </div>
                        </div>
                    </div>
                    <div className="row custom-row labelinfo-cardcontainer mb-5">
                        <div className="col-12 col-lg-6 custom-column">
                            <div className="chart-container">
                                {isLoadingChart ? <LoadingContainer
                                    containerClassName="min-h-350px"
                                /> :
                                    <LineChart
                                        title="Floor Price Chart"
                                        xAxisTitle="Date"
                                        yAxisTitle="Floor price (ETH)"
                                        categories={floorPriceChartData.categories}
                                        seriesData={floorPriceChartData.data}
                                    />
                                }
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 custom-column">
                            <div className="chart-container">
                                {isLoadingHoldingChart ? <LoadingContainer
                                    containerClassName="min-h-350px"
                                /> :
                                    <LineChart
                                        title="Holders Chart"
                                        xAxisTitle="Date"
                                        yAxisTitle="Holders"
                                        categories={holderChartData.categories}
                                        seriesData={holderChartData.data}
                                    />
                                }
                            </div>
                        </div>

                    </div>
                    <div className="poweredby-container">
                        <span className="mr-2">Floor Prices Powered by</span>
                        <img className="logo-icon" src={looksrare_logo} />
                    </div>
                </React.Fragment>}
        </section>
    )
}
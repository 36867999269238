import {
  wallet_front,
  home_color_lens,
  home_email_notification,
  home_open_ai,
  home_time_circle,
} from '../../assets/Index';
import { Link } from 'react-router-dom';
export default function Section5() {
  function Cards(values) {
    const { cls, icon, title, desc, link, btn_text } = values;
    return (
      <div className={cls}>
        <div className="section-2-card p-3 p-sm-4">
          <div className="icon">
            <img src={icon} />
          </div>
          <div className="mt-5 section-2-card-body">
            <h4>{title}</h4>
            <p dangerouslySetInnerHTML={{ __html: desc }} className="mt-4"></p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <section className="section-5">
      <div className="home-custom-container">
        <div className="row d-block d-sm-none">
          <div className="col-12 sub-heading d-flex flex-column justify-content-center mb-4">
            <div className="first mb-4 d-flex align-items-center">
              <img src={wallet_front} className="mr-2" />
              <span>Web3 Wallet Tracker</span>
            </div>
            <h4>
              The Most Advanced NFT Wallet Tracker for Traders & Collectors
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 mb-2 mb-sm-5">
            <div className="row">
              <div className="col-6 mb-4 px-2">
                <Cards
                  icon={home_open_ai}
                  title="Chat GPT Support"
                  desc="Get answered by Chat GPT on any question related to your searched wallet."
                />
              </div>
              <div className="col-6 mb-4 px-2">
                <Cards
                  icon={home_email_notification}
                  title="Email Alerts"
                  desc="Instant wallet transaction updates sent to your inbox with our real-time email alert feature."
                />
              </div>
              <div className="col-6 px-2">
                <Cards
                  icon={home_color_lens}
                  title="Customization"
                  desc="Customize wallets you track with unique labels and color coding."
                />
              </div>
              <div className="col-6 px-2">
                <Cards
                  icon={home_time_circle}
                  title="Real-Time Data"
                  desc="Stay up to date with the latest data to analyze real-time values of Wallets, Collections & NFTs."
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6 sub-heading d-flex flex-column justify-content-center">
            <div className="first mb-4 align-items-center d-none d-sm-flex">
              <img src={wallet_front} className="mr-2" />
              <span>Web3 Wallet Tracker</span>
            </div>
            <h4 className="d-none d-sm-block">
              The Most Advanced NFT Wallet Tracker<br/> for Traders & Collectors
            </h4>
            <p className="mt-4">
              Track any wallet address with precision, access in-depth
              analytics, and leverage Chat GPT for instant answers to your
              wallet-related queries. Stay connected to your wallet transactions
              by subscribing to wallet alerts, delivered directly to your
              mailbox.
            </p>
            <div>
              <Link className="sc-button style-4 mt-5" to="/nft-wallet-tracker">
                Try It Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

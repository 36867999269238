import React from 'react';
import moment from 'moment';
import './timeline.scss';
import { condenseAddress, copyClipboard } from '../../../callbacks/index';
import { getPriceValue } from '../../../utility/commonUtitlity';
import { document_copy_img, noImageFound } from '../../../assets/Index';
export default function Timeline({ data = [] }) {
  function getTransactionClassName(status) {
    const updatedStatus = status
      ? status
          .toString()
          .toLowerCase()
          .replace(/[\(\)\s]/g, '')
      : '';
    switch (updatedStatus) {
      case 'transfer':
        return 'purple';
      case 'sale':
        return 'red';
      case 'swap':
        return 'green';
      case 'mint':
        return 'mint';
      case 'burn':
        return 'red';
      default:
        return 'default';
    }
  }
  return (
    <React.Fragment>
      <div className="timeline">
        {data.map((element, key) => (
          <div
            className={`container-timeline ${key % 2 == 0 ? 'left' : 'right'}`}
          >
            <i
              className={`icon ${getTransactionClassName(
                element?.event
              )}-border`}
            ></i>
            <div className={`date ${getTransactionClassName(element?.event)}`}>
              {moment.unix(element.time).utc().fromNow(true)} ago
            </div>
            <div className="content">
              <div className="d-flex p-3">
                <div className="mr-3">
                  <img src={element?.nft?.image || noImageFound} />
                </div>
                <div className="w-100">
                  <div
                    className="d-flex justify-content-between"
                    style={{ borderBottom: '1px solid #3A3A3A7D' }}
                  >
                    <div className="mb-2 w-100">
                      <h5 className="text-nowrap-new">
                        {element?.nft?.token_id
                          ? '#' + element?.nft?.token_id
                          : 'N/A'}
                      </h5>
                      <span>{element?.name}</span>
                    </div>
                    <div>
                      <span
                        className={`badge ${getTransactionClassName(
                          element.event
                        )}`}
                      >
                        {element.event}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-2 info">
                    <div className="">
                      <p>From</p>
                      <h5
                        onClick={() =>
                          copyClipboard(element?.receiver?.address)
                        }
                      >
                        {condenseAddress(element?.receiver?.address, 3)}
                        {element?.receiver?.address ? (
                          <img
                            src={document_copy_img}
                            style={{
                              width: '18px',
                              height: '18px',
                              maxWidth: 'initial',
                            }}
                          />
                        ) : (
                          '-'
                        )}
                      </h5>
                    </div>
                    <div className="">
                      <p>To</p>
                      <h5
                        onClick={() => copyClipboard(element?.sender?.address)}
                      >
                        {condenseAddress(element?.sender?.address, 3)}
                        {element?.sender?.address ? (
                          <img
                            src={document_copy_img}
                            style={{
                              width: '18px',
                              height: '18px',
                              maxWidth: 'initial',
                            }}
                          />
                        ) : (
                          '-'
                        )}
                      </h5>
                    </div>
                    {/* <div className="">
                      <span className="badge">{element?.blockNumber}</span>
                    </div> */}
                  </div>
                </div>
              </div>
              <div
                className="d-flex justify-content-around align-items-center px-3 py-2 price"
                style={{ borderTop: '1.5px solid #3A3A3A7D' }}
              >
                <div className="text-center">
                  <p>PRICE</p>
                  <h4
                    className={
                      element?.price?.value > 0 ? 'text-success' : 'text-white'
                    }
                  >
                    {getPriceValue(element?.price?.value)}{' '}
                    <span className="text-muted">ETH</span>
                  </h4>
                </div>
                <div className="text-center">
                  <p>Date</p>
                  <h4>
                    {moment
                      .unix(element?.time)
                      .utc()
                      .format('MMM DD, YYYY | hh:mm A')}{' '}
                    +UTC
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

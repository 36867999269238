import React from 'react';
import {
  getNumberWithComma,
  getValueWithDecimal,
  displayAmountWithProfitLossIcon,
  getDisplayAmountClassName,
} from '../../utility/commonUtitlity';
import classNames from 'classnames';

export default function CollectionDetailsModal({
  collectionDetails,
  collectionInfoV2,
  chainName,
  chainId,
}) {
  return (
    <div className="row custom-row labelinfo-cardcontainer mb-4">
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          {chainId === 1 ? (
            <>
              {' '}
              <h6 className="label-info">Market Cap</h6>
              <p className="value-info">
                {getNumberWithComma(collectionDetails?.market_cap)} {chainName}
              </p>
            </>
          ) : (
            <>
              {' '}
              <h6 className="label-info">Holders</h6>
              <p className="value-info">{collectionInfoV2?.holder_num}</p>
            </>
          )}
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Num Owners</h6>
          <p className="value-info">
            {getNumberWithComma(collectionDetails?.num_owners)}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Num Reports</h6>
          <p className="value-info">
            {getNumberWithComma(collectionDetails?.num_reports)}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Total Sales</h6>
          <p className="value-info">
            {getNumberWithComma(collectionDetails?.total_sales)}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Total Supply</h6>
          <p className="value-info">
            {getNumberWithComma(collectionDetails?.total_supply)}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Total Volume</h6>
          <p className="value-info">
            {getNumberWithComma(collectionDetails?.total_volume)} {chainName}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Average Price</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionDetails?.average_price)} {chainName}
          </p>
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">24 Hour Average Price</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.avg_price_eth?.['24h'])}{' '}
            {chainName}
          </p>
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Seven Day Average Price</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.avg_price_eth?.['7d'])}{' '}
            {chainName}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Thirty Day Average Price</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.avg_price_eth?.['30d'])}{' '}
            {chainName}
          </p>
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">24 Hour Floor Price Change</h6>
          <p className="value-info">
            {getValueWithDecimal(
              collectionInfoV2?.floor_price_change_percentage?.['24h']
            )}{' '}
            %
          </p>
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Seven Day Floor Price Change</h6>
          <p className="value-info">
            {getValueWithDecimal(
              collectionInfoV2?.floor_price_change_percentage?.['7d']
            )}{' '}
            %
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Thirty Day Floor Price Change</h6>
          <p className="value-info">
            {getValueWithDecimal(
              collectionInfoV2?.floor_price_change_percentage?.['30d']
            )}{' '}
            %
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">24 Hour Liquidity</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.liquidity?.['24h'])}
          </p>
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Seven Day Liquidity</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.liquidity?.['7d'])}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Thirty Day Liquidity</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.liquidity?.['30d'])}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">24 Hour Transfers</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.transfer_num?.['24h'])}
          </p>
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Seven Day Transfers</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.transfer_num?.['7d'])}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Thirty Day Transfers</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.transfer_num?.['30d'])}
          </p>
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">24 Hour Buyers</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.buyer_num?.['24h'])}
          </p>
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Seven Day Buyers</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.buyer_num?.['7d'])}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Thirty Day Buyers</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.buyer_num?.['30d'])}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">24 Hour Seller</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.seller_num?.['24h'])}
          </p>
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Seven Day Seller</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.seller_num?.['7d'])}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Thirty Day Seller</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.seller_num?.['30d'])}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">24 Hour Trades</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.trader_num?.['24h'])}
          </p>
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Seven Day Trades</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.trader_num?.['7d'])}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Thirty Day Trades</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionInfoV2?.trader_num?.['30d'])}
          </p>
        </div>
      </div>
      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Royalty Income</h6>
          <p className="value-info">
            ${getValueWithDecimal(collectionInfoV2?.royalty_income?.['usd'])}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">One Day Sales</h6>
          <p className="value-info">
            {getNumberWithComma(collectionDetails?.one_day_sales)}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">One Day Change</h6>
          <p
            className={classNames(
              'value-info',
              getDisplayAmountClassName(collectionDetails?.one_day_change)
            )}
          >
            {displayAmountWithProfitLossIcon(collectionDetails?.one_day_change)}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">One Day Volume</h6>
          <p className="value-info">
            {getNumberWithComma(collectionDetails?.one_day_volume)} {chainName}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Seven Day Sales</h6>
          <p className="value-info">
            {getNumberWithComma(collectionDetails?.seven_day_sales)}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Seven Day Change</h6>
          <p
            className={classNames(
              'value-info',
              getDisplayAmountClassName(collectionDetails?.seven_day_change)
            )}
          >
            {displayAmountWithProfitLossIcon(
              collectionDetails?.seven_day_change
            )}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Seven Day Volume</h6>
          <p className="value-info">
            {getNumberWithComma(collectionDetails?.seven_day_volume)}{' '}
            {chainName}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Thirty Day Sales</h6>
          <p className="value-info">
            {getNumberWithComma(collectionDetails?.thirty_day_sales)}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Thirty Day Change</h6>
          <p
            className={classNames(
              'value-info',
              getDisplayAmountClassName(collectionDetails?.thirty_day_change)
            )}
          >
            {displayAmountWithProfitLossIcon(
              collectionDetails?.thirty_day_change
            )}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Thirty Day Volume</h6>
          <p className="value-info">
            {getNumberWithComma(collectionDetails?.thirty_day_volume)}{' '}
            {chainName}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">One Day Difference</h6>
          <p
            className={classNames(
              'value-info',
              getDisplayAmountClassName(collectionDetails?.one_day_difference)
            )}
          >
            {displayAmountWithProfitLossIcon(
              collectionDetails?.one_day_difference
            )}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">One Day Sales Change</h6>
          <p
            className={classNames(
              'value-info',
              getDisplayAmountClassName(collectionDetails?.one_day_sales_change)
            )}
          >
            {displayAmountWithProfitLossIcon(
              collectionDetails?.one_day_sales_change
            )}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Seven Day Difference</h6>
          <p
            className={classNames(
              'value-info',
              getDisplayAmountClassName(collectionDetails?.seven_day_difference)
            )}
          >
            {displayAmountWithProfitLossIcon(
              collectionDetails?.seven_day_difference
            )}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">One Day Average Price</h6>
          <p className="value-info">
            {getValueWithDecimal(collectionDetails?.one_day_average_price)}{' '}
            {chainName}
          </p>
        </div>
      </div>

      <div className="col-6 col-lg-3 custom-column">
        <div className="labelinfo-card">
          <h6 className="label-info">Thirty Day Difference</h6>
          <p
            className={classNames(
              'value-info',
              getDisplayAmountClassName(
                collectionDetails?.thirty_day_difference
              )
            )}
          >
            {displayAmountWithProfitLossIcon(
              collectionDetails?.thirty_day_difference
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

import React, { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { anzaliBuisnessLogo } from '../../../assets/Index';
import MembershipRequestModal from './MembershipRequestModal';

export default function DigitalMemeberShip() {
  const [showRequestModalState, setShowRequestModalState] = useState(false);

  function toggleRequestModalHndlr() {
    setShowRequestModalState(false);
  }

  function sendRequestHndlr() {
    setShowRequestModalState(true);
  }

  return (
    <React.Fragment>
      <section
        id="digital-membership-section"
        className="digitalmembership-container section-top-padding"
      >
        <div className="themesflat-container mb-4">
          <div className="d-flex justify-content-center">
            <div className="membership-container">
              <img
                src={anzaliBuisnessLogo}
                className="buisness-logo mb-5"
                alt="Anzali Buisness Logo"
              />
              <h2 className="mb-5 membership-title">
                Smart Contract and Web3 Development <br /> Services For Your Business
              </h2>
              <div className="membership-actions">
                <button
                  onClick={sendRequestHndlr}
                  className="custom-button border-btn background-dark"
                >
                  Send Request
                </button>
                {/* <button className="custom-button border-light-btn background-dark mx-2 mb-3">
                                        Learn More
                                    </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={showRequestModalState}
        onHide={toggleRequestModalHndlr}
        size="lg"
        centered
        dialogClassName="request-modal"
        className="request-modal-container"
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="membership-request-modal">
          <MembershipRequestModal closeModalHndlr={toggleRequestModalHndlr} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

import React, { useMemo, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { RiCloseLine } from 'react-icons/ri';
//Images
import { document_copy_img, noImageFound } from '../../assets/Index';
import { ReactComponent as TwitterXIcon } from '../../assets/images/icon/twitterx_icon.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useStoreState, useStoreActions } from 'easy-peasy';
import AnzaliLoader from '../../components/AnzaliLoader';
//components
import Tabs from '../../components/Tabs';
import {
  openUrl,
  copyClipboard,
  condenseAddress,
  saveMeta,
} from '../../callbacks/index';
import { BsFacebook } from 'react-icons/bs';
import {
  AiFillPlusCircle,
  AiFillRedditCircle,
  AiOutlineLink,
} from 'react-icons/ai';
import { tabsList, getCollectionData } from './helper';
import PageTitle from '../../components/pagetitle/PageTitle';

//Tab component
import Items from './Items';
import Audit from './Audit';
import LockRare from './LockRare';
import TradingActivity from './TradingActivity';
import Traits from './Traits';
import OpenSea from './OpenSea';
import CollectionDetailsModal from '../../components/CollectionInfo/CollectionDetailsModal';
import { apicallback } from '../../callbacks/index';
import {
  LoadingValue,
  COLLECTION_DETAIL_API_RETRY_COUNT,
  COLLECTION_DETAIL_RETRY_SECOND,
} from './helper';
import {
  getNumberWithComma,
  getValueWithDecimal,
} from '../../utility/commonUtitlity';

function getActiveMenuComponent(tabId) {
  switch (tabId) {
    case 'items':
      return Items;
    case 'traits':
      return Traits;
    case 'tradingActivity':
      return TradingActivity;
    case 'opensea':
      return OpenSea;
    case 'looksRare':
      return LockRare;
    case 'audit':
      return Audit;
    default:
      return null;
  }
}

function replaceImage(error) {
  //replacement of broken Image
  error.target.src = noImageFound;
}

export default function CollectionInfo() {
  const currentPageUrl = window.location.href;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [activeTableId, setActiveTabId] = useState('items');
  const { address: walletAddress, id: nftId } = useParams();
  const authorization = useStoreState((state) => state.authorization);
  const collectionData = useStoreState((state) => state.collectionData);
  const [collectionsInfo, setCollectionInfo] = useState(null);
  const [collectionDetailsState, setCollectionDetailsState] = useState(null);
  const [modalState, setModalState] = useState(false);

  const collectionApiIntervalRef = useRef(null);
  const retryCountRef = useRef(0);

  const setCollectionInfoTabAct = useStoreActions(
    (actions) => actions.setCollectionInfoTabAct
  );
  const setCollectionDataAct = useStoreActions(
    (actions) => actions.setCollectionDataAct
  );
  const setCollectionFloorPriceChartAct = useStoreActions(
    (actions) => actions.setCollectionFloorPriceChartAct
  );
  const setCollectionHolderChartAct = useStoreActions(
    (actions) => actions.setCollectionHolderChartAct
  );

  useEffect(() => {
    if (walletAddress) {
      getCollectionInfoApiCall();
    }

    return () => {
      setCollectionInfo(null);
      setCollectionInfoTabAct(null);
      setCollectionDataAct(null);
      setCollectionFloorPriceChartAct(null);
      setCollectionHolderChartAct(null);
    };
  }, [walletAddress]);

  useEffect(() => {
    if (collectionData) {
      const { open_sea_data } = collectionData,
        { collection } = open_sea_data || {},
        { stats } = collection || {};
      if (stats) {
        setCollectionDetailsState(stats);
      }
    } else if (!collectionData && authorization && walletAddress) {
      getCollectionsApi();
      collectionApiIntervalRef.current = setInterval(async () => {
        getCollectionsApi();
      }, COLLECTION_DETAIL_RETRY_SECOND);
    }
    return () => {
      clearInterval(collectionApiIntervalRef.current);
      collectionApiIntervalRef.current = null;
    };
  }, [collectionData, authorization, walletAddress]);

  async function getCollectionInfoApiCall() {
    try {
      setIsLoading(true);
      const payload = {
          contract_address: walletAddress,
          nft_id: nftId,
        },
        { token } = authorization || {};
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_ENDPOINT}/artblock-detail`,
        payload,
        {
          headers: authorization
            ? {
                'content-type': 'application/json',
                Authorization: `Token ${token}`,
              }
            : null,
        }
      );
      if (response.status === 200) {
        const { data } = response,
          { traits, chart, rarity_data } = data || {};
        const collectionInfo = getCollectionData(data);
        if (collectionInfo.name) {
          saveMeta({
            title: collectionInfo?.name + ' NFT Collection | ANZALI' || null,
            description: collectionInfo?.description || null,
            images: collectionInfo?.cardImgUrl || null,
            url: `/artblock-collection/${walletAddress}`,
            file: 'artblock',
          });
        }
        setCollectionInfo(collectionInfo);
        const storeTabData = {
          items: [],
          collectionInfo,
          traits: [],
          tradingData: [],
          rarityData: null,
        };
        if (Array.isArray(traits)) {
          storeTabData.traits = traits;
        }
        if (Array.isArray(chart)) {
          storeTabData.tradingData = chart;
        }
        if (rarity_data) {
          storeTabData.rarityData = rarity_data;
        }
        setCollectionInfoTabAct(storeTabData);
      }
    } catch (e) {
     
      toast.error('Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  }

  async function getCollectionsApi() {
    try {
      setIsLoadingDetails(true);
      const response = await apicallback(
        null,
        '/fetchCollectionData',
        { collection_address: walletAddress, is_artblock: true },
        'private',
        null
      );
      if (response.status === 'success') {
        if (response?.data?.is_loading) {
          if (retryCountRef.current === COLLECTION_DETAIL_API_RETRY_COUNT) {
            clearInterval(collectionApiIntervalRef.current);
            collectionApiIntervalRef.current = null;
            return;
          }
          retryCountRef.current = +retryCountRef.current + 1;
          return;
        }
        clearInterval(collectionApiIntervalRef.current);
        collectionApiIntervalRef.current = null;
        setCollectionDataAct(response.data);
        setIsLoadingDetails(false);
      }
    } catch (e) {
     
      toast.error('Something went wrong');
      clearInterval(collectionApiIntervalRef.current);
      collectionApiIntervalRef.current = null;
      setIsLoadingDetails(false);
    }
  }

  function tabChangeHandler(tabId) {
    if (tabId) {
      setActiveTabId(tabId);
    }
  }
  const activeComponent = useMemo(() => {
    const TabComponent = getActiveMenuComponent(activeTableId);
    if (TabComponent) {
      return (
        <TabComponent walletAddress={walletAddress} isLoading={isLoading} />
      );
    }
    return null;
  }, [activeTableId, collectionsInfo, walletAddress, isLoading]);

  function copyAddressHandler(event) {
    const walletAddress = event.currentTarget.getAttribute('data-id');
    if (walletAddress) {
      copyClipboard(walletAddress);
    }
  }

  const tabsOptions = useMemo(() => {
    if (!authorization) {
      return tabsList.filter(
        (item) =>
          item.id === 'items' ||
          item.id === 'traits' ||
          item.id === 'tradingActivity'
      );
    }
    return tabsList;
  }, [tabsList, authorization]);

  function toggleModalHndlr() {
    setModalState(false);
  }
  function showMoreHandler() {
    setModalState(true);
  }

  const isEnsItem =
    walletAddress === '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85';
  return (
    <React.Fragment>
      <PageTitle
        meta={{
          title: collectionsInfo?.name || 'Collection Details | ANZALI',
          description:
            collectionsInfo?.description ||
            'Add your NFTs to manage your portfolio and estimated real-time portfolio value.',
          image: collectionsInfo?.cardImgUrl || noImageFound,
        }}
      />
      {isLoading && <AnzaliLoader />}
      <section className="collectioninfo-container">
        <div
          className={classNames(
            'headerimg-container',
            isEnsItem ? 'custom-img' : ''
          )}
          style={
            isEnsItem
              ? {
                  background:
                    'linear-gradient(330.4deg, rgb(68, 188, 240) 4.54%, rgb(114, 152, 248) 59.2%, rgb(160, 153, 255) 148.85%)',
                }
              : { backgroundImage: `url(${collectionsInfo?.bannerUrl})` }
          }
        ></div>
        <div className="page-container">
          <div className="walletdetails-wrapper">
            <div className="walletdetails-container">
              <div className="walletbasic-container">
                <img
                  src={collectionsInfo?.cardImgUrl || noImageFound}
                  className="wallet-img border"
                  onError={replaceImage}
                />
                <div className="d-flex flex-column mb-3 w-100">
                  <h1
                    id="walletName"
                    onClick={() => copyClipboard(collectionsInfo?.name)}
                    className="wallet-name"
                  >
                    {collectionsInfo?.name}
                  </h1>
                  <ReactTooltip
                    anchorSelect={`#walletName`}
                    place="bottom"
                    className="tooltip-wrapper"
                    classNameArrow="tooltip-arrow"
                    offset={2}
                    openOnClick={true}
                    content={collectionsInfo?.name}
                  />
                  <div className="d-flex align-items-center">
                    <span className="mr-1 font-14">
                      {condenseAddress(collectionsInfo?.address)}
                    </span>
                    <img
                      onClick={copyAddressHandler}
                      data-id={collectionsInfo?.address}
                      src={document_copy_img}
                      style={{ width: '20px', height: '20px' }}
                    />
                  </div>
                </div>
              </div>
              <div className="header-actions mb-4">
                <a
                  href="#"
                  onClick={() =>
                    openUrl(
                      `https://www.facebook.com/sharer/sharer.php?u=${currentPageUrl}`
                    )
                  }
                  className="share-icon"
                >
                  <BsFacebook />
                </a>
                <a
                  href="#"
                  onClick={() =>
                    openUrl(
                      `http://www.reddit.com/submit?url=${currentPageUrl}`
                    )
                  }
                  className="share-icon"
                >
                  <AiFillRedditCircle />
                </a>
                <a
                  href="#"
                  onClick={() =>
                    openUrl(
                      `https://twitter.com/intent/tweet?url=${currentPageUrl}`
                    )
                  }
                  className="share-icon twitter-icon"
                >
                  <TwitterXIcon />
                </a>
                <a
                  href="#"
                  onClick={() => copyClipboard(currentPageUrl)}
                  className="share-icon"
                >
                  <AiOutlineLink />
                </a>
              </div>
            </div>
          </div>

          <div className="row custom-row labelinfo-cardcontainer mb-4">
            <div className="col-6 col-lg-3 custom-column">
              <div className="labelinfo-card">
                <h6 className="label-info">Items</h6>
                <LoadingValue isLoading={isLoadingDetails}>
                  <p className="value-info">
                    {getNumberWithComma(collectionDetailsState?.count) || '00'}
                  </p>
                </LoadingValue>
              </div>
            </div>
            <div className="col-6 col-lg-3 custom-column">
              <div className="labelinfo-card">
                <h6 className="label-info">Market Cap</h6>
                <LoadingValue isLoading={isLoadingDetails}>
                  <p className="value-info">
                    {getNumberWithComma(collectionDetailsState?.market_cap)} ETH
                  </p>
                </LoadingValue>
              </div>
            </div>
            <div className="col-6 col-lg-3 custom-column">
              <div className="labelinfo-card">
                <h6 className="label-info">Floor Price</h6>
                <LoadingValue isLoading={isLoading}>
                  <p className="value-info">
                    {getValueWithDecimal(collectionsInfo?.floorPrice)} ETH
                  </p>
                </LoadingValue>
              </div>
            </div>
            <div className="col-6 col-lg-3 custom-column">
              <div className="labelinfo-card">
                <h6 className="label-info">Total Volume</h6>
                <LoadingValue isLoading={isLoadingDetails}>
                  <p className="value-info">
                    {getNumberWithComma(collectionDetailsState?.total_volume) ||
                      0}{' '}
                    ETH
                  </p>
                </LoadingValue>
              </div>
            </div>
            <div className="col-6 col-lg-3 custom-column">
              <div className="labelinfo-card">
                <h6 className="label-info">24 Hr Volume</h6>
                <LoadingValue isLoading={isLoadingDetails}>
                  <p className="value-info">
                    {getNumberWithComma(
                      collectionDetailsState?.one_day_volume
                    ) || 0}{' '}
                    ETH
                  </p>
                </LoadingValue>
              </div>
            </div>
            <div className="col-6 col-lg-3 custom-column">
              <div className="labelinfo-card">
                <h6 className="label-info">Owners</h6>
                <LoadingValue isLoading={isLoadingDetails}>
                  <p className="value-info">
                    {getNumberWithComma(collectionDetailsState?.num_owners) ||
                      '00'}
                  </p>
                </LoadingValue>
              </div>
            </div>
            <div className="col-6 col-lg-3 custom-column">
              <div className="labelinfo-card">
                <h6 className="label-info">More Data</h6>
                <LoadingValue isLoading={isLoadingDetails}>
                  <p className="value-info">
                    <AiFillPlusCircle
                      onClick={showMoreHandler}
                      className="text-white vertical-middle"
                    />
                  </p>
                </LoadingValue>
              </div>
            </div>
          </div>
          <div className="border-bottom">
            <div className="row">
              <div className="col-12 col-lg-12 pb-3">
                <p className="lh-global font-14 collection-text">
                  {collectionsInfo?.description} <br />
                  {collectionsInfo?.externalUrl && (
                    <>
                      Visit &nbsp;
                      <a
                        target="_blank"
                        aria-label={collectionsInfo?.name}
                        href={collectionsInfo?.externalUrl}
                        className="text-decoration-none text-link"
                      >
                        {collectionsInfo?.externalUrl}
                      </a>
                      &nbsp; for more details.
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
          <Tabs
            containerClassName="tab-primary mb-3"
            tabOptions={tabsOptions}
            activeTabId={activeTableId}
            tabChangeHndlr={tabChangeHandler}
          />
          <div className="tabbody-container">{activeComponent}</div>
        </div>
      </section>

      <Modal
        show={modalState}
        onHide={toggleModalHndlr}
        centered
        dialogClassName="performance-items-modal"
        contentClassName="performance-items-modalcontainer"
        className="performance-backdrop"
        // backdrop="static"
        keyboard={false}
      >
        <Modal.Title className="modal-header  sticky-top">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              <img
                src={collectionsInfo?.cardImgUrl || noImageFound}
                onError={replaceImage}
                className="mr-3 avatar-md"
              />
              <h4 className="mb-0">{collectionsInfo?.name}</h4>
            </div>
            <span className="">
              <RiCloseLine
                onClick={toggleModalHndlr}
                className="text-neutral text-white"
                style={{ height: '30px', width: '30px' }}
              />
            </span>
          </div>
        </Modal.Title>
        <Modal.Body className="py-4 scroll-model">
          <CollectionDetailsModal collectionDetails={collectionDetailsState} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from 'react';
import { apicallback } from '../../callbacks/index';

const Condition = () => {
  const [terms, setterms] = useState(null);
  useEffect(async () => {
    const header = document.getElementById('header_main');
    const footer = document.getElementById('footer');
    if (header) {
      header.remove();
      footer.remove();
    }
    const response = await apicallback(
      null,
      '/getTermsAndConditions',
      {},
      null,
      null,
      'get'
    );
    if (response && response.status == 'success') {
      setterms(response.data.terms_and_conditions);
    }
  });

  return (
    <div className="authors">
      <section className=" inner">
        <div className="overlay"></div>
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div className="page-title-heading mg-bt-12"></div>
              <div className="breadcrumbs style2"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="tf-section our-creater dark-style2">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="tf-title style4 mg-bt-38">Terms of Use</h2>
              <br></br>
              <div dangerouslySetInnerHTML={{ __html: terms }} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Condition;

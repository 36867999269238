import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Image from '../../components/Image';
import InstagramLoader from '../../components/content_loader/InstagramLoader';
import { mainLogo } from '../../assets/Index';
import { detectIncognito } from 'detectincognitojs';

import {
  FaEthereum,
  GrChatOption,
  FaDoorOpen,
} from '../../components/icons/Index';
import loadable from '@loadable/component';
const LoginSignUp = loadable(() =>
  import('../../components/models/LoginSignUp')
);
const server = process.env.REACT_APP_SERVER;
const TodayPicks = ({
  data,
  wallets,
  authorization,
  isAnzaliActive,
  openToasterAlert,
  handelClose,
  ethPrice,
  apicallback,
}) => {
  const notify = () =>
    openToasterAlert(
      'error',
      `${wallets
        ? "You cannot access this Reward since you don't hold this asset in your currently connected wallet."
        : 'Connect your wallet to continue.'
      }`,
      {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      }
    );
  const [list, satList] = useState(null);
  const [openIframe, setIframe] = useState(false);
  const [nftPayToken, setNftPayToken] = useState('');
  const [showModal, setModalShowSignup] = useState(false);
  const [checkCookies, setCookies] = useState(false);
  const [alertInfo, setAlertInfo] = useState(false);
  const [events, setEvents] = useState(null);
  useEffect(() => {
    detectIncognito().then((result) => {
      setCookies(result.isPrivate);
    });

    updateValues();
  }, [data]);

  const updateValues = async () => {
    let arrayList = data;
    const tt = await Promise.all(
      Object.entries(arrayList).map(async ([index, item]) => {
        if (item.custom_price_new != '') {
          const pp = item.custom_price_new * ethPrice;
          const new_p = (pp / 100) * 10;
          arrayList[index].doller = ethPrice
            ? item.custom_price_new * ethPrice + new_p
            : 0;
        } else {
          arrayList[index].doller = 0;
        }
        return arrayList[index];
      })
    );
    if (tt.length > 0) satList(tt);
    else satList(satList);
    return;
  };

  const getUtilities = async (collection_id) => {
    const response = await apicallback(
      null,
      '/getUtilityByCollection',
      {
        collection_id: collection_id,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      setEvents(response.data.length > 0 ? response.data : null);
    }
  };

  return (
    <section id="discover" className="tf-section today-pick p-0">
      {alertInfo ? (
        <div className="alertUIModal">
          <div className="position-relative mb-4">
            <h3 className="text-center mb-3">Current Benefits</h3>
            <button
              onClick={() => setAlertInfo(false)}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <hr />
          {events ? (
            <div className="tf-section tf-item-details p-0">
              <div className="content-right">
                <div className="sc-item-details">
                  <div className="flat-tabs themesflat-tabs">
                    <ul className="bid-history-list p-2">
                      {events.map((item, index) => (
                        <li key={index}>
                          <div className="content">
                            <div className="client">
                              <div className="sc-author-box style-2">
                                <div className="author-avatar">
                                  <Link to="#">
                                    <img
                                      src={item.image ? item.image : ''}
                                      alt={item.title}
                                      className="avatar"
                                    />
                                  </Link>
                                </div>
                                <div className="author-infor">
                                  <div className="name">
                                    <h6>
                                      <Link to="#"> {item.title} </Link>
                                    </h6>
                                  </div>
                                  <span className="time">{item.date_text}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <p className="text-center mt-3">Sign up or sign in to enter Rewards.</p>
          <div className="d-flex flex-column justify-content-between">
            <Link
              className="sc-button style-4 my-4 d-flex justify-content-center align-item-center"
              to="/sign-up"
              state={{ prevPath: window.location.pathname }}
            >
              <span className="pl-0">Sign Up</span>
            </Link>
            <Link
              to="/sign-in"
              state={{ prevPath: window.location.pathname }}
              className="sc-button style-1 d-flex justify-content-center align-item-center"
            >
              <span className="pl-0">Sign In</span>
            </Link>
          </div>
        </div>
      ) : (
        ''
      )}
      {openIframe ? (
        <div className="alertUIModal">
          <div className="position-relative mb-4">
            <h3 className="text-center mb-3">
              {checkCookies ? 'Warning' : 'Pay Now'}
            </h3>
            <button
              onClick={() => {
                setIframe(false);
                handelClose();
              }}
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <hr />
          {checkCookies == false ? (
            <iframe
              className="mt-4"
              height="510px"
              width="100%"
              src={`   
            https://${server == 'prod' ? 'payments' : 'sandbox'
                }.nftpay.xyz/iframe/iframe_pay/${nftPayToken}?email=${authorization.data.email
                }`}
            />
          ) : (
            <>
              <p className="mt-5">
                It seems you're accessing this page from an incognito window or
                your cookies are disabled. Please try again from a fresh browser
                and ensure your cookies are enabled.
              </p>
            </>
          )}
        </div>
      ) : (
        ''
      )}

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-live-auctions mg-bt-21">
              <h2 className="tf-title pad-l-7"></h2>
            </div>
          </div>
          {isAnzaliActive ? (
            <div className="col-xl-4 col-lg-4 col-md-6 col-6 px-2 px-sm-3">
              <div className={`sc-card-product`}>
                <div className="card-media">
                  <Link to="#">
                    <Image
                      image={{
                        src: mainLogo,
                        height: '100%',
                        width: '100%',
                        alt: 'asset',
                      }}
                    />
                  </Link>
                </div>
                <div className="card-title">
                  <h5 className="style2">
                    <Link to="#">Anzali</Link>
                    <div className="tags">Buy NFT</div>
                  </h5>
                </div>
                <div className="meta-info">
                  <div className="author">
                    <div className="info">
                      <span>Live Chat Room</span>
                      <h6 className="mb-5"></h6>
                    </div>
                  </div>
                  <div className="author">
                    <div className="info chat-ico">
                      {authorization ? (
                        <Link to="/anzali-zone" className="sc-button style-4">
                          <span className="d-block d-sm-none">
                            <GrChatOption />
                          </span>
                          <span className="d-none d-sm-block">Enter Room</span>
                        </Link>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}

          {list != null ? (
            list.map((item, index) => (
              <div
                key={index}
                className="col-xl-4 col-lg-4 col-md-6 col-6 px-2 px-sm-3"
              >
                <div className={`sc-card-product`}>
                  <div className="card-media">
                    {authorization ? (
                      <Link
                        to={item.select ? `/rewards/${item.slug}` : '#'}
                        onClick={item.select ? null : () => notify()}
                        state={{ obj: item }}
                      >
                        <Image
                          image={{
                            src: item.image_url,
                            height: '100%',
                            width: '100%',
                            alt: 'asset',
                          }}
                        />
                      </Link>
                    ) : (
                      <Link
                        onClick={() => {
                          setAlertInfo(true);
                          setEvents(null);
                          getUtilities(item.id);
                        }}
                        to="#"
                      >
                        <Image
                          image={{
                            src: item.image_url,
                            height: '100%',
                            width: '100%',
                            alt: 'asset',
                          }}
                        />
                      </Link>
                    )}
                  </div>
                  <div className="card-title">
                    <h5 className="style2">
                      <Link to="#" state={{ obj: item }}>
                        {item.collection_name
                          ? `${item.collection_name}`
                          : `#${item.slug}`}
                      </Link>
                    </h5>
                    {item.nftpay_uid && authorization ? (
                      <div
                        className="tags cursor-pointer d-none d-sm-block"
                        style={{ width: "auto", padding: "0px 8px", letterSpacing: 'normal', whiteSpace: 'nowrap' }}
                        onClick={() => {
                          setNftPayToken(item.nftpay_uid);
                          setIframe(true);
                        }}
                      >
                        Buy Membership
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="meta-info">
                    <div className="author">
                      {item.enable_disable_floor_price_for_zones ? (
                        <div className="info mb-3">
                          <span>{item.doller ? '' : 'Floor'} Price</span>
                          <h6>
                            <Link to="#">
                              <FaEthereum />{' '}
                              {item.custom_price_new != null
                                ? item.custom_price_new
                                : item.floor_price}
                              {item.doller ? (
                                <>
                                  <l className="text-main ml-1">
                                    (EST. ${item.doller.toFixed(0)})
                                  </l>
                                </>
                              ) : (
                                ''
                              )}
                            </Link>
                          </h6>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="author">
                      <div className="info chat-ico">
                        {authorization ? (
                          <Link
                            to={item.select ? `/rewards/${item.slug}` : '#'}
                            onClick={item.select ? null : () => notify()}
                            className={`sc-button ${item.select ? 'style-4' : 'style-5'
                              }`}
                            state={{ obj: item }}
                          >
                            <span className="d-block d-sm-none">
                              <FaDoorOpen />
                            </span>
                            <span className="d-none d-sm-block">
                              Enter Rewards
                            </span>
                          </Link>
                        ) : (
                          <Link
                            onClick={() => {
                              setAlertInfo(true);
                              setEvents(null);
                              getUtilities(item.id);
                            }}
                            to="#"
                            className="sc-button style-5"
                          >
                            <span className="d-block d-sm-none">
                              <FaDoorOpen />
                            </span>
                            <span className="d-none d-sm-block">
                              Enter Rewards
                            </span>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  {item.nftpay_uid && authorization ? (
                    <Link
                      className={`d-block d-sm-none sc-button w-100 text-center style-4`}
                      onClick={() => {
                        setNftPayToken(item.nftpay_uid);
                        setIframe(true);
                      }}
                    >
                      <span> Buy NFT</span>
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ))
          ) : (
            <InstagramLoader value={5} coloum={4} is_mobile={2} />
          )}
        </div>
      </div>
      <LoginSignUp
        show={showModal}
        onHide={() => setModalShowSignup(false)}
        text={'Sign up or sign in to enter Rewards.'}
        url={true}
      />
    </section>
  );
};

export default TodayPicks;

import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { FaRegCopy } from '../../components/icons/Index';
import { Link } from 'react-router-dom';
const Index = ({ title, logo, url, copyClipboard, cls = '' }) => {
  return (
    <ul className={cls}>
      <li>
        <Link to="#" className="text-twitter">
          <TwitterShareButton url={url} title={title} media={logo}>
            <i className="fab fa-twitter"></i>
          </TwitterShareButton>
        </Link>
      </li>
      <li>
        <Link to="#" className="text-facebook">
          <FacebookShareButton url={url} title={title} media={logo}>
            <i className="fab fa-facebook"></i>
          </FacebookShareButton>
        </Link>
      </li>
      <li className="style-2">
        <Link to="#" className="text-telegram">
          <TelegramShareButton url={url} title={title}>
            <i className="fab fa-telegram-plane"></i>
          </TelegramShareButton>
        </Link>
      </li>
      <li>
        <Link to="#" className="text-whatsapp">
          <WhatsappShareButton url={url} title={title}>
            <i className="fab fa-whatsapp"></i>
          </WhatsappShareButton>
        </Link>
      </li>
      <li>
        <Link to="#">
          <FaRegCopy
            className="copyBtn text-main"
            onClick={() => copyClipboard(url)}
          />
        </Link>
      </li>
    </ul>
  );
};

export default Index;

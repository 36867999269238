import { Link } from 'react-router-dom';
import {
  home_section_9,
  home_travel_front,
  home_location,
  home_megaphone,
  home_notebook,
  home_rocket,
} from '../../assets/Index';
export default function Section9() {
  function List(value) {
    const { img, text, cls } = value;
    return (
      <div className={cls}>
        <div className="list d-flex align-items-center">
          <div className="image mr-3">
            <img src={img} />
          </div>
          <h5>{text}</h5>
        </div>
      </div>
    );
  }
  return (
    <section className="section-9">
      <div className="home-custom-container">
        <div className="row">
          <div className="col-md-6">
            <img src={home_section_9} className="w-100 d-none d-md-block" />
          </div>
          <div className="col-md-6 first d-flex flex-column justify-content-center">
            <div className="first mb-4 d-flex align-items-center">
              <img src={home_travel_front} className="mr-2" />
              <span>Resources</span>
            </div>
            <h4>Boost Your Web3 Knowledge  <br className="d-none d-md-block" /> with Our Useful Resources</h4>
            <img
              src={home_section_9}
              className="w-100 d-block d-md-none my-3"
            />
            <p>
              Leverage our Web3 resources and insights library to learn the
              basics around NFTs and Web3.
            </p>
            <div className="mt-0 mt-md-5">
              <p className="d-none d-md-block">
                Check out our various resources
              </p>
              <div className="row mt-4 mx-0 mx-md-1">
                <List
                  img={home_rocket}
                  cls="col-6 mb-3 px-1"
                  text="Historical Floor Price Analysis"
                />
                <List
                  img={home_notebook}
                  cls="col-6 mb-3 px-1"
                  text="Learn About Blockchain, Web3, Crypto & NFTs"
                />
                <List
                  img={home_megaphone}
                  cls="col-6 mb-3 px-1"
                  text="Stay Up-to-Date with latest Web3 News"
                />
                <List
                  img={home_location}
                  cls="col-6 mb-3 px-1"
                  text="Share The Information with Anyone Easily"
                />
              </div>
              <Link
                to="/resources"
                className="sc-button style-4 text-dark mt-md-5 mt-3"
              >
                Explore Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import InstagramLoader from '../components/content_loader/InstagramLoader';
import PageTitle from '../components/pagetitle/PageTitle';
import { Tweet } from 'react-twitter-widgets';
import {
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { apicallback, saveMeta } from '../callbacks/index';
import { openToasterAlert } from '../components/alert/Index';
import { FaRegCopy } from '../components/icons/Index';
import { twitterx_icon } from '../assets/Index';
const ArticleDetails = () => {
  const currentPageUrl = window.location.href;
  const [obj, setObj] = useState([]);
  const [ls, setList] = useState([]);
  const [tags, setTags] = useState([]);
  const [loader, setLoader] = useState(true);
  const [tweetID, setTweetID] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    async function articles() {
      await getArticleDetail();
    }
    articles();
  }, []);

  const getArticleDetail = async (slug = null) => {
    const response = await apicallback(
      'content',
      '/articleDetails',
      { article_id: slug ? slug : id },
      null,
      null
    );
    if (response && response.status == 'success') {
      saveMeta({
        title: response?.data?.seo_title + '' || null,
        description: response?.data?.meta_description || null,
        images: response?.data?.image || null,
        url: `/news/${response?.data?.slug}`,
        file: 'news',
        keywords: `${
          response?.data?.title
        },Web3 News, ANZALI Web3 News,web3,digital technology,blockchain news,buy nft,web3 meaning,non fungible,free nft, ${
          response?.data?.keyword || ''
        }`,
      });
      setObj(response.data);
      setTags(response.popular_tags);
      setLoader(false);
      await getAllArticles(response.data.title);
    } else {
      setLoader(false);
    }
  };

  const getAllArticles = async (title) => {
    const response = await apicallback('content', '/articles', {}, null, null);
    if (response && response.status == 'success') {
      let ls = response.data;
      ls = ls.filter((item) => {
        return item.title != title;
      });
      ls = ls.sort((a, b) => new Date(b.date) - new Date(a.date));
      setList(ls);
    }
  };

  const renderNewNew = (slug) => {
    window.history.replaceState(
      {},
      null,
      window.location.origin + `/news/${slug}`
    );
    setLoader(true);
    getArticleDetail(slug);
  };

  const TopPost = () => {
    return (
      <ul>
        {ls
          ? ls.map((item, index) => (
              <li key={index} className="box-recent-post">
                <div className="box-feature">
                  <Link
                    to={`/news/${item.slug ? item.slug : item.article_id}`}
                    onClick={() =>
                      renderNewNew(item.slug ? item.slug : item.article_id)
                    }
                  >
                    <img
                      src={item.image}
                      alt={item?.image_alt_text || item?.title}
                    />
                  </Link>
                </div>
                <div className="box-content">
                  <Link
                    to={`/news/${item.slug ? item.slug : item.article_id}`}
                    onClick={() =>
                      renderNewNew(item.slug ? item.slug : item.article_id)
                    }
                    className="title-recent-post"
                  >
                    {item.title}
                  </Link>
                  <span>
                    <span className="sub-recent-post"></span>
                    <Link
                      to={`/news/${item.slug ? item.slug : item.article_id}`}
                      onClick={() =>
                        renderNewNew(item.slug ? item.slug : item.article_id)
                      }
                      className="day-recent-post"
                    >
                      {item.date}
                    </Link>
                  </span>
                </div>
              </li>
            ))
          : ''}
      </ul>
    );
  };

  const copy = (e) => {
    navigator.clipboard.writeText(currentPageUrl).then(() => {
      openToasterAlert('success', 'Copied to clipboard.');
    });
  };

  function handelContent(content) {
    // Sample string
    let text = content;
    // Regular expression to match Twitter URLs and extract tweet ID
    const twitterRegex = /https?:\/\/twitter\.com\/\w+\/status\/(\d+)/g;
    // Find all Twitter links in the string and extract tweet IDs
    let match;
    const tweetIDs = [];
    while ((match = twitterRegex.exec(text)) !== null) {
      tweetIDs.push(match[1]);
    }
    return tweetIDs.length > 0 ? (
      <div className="row">
        <div className="col-12 mb-3">
          <h3>Referenced X Posts</h3>
        </div>
        {tweetIDs.map((v) => (
          <div className="col-sm-4">
            <Tweet tweetId={v} />
          </div>
        ))}
      </div>
    ) : (
      ''
    );
  }

  return (
    <div className="tf-section post-details" style={{ padding: '40px 0 59px' }}>
      <div className="themesflat-container">
        <div className="wrap-flex-box style">
          {loader ? (
            <InstagramLoader value={4} coloum={6} is_mobile={1} />
          ) : (
            <>
              <PageTitle
                meta={{
                  title:
                    obj?.seo_title +
                    ` ${obj?.seo_title ? ' | ' : ' '} ANZALI Web3 News`,
                  description: obj?.meta_description,
                  image: obj.image,
                }}
              />
              <div className="post">
                <div className="inner-content">
                  <h1 className="title-post">{obj.title}</h1>
                  <div className="divider"></div>
                  <div className="meta-post flex mg-bt-31">
                    <div className="box">
                      <div className="inner">
                        <h6 className="desc">Posted</h6>
                        <p>{obj.timestamp}</p>
                      </div>
                    </div>
                    <div className="box left">
                      <div className="inner boder pad-r-50">
                        <h6 className="desc">Author</h6>
                        <p>{obj.author}</p>
                      </div>
                      <div className="inner mg-l-39 mg-r-1">
                        <h6 className="desc">DATE</h6>
                        <p>{obj.date}</p>
                      </div>
                    </div>
                  </div>
                  <div className="image">
                    <img
                      src={obj.image}
                      alt={obj?.image_alt_text || obj?.title}
                    />
                  </div>
                  <div className="inner-post mg-t-40">
                    <div
                      className="mg-bt-24 changeanchorcolor"
                      // dangerouslySetInnerHTML={{ __html: obj.content }}
                    >
                      {parse(obj.content)}
                      {handelContent(obj.content ? obj.content : '')}
                    </div>
                  </div>
                  <div className="sc-widget style-1">
                    <div className="widget widget-tag style-2">
                      <h4 className="title-widget">Tags</h4>
                      <ul>
                        {Object.entries(obj.tags_ids).map(([index, item]) => (
                          <li key={index}>
                            <Link
                              to={`/nft-news`}
                              state={{
                                tag: index,
                              }}
                            >
                              {item}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="widget widget-social style-2">
                      <h4 className="title-widget">Share:</h4>
                      <ul>
                        <li>
                          <Link to="#">
                            <TwitterShareButton
                              url={currentPageUrl}
                              title={obj.title}
                              // via={obj.content}
                            >
                              <img src={twitterx_icon} width="15" />
                            </TwitterShareButton>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <FacebookShareButton
                              url={currentPageUrl}
                              title={obj.title}
                            >
                              <i className="fab fa-facebook"></i>
                            </FacebookShareButton>
                          </Link>
                        </li>
                        <li className="style-2">
                          <Link to="#">
                            <TelegramShareButton
                              url={currentPageUrl}
                              title={obj.title}
                            >
                              <i className="fab fa-telegram-plane"></i>
                            </TelegramShareButton>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <RedditShareButton
                              url={currentPageUrl}
                              title={obj.title}
                            >
                              <i className="fab fa-reddit"></i>
                            </RedditShareButton>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <WhatsappShareButton url={currentPageUrl}>
                              <i className="fab fa-whatsapp"></i>
                            </WhatsappShareButton>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <FaRegCopy className="copyBtn" onClick={copy} />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="side-bar details">
                <div className="widget widget-recent-post mg-bt-43">
                  <h3 className="title-widget mg-bt-23">Recent Posts</h3>
                  <TopPost />
                </div>
                {tags.length > 0 ? (
                  <div className="widget widget-tag style-1">
                    <h3 className="title-widget mg-bt-23">Popular Tags</h3>
                    <ul>
                      {tags.map((item, index) => (
                        <li key={index}>
                          <Link
                            to={`/nft-news`}
                            state={{
                              tag: item.id,
                            }}
                            className="box-widget-tag"
                          >
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default ArticleDetails;

import React from 'react';
import { Instagram } from 'react-content-loader';
const InstagramLoader = ({ value, coloum, center = false, is_mobile = 1 }) => {
  let data = [];
  for (let i = 1; i <= value; i++) {
    data.push(
      <div
        className={`fl-item col-xl-${coloum} ${
          center ? 'mx-auto' : ''
        } mt-4 col-${12 / is_mobile}`}
        key={i}
      >
        <Instagram
          speed={3}
          // backgroundColor={'#333'}
          foregroundColor={'#ccc'}
          // viewBox="0 0 380 70"
        />
      </div>
    );
  }
  return data;
};
export default InstagramLoader;

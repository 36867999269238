import React, { useMemo, useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import Table from '../../../components/table/Table';
import { Bars } from 'react-loader-spinner';
import { generateColumns } from './helper';
import { FiSearch, FaTrash } from '../../../components/icons/Index';
//Assets
import { auth_required_img } from '../../../assets/Index';
//API Actions
import { copyClipboard, apicallback } from '../../../callbacks/index';

export default function BlueChips({
  authorizeCallback,
  loginModalTextCallback,
}) {
  const authorization = useStoreState((state) => state.authorization);
  const [isLoadingBlueChipState, setIsLoadingBlueChipState] = useState(true);
  const [blueChipState, setBlueChipState] = useState(undefined);
  const { wallet_address: walletAddress } = useParams();

  useEffect(() => {
    async function getBlueChipApiCall() {
      const response = await apicallback(
        null,
        `/fetchBlueChipsV2?address=${walletAddress}`,
        {},
        'private',
        authorization.token,
        'get'
      );
      if (response && response.status == 'success') {
        const { data } = response || {};
        if (data) {
          setBlueChipState(data);
        }
        setIsLoadingBlueChipState(false);
      } else {
        setIsLoadingBlueChipState(false);
      }
    }
    if (authorization && walletAddress) {
      getBlueChipApiCall();
    }
  }, [authorization, walletAddress]);

  function copyAddressHndlr(event) {
    const walletAddress = event.currentTarget.getAttribute('data-id');
    if (walletAddress) {
      copyClipboard(walletAddress);
    }
  }

  const mintingColumns = useMemo(
    () => generateColumns(copyAddressHndlr),
    [blueChipState]
  );
  const { collections: blueChipData } = blueChipState || {};
  return (
    <React.Fragment>
      {authorization ? (
        <div className="walletcollections-container">
          <div className="row">
            <div className="col-12">
              <div className="tabbody-headercontainer">
                <h4 className="mb-0">Blue Chips</h4>

                {/* <div className="searchwallet-container ">
                                        <div className="search-icon-container">
                                            <FiSearch className="searchinput-icon" />
                                        </div>
                                        <input
                                            type="text"
                                            id="walletAddress"
                                            name="walletAddress"
                                            className="search-input size-small"
                                            // value={}
                                            // onChange={onChangeHndlr}
                                            placeholder="Search Blue Chips"
                                        />
                                        {false ? (
                                            <div className="searching-bar-container">
                                                <Bars color="#ffffff" height={20} width={50} />
                                            </div>
                                        ) : <div className="right-icon-container">
                                            /
                                        </div>
                                        }
                                    </div> */}
              </div>
            </div>
            <div className="col-12">
              <Table
                columns={mintingColumns}
                data={Array.isArray(blueChipData) ? blueChipData : []}
                isDataLoading={isLoadingBlueChipState}
                loadingMessage="Loading Blue Chips."
                noDataTitle="No blue chip collections detected in this wallet."
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          onClick={() => {
            authorizeCallback(true);
            loginModalTextCallback(
              'Sign up or sign in to view the blue chip collections held in this wallet.'
            );
          }}
        >
          <h4 className="my-4 text-main">
            Sign up or sign in to see performance.
          </h4>
          <img src={auth_required_img} width="250" />
        </div>
      )}
    </React.Fragment>
  );
}

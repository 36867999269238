import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import MainStore from './Store/MainStore';
import { StoreProvider } from 'easy-peasy';
import { ToastContainer } from 'react-toastify';
import App from './App';
import './assets/main/css/style.min.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/main/scss/main.scss';
//Web3Modal
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from '@web3modal/ethereum';
import { Web3Modal, Web3Button } from '@web3modal/react';
import { configureChains, WagmiConfig, createConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
//Web3Modal
//const renderMethod = ReactDOM.render;
const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
const projectId = 'e66d55ca50b02685a550d619e8b67e9e';
const chains = [mainnet];
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);
renderMethod(
  <>
    <WagmiConfig config={wagmiClient}>
      <BrowserRouter>
        <StoreProvider store={MainStore}>
          <App />
          <ScrollToTop />
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{ fontSize: '2rem', minWidth: '50%', maxWidth: '100%' }}
            theme="dark"
          />
        </StoreProvider>
      </BrowserRouter>
    </WagmiConfig>
    <Web3Modal
      projectId={projectId}
      ethereumClient={ethereumClient}
      themeVariables={{
        '--w3m-font-family': 'Roboto',
        '--w3m-z-index': '999999',
      }}
    />
  </>,
  document.getElementById('root')
);

import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { Monkey_404 } from '../../assets/Index';
const Index = () => {
  const history = useNavigate();
  const authorization = useStoreState((actions) => actions.authorization);
  useEffect(() => {
    if (authorization) {
      history(`/`);
    }
  }, []);
  return (
    <div className="container">
      <div className="d-flex d-sm-flex align-items-center justify-content-center vh-100 flex-column flex-sm-row">
        <div className="text-center mr-0 mr-sm-5 mb-5">
          <h3 className="mb-4">
            We had issues producing the <br />
            response to your request.
          </h3>
          <p>
            Sorry about that. Please try refreshing and
            <br /> contact us if the problem persists.
          </p>
        </div>

        <img
          src={Monkey_404}
          id="error_404"
          alt="404 Error page not found mt-3"
        />
      </div>
    </div>
  );
};

export default Index;

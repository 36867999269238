import React, { useState } from 'react';
import { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Accordion } from 'react-bootstrap';
import { TfiArrowCircleDown } from 'react-icons/tfi';
import NoDataComponent from '../../components/NoDataComponent';
import { LoadingContainer } from './helper';

export default function Traits({ isLoading }) {
  const collectionInfoTabsData = useStoreState(
    (state) => state.collectionInfoTabsData
  );
  const [traitState, setTraitState] = useState({});

  useEffect(() => {
    const { traits } = collectionInfoTabsData || {};
    setTraitState(traits);
  }, [collectionInfoTabsData]);

  return (
    <section id="traits" className="traits-container">
      <div className="row row-gap-md row traits-row">
        {traitState.length ? (
          Array.isArray(traitState) &&
          traitState.map((item, index) => {
            const traitsData = item?.values || [];
            return (
              <div
                key={`traits-${index}`}
                className="column-gap-md col-12 col-lg-6"
              >
                <Accordion className="accordian-container ">
                  <Accordion.Header className="accordian-header">
                    <div className="trait-header">
                      <h5 className="text-capitalize">{item.category}</h5>
                      <span>
                        <TfiArrowCircleDown />
                      </span>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="trait-items">
                      {traitsData.map((info, childIndex) => (
                        <div
                          key={`traitchild-${index}-${childIndex}`}
                          className="trait-item"
                        >
                          <span className="item-label">{info?.name}</span>
                          <div className="item-value">
                            <span className="text-white mr-1">
                              {info?.count}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion>
              </div>
            );
          })
        ) : (
          <p className='text-center w-100'>No Traits Found</p>
        )}

        {/* {traitState.length && (
          <div className="column-gap-md col-12 ">
            <NoDataComponent title="No Traits Found." />
          </div>
        )} */}
      </div>
    </section>
  );
}

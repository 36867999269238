import React, { useState, useEffect, useMemo } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import PageTitle from '../../components/pagetitle/PageTitle';
import SideNav from './SideNav';
import Slider from './Slider';
import Card from './Card';
import SliderList from './SliderList';
import './resource.scss';
import { apicallback } from '../../callbacks/index';
import AnzaliLoader from '../../components/AnzaliLoader';
export default function Index() {
  const authorization = useStoreState((state) => state.authorization);
  const resourceCategory = useStoreState((state) => state.resourceCategory);
  const resourceCategoryId = useStoreState((state) => state.resourceCategoryId);
  const setResourceCategory = useStoreActions(
    (actions) => actions.setResourceCategory
  );
  const setResourceCategoryId = useStoreActions(
    (actions) => actions.setResourceCategoryId
  );

  const [allResource, setAllResource] = useState(null);
  const [videos, setVideos] = useState(null);
  const [videoPagination, setVideoPagination] = useState(0);
  const [isLoadingState, setIsLoadingState] = useState(false);

  useEffect(() => {
    if (resourceCategory.length <= 0) getCategoryList();
    getResource(resourceCategoryId);
  }, []);

  async function getResource(value, offset) {
    try {
      setIsLoadingState(true);
      if (value) {
        const response = await apicallback(
          null,
          '/getVideosbyCategory',
          { category_id: value, limit: 10, offset: offset },
          'private',
          authorization?.token || process.env.REACT_APP_DJANGO_TOKEN
        );
        if (response && response.status === 'success') {
          if (offset) {
            setVideos([...videos, ...response.data]);
          } else {
            setVideos(response.data);
          }
          setVideoPagination({
            offset: response.offset,
            limit: response.limit,
            count: response.count,
            name: response.name,
          });
          setIsLoadingState(false);
        }
      } else {
        const response = await apicallback(
          null,
          '/getAllResources',
          { limit: 10, offset: 0 },
          'private',
          authorization?.token || process.env.REACT_APP_DJANGO_TOKEN
        );
        if (response && response.status === 'success') {
          setAllResource(response);
          setIsLoadingState(false);
        }
      }
    } catch (error) {
      setIsLoadingState(false);
    }
  }

  async function getCategoryList() {
    try {
      const response = await apicallback(
        null,
        '/getSectionsCategorylist',
        {},
        'private',
        authorization?.token || '',
        'get'
      );
      if (response && response.status == 'success') {
        setResourceCategory(response.data);
      }
    } catch (error) {}
  }

  function handleCategoryClick(value) {
    getResource(value);
    setResourceCategoryId(value);
  }
  return (
    <React.Fragment>
      <PageTitle
        meta={{
          title: 'Start Your Learning Journey With Our Web3 and NFT Resources | ANZALI',
          description: 'Unlock Web3 and NFT resource insights & dive into real-time prices, historical trends, and expert video guidance to master your digital asset portfolio.',
        }}
      />
      {isLoadingState && <AnzaliLoader />}
      <div className="page-container">
        <section className="tf-section tf-item-details">
          <div className="resources">
            <div className="row">
              <div className="col-sm-12 col-md-3 col-lg-3">
                <SideNav
                  handleCategoryClick={handleCategoryClick}
                  list={resourceCategory}
                  active={resourceCategoryId}
                />
              </div>
              <div className="col-sm-12 col-md-9 col-lg-9">
                {resourceCategoryId ? (
                  <div className="px-4 px-sm-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h3>{videoPagination?.name || 'Name Not send From API'}</h3>
                      {/* <div>Search</div> */}
                    </div>
                    <div className="mt-5 row">
                      {Array.isArray(videos) &&
                        videos.map((data, key) => (
                          <div className="col-6 col-sm-6 col-md-4 col-lg-3 mb-5">
                            <Card
                              key={key}
                              data={data}
                              authorization={authorization ? true : false}
                            />
                          </div>
                        ))}
                    </div>
                    {videoPagination.offset ? (
                      <div className="mt-5 text-center">
                        <button
                          className="sc-button style-4"
                          onClick={() =>
                            getResource(
                              resourceCategoryId,
                              videoPagination.offset
                            )
                          }
                        >
                          Load More
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <>
                    {allResource && allResource.data.sliders.length ? (
                      <div className="mb-5">
                        <div className="d-flex align-items-center justify-content-between mb-4 px-4 px-sm-4">
                          <h3>All Resources</h3>
                          {/* <div>Search</div> */}
                        </div>
                        <Slider list={allResource?.data?.sliders || []} />
                      </div>
                    ) : (
                      ''
                    )}
                    {Array.isArray(allResource?.data?.sections) &&
                    allResource.data.sections.length
                      ? allResource.data.sections.map((value, key) => (
                          <div className="px-4 px-sm-4 mb-5" key={key}>
                            <div className="d-flex align-items-center justify-content-between">
                              <h3>{value.name}</h3>
                              {value.videos.length ? (
                                <button
                                  className="sc-button style-4 text-dark py-2 d-none d-sm-block"
                                  onClick={() => handleCategoryClick(value.id)}
                                >
                                  View All
                                </button>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="mt-4">
                              {value.videos.length ? (
                                <SliderList
                                  list={value?.videos || []}
                                  authorization={authorization}
                                />
                              ) : (
                                <p className="py-5 text-muted text-center">
                                  We'll be adding content here soon
                                </p>
                              )}
                            </div>
                            {value.videos.length ? (
                              <button
                                className="sc-button style-4 text-dark py-2 d-block d-sm-none mt-4 w-100"
                                onClick={() => handleCategoryClick(value.id)}
                              >
                                View All
                              </button>
                            ) : (
                              ''
                            )}
                          </div>
                        ))
                      : ''}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

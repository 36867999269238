import React, { useState } from 'react';
import RequestHeader from './RequestHeader';
import ContentLoader from 'react-content-loader';
import { Bars } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { anzaliBuisnessLogo } from '../../../assets/Index';
import {
  ImCheckmark,
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIos,
} from '../../../../src/components/icons/Index';
import InputText from '../../../components/inputext';
import InputCheckbox from '../../../components/inputcheckbox';
import InputTextArea from '../../../components/inputtextarea';
import DatePicker from '../../../components/datepicker';
import classNames from 'classnames';
import {
  serviceTabInitialValue,
  serviceTabValidationSchema,
  descriptionInitialValue,
  descriptionValidationSchema,
  projectInfoInitialValue,
  projectInfoValidationSchema,
  timelineInitialValue,
  timelineValidationSchema,
} from './helper';
//API Services
import { getServices } from '../../../apis/Index';

import { useFormik } from 'formik';
import { useEffect } from 'react';

export function StartRequestTab({
  nextTabHndlr,
  backTabHndlr,
  closeModalHndlr,
}) {
  return (
    <div className="tabs-container">
      <div className="startrequest-header">
        <button
          onClick={closeModalHndlr}
          className="custom-text-button btn-white fw-normal"
        >
          Cancel
        </button>
      </div>
      <div className="startrequest-body requestmodal-body">
        <div className="row">
          <div className="col-12">
            <div className="buisnesslogo-container mx-auto">
              <img src={anzaliBuisnessLogo} className="buisness-logo" />
            </div>
          </div>
          <div className="col-12 mb-20">
            <h2 className="mb-0">Project Request</h2>
          </div>
          <div className="col-12 col-lg-7 mx-auto mb-20">
            <span className="info">
              Submit this form and we'll get back to you.
            </span>
          </div>
          <div className="col-12">
            <button
              onClick={() => nextTabHndlr(undefined)}
              className="custom-text-button btn-primary mx-auto"
            >
              Start <MdOutlineArrowForwardIos />
            </button>
          </div>
        </div>
      </div>
      <div className="startrequest-footer">
        <span className="text-hatefrom">Or contact us directly: </span>
        <span className="text-white text-underline cursor-pointer">
          support@anzali.io
        </span>
      </div>
    </div>
  );
}

export function ServiceTab({
  tabData,
  activeTabId,
  nextTabHndlr,
  backTabHndlr,
  closeModalHndlr,
}) {
  const [serviceListState, setServiceListState] = useState([]);
  useEffect(() => {
    async function getServicesApiCall() {
      const serviceResp = await getServices();
      if (serviceResp?.status === 'success') {
        if (Array.isArray(serviceResp?.data)) {
          setServiceListState(serviceResp?.data);
        }
      }
    }
    getServicesApiCall();
  }, []);
  useEffect(() => {
    if (tabData) {
      serviceTabForm.resetForm({
        values: { ...serviceTabInitialValue, ...tabData },
      });
    }
  }, [activeTabId, tabData]);

  const serviceTabForm = useFormik({
    initialValues: serviceTabInitialValue,
    validationSchema: serviceTabValidationSchema,
    onSubmit: (values) => {
      nextTabHndlr(values);
    },
  });

  function onSelectServiceHndlr(event) {
    const dataId = event.currentTarget.getAttribute('data-id');
    if (dataId) {
      serviceTabForm.setFieldValue('service_id', dataId);
    }
  }

  function backBtnHandlr() {
    backTabHndlr(serviceTabForm.values);
  }

  return (
    <div className="tabs-container">
      <RequestHeader
        activeStepIndex={1}
        backBtnHndlr={backBtnHandlr}
        closeModalHndlr={closeModalHndlr}
      />
      <form onSubmit={serviceTabForm.handleSubmit}>
        <div className="requestmodal-body">
          <h2 className="mb-5 text-center">Which services do you need?</h2>
          <div className="mb-5">
            <div className="row">
              {serviceListState.length > 0 &&
                serviceListState.map((item, index) => (
                  <div key={`service-${index}`} className="col-12 col-lg-6">
                    <div
                      tabIndex={0}
                      onClick={onSelectServiceHndlr}
                      data-id={item?.service_id}
                      className={classNames('service-option', {
                        'service-selected':
                          item.service_id == serviceTabForm.values.service_id,
                      })}
                    >
                      <span>{item?.service_name}</span>
                      <div className="service-option-radio">
                        <ImCheckmark className="check-icon text-white fw-bold" />
                      </div>
                    </div>
                  </div>
                ))}
              {serviceListState.length === 0 &&
                Array(5)
                  .fill(0)
                  .map((item, index) => (
                    <div key={`service-${index}`} className="col-12 col-lg-6">
                      <ContentLoader viewBox="0 0 380 65">
                        <rect
                          x="0"
                          y="0"
                          rx="5"
                          ry="5"
                          width="100%"
                          height="45"
                        />
                      </ContentLoader>
                    </div>
                  ))}
              {serviceTabForm.touched.service_id &&
                serviceTabForm.errors.service_id && (
                  <div className="col-12 d-flex justify-content-center">
                    <span className="custom-invalid-error">
                      {serviceTabForm.touched.service_id &&
                      serviceTabForm.errors.service_id
                        ? serviceTabForm.errors.service_id
                        : ''}
                    </span>
                  </div>
                )}
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <button
              className="sc-button style-4 text-dark"
              id="try-now"
              type="submit"
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export function TimelineTab({
  tabData,
  activeTabId,
  nextTabHndlr,
  backTabHndlr,
  closeModalHndlr,
}) {
  useEffect(() => {
    if (tabData) {
      timelineForm.resetForm({
        values: { ...descriptionInitialValue, ...tabData },
      });
    }
  }, [activeTabId, tabData]);

  const timelineForm = useFormik({
    initialValues: timelineInitialValue,
    // validationSchema: timelineValidationSchema,
    onSubmit: (values) => {
      nextTabHndlr(values);
    },
  });

  function backBtnHandlr() {
    backTabHndlr(timelineForm.values);
  }

  function onDateChangeHndlr(name, selectedDate, event) {
    timelineForm.setFieldValue(name, selectedDate);
  }
  return (
    <div className="tabs-container">
      <RequestHeader
        activeStepIndex={2}
        backBtnHndlr={backBtnHandlr}
        closeModalHndlr={closeModalHndlr}
      />

      <div className="requestmodal-body">
        <form onSubmit={timelineForm.handleSubmit}>
          <h2 className="mb-5 text-center">Do you have a timeline?</h2>
          <div className="mb-5">
            <div className="row flex-column align-items-center">
              <div className="col-12 col-lg-6 mb-25">
                <DatePicker
                  labelName="Start"
                  name="start_date"
                  helperLabel="Optional"
                  isOptional={true}
                  value={timelineForm.values.start_date}
                  // onBlur={timelineForm.handleBlur}
                  onChange={onDateChangeHndlr}
                  invalid={
                    timelineForm.touched.start_date &&
                    timelineForm.errors.start_date
                      ? true
                      : false
                  }
                  error={
                    timelineForm.touched.start_date &&
                    timelineForm.errors.start_date
                      ? timelineForm.errors.start_date
                      : ''
                  }
                />
                {/* <InputText
                                    labelName="Start"
                                    // labelClassName="fw-bold"
                                    name="start_date"
                                    helperLabel="Optional"
                                    isOptional={true}
                                    value={timelineForm.values.start_date}
                                    onBlur={timelineForm.handleBlur}
                                    onChange={timelineForm.handleChange}
                                    invalid={(timelineForm.touched.start_date && timelineForm.errors.start_date) ? true : false}
                                    error={(timelineForm.touched.start_date && timelineForm.errors.start_date) ? timelineForm.errors.start_date : ""}
                                /> */}
              </div>
              <div className="col-12 col-lg-6">
                <DatePicker
                  labelName="End"
                  name="end_date"
                  helperLabel="Optional"
                  isOptional={true}
                  value={timelineForm.values.end_date}
                  // onBlur={timelineForm.handleBlur}
                  onChange={onDateChangeHndlr}
                  invalid={
                    timelineForm.touched.end_date &&
                    timelineForm.errors.end_date
                      ? true
                      : false
                  }
                  error={
                    timelineForm.touched.end_date &&
                    timelineForm.errors.end_date
                      ? timelineForm.errors.end_date
                      : ''
                  }
                />
                {/* <InputText
                                    labelName="End"
                                    // labelClassName="fw-bold"
                                    name="end_date"
                                    helperLabel="Optional"
                                    isOptional={true}
                                    value={timelineForm.values.end_date}
                                    onBlur={timelineForm.handleBlur}
                                    onChange={timelineForm.handleChange}
                                    invalid={(timelineForm.touched.end_date && timelineForm.errors.end_date) ? true : false}
                                    error={(timelineForm.touched.end_date && timelineForm.errors.end_date) ? timelineForm.errors.end_date : ""}
                                /> */}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="sc-button style-4 text-dark"
              id="try-now"
              type="submit"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export function DescriptionTab({
  tabData,
  activeTabId,
  nextTabHndlr,
  backTabHndlr,
  closeModalHndlr,
}) {
  useEffect(() => {
    if (tabData) {
      descriptionForm.resetForm({
        values: { ...descriptionInitialValue, ...tabData },
      });
    }
  }, [activeTabId, tabData]);
  const descriptionForm = useFormik({
    initialValues: descriptionInitialValue,
    validationSchema: descriptionValidationSchema,
    onSubmit: (values) => {
      nextTabHndlr(values);
    },
  });

  function backBtnHandlr() {
    backTabHndlr(descriptionForm.values);
  }
  return (
    <div className="tabs-container">
      <RequestHeader
        activeStepIndex={3}
        backBtnHndlr={backBtnHandlr}
        closeModalHndlr={closeModalHndlr}
      />
      <form onSubmit={descriptionForm.handleSubmit}>
        <div className="requestmodal-body">
          <div className="mb-5">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-7 mb-5">
                <h2 className="text-center line-height-title">
                  Anything else to share about the project?
                </h2>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-6 mb-5">
                <InputTextArea
                  labelName="Description"
                  name="description"
                  value={descriptionForm.values.description}
                  onBlur={descriptionForm.handleBlur}
                  onChange={descriptionForm.handleChange}
                  invalid={
                    descriptionForm.touched.description &&
                    descriptionForm.errors.description
                      ? true
                      : false
                  }
                  error={
                    descriptionForm.touched.description &&
                    descriptionForm.errors.description
                      ? descriptionForm.errors.description
                      : ''
                  }
                />
              </div>
              <div className="col-12 d-flex justify-content-center">
                <button
                  className="sc-button style-4 text-dark mx-auto"
                  id="try-now"
                  type="submit"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export function ContactInformationTab({
  tabData,
  activeTabId,
  nextTabHndlr,
  backTabHndlr,
  isSubmitting,
  closeModalHndlr,
}) {
  useEffect(() => {
    if (tabData) {
      contactForm.resetForm({
        values: { ...descriptionInitialValue, ...tabData },
      });
    }
  }, [activeTabId, tabData]);

  const contactForm = useFormik({
    initialValues: projectInfoInitialValue,
    validationSchema: projectInfoValidationSchema,
    onSubmit: (values) => {
      nextTabHndlr(values);
    },
  });

  function backBtnHandlr() {
    backTabHndlr(contactForm.values);
  }

  return (
    <div className="tabs-container">
      <RequestHeader
        activeStepIndex={4}
        backBtnHndlr={backBtnHandlr}
        closeModalHndlr={closeModalHndlr}
      />
      <form onSubmit={contactForm.handleSubmit}>
        <div className="requestmodal-body">
          <div>
            <div className="row justify-content-center">
              <div className="col-12 col-lg-7 mb-5 text-center">
                <h2 className="line-height-title">Your Contact Details</h2>
              </div>
            </div>
            <div className="row">
              <div className="w-100"></div>
              <div className="col-12 col-lg-6 mb-25">
                <InputText
                  labelName="Your Name"
                  // labelClassName="fw-bold"
                  name="name"
                  value={contactForm.values.name}
                  onBlur={contactForm.handleBlur}
                  onChange={contactForm.handleChange}
                  invalid={
                    contactForm.touched.name && contactForm.errors.name
                      ? true
                      : false
                  }
                  error={
                    contactForm.touched.name && contactForm.errors.name
                      ? contactForm.errors.name
                      : ''
                  }
                />
              </div>
              <div className="col-12 col-lg-6 mb-25">
                <InputText
                  labelName="Company Name"
                  // labelClassName="fw-bold"
                  name="company_name"
                  isOptional={true}
                  value={contactForm.values.company_name}
                  onBlur={contactForm.handleBlur}
                  onChange={contactForm.handleChange}
                />
              </div>
              <div className="col-12 col-lg-6 mb-25">
                <InputText
                  labelName="Your Mobile Number"
                  // labelClassName="fw-bold"
                  name="mobile_number"
                  helperLabel="Optional"
                  value={contactForm.values.mobile_number}
                  onBlur={contactForm.handleBlur}
                  onChange={contactForm.handleChange}
                  invalid={
                    contactForm.touched.mobile_number &&
                    contactForm.errors.mobile_number
                      ? true
                      : false
                  }
                  error={
                    contactForm.touched.mobile_number &&
                    contactForm.errors.mobile_number
                      ? contactForm.errors.mobile_number
                      : ''
                  }
                />
              </div>
              <div className="col-12 col-lg-6 mb-25">
                <InputText
                  labelName="Your Email"
                  // labelClassName="fw-bold"
                  name="email"
                  value={contactForm.values.email}
                  onBlur={contactForm.handleBlur}
                  onChange={contactForm.handleChange}
                  invalid={
                    contactForm.touched.email && contactForm.errors.email
                      ? true
                      : false
                  }
                  error={
                    contactForm.touched.email && contactForm.errors.email
                      ? contactForm.errors.email
                      : ''
                  }
                />
              </div>
              <div className="col-12 d-flex justify-content-center mb-5">
                <InputCheckbox
                  name="terms_condition"
                  checked={contactForm.values.terms_condition}
                  onChange={contactForm.handleChange}
                  invalid={
                    contactForm.touched.terms_condition &&
                    contactForm.errors.terms_condition
                      ? true
                      : false
                  }
                  error={
                    contactForm.touched.terms_condition &&
                    contactForm.errors.terms_condition
                      ? contactForm.errors.terms_condition
                      : ''
                  }
                >
                  You must agree with our
                  <Link to="/terms-of-use" target="_blank">
                    <span className="text-custom-primary"> Terms of Use</span>
                  </Link>
                  <span style={{ color: '#666666' }}> and </span>
                  <Link to="/privacy-policy" target="_blank">
                    <span className="text-custom-primary">Privacy Policy</span>
                  </Link>
                </InputCheckbox>
              </div>

              <div className="col-12 d-flex justify-content-center">
                <button
                  className="sc-button style-4 text-dark mx-auto"
                  id="try-now"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Bars color="#ffffff" height={30} width={80} />
                  ) : (
                    'Submit'
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export function ThankyouTab({ closeModalHndlr }) {
  return (
    <div className="tabs-container">
      <div className="thankyoutab-body requestmodal-body">
        <span className="font-16 mb-5">Confirmation</span>
        <h2 className="mb-4">Thanks for your request.</h2>
        <span className="mb-5 info">
          We will be in touch with you as soon as possible.
        </span>
        <button
          className="custom-text-button btn-primary"
          onClick={closeModalHndlr}
        >
          Close
        </button>
      </div>
    </div>
  );
}

import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { IoGridOutline, IoAddOutline, IoIosImages } from '../../icons/Index';
import { instagramPost } from '../../../assets/Index';
import { useStoreState } from 'easy-peasy';
import loadable from '@loadable/component';
import { openToasterAlert } from '../../../components/alert/Index';
import '../../../popup.css';
const Image = loadable(() => import('../../Image/index'));
const NftDetails = loadable(() => import('../../models/NftDetails'));
const ImageSlider = loadable(() => import('../../models/ImageSlider'));
const LoginSignUp = loadable(() =>
  import('../../../components/models/LoginSignUp')
);
const NewPopModel = loadable(() =>
  import('../../../components/models/NewPopModel')
);

const ProfileNftItem = (props) => {
  const history = useNavigate();
  const data = props.data;
  const isCheck = props.isCheck && props.isCheck ? props.isCheck : false;
  const loaded = props.showanim;
  const [visible, setVisible] = useState(8);
  const [dataNew, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState(true);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 8);
  };
  const authorization = useStoreState((actions) => actions.authorization);
  const [modalShowDetails, setModalShowDetails] = useState(false);
  const [modalShowNewPopUp, setmodalShowNewPopUp] = useState(false);
  const [selected, setSelected] = useState(null);
  const [openLighBox, setLighBox] = useState(false);
  const [clickItem, setclickItem] = useState({});
  const [checkEmptyIndex, setEmptyIndex] = useState('');
  const [showModal, setModalShow] = useState(false);
  const handelGetBack = (v) => {
    setLighBox(v);
  };
  useEffect(() => {
    seeeArr();
  }, [data]);

  const seeeArr = async () => {
    let newArr = {};
    let emptyIndex = checkEmptyIndex;
    data.forEach((element) => {
      newArr[element.order] = element;
    });
    for (let index = 0; index <= 7; index++) {
      if (newArr[index] == undefined) {
        if (emptyIndex == '') {
          emptyIndex = index;

          setEmptyIndex(emptyIndex);
        }
        newArr[index] = {};
      }
    }
    setData(newArr);
  };

  const shortUi = (value) => {
    setSort(value);
  };

  return (
    <Fragment>
      <section className="mt-4 sc-explore-1 profileNft">
        <div className="themesflat-container">
          {data.length == 0 && loaded == false ? (
            <div className="row">{props.instagramLoader}</div>
          ) : (
            ''
          )}
          {openLighBox ? (
            <ImageSlider
              images={data}
              openLighBox={openLighBox}
              handelGetBack={handelGetBack}
              type={true}
            />
          ) : null}
          <div className="row">
            <div className="col-md-12">
              <div className="row mg-bt-21">
                {props.data.length > 0 && isCheck == true ? (
                  <div className="col-sm-12 col-12 mb-3 mb-sm-0 text-right text-sm-left">
                    <Link
                      to={checkEmptyIndex == '' ? '#' : '/post'}
                      state={{
                        order: checkEmptyIndex,
                        selected: null,
                      }}
                    >
                      <button
                        aria-label="toster"
                        className="sc-button style-4 text-dark"
                        onClick={() => {
                          if (checkEmptyIndex == '') {
                            openToasterAlert(
                              'error',
                              'All slots on your profile are full. Replace an existing post to add a new post.'
                            );
                          }
                        }}
                      >
                        <IoAddOutline
                          style={{
                            fontSize: '2rem',
                            verticalAlign: 'sub',
                            fontWeight: '600',
                          }}
                        />{' '}
                        Add Post
                      </button>
                    </Link>
                  </div>
                ) : (
                  ''
                )}
                <div className="col-6">
                  <div className="sorting2 d-block d-sm-none ">
                    <IoGridOutline
                      style={{
                        color: sort ? '#f6c946' : '#ffffff',
                        fontSize: sort ? '2.2rem' : '18px',
                      }}
                      onClick={() => shortUi(true)}
                    />
                    <IoIosImages
                      style={{
                        color: sort ? '#ffffff' : '#f6c946',
                        fontSize: sort ? '18px' : '2.2rem',
                      }}
                      onClick={() => shortUi(false)}
                    />
                  </div>
                </div>
                <div
                  className={`heading-live-auctions-2 mt-5 ${
                    isCheck ? 'col-6' : 'col-sm-12 col-6'
                  }`}
                >
                  {data.length > 0 ? (
                    <Link
                      to="#"
                      onClick={() => setLighBox(true)}
                      className="exp style2 text-uppercase"
                    >
                      Slide Show
                    </Link>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>

            {props.data.length <= 0 && isCheck == true ? (
              <div className="col-md-12 mb-5">
                <Link
                  to={authorization ? '/post' : '#'}
                  onClick={() => {
                    if (authorization) {
                      setModalShow(false);
                    } else {
                      setModalShow(true);
                    }
                  }}
                  state={{
                    order: 0,
                    selected: null,
                  }}
                >
                  <button
                    className="sc-button style-4 text-dark"
                    id="create-new-post"
                    aria-label="post"
                  >
                    <IoAddOutline
                      style={{
                        fontSize: '2rem',
                        verticalAlign: 'sub',
                        fontWeight: '600',
                      }}
                    />{' '}
                    Create First Post
                  </button>
                </Link>
              </div>
            ) : (
              ''
            )}
            {isCheck
              ? Object.entries(dataNew).map(([index, item]) =>
                  item && item.caption == undefined ? (
                    <div
                      key={index}
                      className={`px-2 px-sm-3 fl-item col-xl-3 col-lg-4 col-md-4 col-sm-4 col-${
                        sort ? 6 : 4
                      }`}
                    >
                      <div className={`sc-card-product`}>
                        <div className="card-media mb-0">
                          <Link
                            to={authorization ? '/post' : '#'}
                            onClick={() => {
                              if (authorization) {
                                setModalShow(false);
                              } else {
                                setModalShow(true);
                              }
                            }}
                            state={{ order: index, selected: null }}
                          >
                            <img
                              src={instagramPost}
                              alt="Add your NFTs to manage your portfolio and estimated real-time portfolio value."
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : sort ? (
                    <div
                      key={index}
                      className={`px-2 px-sm-3 fl-item col-xl-3 col-lg-4 col-md-4 col-sm-4 col-${
                        sort ? 6 : 4
                      }`}
                    >
                      <div className={`sc-card-product`}>
                        <div className="card-media">
                          <Link to="#">
                            <img
                              onClick={() => {
                                setModalShowDetails(true);
                                setSelected(item);
                                setOpen(true);
                              }}
                              src={item.image}
                              alt={item.name}
                            />
                          </Link>
                          {isCheck ? (
                            <div className="button-place-bid">
                              <button
                                className="sc-button style-4"
                                aria-label="edit"
                                onClick={() => {
                                  history(`/post`, {
                                    state: {
                                      order: item.order,
                                      selected: item,
                                    },
                                  });
                                }}
                              >
                                <span>Edit</span>
                              </button>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>

                        <div
                          className="card-title"
                          onClick={() => {
                            setModalShowDetails(true);
                            setSelected(item);
                            setOpen(true);
                          }}
                        >
                          <h5 className="style2">
                            <Link to="#">{item.name}</Link>
                          </h5>
                        </div>
                        <div className="meta-info">
                          <div className="author">
                            <div className="info">
                              <span className="text-nowrap">
                                Collection Name
                              </span>
                              <h6 className="text-nowrap">
                                {item.collection_name != 'null'
                                  ? item.collection_name
                                  : '-'}
                              </h6>
                            </div>
                          </div>
                          <div className="price">
                            <span className="d-none d-sm-block">
                              Floor Price
                            </span>
                            <span className="d-block d-sm-none">FP</span>
                            <h6>
                              {' '}
                              {item.collection && item.collection.floor_price
                                ? item.collection.floor_price
                                : '-'}
                            </h6>
                          </div>
                        </div>
                        <div className="div">
                          <div className="info">
                            <span
                              style={{
                                color: '#8a8aa0',
                                fontSize: '13px',
                                lineHeight: '21px',
                              }}
                            >
                              Description
                            </span>
                          </div>
                          <h6
                            className="mt-2 text-nowrap"
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {item.caption}
                          </h6>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={index}
                      className="col-xl-3 col-lg-4 col-md-6 col-4 mb-4 px-2 px-sm-3 newImageList"
                    >
                      <div
                        onClick={() => {
                          setclickItem(item);
                          setmodalShowNewPopUp(true);
                        }}
                      >
                        <img src={item.image} alt={item.name} />
                      </div>
                    </div>
                  )
                )
              : sort
              ? //////////////////For User View
                data.slice(0, visible).map((item, index) => (
                  <div
                    key={index}
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 px-2 px-sm-3"
                    onClick={() => {
                      setSelected(item);
                      setModalShowDetails(true);
                    }}
                  >
                    <div className={`sc-card-product`}>
                      <div className="card-media">
                        <Link to="#">
                          <img src={item.image} alt={item.name} />
                        </Link>
                      </div>
                      <div className="card-title">
                        <h5 className="style2">
                          <Link to="#">{item.name}</Link>
                        </h5>
                      </div>
                      <div className="meta-info">
                        <div className="author">
                          <div className="info">
                            <span className="text-nowrap">Collection Name</span>
                            <h6 className="text-nowrap">
                              {item.collection_name != 'null'
                                ? item.collection_name
                                : '-'}
                            </h6>
                          </div>
                        </div>
                        <div className="price">
                          <span className="d-none d-sm-block">Floor Price</span>
                          <span className="d-block d-sm-none">FP</span>
                          <h6>
                            {item.collection && item.collection.floor_price
                              ? item.collection.floor_price
                              : '-'}
                          </h6>
                        </div>
                      </div>
                      <div className="div">
                        <div className="info">
                          <span
                            style={{
                              color: '#8a8aa0',
                              fontSize: '13px',
                              lineHeight: '21px',
                            }}
                          >
                            Description
                          </span>
                        </div>
                        <h6
                          className="mt-2"
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {' '}
                          {item.name}
                        </h6>
                      </div>
                    </div>
                  </div>
                ))
              : data.slice(0, visible).map((item, index) => (
                  <div
                    key={index}
                    className="col-xl-3 col-lg-4 col-md-6 col-4 mb-4 px-2 px-sm-3 newImageList"
                    onClick={() => {
                      setSelected(item);
                      setModalShowDetails(true);
                    }}
                  >
                    <Link
                      to="#
                "
                      className="pb-5"
                    >
                      <Image
                        image={{
                          src: item.image,
                          height: '100%',
                          width: '100%',
                          alt: 'Nft',
                        }}
                      />
                    </Link>
                  </div>
                ))}

            {visible < data.length && (
              <div className="col-md-12 wrap-inner load-more text-center">
                <Link
                  to="#"
                  id="load-more"
                  className="sc-button loadmore fl-button pri-3"
                  onClick={showMoreItems}
                >
                  <span>Load More</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </section>

      <NftDetails
        show={modalShowDetails}
        onHide={() => setModalShowDetails(false)}
        selected={selected}
        actulaNFT={false}
      />
      <NewPopModel
        show={modalShowNewPopUp}
        onHide={() => setmodalShowNewPopUp(false)}
        clickActionView={() => {
          setModalShowDetails(true);
          setSelected(clickItem);
          setOpen(true);
          setmodalShowNewPopUp(false);
        }}
        clickActionEdit={(e) => {
          history('/post', {
            state: { order: clickItem.order, selected: clickItem },
          });
        }}
        name={clickItem.caption}
      />
      <LoginSignUp
        show={showModal}
        onHide={() => setModalShow(false)}
        text="Sign up or sign in to manage your Profile."
        url={true}
      />
    </Fragment>
  );
};

ProfileNftItem.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ProfileNftItem;

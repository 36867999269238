import React from "react";
import classNames from "classnames";
import { getTwoDigitValue } from "../../utility/commonUtitlity";

export default function Tabs({ tabOptions, containerClassName, activeTabId, tabChangeHndlr }) {
    if (!Array.isArray(tabOptions)) {
        return null;
    }
    function tabClickHandler(event) {
        const tabId = event.currentTarget.getAttribute("data-id");
        tabChangeHndlr(tabId);
    }
    return (
        <div className={classNames("blocktabs-container", containerClassName)}>
            <div className={classNames("taboptions-wrapper")}>
                {
                    tabOptions.map((item, index) => (
                        <div key={item.id}
                            data-id={item.id}
                            onClick={tabClickHandler}
                            className={classNames("tab-option", (activeTabId === item.id ? "active" : ""),
                                (item.count > 0 ? "count-exist" : ""))}>
                            {item.name}
                            {(item.count > 0) && <span className="count-label fw-bold">
                                ({getTwoDigitValue(item.count)})
                            </span>}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
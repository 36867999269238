import React, { useState, useEffect } from 'react';
import { apicallback, openUrl } from '../../../callbacks/index';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Countdown from 'react-countdown';
import Fullwidth from '../../../components/Image/fullwidth';
import { imgCube } from '../../../assets/Index';
import PageTitle from '../../../components/pagetitle/PageTitle';
import { useStoreState } from 'easy-peasy';
import { IoArrowBackSharp } from '../../../components/icons/Index';
import moment from 'moment';
const ItemDetails01 = () => {
  const navigate = useNavigate();
  const authorization = useStoreState((actions) => actions.authorization);
  const [dataBox, setAnnouncement] = useState({});
  const params = useParams();
  useEffect(async () => {
    await getAnnouncement();
  }, []);
  const getAnnouncement = async () => {
    const response = await apicallback(
      null,
      '/getAnnouncementsbyId',
      {
        announcement_id: params.id,
      },
      'private',
      authorization && authorization.token
    );
    if (response && response.status == 'success') {
      setAnnouncement(response.data);
    }
  };
  return (
    <div className="item-details">
      <PageTitle
        meta={{
          title: dataBox ? dataBox.title : '',
          description: dataBox.description,
          image: dataBox ? dataBox.image : '',
        }}
      />

      <div className="tf-section tf-item-details">
        <div className="themesflat-container">
          <div className="mx-auto mb-4">
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="h4 d-flex"
            >
              <IoArrowBackSharp className="mr-2" /> Go back
            </Link>
          </div>
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-left">
                <div className="media">
                  <Fullwidth
                    image={{
                      src: dataBox ? dataBox.image : '',
                      height: '100%',
                      width: '100%',
                      alt: dataBox ? dataBox.title : '',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <h2 className="style2">{dataBox.title} </h2>
                  <div className="meta-item"></div>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img src={imgCube} alt={dataBox.title} />
                        </div>
                        <div className="info">
                          <span>Release Date</span>
                          <h6>
                            {' '}
                            <Link to="/author-02">
                              {dataBox.release_date}
                            </Link>{' '}
                          </h6>
                        </div>
                      </div>
                    </div>
                    {dataBox.author ? (
                      <div className="meta-info">
                        <div className="author">
                          <div className="avatar">
                            <img src={imgCube} alt={dataBox.author} />
                          </div>
                          <div className="info">
                            <span>Author</span>
                            <h6>
                              <Link to="#">{dataBox.author}</Link>{' '}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <p>{dataBox.description}</p>
                  <div className="meta-item-details style2">
                    {moment(parseInt(dataBox.epoch_time)).isSameOrAfter(
                      moment()
                    ) ? (
                      <div className="item count-down">
                        <span className="heading style-2">Countdown</span>
                        <Countdown
                          date={new Date(parseInt(dataBox.epoch_time))}
                        >
                          <Link to="#" onClick={() => openUrl(dataBox.url)}>
                            Launch Details
                          </Link>
                        </Countdown>
                      </div>
                    ) : (
                      <button
                        className="sc-button style-4"
                        onClick={() => openUrl(dataBox.url)}
                      >
                        Launch Details
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails01;

import {
    noImageFound,
} from '../../../assets/Index';
import {
    condenseAddress,
} from '../../../callbacks/index';
import {
    document_copy_img,
} from '../../../assets/Index';


function replaceImage(error) {
    error.target.src = noImageFound;
}

function WalletInfo({ imageUrl, title, subTitle }) {
    return (
        <div className="asset-details ">
            <img
                src={imageUrl || noImageFound}
                className="avatar-img"
            />
            <div className="d-flex flex-column overflow-hidden">
                <span title={title} className="mb-0 fw-bold title">
                    {title}
                </span>
                <span className="subtitle fw-bold">{subTitle}</span>
            </div>
        </div>
    );
}

export function generateColumns(copyClipboard) {
    return [
        {
            id: 'id',
            Header: '#',
            accessor: 'id',
            disableSortBy: true,
            Cell: ({ row: { index } }) => {
                return <span>{index + 1}</span>;
            },
        },
        {
            id: 'collection_name',
            Header: 'Collection',
            accessor: "collection_name",
            columnClassName: 'w-300px min-w-300px',
            Cell: ({ row: { original } }) => {
                return (
                    <WalletInfo
                        imageUrl={original?.collection_image_url}
                        title={original.collection_name}
                        subTitle={original.collection_symbol}
                    />
                );
            },
        },
        {
            id: 'contract_circulating_supply',
            Header: 'Supply',
            accessor: 'contract_circulating_supply',
            disableSortBy: true,
        },
        {
            id: 'contract_standard',
            Header: 'Standard',
            accessor: 'contract_standard',
            disableSortBy: true
        },
        {
            id: 'fromAddress',
            Header: 'Address',
            accessor: 'collection_address',
            disableSortBy: true,
            Cell: ({ value }) => {
                return <div className='d-flex align-items-center' data-id={value} onClick={copyClipboard}>
                    <span className='mr-1'>{condenseAddress(value)}</span>
                    <img
                        src={document_copy_img}
                        style={{ width: '20px', height: '20px', maxWidth: 'initial' }}
                    />
                </div>;
            },
        },
        {
            id: 'count',
            Header: 'Holdings',
            accessor: 'count',
            disableSortBy: true
        },
    ];
}

import bannerAVAX from '../assets/images/chain_banners/AVAX.png';
import bannerETH from '../assets/images/chain_banners/ETH.png';
import bannerMatic from '../assets/images/chain_banners/Matic.png';
import bannerBTC from '../assets/images/chain_banners/BTC.png';
import bannerSolona from '../assets/images/chain_banners/Solona.png';
import baseSolona from '../assets/images/chain_banners/Base.png';

export function getTrimmedWalletAddress(address) {
  if (address) {
    const startString = address.substring(0, 6),
      endString = address.substr(-4);
    return startString + '...' + endString;
  }
  return '-';
}

export function getValueWithDecimal(value, size = 2) {
  if (!isNaN(value)) {
    return Number(value).toFixed(size);
  }
  return Number(0).toFixed(size);
}
export function getValueWithDecimalLocalString(value, size = 2) {
  let v;
  if (!isNaN(value)) {
    v = parseInt(value);
  }
  return v.toLocaleString();
}

export function getValueWithThreeDecimal(value) {
  if (!isNaN(value)) {
    return Number(value).toFixed(3);
  }
  return Number(0).toFixed(3);
}

export function getValueWithFourDecimal(value) {
  if (!isNaN(value)) {
    return Number(value).toFixed(4);
  }
  return Number(0).toFixed(4);
}

export function getRoundedValue(value) {
  if (!isNaN(value)) {
    return Math.round(value).toString().padStart(2, 0);
  }
  return '00';
}

export function getWalletBalanceValue(value) {
  if (!value) {
    return Number(0).toFixed(3);
  }
  const balance = value && value.toString().replace(/n/g, '');
  if (isNaN(balance)) {
    return Number(0).toFixed(3);
  }
  return Number(Number(balance) / 1e18).toFixed(3);
}

export function getPriceValue(value, roundValue = 2) {
  if (!value) {
    return Number(0).toFixed(roundValue);
  }
  const balance = value && value.toString().replace(/n/g, '');
  if (isNaN(balance)) {
    return Number(0).toFixed(roundValue);
  }
  return Number(Number(balance) / 1e18).toFixed(roundValue);
}

export function getTwoDigitValue(inputValue) {
  if (!inputValue) {
    return '00';
  }
  return inputValue.toString().padStart(2, 0);
}

export const debounce = (fn, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

export function getDisplayValueWithDash(value) {
  return value || '-';
}

export function getDisplayValueWithNA(value) {
  return value || 'N/A';
}

export function getDisplayAmountClassName(value) {
  const numberValue = isNaN(value) ? 0 : Number(value);
  if (numberValue === 0) {
    return 'text-white';
  } else if (numberValue > 0) {
    return 'text-success-dark';
  } else {
    return 'text-danger';
  }
}

export function displayAmountWithProfitLossIcon(value) {
  const amountValue = getValueWithDecimal(value),
    numAmountValue = Number(amountValue);
  if (numAmountValue === 0) {
    return numAmountValue;
  } else if (numAmountValue > 0) {
    return `+${Math.abs(numAmountValue).toFixed(2)}`;
  } else {
    return `-${Math.abs(numAmountValue).toFixed(2)}`;
  }
}

export function getTransactionClassName(status) {
  const updatedStatus = status
    ? status
        .toString()
        .toLowerCase()
        .replace(/[\(\)\s]/g, '')
    : '';
  switch (updatedStatus) {
    case 'burn':
      return 'burn';
    case 'transferin':
      return 'transferin';
    case 'transferout':
      return 'transferout';
    case 'salebought':
      return 'salebought';
    case 'salesold':
      return 'salesold';
    case 'swap':
      return 'swap';
    case 'mint':
      return 'mint';
    default:
      return '';
  }
}

export function getTransactionStatus(
  status,
  walletAddress,
  fromAddress,
  toAddress
) {
  const updatedStatus = status
    ? status.toString().toLowerCase().replace(/\s/g, '')
    : '';
  if (updatedStatus === 'transfer') {
    return walletAddress === toAddress ? 'TRANSFER IN' : 'TRANSFER OUT';
  } else if (updatedStatus === 'sale') {
    return walletAddress === fromAddress ? 'SALE (SOLD)' : 'SALE (BOUGHT)';
  } else {
    return status;
  }
}

export function getNumberWithComma(value) {
  if (isNaN(value)) {
    return value;
  }
  return Math.round(value).toLocaleString();
}

export function getAllTransactionValue(value) {
  if (isNaN(value)) {
    return value;
  }
  const updateValue = Number(value) / 1e18;
  const [beforeDecimal, afterDecimal] = updateValue.toString().split('.');
  let decimalString = '',
    numberLastIndex = 0;
  for (let i = 0; afterDecimal && i < afterDecimal.length; i++) {
    if (i === 6) {
      break;
    }
    if (afterDecimal[i] === '0') {
      decimalString += afterDecimal[i];
    } else {
      decimalString += afterDecimal[i];
      numberLastIndex = i;
    }
  }

  const updatedDecimalString = decimalString.substring(0, numberLastIndex + 1);
  if (Number(updatedDecimalString)) {
    return `${beforeDecimal}.${updatedDecimalString}`;
  } else {
    return beforeDecimal;
  }
}

export function extractContentFromHtmlString(htmlString) {
  if (!htmlString) {
    return '-';
  }
  var parser = new DOMParser();
  var doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.textContent;
}

export function collectionChains(value = null) {
  const chain = {
    1: 'ETH COLLECTIONS',
    2: 'MATIC COLLECTIONS',
    3: 'BTC COLLECTIONS',
    4: 'SOL COLLECTIONS',
    5: 'AVAX COLLECTIONS',
    6: 'BASE COLLECTIONS',
  };
  return value ? chain[value] : chain;
}
export function chainList(value = null) {
  const chains = {
    1: 'ETH',
    2: 'MATIC',
    3: 'BTC',
    4: 'SOL',
    5: 'AVAX',
    6: 'BASE',
  };
  return value ? (value == 6 ? 'ETH' : chains[value]) : chains;
}
export function chainListById(value = null) {
  const chains = {
    ETH: 1,
    MATIC: 2,
    BTC: 3,
    SOL: 4,
    AVAX: 5,
    BASE: 6,
  };
  return value ? chains[value] : chains;
}

export function chainBackgroundList(value = null) {
  const chains = {
    1: bannerETH,
    2: bannerMatic,
    3: bannerBTC,
    4: bannerSolona,
    5: bannerAVAX,
    6: baseSolona,
  };
  return value ? chains[value] : chains;
}
export function chainLinkList(value = null) {
  const chainLink = {
    1: 'https://etherscan.io/tx/',
    2: 'https://polygonscan.com/tx/',
    3: 'https://btcscan.org/tx/',
    4: 'https://solscan.io/tx/',
    5: 'https://avascan.info/blockchain/shrapnel/tx/',
    6: 'https://basescan.org/tx/',
  };
  return value ? chainLink[value] : chainLink;
}
